<template>
    <div id="listaModal" :class="`col tableFixHead ${showTable ? 'table-full' : ''}`" v-if="Notas.length >= 1" :style="{'--height':height}">
        <table>
            <thead>
                <tr>
                    <th class="text-center" >Ação</th>
                    <th style="color: blue; text-align: center;">Nº Digitação</th>
                    <th class="text-left">Nº Venda</th>
					<th class="text-center text-nowrap">Emissão</th>
                    <th class="text-center text-nowrap">CFOP</th>
					<th class="text-center text-nowrap">Cliente</th>
                    <th class="text-center text-nowrap">Valor</th>
                    <th class="text-center text-nowrap">Frete</th>
                    <th class="text-center text-nowrap">Desconto</th>
                    <th class="text-center text-nowrap">Valor da Nota</th>
                    <th class="text-center text-nowrap">Base ICMS</th>
                    <th class="text-center text-nowrap">Valor ICMS</th>
				</tr>
            </thead>
            <tbody>
                <tr class="itemLista" v-for="(nota, index) in Notas" :key="index">
                    <td class="center text-nowrap">
						<div class="tooltip">
							<span class="tooltiptext">Carta de Correção</span>
							<i class="far fa-envelope" style="color: var(--confirmar)" @click="$emit('iniciaCartaCorrecao', nota)"></i>
                        </div>
						<!-- <div class="tooltip">
							<span class="tooltiptext">Visualizar</span>
							<i class="fas fa-eye" style="color: var(--confirmar)" @click="selecionarNota(nota)"></i>
						</div> -->
						<!-- <div class="tooltip">
							<span class="tooltiptext">Cancelamento</span>
							<i class="fas fa-times" style="color: var(--cancelar-borda)"></i>
						</div> -->
					</td>
					<td style="color: blue; text-align: center;" class="text-nowrap" @click="selecionarNota(nota)">
                        <button :id="index" class="hand-button" v-if="index == indexNota" @keydown.tab.prevent="" > 
                            <i class="fas fa-hand-point-right" style="color: var(--primario)" >
                            </i>
                        </button>
                        {{nota.cdVenda}}
                    </td>
                    <td class="text-left text-nowrap" @click="selecionarNota(nota)">{{"N/C"}}</td>
                    <td class="text-nowrap" @click="selecionarNota(nota)">{{new Date(`${nota.dataEmissao}`).toLocaleString("pt-BR").split(",")[0]}}</td>
                    <td class="text-left text-nowrap" @click="selecionarNota(nota)">{{"N/C"}}</td>
                    <td class="text-left text-nowrap campo-nome"  @click="selecionarNota(nota)"><span>{{nota.cliente.id+" - "+nota.nomeCliente}}</span></td>
					<td class="text-nowrap" @click="selecionarNota(nota)">{{nota.valorBruto.toLocaleString("pt-BR", {style: "currency", currency: "BRL" })}}</td>
					<td class="text-nowrap" @click="selecionarNota(nota)">{{nota.valorfrete.toLocaleString("pt-BR", {style: "currency", currency: "BRL" })}}</td>
					<td class="text-nowrap" @click="selecionarNota(nota)">{{nota.descontoMoeda.toLocaleString("pt-BR", {style: "currency", currency: "BRL" })}}</td>
					<td class="text-nowrap" @click="selecionarNota(nota)">{{"N/C"}}</td>
					<td class="text-nowrap" @click="selecionarNota(nota)">{{"N/C"}}</td>
					<td class="text-nowrap" @click="selecionarNota(nota)">{{"N/C"}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-else :class="`col center tableFixHead ${showTable ? 'table-full' : ''}`">
        <i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
        <h1>Nenhum registro encontrado.</h1>
        <h2>Tente alterar ou diminiur os parâmetros de busca.</h2>
    </div>
</template>

<script>
export default {
	name: "TabelaNotas",

	props: {
		height: String,
		showTable: Boolean,
	},

	computed: {
		Notas() {
			return this.$store.state.VendasFiltradas;
		}
	},

	data() {
		return {
			indexNota: 0,
		};
	},

	methods: {
		selecionarNota(pessoa){
			if(pessoa.cdcliente) {
				pessoa.id = pessoa.cdcliente;
			}
			return this.$emit("selecionarNota", pessoa);
		},
		eventosTeclado(e) {
			if (this.$store.state.mensagemErro || this.$store.state.carregando) {
				return;
			}
			if (e.key == "ArrowUp") {
				if (this.indexNota > 0) {
					this.indexNota--;
				}
				return setTimeout(() => {
					document.getElementById(this.indexNota).focus();
				}, 10);
			}
			if (e.key == "ArrowDown") {
				if (this.indexNota < this.Notas.length -1) {
					this.indexNota++;
				}
				return setTimeout(() => {
					document.getElementById(this.indexNota).focus();
				}, 10);
			}
			if (e.key == "Enter") {
				e.preventDefault();
				return this.selecionarNota(this.Notas[this.indexNota]);
			}
			if (e.key == "Tab") {
				this.indexNota = 0;
				return setTimeout(() => {
					document.getElementById("textoPesquisa")?.focus();
				}, 80);
			}
		}
	},

	mounted(){
		document.addEventListener("keydown", this.eventosTeclado);
		const elemento = document.getElementById("textoPesquisa");
		if(elemento){
			elemento.addEventListener("focus", () => {
				this.indexNota = 0;
			});
		}
	},

	destroyed() {
		document.removeEventListener("keydown",this.eventosTeclado);
		this.$store.commit(`atualizarLista${this.tipoConteudo}`, {content: []});
	}
};
</script>

<style scoped>
.campo-nome {
	display: flex;
	flex-direction: column;
}

#listaModal.table-full{
	height: 78vh !important;
}

#listaModal {
    margin: 0 auto;
    font-size: var(--txt-normal);
	height: var(--height);
}
.fa-arrow-up, .fa-arrow-down {
	color: var(--primario);
}

.ordered {
	cursor: pointer;
}


label {
    cursor: pointer;
}

.hand-button {
    background: none;
    border: none;
    outline: none;
    clear: both;
}

thead td:nth-child(1),
tbody td:nth-child(1) {
    width: 100px;
}

.tableFixHead {
	overflow-y: auto;
	height: 60vh;
	width: 98%;
}

.tableFixHead thead th {
	position: sticky; 
	top: 0px;
	font-weight: normal;
}

.dependente{
	color: #f00;
	font-weight: bold;
	font-size: 13px;
	line-height: 20px;
}

@media screen and (max-width: 1024px) {
	.tableFixHead {
		height: 72vh;
	} 
}

@media screen and (max-width: 768px) {
	.tableFixHead {
		height: 46vh;
	}

	#listaModal.table-full{
		height: 67vh !important;
	}

	#listaModal{
		height: 45vh;
	}
}

@media screen and (max-width: 425px) {
	

	#listaModal.table-full{
		height: 71vh !important;
	}

	#listaModal{
		height: 43vh;
	}
}
</style>