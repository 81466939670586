<template>
	<div class="cadastro-produto-aba">
		<div class="cadastro-produto-cabecalho" v-if="PropProduto">
			<h3 style="color: var(--primario);">
				{{PropProduto ? PropProduto.id : ""}}
				&nbsp; - &nbsp;
				{{PropProduto ? PropProduto.descricao : ""}}
			</h3>
		</div>
		<div class="cadastro-produto-lancamento">
			<aside class="lancamento-custo">
				<div class=tabela-empresas>
					<table class="lancamento-custo-empresa">
						<thead>
							<tr>
								<td style="color: blue; text-align: center;">Cód.</td>
								<td style="text-align: left;">Empresa</td>
								<td style="text-align: center;">Cadastrar</td>
								<td style="text-align: center;">Favoritar</td>
							</tr>
						</thead>
						<tbody>
							<tr class="itemLista" v-for="(empresa, index) in Empresas" :key="index">
								<td style="color: blue; text-align: center;" class="text-nowrap" @click="adicionarEmpresaFormaPreco(index)">
									<button :id="index" class="hand-button" v-if="index == indexAtual" @keydown.tab.prevent="" > 
										<i class="fas fa-hand-point-right" style="color: var(--primario)" >
										</i>
									</button>
									{{empresa.id}}
								</td>
								<td style="text-align: left;" class="text-nowrap" @click="adicionarEmpresaFormaPreco(index)">{{empresa.nome}}</td>
								<td style="text-align: center;" class="text-nowrap">
									<input type="checkbox" class="pointer" :id="empresa.id" :value="true" v-model="empresa.formaPreco" 
									@change="adicionarEmpresaFormaPreco(index, true)">
								</td>
								<td style="text-align: center;" class="text-nowrap">
									<input type="checkbox" class="pointer" :id="empresa.id" :value="true" v-model="empresa.favorito" 
									:disabled="!empresa.formaPreco">
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="forma-custo-container">
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Formação de custo &nbsp;
						</div>
						<div class="forma-custo-item">
							Último
						</div>
						<div class="forma-custo-item">
							Médio
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Custo: &nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								id="custo"
								type="text"
								v-model="custo"
								@input="calcusto"
								@keydown="mascara"
								@keydown.enter="proximoCampo('markup')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
						<div class="forma-custo-item">
							<input
								type="text"
								id="customedio"
								v-model="customedio"
								@input="calcusto"
								@keydown="mascara"
								@keydown.enter="proximoCampo('ipi')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Descrição &nbsp;
						</div>
						<div class="forma-custo-item">
							Percentual
						</div>
						<div class="forma-custo-item">
							Valor
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Valor IPI: [+]&nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								id="ipi"
								type="text"
								v-model="ipi"
								@input="calcusto"
								@keydown="mascara"
								@keydown.enter="proximoCampo('valoripi')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
						<div class="forma-custo-item">
							<input
								type="text"
								id="valoripi"
								v-model="valoripi"
								@input="calcusto"
								@keydown="mascara"
								@keydown.enter="proximoCampo('pis')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Valor PIS: [=]&nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								type="text"
								id="pis"
								v-model="pis"
								@input="calcusto"
								@keydown="mascara"
								@keydown.enter="proximoCampo('valorpis')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
						<div class="forma-custo-item">
							<input
								v-model="valorpis"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="valorpis"
								@keydown.enter="proximoCampo('cofins')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Valor Cofins: [=]&nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								v-model="cofins"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="cofins"
								@keydown.enter="proximoCampo('valorcofins')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
						<div class="forma-custo-item">
							<input
								v-model="valorcofins"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="valorcofins"
								@keydown.enter="proximoCampo('icmsst')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Valor ICMS ST: [+]&nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								v-model="icmsst"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="icmsst"
								@keydown.enter="proximoCampo('valoricmsst')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
						<div class="forma-custo-item">
							<input
								v-model="valoricmsst"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="valoricmsst"
								@keydown.enter="proximoCampo('frete')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Valor Frete: [+]&nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								v-model="frete"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="frete"
								@keydown.enter="proximoCampo('valorfrete')"
								@focusout="focusout"
								class="input-forma-preco-custo"
								autocomplete="off"
							>
						</div>
						<div class="forma-custo-item">
							<input
								v-model="valorfrete"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="valorfrete"
								@keydown.enter="proximoCampo('outros')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Valor Outros: [+]&nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								v-model="outros"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="outros"
								@keydown.enter="proximoCampo('valoroutros')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
						<div class="forma-custo-item">
							<input
								v-model="valoroutros"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="valoroutros"
								@keydown.enter="proximoCampo('markupseg')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Margem segurança: [+]&nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								v-model="markupseg"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="markupseg"
								@keydown.enter="proximoCampo('valormarkupseg')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
						<div class="forma-custo-item">
							<input
								v-model="valormarkupseg"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="valormarkupseg"
								@keydown.enter="proximoCampo('percdescontoentrada')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Desconto: [-]&nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								v-model="percdescontoentrada"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="percdescontoentrada"
								@keydown.enter="proximoCampo('descontoentrada')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
						<div class="forma-custo-item">
							<input
								v-model="descontoentrada"
								@input="calcusto"
								@keydown="mascara"
								type="text"
								id="descontoentrada"
								@keydown.enter="proximoCampo('markup')"
								@focusout="focusout"
								autocomplete="off"
								class="input-forma-preco-custo"
							>
						</div>
					</div>
					<div class="forma-custo-linha">
						<div class="forma-custo-item">
							Custo Fixo: [=]&nbsp;
						</div>
						<div class="forma-custo-item">
							<input
								type="text"
								:value="AtualizacFixo"
								disabled
								class="input-forma-preco-custo"
							>
						</div>
						<div class="forma-custo-item">
							<input
								type="text"
								:value="AtualizapercFixo"
								disabled
								class="input-forma-preco-custo"
							>
						</div>
					</div>
				</div>
			</aside>
			<div class="forma-preco-venda">
				<div class="input-group">					
					<input
						class="input-pequeno"
						type="text"
						placeholder="0"
						id="markup"
						v-model="markup"
						@input="calcusto"
						@keydown="mascara" 
						@keydown.enter="proximoCampo('fator')"
						@focusout="focusout"
						autocomplete="off"
					>
					<label for="markup">% Margem:&nbsp;</label>
				</div>
				<div class="input-group">
					<input
						class="input-pequeno"
						type="text"
						placeholder="0"
						id="fator" 
						v-model="fator"
						@input="calcusto"
						@keydown="mascara"
						@keydown.enter="proximoCampo('pontoequi')"
						@focusout="focusout"
						autocomplete="off"
					>					
					<label for="fator">Fator:&nbsp;</label>
				</div>
				<div class="input-group">
					<input
						class="input-pequeno"
						type="text"
						placeholder="0"
						id="pontoequi"  
						v-model="pontoequi"
						@input="calcusto"
						@keydown="mascara"
						@keydown.enter="proximoCampo('descvista')"
						@focusout="focusout"
						autocomplete="off"
					>					
					<label for="pontoequi">$ Prazo:&nbsp;</label>
				</div>
				<div class="input-group">					
					<input
						class="input-pequeno"
						type="text"
						placeholder="0"
						id="descvista" 
						v-model="descvista"
						@input="calcusto"
						@keydown="mascara"
						@keydown.enter="proximoCampo('vlrvista')"
						@focusout="focusout"
						autocomplete="off"
					>
					<label for="desco">% Desconto:&nbsp;</label>
				</div>
				<div class="input-group">
					<input
						class="input-pequeno"
						type="text"
						placeholder="0"
						id="vlrvista"
						v-model="vlrvista"
						@input="calcusto"
						@keydown="mascara"
						@focusout="focusout"
						@keydown.enter.prevent="proximoCampo('btn-salvar-preco')"
						autocomplete="off"
					>
					<label for="vista">$ Vista:&nbsp;</label>
				</div>
			</div>
			<div id="botoes" class="botoes">
				<button class="botao botao-cancelar" @click="cancelarEdicao">
					Cancelar
					<span>[Alt+c]</span>
				</button>
				<button class="botao botao-acao" @click="solicitarCodigoBarra">
					<i class="fas fa-barcode" style="color:var(--acao-texto)"></i>
					Cod. Barra
					<span>[Alt+b]</span>
				</button>
				<button id="btn-salvar-preco" class="botao botao-adicionar" @click="salvarpreco">
					Salvar
					<span>[Alt+s]</span>
				</button>
			</div>
			<aside class="produtos-todas-empresas">
				<table>
					<thead>
						<tr>
							<td style="color: blue; text-align: center;">Cód.</td>
							<td style="text-align: left;">Empresa</td>
							<td style="text-align: center;">Saldo</td>
							<td class="text-center text-nowrap">$ Custo</td>
							<td class="text-center text-nowrap">$ Custo Fixo</td>
							<td class="text-center text-nowrap">% Margem</td>
							<td class="text-center text-nowrap">$ Médio</td>
							<td class="text-center text-nowrap">$ Prazo</td>
							<td class="text-center text-nowrap">$ Vista</td>
						</tr>
					</thead>
					<tbody>
						<tr class="itemLista" v-for="(aPrecos, index) in Precos" :key="index" @click="SelecionaDados(aPrecos)">
							<td style="text-align: center;">{{aPrecos.cdempresa}}</td>
							<td style="text-align: center;">{{Empresas.find(item => item.id == aPrecos.cdempresa).nome}}</td>
							<td style="text-align: center;">{{(aPrecos.saldo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4})}}</td>
							<td style="text-align: right;">{{(aPrecos.custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4})}}</td>
							<td style="text-align: right;">{{(aPrecos.custofixo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4})}}</td>
							<td style="text-align: right;">{{(aPrecos.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4})}}</td>
							<td style="text-align: right;">{{(aPrecos.customedio || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4})}}</td>
							<td style="text-align: right;">{{(aPrecos.pontoequi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4})}}</td>
							<td style="text-align: right;">{{(aPrecos.vlrvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4})}}</td>
						</tr>
					</tbody>
				</table>
			</aside>
		</div>
		<Modal 
			v-if="telaBarra" 
			:dimensoes="{
				width: '80%',
				height: '80%'
			}" 
			@fecharModal="telaBarra = false"
			:idModal="'codigo-barras'"
		>
			<CodigoBarra 
				v-bind:codiBarra="CodiBarra" 
				@fechartela="fechatelabarra"
			/>
		</Modal>
	</div>    
</template>

<script>
import { FormacaoPreco } from "../models/Produto";
import Modal from "./Modal.vue";
import CodigoBarra from "./CodigoBarra.vue";

export default {
	name: "ProdutoPreco", 
	
	props: {
		PropProduto: Object,
	},

	computed: {
		Empresas() {
			return this.$store.state.Empresas;
		},
		EmpresaSel() {
			return this.$store.state.Empresa;
		},
		Precos() {
			return this.$store.state.PrecoAll;
		},
		AtualizacFixo(){
			let preco = 0;
			preco += Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) + Number(`${this.valoripi || 0}`.split(".").join("").replace(",", ".")) +
				Number(`${this.valoricmsst || 0}`.split(".").join("").replace(",", ".")) + Number(`${this.valorfrete || 0}`.split(".").join("").replace(",", "."))+
				Number(`${this.valoroutros || 0}`.split(".").join("").replace(",", ".")) + Number(`${this.valormarkupseg || 0}`.split(".").join("").replace(",", "."))
				- Number(`${this.descontoentrada || 0}`.split(".").join("").replace(",", "."));
			return preco.toFixed(4).replace(".", ",");
		},

		AtualizapercFixo(){
			let somaPerc = 0;
			somaPerc += Number(`${this.ipi || 0}`.split(".").join("").replace(",", ".")) + Number(`${this.icmsst || 0}`.split(".").join("").replace(",", ".")) +
				Number(`${this.frete || 0}`.split(".").join("").replace(",", ".")) + Number(`${this.outros || 0}`.split(".").join("").replace(",", ".")) + 
				Number(`${this.markupseg || 0}`.split(".").join("").replace(",", ".")) - Number(`${this.descontoentrada || 0}`.split(".").join("").replace(",", "."));
			return somaPerc.toFixed(4).replace(".", ",");
		}
	},

	data(){
		return {
			custo : "",	
			customedio : "",
			valoripi : "",	
			ipi : "",
			valorpis : "",
			pis : "",
			valorcofins : "",
			cofins : "",
			valoricmsst : "",
			icmsst : "",
			valorfrete : "",
			frete : "",
			valoroutros : "",
			outros : "",
			valormarkupseg : "",
			markupseg : "",
			percdescontoentrada: "",
			descontoentrada: "",
			custofixo : "",
			markup : "",
			fator : "",
			pontoequi : "",
			descvista : "",	
			vlrvista : "",
			percentualacrescimofisica : "",
			promocao : "",
			ultcusto : "",
			dataultalteracao : "",	
			ultmarkup : "",	
			ultfator : "",	
			ultvenda : "",	
			ultsaida : "",
			encargos: "",
			fixo: "",
			margem: "",
			prazo: "",
			desco: "",
			vista: "",
			indexAtual: 0,
			telaBarra: false,
			CodiBarra : {}
		};
	},
	
	components: {
		CodigoBarra,
		Modal
	},

	methods: {
		fechatelabarra(){
			this.telaBarra = false;
		},

		solicitarCodigoBarra(){
			this.CodiBarra = {
				barraunico : this.PropProduto,
			};
			this.telaBarra = true;
		},

		cancelarEdicao() {
			this.custo = "";	
			this.customedio = "";
			this.valoripi = "";	
			this.ipi = "";
			this.valorpis = "";
			this.pis = "";
			this.valorcofins = "";
			this.cofins = "";
			this.valoricmsst = "";
			this.icmsst = "";
			this.valorfrete = "";
			this.frete = "";
			this.valoroutros = "";
			this.outros = "";
			this.valormarkupseg = "";
			this.markupseg = "";
			this.percdescontoentrada = "";
			this.descontoentrada = "";
			this.custofixo = "";
			this.markup = "";
			this.fator = "";
			this.pontoequi = "";
			this.descvista = "";	
			this.vlrvista = "";
			this.percentualacrescimofisica = "";
			this.promocao = "";
			this.ultcusto = "";
			this.dataultalteracao = "";	
			this.ultmarkup = "";	
			this.ultfator = "";	
			this.ultvenda = "";	
			this.ultsaida = "";
			this.indexAtual = 0;
			if (this.PropProduto.pai) {
				this.$emit("mudarAba", {target: {id: "variacao" }});
			} else {
				this.$emit("mudarAba", {target: {id: "dados" }});
			}
		},

		async salvarpreco() {
			let formacaoPreco = {};
			this.Empresas.forEach(element => {
				if (element.formaPreco) {
					formacaoPreco[element.id] = new FormacaoPreco({
						...this.$store.state.produtoSelecionado,
						...this.PropProduto,
						cofins : Number(`${this.cofins || 0}`.split(".").join("").replace(",",".")),
						custo  : Number(`${this.custo || 0}`.split(".").join("").replace(",",".")),
						custofixo: Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",",".")),
						customedio: Number(`${this.customedio || 0}`.split(".").join("").replace(",",".")),
						dataultalteracao: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
						descvista: Number(`${this.descvista || 0}`.split(".").join("").replace(",",".")),
						descontoentrada: Number(`${this.descontoentrada || 0}`.split(".").join("").replace(",",".")),
						fator: Number(`${this.fator || 0}`.split(".").join("").replace(",",".")),
						frete: Number(`${this.frete || 0}`.split(".").join("").replace(",",".")),
						icmsst: Number(`${this.icmsst || 0}`.split(".").join("").replace(",",".")),
						ipi: Number(`${this.ipi || 0}`.split(".").join("").replace(",",".")),
						markup: Number(`${this.markup || 0}`.split(".").join("").replace(",",".")),
						markupseg: Number(`${this.markupseg || 0}`.split(".").join("").replace(",",".")),
						outros: Number(`${this.outros || 0}`.split(".").join("").replace(",",".")),
						percentualacrescimofisica: Number(`${this.percentualacrescimofisica || 0}`.split(".").join("").replace(",",".")),
						pis: Number(`${this.pis || 0}`.split(".").join("").replace(",",".")),
						pontoequi: Number(`${this.pontoequi || 0}`.split(".").join("").replace(",",".")), 
						promocao: Number(`${this.promocao || 0}`.split(".").join("").replace(",",".")),
						// saldo: Number(`${this.saldo || 0}`.split(".").join("").replace(",",".")),
						// ultcusto: this.
						// ultfator: 0,
						// ultmarkup: 0,
						// ultsaida: 0,
						// ultvenda: 0,
						valorcofins: Number(`${this.valorcofins || 0}`.split(".").join("").replace(",",".")),
						valorfrete: Number(`${this.valorfrete || 0}`.split(".").join("").replace(",",".")),
						valoricmsst: Number(`${this.valoricmsst || 0}`.split(".").join("").replace(",",".")),
						valoripi: Number(`${this.valoripi || 0}`.split(".").join("").replace(",",".")),
						valormarkupseg: Number(`${this.valormarkupseg || 0}`.split(".").join("").replace(",",".")),
						valoroutros: Number(`${this.valoroutros || 0}`.split(".").join("").replace(",",".")),
						valorpis: Number(`${this.valorpis || 0}`.split(".").join("").replace(",",".")),
						vlrvista: Number(`${this.vlrvista || 0}`.split(".").join("").replace(",",".")),
						favorito: element.favorito,
					});
				}
			});
			await this.$store.dispatch("formarPreco", {formacaoPreco, id: this.PropProduto.id});
			setTimeout(() => {
				this.$emit("salvarProduto");
			}, 50);
		},

		mascara(e){
			const regex = /[^0-9.,]/;
			const regexKey = [
				"Backspace",
				"ArrowRight",
				"ArrowLeft",
				"Delete",
				"End",
				"Home",
				"Tab"
			];
			if (regex.test(e.key) && !regexKey.includes(e.key)) {
				e.preventDefault();
			}
			if ((`${e.target.value}`.includes(",") || !`${e.target.value}`.length) && e.key == ","){
				e.preventDefault();
			}
		},

		RecalculaValores() {
			this.ipi =  ((Number(`${this.valoripi || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			this.valoripi = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.ipi || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			this.pis =  ((Number(`${this.valorpis || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			this.valorpis = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.pis || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			this.cofins = ((Number(`${this.valorcofins || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			this.valorcofins = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.cofins || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			this.icmsst = ((Number(`${this.valoricmsst || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			this.valoricmsst = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.icmsst || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			this.frete = ((Number(`${this.valorfrete || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			this.valorfrete = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.frete || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			this.outros = ((Number(`${this.valoroutros || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			this.valoroutros = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.outros || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			this.markupseg = ((Number(`${this.valormarkupseg || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			this.valormarkupseg = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.markupseg || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			this.calcularMarkup();
		},

		calcularMarkup() {
			const custo = Number(`${this.custo || 0}`.split(".").join("").replace(",", "."));
			const pontoequi = Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", "."));
			if (!custo) {
				this.markup = (0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.fator = (0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				return;
			}
			if (!pontoequi) {
				this.markup = (0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.fator = (0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				return;
			}
			if (this.$store.state.Empresa.customedio) {
				this.markup = (100-(((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * 100) / (Number(`${this.pontoequi || 1}`.split(".").join("").replace(",", ".") || 0)))).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.fator = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0)).toFixed(4).replace(".", ",");
			} else {
				this.markup = (((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".") / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)) || 0) -100).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.fator = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0)).toFixed(4).replace(".", ",");
			}
		},

		calcusto(e){
			const id = e.target.id;
			const custo = Number(`${this.custo || 0}`.split(".").join("").replace(",", "."));
			let pontoequi = Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", "."));
			if ("custo" == id){
				this.pontoequi = ((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) + ((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * (Number(`${this.markup || 0}`.split(".").join("").replace(",", ".")/100) || 0))).toFixed(2).replace(".", ",");
				pontoequi = Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", "."));
				if (!pontoequi) {
					this.vlrvista = this.pontoequi;
					this.descvista = "0,000";
				} else {
					this.vlrvista = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.pontoequi || 1}`.split(".").join("").replace(",", ".")) || 1) * ((Number(`${this.descvista || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
					this.descvista = (100-((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
				}
				this.RecalculaValores();
			} else if ("valoripi" == id){
				this.ipi =  ((Number(`${this.valoripi || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			} else if ("ipi" == id){
				this.valoripi = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.ipi || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			} else if ("valorpis" == id){
				this.pis =  ((Number(`${this.valorpis || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			} else if ("pis" == id){
				this.valorpis = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.pis || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			} else if ("valorcofins" == id){
				this.cofins = ((Number(`${this.valorcofins || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			} else if ("cofins" == id){
				this.valorcofins = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.cofins || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			} else if ("valoricmsst" == id){
				this.icmsst = ((Number(`${this.valoricmsst || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			} else if ("icmsst" == id){
				this.valoricmsst = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.icmsst || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			} else if ("valorfrete" == id){
				this.frete = ((Number(`${this.valorfrete || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			} else if ("frete" == id){
				this.valorfrete = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.frete || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			} else if ("valoroutros" == id){
				this.outros = ((Number(`${this.valoroutros || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			} else if ("outros" == id){
				this.valoroutros = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.outros || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			} else if ("valormarkupseg" == id){
				this.markupseg = ((Number(`${this.valormarkupseg || 0}`.split(".").join("").replace(",", ".")) * 100) / (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			} else if ("markupseg" == id){
				this.valormarkupseg = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * (Number(`${this.markupseg || 0}`.split(".").join("").replace(",", ".")) /100)).toFixed(4).replace(".", ",");
			} else if ("markup" == id){
				if (this.$store.state.Empresa.customedio) {
					if (Number(this.markup.replace(",", ".")) == 100){
						this.pontoequi = ((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) + ((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * (Number(`${this.markup || 0}`.split(".").join("").replace(",", ".")/100) || 0))).toFixed(2).replace(".", ",");
					} else {
						this.pontoequi = ((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) + (((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * (Number(`${this.markup || 0}`.split(".").join("").replace(",", ".") || 0)  / (100- (Number(this.markup.replace(",", ".") || 0))))))).toFixed(2).replace(".", ",");
					}
					this.fator = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
					this.vlrvista = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.descvista || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
					if (!custo) {
						this.descvista = (0).toFixed(4).replace(".", ",");
					} else {
						this.descvista = (100-((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
					}
					this.promocao = ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.percentualacrescimofisica || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
					this.percentualacrescimofisica = (100-((Number(`${this.promocao || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
				} else {
					this.pontoequi = ((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) + ((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * (Number(`${this.markup || 0}`.split(".").join("").replace(",", ".")/100) || 0))).toFixed(2).replace(".", ",");
					this.fator = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
					this.vlrvista = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.pontoequi || 1}`.split(".").join("").replace(",", ".")) || 1) * ((Number(`${this.descvista || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
					if (!custo) {
						this.descvista = (0).toFixed(4).replace(".", ",");
					} else {
						this.descvista = (100-((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
					}
					this.promocao = ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.percentualacrescimofisica || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
					this.percentualacrescimofisica = (100-((Number(`${this.promocao || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.vlrvista || 1}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
				}
			} else if (id == "fator") {
				const fator = Number(this.fator.split(".").join("").replace(",", "."));
				this.pontoequi = ((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * fator).toFixed(2).replace(".", ",");
				this.markup = ((((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 1)) * 100) - 100).toFixed(2).replace(".", ",");
				this.vlrvista = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.descvista || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
				if (!custo) {
					this.descvista = (0).toFixed(4).replace(".", ",");
				} else if (pontoequi <= 0 || fator <= 0) {
					this.descvista = "0,00";
				} else {
					this.descvista = (100-((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
				}
				this.promocao = ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.percentualacrescimofisica || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
				this.percentualacrescimofisica = (100-((Number(`${this.promocao || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
			} else if ("percdescontoentrada" == id){
				this.descontoentrada = (Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * Number(`${this.percdescontoentrada || 0}`.split(".").join("").replace(",", ".")) / 100).toFixed(4).replace(".", ",");
				if (this.$store.state.Empresa.customedio) {
					this.markup = (100-(((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * 100) / (Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".") || 0)))).toFixed(4).replace(".", ",");
				} else {
					this.markup = (((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".") / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)) || 0) -100).toFixed(4).replace(".", ",");
				}
				this.fator = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0)).toFixed(4).replace(".", ",");
			}  else if ("descontoentrada" == id){
				this.percdescontoentrada = (Number(`${this.descontoentrada || 0}`.split(".").join("").replace(",", ".")) / Number(`${this.custo || 0}`.split(".").join("").replace(",", ".")) * 100).toFixed(4).replace(".", ",");
				if (this.$store.state.Empresa.customedio) {
					this.markup = (100-(((Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * 100) / (Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".") || 0)))).toFixed(4).replace(".", ",");
				} else {
					this.markup = (((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".") / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)) || 0) -100).toFixed(4).replace(".", ",");
				}
				this.fator = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) / (Number(`${this.AtualizacFixo || 0}`.split(".").join("").replace(",", ".")) || 0)).toFixed(4).replace(".", ",");
			} else if ("pontoequi" == id){
				this.calcularMarkup();
				const pontoequi = Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", "."));
				this.vlrvista = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.descvista || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
				if (!pontoequi) {
					this.descvista = (0).toFixed(4).replace(".", ",");
				}
				this.promocao = ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.percentualacrescimofisica || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
				this.percentualacrescimofisica = (100-((Number(`${this.promocao || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0)).toFixed(4).replace(".", ",");
			}
			else if ("descvista" == id){
				let descvista = Number(`${this.descvista || 0}`.split(".").join("").replace(",", ".") || 0);
				if (descvista > 100) {
					this.$store.commit("relatarErro", {
						mensagem: "Desconto não pode ser maior que 100%! Alerado para 100%, verifique!",
						alerta: true
					});
					descvista = 100;
					this.descvista = (descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				}
				this.vlrvista = ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 0) * ((descvista)/100))).toFixed(2).replace(".", ",");
				this.promocao = ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.percentualacrescimofisica || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
				this.percentualacrescimofisica = (100-((Number(`${this.promocao || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0)).toFixed(4).replace(".", ",");
			}
			else if ("vlrvista" == id){
				if (!pontoequi) {
					this.descvista = "0,00";
				} else {
					this.descvista = (100-((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.pontoequi || 0}`.split(".").join("").replace(",", ".")) || 1)).toFixed(4).replace(".", ",");
				}
				this.promocao = ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.percentualacrescimofisica || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
				this.percentualacrescimofisica = (100-((Number(`${this.promocao || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0)).toFixed(4).replace(".", ",");
			}
			else if ("percentualacrescimofisica" == id){
				this.promocao = ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) - ((Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0) * ((Number(`${this.percentualacrescimofisica || 0}`.split(".").join("").replace(",", ".")) || 0)/100))).toFixed(2).replace(".", ",");
			}
			else if ("promocao" == id){
				this.percentualacrescimofisica = (100-((Number(`${this.promocao || 0}`.split(".").join("").replace(",", ".")) || 0) * 100)/(Number(`${this.vlrvista || 0}`.split(".").join("").replace(",", ".")) || 0)).toFixed(4).replace(".", ",");
			}
			if (id != "markup" && id != "fator") {
				this.calcularMarkup();
			}
		},
		
		SelecionaDados(produto) {
			this.custo = (produto.custo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.customedio = (produto.customedio || produto.custoMedio || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.valoripi = (produto.valoripi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.ipi = (produto.ipi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.valorpis = (produto.valorpis || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.pis = (produto.pis || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.valorcofins = (produto.valorcofins || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.cofins = (produto.cofins || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.valoricmsst = (produto.valoricmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.icmsst = (produto.icmsst || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.valorfrete = (produto.valorfrete || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.frete = (produto.frete || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.valoroutros = (produto.valoroutros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.outros = (produto.outros || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.valormarkupseg = (produto.valormarkupseg || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.markupseg = (produto.markupseg || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.descontoentrada = (produto.descontoentrada || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.percdescontoentrada = ((produto.descontoentrada || 0) / (produto.custo || 1) * 100).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.custofixo = (produto.custofixo || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.markup = (produto.markup || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.fator = (produto.fator || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.pontoequi = (produto.pontoequi || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.descvista = (produto.descvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4});
			this.vlrvista  = (produto.vlrvista || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			setTimeout(() => {
				this.proximoCampo("custo");
			}, 80);
		},

		capturarEventosTeclado(e) {
			if (`${e.key}`.toUpperCase() == "S" && e.altKey) {
				e.preventDefault();
				this.salvarpreco();
			} else if (`${e.key}`.toUpperCase() == "C" && e.altKey) {
				e.preventDefault();
				this.cancelarEdicao();
			} else if (`${e.key}`.toUpperCase() == "B" && e.altKey) {
				e.preventDefault();
				this.solicitarCodigoBarra();
			} else if (e.key == "ArrowUp") {
				if (this.indexAtual > 0) {
					this.indexAtual--;
				}
				e.preventDefault();
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			} else if (e.key == "ArrowDown") {
				if (this.indexAtual < this.Empresas.length -1) {
					this.indexAtual++;
				}
				e.preventDefault();
				setTimeout(() => {
					document.getElementById(this.indexAtual).focus();
				}, 10);
			}
		},

		focusout(evento){
			const id = evento.target.id;
			if (this[id].indexOf(",") == -1){
				this[id] =  Number(this[id]).toFixed(4).replace(".",",");
			}
		},

		proximoCampo(id) {
			try {
				if (!id) {
					throw new Error("Falta o atribuito id!");
				}
				setTimeout(() => {
					const input = document.getElementById(id);
					if (input) {
						input.focus();
						if (input.select) {
							input.select();
						}
					}
				},20);
			} catch (error) {
				console.log(error);
			}
		},
		
		adicionarEmpresaFormaPreco(index, manterValor) {
			if (this.Empresas[index].id == this.EmpresaSel.id) return this.indexAtual = index;
			if (!manterValor) this.Empresas[index].formaPreco = !this.Empresas[index].formaPreco;
			if (!this.Empresas[index].formaPreco) {
				this.indexAtual = this.Empresas.map(emp => emp.id).indexOf(this.EmpresaSel.id);
			} else {
				this.indexAtual = index;
			}
			this.$forceUpdate();
		},
		async preencherEmpresas() {
			await this.$store.dispatch("buscarEmpresas");
			this.Empresas.forEach((emp, index) => {
				if (emp.id == this.EmpresaSel.id) {
					emp.formaPreco = true;
					this.indexAtual = index;
				}
			});
			this.preencherProdutosTodasEmpresas();
		},
		async preencherProdutosTodasEmpresas() {
			await this.$store.dispatch("buscarProdutoPorIdAllEmp", this.PropProduto.id);
			this.Precos.forEach(prod => {
				const index = this.Empresas.map(emp => emp.id).indexOf(prod.cdempresa);
				if (index >= 0) {
					this.Empresas[index].formaPreco = true;
				}
				if(prod.favorito == "S"){
					const indexFav = this.Empresas.map(emp => emp.id).indexOf(prod.cdempresa);
					if (indexFav >= 0) {
						this.Empresas[indexFav].favorito = true;
					}
				}
			});
			this.$forceUpdate();
		}
	},

	mounted() {
		if (!this.PropProduto || !this.PropProduto.id) {
			this.$emit("mudarAba", {target: {id: "dados" }});
			return this.$store.commit("relatarErro", {mensagem: "É necessário cadastrar os dados do produto primeiro ou selecionar um produto!", alerta: true});
		}
		document.addEventListener("keydown", this.capturarEventosTeclado);
		this.preencherEmpresas();
		setTimeout(() => {
			const prod = this.Precos.find(p => p.cdempresa === this.EmpresaSel.id);
			if(prod){
				this.SelecionaDados(prod);
			} else {
				this.SelecionaDados(this.PropProduto);
			}
		}, 200);
	},

	destroyed() {
		document.removeEventListener("keydown", this.capturarEventosTeclado);
		this.$store.commit("atualizarProdutosAll", []);
	}
};

</script>
	
<style scoped>
.cadastro-produto-cabecalho {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: var(--bg-app);
	width: 100%;
}
.cadastro-produto-cabecalho h3 {
	margin: 5px;
}
#botoes {
	width: 100%;
	display: flex;
	margin: 2px auto;
	justify-content: space-around;
}
#botoes button {
	width: 100%;
	margin: 0px 5px;
}
.cadastro-produto-lancamento {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
}
.lancamento-custo {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap-reverse;
}

.tabela-empresas{
	height: 100%;
	max-height: 338px;
	flex-grow: 1;
	border: 2px solid slategrey;
	margin-left: 10px;
	overflow-y: auto;
	overflow-x: hidden;
}

.forma-custo-container {
    order: 1;
    display: flex;
    flex-direction: column;
    margin: 5px;
	border: 2px solid slategrey;
	padding: 5px;
}

.table-empresas{
	order: 2;
	flex-grow: 1;
	max-height: 310px;
	overflow-y: auto;
	overflow-x: hidden;
	border: 2px solid slategrey;
	padding: 5px;
}
.lancamento-custo-empresa {
	width: 100%;
    height: max-content;
    margin: 5px;
}
.forma-custo-linha:nth-child(1) {
	background-color: var(--bg-secundario);
}
.forma-custo-linha:nth-child(3) {
	background-color: var(--bg-secundario);
}
.forma-custo-linha div:nth-child(1) {
	width: 200px;
}
.forma-custo-linha div:nth-child(2) {
	width: 90px;
}
.forma-custo-linha div:nth-child(3) {
	width: 90px;
}
.forma-custo-linha {
	display: flex;
	flex-direction: row;
}
.forma-custo-item {
	display: flex;
	flex-direction: column;
	text-align: right;
	border: 1px solid black;
}
.forma-custo-item input.input-forma-preco-custo {
	height: 100%;
	border: none;
	text-align: right;
}
.forma-custo-item input.input-forma-preco-custo:focus {
	box-shadow: 0 0 0 0;
	border: 0 none;
	outline: 0;
}
.forma-custo-item:nth-child(2),
.forma-custo-item:nth-child(3) {
	min-width: 90px;
	justify-content: flex-end;
}
.forma-preco-venda {
    margin: 10px 5px;
    height: auto;
    display: flex;
    flex-direction: row;    
    flex-flow: wrap;
	align-items:center;
    border: 2px solid slategrey; 
}
.input-group{
	margin-top: 9px;
	margin-bottom: 5px;
}
.produtos-todas-empresas {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 100%;
	overflow: auto;
}
.produtos-todas-empresas table {
	width: 100%;
}

@media screen and (max-width: 1400px){
	.input-group{
		max-width: 200px;
	}
}

@media screen and (max-width: 1024px){
	.input-group{
		max-width: 150px;
	}
}

@media screen and (max-width: 620px) {
	#botoes span {
		display: none;
	}
	#botoes button {
		font-size: 12pt;
	}

	.tabela-empresas {
		height: auto;
	}
}

@media screen and (max-width: 425px) {
	.forma-preco-venda{
		height: auto;
		flex-direction: column;
	}
	.forma-preco-venda .input-group{
		margin-top: 9px;
	}
	.tabela-empresas{
		height: auto;
	}
}

@media screen and (max-width: 420px) {
	#botoes i {
		display: none;
	}
}

@media screen and (max-width: 390px) {
	.cadastro-produto-cabecalho {
		font-size: 12pt;
	}
	.lancamento-custo {
		font-size: 11pt;
	}
	.lancamento-custo table tbody tr td {
		white-space: normal;
	}
	.forma-custo-linha div:nth-child(1) {
		width: 100%;
	}
}
</style>