import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import extenso from "extenso";
import JsBarcode from "jsbarcode";
import { Empresa } from "../models/Empresa";
import { dateFormatBR } from "../utils/dateformat";
// import router from "../router";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// pdfMake.fonts = fonts;

export const gerarCodigoBarras = (texto, height, format) => {
	const canvas = document.createElement("canvas");
	JsBarcode(canvas, texto, {
		format: format || "EAN13",
		height: height || 30,
		displayValue: false,
	}).options({font: "OCR-b"});
	return canvas.toDataURL("image/png");
};

const gerarMensagemAgradecimento = (hora) => {
	if (hora >= 4 && hora < 12) {
		return "Obrigado, Tenha um Bom Dia!";
	} else if (hora >= 12 && hora < 18) {
		return "Obrigado, Tenha uma Boa tarde!";
	} else {
		return "Obrigado, Tenha uma Boa noite!";
	}
};

const styles = {
	header: {
		fontSize: 12,
		bold: true,
		alignment: "center",
		margin: [0, 2, 0, 0]
	},

	headerTabela: {
		fontSize: 9,
		bold: true,
		alignment: "center",
		noWrap: false
	},

	itemTabela: {
		fontSize: 8,
		alignment: "center",
		decorationColor: "red",
	},

	dataDocumento: {
		fontSize: 9,
		alignment: "right"
	},

	descricao: {
		fontSize: 8,
		alignment: "left",
	},

	descricaoCentralizada: {
		alignment: "center",
		fontSize: 8
	},

	headerPedido: {
		fontSize: 9,
		alignment: "left",
		bold: true,
	},

	valores: {
		alignment: "right",
		bold: true,
		fontSize: 9
	},

	rodape: {
		alignment: "center",
		fontSize: 7,
		margin: [0, 0, 0, 3]
	},

	naoFiscal: {
		alignment: "center",
		fontSize: 7
	},

	codigobarra: {
		alignment: "center",
	}
};


const gerarHeaderCupom = (Empresa) => {
	return [
		{ text: Empresa.fantasia, style: "header" },
		{
			columns: [
				{ text: `CNPJ: ${Empresa.cnpj}`, style: "descricao" },
				{ text: `IE: ${Empresa.ie}`, style: "descricao", alignment: "right" },
			]
		},
		{ text: `${Empresa.endereco}, ${Empresa.numero} - ${Empresa.bairro}`, style: "descricao" },
		{ text: `CEP: ${Empresa.cep}. CIDADE: ${Empresa.nomeCidade} / ${Empresa.ufCidade}`, style: "descricao" },
		{ text: `TELEFONE: ${Empresa.fone} / ${Empresa.celular}`, style: "descricao" },
	];
};

export const gerarReciboCredito = async ({state}, { credito }) => {
	try {
		let pageSize =  {
			width: 200,
			height: "auto"		
		};
		const documento = pdfMake.createPdf({
			info: {
				title: "Comprovante de crédito",
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: [2, 1, 2, 5],

			pageOrientation: "portrait",

			content: [
				{
					text: `À ${credito?.cliente?.nome}`,
					fontSize: 11,
					margin: [5, 2, 5, 1]
				},
				{
					text: `CPF: ${credito?.cliente?.cnpj}`,
					fontSize: 11,
					margin: [5, 2, 5, 1]
					
				},
				{
					text: "Comprovante de Crédito",
					margin: [0, 7, 0, 7],
					alignment: "center",
					bold: true,
				},
				{
					text: `No valor de ${extenso(credito?.valor)} reais`,
					margin: [5, 3, 5, 1],
				},
				credito?.observacao ? {
					text: `Observação: ${credito?.observacao}`,
					margin: [5, 3, 5, 1],
					fontSize: 11,
				} : {},
				{
					text: "Atenciosamente",
					margin: [5, 10, 5, 1],
				},
				{
					text: `${state?.Empresa.fantasia}`,
					fontSize: 11,
					margin: [5, 5, 5, 1],
				},
				{
					text: `CNPJ: ${state?.Empresa.cnpj}`,
					fontSize: 11,
					margin: [5, 1, 5, 3],
				},
				{
					text: `Endereço: ${state?.Empresa?.nomeCidade || ""} - ${state?.Empresa?.ufCidade || ""}, ${state?.Empresa?.endereco || ""}, ${state?.Empresa?.numero || ""}, ${state?.Empresa?.bairro}`,
					fontSize: 11,
					margin: [5, 1, 5, 3]
				},
				{
					text: "* O valor do credito tem que ser gasto em 90 dias",
					margin: [5, 3, 5, 1],
					fontSize: 11,
					bold: true,
					alignment: "center"
				}
			]
		});
	
		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			state.base64 = url;
		});
	} catch (error) {
		console.log(error);
	}


};

export const gerarCupomLanchonete = async ({state}, {venda, finalizar}) => {
	const empresa = state.Empresa;
	const itens = venda?.itens;
	const ehLanchonete = empresa?.tiposistema === "L";
	function unificarProdutosPorId(produtos){
		const produtosUnificados = [];
		for(const produto of produtos) {
			const id = produto.id;
			if(produtosUnificados[id]) {
				produtosUnificados[id].quantidade += produto.quantidade;
				for(const sugestao of produto?.composicaoKit) {
					const sugestaoExistente = produtosUnificados[id].composicaoKit.find(sug => `${sug?.descricao}`.toUpperCase().trim() === `${sugestao?.descricao}`.toUpperCase().trim());
					if(sugestaoExistente) {
						sugestaoExistente.quantidade += sugestao.quantidade;
					} else {
						produtosUnificados[id].composicaoKit.push(sugestao);
					}
				}
			} else {
				produtosUnificados[id] = {... produto, composicaoKit: [...produto.composicaoKit]};
			}
		}
		return Object.values(produtosUnificados);
	}

	const Header = [
		{
			text: empresa.fantasia,
			style: "title"
		},
		{
			text: `CNPJ: ${empresa?.cnpj} - IE: ${empresa?.ie}`,
			style: "subtitle"
		},
		{
			text: `End.: ${empresa?.endereco}${empresa?.numero ? `, n° ${empresa.numero}` : ""}`,
			style: "subtitle"
		},
		{
			text: `Fone: ${empresa?.fone}`,
			style: "subtitle"
		},
		{
			text: [
				`Data: ${new Date(`${venda?.dataEmissao}`).toLocaleDateString("pt-BR")} `,
				`Pedido: n° ${venda.cdVenda}`
			],
			style: "title"
		},
		{
			text:" ",
			style:{
				fontSize: 2
			}
		},
		{
			text: venda?.nomeCliente ? venda?.nomeCliente : venda?.cliente?.nome,
			style: "title"
		},
		venda?.foneCliente ? {
			text: `FONE: ${venda?.foneCliente}`,
			style: {
				fontSize: 10,
				alignment: "center",
				margin: [0, 10, 0, 1],
				bold: true,
			}
		} : null,
		{
			text:" ",
			style:{
				fontSize: 2
			}
		},
		venda?.enderecoCliente ? {
			text: `End.: ${venda?.enderecoCliente} ${(venda?.cliente?.numero || venda?.numeroEndereco)  
				? `, ${venda?.cliente?.numero || venda?.numeroEndereco}` : ""}`,
			style: {
				fontSize: 10,
				alignment: "left",
				margin: [0, 10, 0, 1],
				bold: true,
			}
		} : null,
		venda?.enderecoCliente ? {
			text: `Bairro: ${venda.bairroCliente ? `${venda?.bairroCliente}` : ""} \n${venda?.cidadeCliente ? venda?.cidadeCliente : ""} - ${venda?.ufCliente ? venda.ufCliente : ""} `,
			style: {
				fontSize: 10,
				alignment: "left",
				margin: [0, 10, 0, 1],
				bold: true,
			}
		} : null,
		venda?.identificador ? {
			text: `${venda.identificador}`,
			style: {
				fontSize: 10,
				alignment: "left",
				margin: [0, 10, 0, 1],
				bold: true,
			}
		} : null,
		{
			text:" ",
			style:{
				fontSize: 2
			}
		},
	];

	let tabelaProdutos = [];
	if(ehLanchonete) {
		tabelaProdutos = [
			[
				{
					text: "Produto",
					style: "headerTabela"
				},
				{
					text: "Qtd.",
					style: "headerTabela"
				},
				{
					text: "Vlr total",
					style: "headerTabela"
				},
			]
		];
	} else {
		tabelaProdutos = [
			[
				{
					text: "Produto",
					style: "headerTabela"
				},
				{
					text: "Qtd.",
					style: "headerTabela"
				},
				{
					text: "Vlr unit.",
					style: "headerTabela"
				},
				{
					text: "Vlr total",
					style: "headerTabela"
				},
			]
		];
	}
	const tabelaProdutosWidths = ehLanchonete ? [115, 20, 40, "*"] : [80, "*", 30, 30];
	const produtos = unificarProdutosPorId(itens);

	produtos.forEach(produto => {
		let produtos = [];
		const sugestoes = [];
		if (produto?.composicaoKit?.length > 0) {
			produto.composicaoKit.forEach(sug => {
				if(sug.quantidade > 0) {
					sugestoes.push({
						text: `+ ${((sug.multiplo || 1) * (sug.quantidade))} ${sug.descricao}`,
						style: "sugestaoTabela",
					});
				}
				if(!ehLanchonete) {

					produtos = [
						{
							stack: [
								`${produto?.descricao}`,
								...sugestoes.map(sugestao => ({
									text: sugestao.text,
									style: sugestao.style
								}))
							],
							style: "bodyTabela",
							fontSize: 9,
						},
						{
							text: (produto?.quantidade || 1).toLocaleString("pt-BR", {
								style: "decimal",
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							}),
							style: "bodyTabela"
						},
						{
							text: (produto?.valorUnitario || 0).toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL"
							}),
							style: "bodyTabela"
						},
						{
							text: ((produto?.valorUnitario || 0) * (produto?.quantidade || 1)).toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL"
							}),
							style: "bodyTabela",
						}
					];
				} else {
					produtos = [
						{
							stack: [
								`${produto?.descricao}`,
								...sugestoes.map(sugestao => ({
									text: sugestao.text,
									style: sugestao.style
								}))
							],
							style: "bodyTabela",
							fontSize: 9,
						},
						{
							text: (produto?.quantidade || 1).toLocaleString("pt-BR", {
								style: "decimal",
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							}),
							style: "bodyTabela"
						},
						{
							text: ((produto?.valorUnitario || 0) * (produto?.quantidade || 1)).toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL"
							}),
							style: "bodyTabela",
						}
					];
				}
			});

		} else {
			produtos = [
				{
					text: produto?.descricao,
					fontSize: 9,
					style: "bodyTabela"
				},
				{
					text: (produto?.quantidade || 1).toLocaleString("pt-BR", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					}),
					style: "bodyTabela"
				},
				{
					text: (produto?.valorUnitario || 0).toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL"
					}),
					style: "bodyTabela"
				},
				{
					text: ((produto?.valorUnitario || 0) * (produto?.quantidade || 1)).toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL"
					}),
					style: "bodyTabela",
				}
			];
		}
		tabelaProdutos.push(produtos);
	});

	const tabelaFinanceiro = [	
		[
			{text: "Método pagamento", style: 
				{	fontSize: 10,
					bold: true,
				}
			},
			{text: "$ Pago", style: 
				{	fontSize: 10,
					bold: true,
				}
			},
			
		],
	
	];
	if(venda?.financeiro?.length > 0){
		venda.financeiro.forEach(fin => {
			tabelaFinanceiro.push([
				{text: fin?.pagamento, style:
					{
						fontSize: 10,
						alignment: "left",
						margin: [0, 1, 0, 1],
						bold: true,
					}
				},
				{text: (fin?.valorParcela || 0).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL",
				}), style: 
					{
						fontSize: 10,
						alignment: "left",
						margin: [0, 1, 0, 1],
						bold: true,
					}
				},
			]);
		});
	}


	const styles = {
		title: {
			fontSize: 10,
			alignment: "center",
			margin: [0, 1, 0, 1],
			bold: true,
		},
		subtitle: {
			fontSize: 8,
			bold: true,
			alignment: "center",
			margin: [0, 1, 0, 1]
		},

		headerTabela: {
			fontSize: 7,
			bold: true,
		},

		bodyTabela: {
			fontSize: 7
		},
		sugestaoTabela: {
			fontSize: 7.5
		},
		rodape: {
			alignment: "center",
			fontSize: 6
		}
	};
	const documento = pdfMake.createPdf({
		info: {
			title: `Pedido Nº ${venda.cdVenda}`,
			author: "BDS Soluções em Sistemas"
		},


		pageSize: {
			width: 200,
			height: "auto",
		},

		pageMargins: [1,1,1,5],
		pageOrientation: "portrait",

		content: [
			Header,
			{
				table: {
					widths: tabelaProdutosWidths,
					body: tabelaProdutos,
				},
				layout: {
					hLineWidth: function (i) {
					
						return (i === 1 || i === 0) ? 1 : 0;
					},
					vLineWidth: function (i, node) {
						return (i === 0 || i === node.table.widths.length) ? 0 : 0;
					},
		
					hLineStyle: function (i) {
						if (i === 0 || i === 1) {
							return {dash: {length: 5, space: 1}};
						}
						return null;
					},
				},
				margin: [2, 5, 2, 5]
			},
			{
				text: "-----------------------------------------------------------",
				alignment: "center"
			},
			{
				text: `Total: ${(venda?.valorBruto || 0).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL"
				})}`,
				style: {
					fontSize: 11,
					bold: true,
					alignment: "right",
					margin: [0, 2, 2, 3]
				}
			},
			
			venda?.descontoMoeda > 0 ? {
				text: `Desconto: ${(venda?.valorBruto - venda?.valorPago || 0).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL"
				})}`,
				style: {
					fontSize: 9,
					alignment: "right",
				}
			} : null,

			venda?.acrescimo > 0 ? {
				text: `Acréscimos: ${(venda?.acrescimo || 0).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL"
				})}`,
				style: {
					fontSize: 9,
					alignment: "right",
				}
			} : null,

			venda?.valorfrete && !venda?.fretegratis ? {
				text: `Taxa de entrega: ${(venda?.valorfrete || 0).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL"
				})}`,
				style: {
					fontSize: 9,
					alignment: "right",
				}
			} : null,
			
			finalizar ?{
				text: "PAGAMENTOS",
				style: {
					fontSize: 10,
					alignment: "center",
					bold: true,
					margin: [0, 20, 0, 5]
				}
			} : null,
			
			finalizar ? {
				table: {
					widths: ["*", 50],
					body: tabelaFinanceiro
				},
				margin: [2, 2, 2, 2]
			} : null,
			
			
			
			{
				text: "DOCUMENTO NÃO FISCAL",
				style: "rodape",
				margin: [0, 10, 0, 0],
			},

			// cpfnota ? {
			// 	text: `CPF.: ${cpfCliente}`
			// } : null,

			{ text: gerarMensagemAgradecimento(new Date().getHours()), fontSize: 7, alignment: "center"},
			{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
			{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },



			

		],

		styles

	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		state.base64 = url;
	});
};

export const gerarCupom = async ({ state }, { empresa, venda, pageWidth }) => {
	try {
		const tabelaProdutos = [
			[
				{ text: "Cod", style: "headerTabela" },
				{ text: "Descrição venda", style: "headerTabela" },
				{ text: "Qtd", style: "headerTabela" },
				{ text: "Unit.", style: "headerTabela" },
				{ text: "Total", style: "headerTabela" },
			]
		];

		const tabelaTroca = [
			[
				{ text: "Cod", style: "headerTabela" },
				{ text: "Descrição troca", style: "headerTabela" },
				{ text: "Qtd", style: "headerTabela" },
				{ text: "Unit.", style: "headerTabela" },
				{ text: "Total", style: "headerTabela" },
			]
		];

		const tabelaCartao = [
			[
				{ text: "N° Parcela", style: "headerTabela" },
				{ text: "Pagamento", style: "headerTabela" },
				{ text: "$ Parcela", style: "headerTabela" },
			]
		];
		
		const tabelaParcelas = [
			[
				{ text: "Parc", style: "headerTabela" },
				{ text: "Tipo", style: "headerTabela" },
				{ text: "Venc.", style: "headerTabela" },
				{ text: "$ Valor", style: "headerTabela" },
			]
		];

		const tabelaParcelasDuplicata = [
			[
				{ text: "Parc", style: "headerTabela" },
				{ text: "Venc.", style: "headerTabela" },
				{ text: "Obs.", style: "headerTabela" },
				{ text: "$ Valor", style: "headerTabela" },
			]
		];

		let nomeDuplicata = ""; 
		if(venda?.financeiro?.forEach) {
			venda.financeiro.forEach(fin => {
				if(fin.cartao === "S") {
					for(let i=0; i < fin.qtdeParcelaCartao; i++) {
						const valorParcela = fin.valorParcela / fin.qtdeParcelaCartao;

						tabelaCartao.push([
							{ text: i + 1, style: "itemTabela" },
							{ text: fin.pagamento, style: "itemTabela", alignment: "center" },
							{ text: (valorParcela || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }), style: "itemTabela" },
						]);
					}
				} 
				else if(fin.cartao === "N" && fin.cheque === "N" && fin.vistaprazo == "3" && !fin.valorVista){
					nomeDuplicata = fin.pagamento;
					tabelaParcelasDuplicata.push([
						{ text: fin.cdLan, style: "itemTabela" },
						{
							text: (dateFormatBR(fin.dataVenc || null) || new Date().toLocaleDateString("pt-BR")),
							style: "itemTabela"
						}, 
						{ text: "", style: "itemTabela", alignment: "center" },
						{
							text: Number(fin.contarc > 0 ? fin.contarc : fin.credito).toFixed(2).replace(".", ","),
							style: "itemTabela"
						},
					]);
				}else {
					tabelaParcelas.push([
						{ text: fin.cdLan, style: "itemTabela" },
	
						{ text: fin.pagamento, style: "itemTabela", alignment: "center" },
						fin.vistaprazo == 3 ? {
							text: (dateFormatBR(fin.dataVenc || null) || new Date().toLocaleDateString("pt-BR")),
							style: "itemTabela"
						} : {
							text: "",
							style: {
								fontSize: 6,
								bold: true,
								alignment: "center"
							},
						},
						{
							text: Number(fin.contarc > 0 ? fin.contarc : fin.credito).toFixed(2).replace(".", ","),
							style: "itemTabela"
						},
					]);
				}
			});
		}
		let troca = false;
		let produtoEmPromocao = false;
		venda.itens.forEach(item => {
			if(item.produto.cdpromocao) {
				produtoEmPromocao = true;
			}
			if (item.cdTipo != "T" && (item.situacao == "A" || item.situacao == "S" || !item.situacao) ||
				(item.situacao == "B" && (venda.cdIdentificador < 0))) {
				tabelaProdutos.push([
					{ text: item?.id ? item.id : item.produto.id, style: "itemTabela" },

					{ text: item?.descricao ? item.descricao : item.produto.descricao , style: "itemTabela", alignment: "left" },

					{ text: (item.quantidade || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },

					{ text: (item.valorUnitario).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },

					{
						text: (item.valorUnitario * item.quantidade).toFixed(2).replace(".", ","),
						style: "itemTabela", alignment: "right"
					},
				]);
			} else if (item.cdTipo == "T") {
				tabelaTroca.push([
					{ text: item.produto.id, style: "itemTabela" },

					{ text: item.produto.descricao, style: "itemTabela", alignment: "left" },

					{ text: (item.quantidade || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },

					{ text: (item.valorUnitario).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },

					{
						text: (item.valorUnitario * item.quantidade).toFixed(2).replace(".", ","),
						style: "itemTabela", alignment: "right"
					},
				]);
				troca = true;
			}
		});
	
		let pageSize = !pageWidth ? {
			width: 200,
			height: "auto"		
		} : pageWidth;
		let temPrazo = false;
		venda?.financeiro.forEach(
			financeiro => {
				if(!financeiro.valorVista){
					temPrazo = true;
				}
			}
		);
		const documento = pdfMake.createPdf({
			info: {
				title: `Pedido Nº ${venda.cdVenda}`,
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: [1, 1, 1, 5],

			pageOrientation: "portrait",

			watermark: venda.cancelada == "S" ? { text: "CANCELADA", opacity: 0.3, bold: true, italics: false, angle: 80 } : null,

			content: [
				...gerarHeaderCupom(empresa),

				venda.valorPago > 0 ? { text: "Documento não fiscal", style: "naoFiscal" } :
					venda.valorCondicional > 0 ?
						{
							text: "CONDICIONAL",
							style: "header"
						} : null,

				{
					columns: [
						{ text: `Pedido nº ${venda.cdVenda}`, style: "headerPedido", width: "40%" },
						{
							text: `Data: ${new Date(`${venda.dataEmissao}`).toLocaleString("pt-BR")}`,
							style: "dataDocumento"
						},
					]
				},

				{
					text: `Cliente: ${venda.cliente.id} - ${(venda.cliente.id == state.ClientePadrao.id) && venda.nomeCliente ? venda.nomeCliente : venda.cliente.nome}`,
					style: "header"
				},

				venda.cliente.avalista?.cdcliente? {
					text: `Avalista: ${venda.cliente.avalista.cdcliente} - ${venda.cliente.avalista.nome}`,
					style: "header"
				}:null,

				venda.cliente.id !== state.ClientePadrao.id ?{
					columns: [
						{ text: `${venda.cliente.tipoPessoa == "F" ? "CPF" : "CNPJ"}: ${venda.cliente.id == state.ClientePadrao.id ? venda.cnpjCliente : venda.cliente.cnpj ? venda.cliente.cnpj : "Não cadastrado"}`, style: "descricao" },
						{ text: `${venda.cliente.tipoPessoa == "F" ? "RG" : "IE"}: ${venda.cliente.id == state.ClientePadrao.id ? venda.ieCliente : venda.cliente.ierg ? venda.cliente.ierg : "Não cadastrado"}`, style: "descricao", alignment: "right" }
					]
				}:null,

				venda.observacao ? {
					text: `Obs.: ${venda.observacao}.`,
					style: "descricao"
				} : null,

				venda.cliente.id !== state.ClientePadrao.id ?{
					text: `${venda.cliente.id == state.ClientePadrao.id ? venda.enderecoCliente : venda.cliente.endereco ? venda.cliente.endereco + `${venda.cliente.numero && venda.cliente.numero != "null" ? venda.cliente.numero : ""}` : "Não cadastrado"} - ${venda.cliente.id == state.ClientePadrao.id ? venda.bairroCliente : venda.cliente.bairro ? venda.cliente.bairro : "Não cadastrado"}`,
					style: "descricao"
				}:null,

				venda.cliente.id !== state.ClientePadrao.id ?{
					columns:[
						{ text: `CEP: ${venda.cliente.id == state.ClientePadrao.id ? venda.cepCliente : venda.cliente.cep ? venda.cliente.cep : "Não cadastrado"}.`, style: "descricao", width: "*" },				
						{ text: `Cidade: ${venda.cliente.id == state.ClientePadrao.id ? venda.cidadeCliente : venda.cliente.cidade ? venda.cliente.cidade.nomecidade : "Não cadastrado"}`, style: "descricao", width: "*", alignment: "right" },				
					]
				}:null,
				venda.cliente.id !== state.ClientePadrao.id ?{
					columns:[
						{ text: `Telefone: ${(venda.cliente.id == state.ClientePadrao.id) && venda.foneCliente ? venda.foneCliente : venda.cliente.fone ? venda.cliente.fone : "Não cadastrado"}`, style: "descricao" },
						{ text: `${venda.cliente.celular ? venda.cliente.celular : "Não cadastrado"}`, style: "descricao", alignment: "right" },
					]
				}:null,
				

				{
					columns: [
						{ text: `Vendedor: ${venda.vendedor.nome}`, style: "descricao" },
						{ text: `Usuario: ${venda.usuarioCaixa.nome}`, style: "descricao", alignment: "right" },
					]
				},

				state.Caixas && venda.cdIdentificador ?
					state.Caixas.content.find(caixa => caixa.cdcaixa == venda.cdIdentificador) ? {
						text: `Caixa: ${state.Caixas.content.find(caixa => caixa.cdcaixa == venda.cdIdentificador).identificador}`,
						style: "descricao"
					} : null
					: null,

				troca ? {
					table: {
						headerRows: 1,
						widths: [18, 75, 25, 29, 35],
						body: tabelaTroca,
					},
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 1;
						},
						paddingRight: function () {
							return 1;
						},
						paddingTop: function () {
							return 1;
						}
					},
				} : null,

				troca ? {
					text: `Troca: ${Number(venda.valorTroca).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "valores"
				} : null,

				{
					table: {
						headerRows: 1,
						widths: [18, 75, 25, 29, 35],
						body: tabelaProdutos,
					},
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 1;
						},
						paddingRight: function () {
							return 1;
						},
						paddingTop: function () {
							return 1;
						}
					},
				},

				{
					columns: [
						venda.itens.length > 0 ? {
							text: `Qtde. peças: ${(venda.itens.filter(item => item.cdTipo != "T" && item.situacao != "D").length > 0 ?
								venda.itens.filter(item => item.cdTipo != "T" && item.situacao != "D")
									.map(item => item.quantidade).reduce((t, v) => t += v) : 0)
								.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
							style: "valores",
							alignment: "left",
							width: "40%"
						} : null,

						{
							text: `Sub-total: ${(venda.valorBruto || 0).toLocaleString("pt-BR", {
								style: "currency", currency: "BRL"
							})}`, style: "valores", alignment: "right"
						},
					]
				},

				troca ? {
					text: `Troca: ${Number(venda.valorTroca || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "valores", width: "60%"
				} : null,
				Number(venda.valorConsorcio) > 0 ? {
					text: `Consórcio: ${Number(venda.valorConsorcio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "valores",
				} : null,

				Number(venda.descontoMoeda) > 0 ? {
					text: `Desconto: ${(venda.descontoMoeda >= 0 ? venda.descontoMoeda : 0).toLocaleString("pt-BR", {
						style: "currency", currency: "BRL"
					})}`, style: "valores", alignment: "right"
				} : null,

				{
					text: `Valor ${venda.valorPago > 0 ? "pago" : venda.valorCondicional > 0 ? "condicional" : "total"}: ${Number(venda.valorPago) > 0 ?
						Number(venda.valorPago).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) :
						Number(venda.valorCondicional) > 0 ?
							Number(venda.valorCondicional).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) :
							Number(0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
					}`,
					style: "valores",
					margin: [5, 0, 0, 0],
					fontSize: 10
				},

				{ 
					columns:[
						{text: `Pedido: ${venda.cdVenda}`, style: "valores", alignment: "left", width: "40%" },
						tabelaParcelasDuplicata.length > 1 ? {text: `${nomeDuplicata}`, style: "valores", alignment: "right"}:null,
					]
				},
				
				tabelaParcelas.length > 1 ? {
					table: {
						headerRows: 1,
						body: tabelaParcelas,
						widths: ["auto", "*", "*", "*"],
					},
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 2;
						},
						paddingRight: function () {
							return 2;
						},
						paddingTop: function () {
							return 1;
						}
					},
				} : null,

				tabelaParcelasDuplicata.length > 1 ? {
					table: {
						headerRows: 1,
						body: tabelaParcelasDuplicata,
						widths: ["auto", "auto", "*", "auto"],
					},
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 2;
						},
						paddingRight: function () {
							return 2;
						},
						paddingTop: function () {
							return 1;
						}
					},
				} : null,

				tabelaCartao.length > 1 ? {
					table: {
						headerRows: 1,
						body: tabelaCartao,
						widths: ["auto", "*", "auto"],
					},
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 2;
						},
						paddingRight: function () {
							return 2;
						},
						paddingTop: function () {
							return 1;
						}
					},

					margin: tabelaCartao.length > 1 && tabelaParcelas.length > 1 ? [0, 10, 0, 0] : [0, 0, 0, 0]
				} : null,

				venda.valorCondicional > 0 ? {
					text: `PRAZO FECHAMENTO CONDICIONAL: ${new Date(
						new Date(`${venda.dataEmissao}`).getFullYear(),
						new Date(`${venda.dataEmissao}`).getMonth(),
						new Date(`${venda.dataEmissao}`).getDate() + 2
					).toLocaleDateString("pt-BR")}`,
					style: "header", fontSize: 10
				} : produtoEmPromocao ? {
					text: "NÃO TROCAMOS PRODUTO(S) EM PROMOÇÃO",
					style: "header", fontSize: 10
				} :	
					{
						text: `PRAZO MÁXIMO PARA TROCA: ${new Date(
							new Date(`${venda.dataEmissao}`).getFullYear(),
							new Date(`${venda.dataEmissao}`).getMonth(),
							new Date(`${venda.dataEmissao}`).getDate() + (empresa.prazoTrocaCupom || 30)
						).toLocaleDateString("pt-BR")}`,
						style: "header", fontSize: 10, margin: [0, 2, 0, 2],
					},

				{
					text: gerarMensagemAgradecimento(new Date().getHours()),
					style: "header"
				},
				
				!venda?.financeiro[0] || temPrazo ? {
					text: "_____________________________",
					style: "descricaoCentralizada", margin: [0,25,0,0],
				} : null,
				!venda?.financeiro[0] || temPrazo ? {
					text: "Assinatura",
					style: "descricaoCentralizada",
				} : null,

				{ text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape", margin: [0, 1, 0, 0] },
				// { text: "", style: "rodape" },
				{ text: `Via: ${venda.via || 1}`, style: "rodape", alignment: "right" },
			],

			styles
		});

		// return documento.print();
		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			state.base64 = url;
		});
	} catch (error) {
		console.log(error);
		return { error };
	}
};

export const gerarCupomPreVenda = async ({ state }, { empresa, venda, pageWidth }) => {
	try {
		const tabelaProdutos = [
			[
				{ text: "Cod", style: "headerTabela" },
				{ text: "Descrição venda", style: "headerTabela" },
				{ text: "Qtd", style: "headerTabela" },
				{ text: "Unit.", style: "headerTabela" },
				{ text: "Total", style: "headerTabela" },
			]
		];

		const tabelaTroca = [
			[
				{ text: "Cod", style: "headerTabela" },
				{ text: "Descrição troca", style: "headerTabela" },
				{ text: "Qtd", style: "headerTabela" },
				{ text: "Unit.", style: "headerTabela" },
				{ text: "Total", style: "headerTabela" },
			]
		];

		let troca = false;
		let produtosEmPromocao = false;
		venda.itens.forEach(item => {
			if(item.produto.cdpromocao) {
				produtosEmPromocao = true;
			}
			if (item.cdTipo != "T" && (item.situacao == "A" || item.situacao == "S" || !item.situacao) ||
				(item.situacao == "B" && (venda.cdIdentificador < 0))) {
				tabelaProdutos.push([
					{ text: item?.id ? item.id : item.produto.id, style: "itemTabela" },

					{ text: item?.descricao ? item.descricao : item.produto.descricao , style: "itemTabela", alignment: "left" },

					{ text: (item.quantidade || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },

					{ text: (item.valorUnitario).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },

					{
						text: (item.valorUnitario * item.quantidade).toFixed(2).replace(".", ","),
						style: "itemTabela", alignment: "right"
					},
				]);
			} else if (item.cdTipo == "T") {
				tabelaTroca.push([
					{ text: item.produto.id, style: "itemTabela" },

					{ text: item.produto.descricao, style: "itemTabela", alignment: "left" },

					{ text: (item.quantidade || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },

					{ text: (item.valorUnitario).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },

					{
						text: (item.valorUnitario * item.quantidade).toFixed(2).replace(".", ","),
						style: "itemTabela", alignment: "right"
					},
				]);
				troca = true;
			}
		});
	
		let pageSize = !pageWidth ? {
			width: 200,
			height: "auto"		
		} : pageWidth;
		let temPrazo = false;
		venda?.financeiro.forEach(
			financeiro => {
				if(!financeiro.valorVista){
					temPrazo = true;
				}
			}
		);
		const documento = pdfMake.createPdf({
			info: {
				title: `Pedido Nº ${venda.cdVenda}`,
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: [1, 1, 1, 5],

			pageOrientation: "portrait",

			watermark: venda.cancelada == "S" ? { text: "CANCELADA", opacity: 0.3, bold: true, italics: false, angle: 80 } : null,

			content: [
				...gerarHeaderCupom(empresa),

				venda.valorPago > 0 ? { text: "Documento não fiscal", style: "naoFiscal" } : null,

				{
					columns: [
						{ text: `Pré Venda nº ${venda.cdVenda}`, style: "headerPedido", width: "40%" },
						{
							text: `Data: ${new Date(`${venda.dataEmissao}`).toLocaleString("pt-BR")}`,
							style: "dataDocumento"
						},
					]
				},

				{
					text: `Cliente: ${venda.cliente.id} - ${(venda.cliente.id == state.ClientePadrao.id) && venda.nomeCliente ? venda.nomeCliente : venda.cliente.nome}`,
					style: "header"
				},

				venda.cliente.avalista?.cdcliente? {
					text: `Avalista: ${venda.cliente.avalista.cdcliente} - ${venda.cliente.avalista.nome}`,
					style: "header"
				}:null,

				venda.cliente.id !== state.ClientePadrao.id ?{
					columns: [
						{ text: `${venda.cliente.tipoPessoa == "F" ? "CPF" : "CNPJ"}: ${venda.cliente.id == state.ClientePadrao.id ? venda.cnpjCliente : venda.cliente.cnpj ? venda.cliente.cnpj : "Não cadastrado"}`, style: "descricao" },
						{ text: `${venda.cliente.tipoPessoa == "F" ? "RG" : "IE"}: ${venda.cliente.id == state.ClientePadrao.id ? venda.ieCliente : venda.cliente.ierg ? venda.cliente.ierg : "Não cadastrado"}`, style: "descricao", alignment: "right" }
					]
				}:null,

				venda.observacao ? {
					text: `Obs.: ${venda.observacao}.`,
					style: "descricao"
				} : null,

				venda.cliente.id !== state.ClientePadrao.id ?{
					text: `${venda.cliente.id == state.ClientePadrao.id ? venda.enderecoCliente : venda.cliente.endereco ? venda.cliente.endereco + `${venda.cliente.numero && venda.cliente.numero != "null" ? venda.cliente.numero : ""}` : "Não cadastrado"} - ${venda.cliente.id == state.ClientePadrao.id ? venda.bairroCliente : venda.cliente.bairro ? venda.cliente.bairro : "Não cadastrado"}`,
					style: "descricao"
				}:null,

				venda.cliente.id !== state.ClientePadrao.id ?{
					columns:[
						{ text: `CEP: ${venda.cliente.id == state.ClientePadrao.id ? venda.cepCliente : venda.cliente.cep ? venda.cliente.cep : "Não cadastrado"}.`, style: "descricao", width: "*" },				
						{ text: `Cidade: ${venda.cliente.id == state.ClientePadrao.id ? venda.cidadeCliente : venda.cliente.cidade ? venda.cliente.cidade.nomecidade : "Não cadastrado"}`, style: "descricao", width: "*", alignment: "right" },				
					]
				}:null,
				venda.cliente.id !== state.ClientePadrao.id ?{
					columns:[
						{ text: `Telefone: ${(venda.cliente.id == state.ClientePadrao.id) && venda.foneCliente ? venda.foneCliente : venda.cliente.fone ? venda.cliente.fone : "Não cadastrado"}`, style: "descricao" },
						{ text: `${venda.cliente.celular ? venda.cliente.celular : "Não cadastrado"}`, style: "descricao", alignment: "right" },
					]
				}:null,
				

				{
					columns: [
						{ text: `Vendedor: ${venda.vendedor.nome}`, style: "descricao" },
						{ text: `Usuario: ${venda.usuarioCaixa.nome}`, style: "descricao", alignment: "right" },
					]
				},

				state.Caixas && venda.cdIdentificador ?
					state.Caixas.content.find(caixa => caixa.cdcaixa == venda.cdIdentificador) ? {
						text: `Caixa: ${state.Caixas.content.find(caixa => caixa.cdcaixa == venda.cdIdentificador).identificador}`,
						style: "descricao"
					} : null
					: null,

				troca ? {
					table: {
						headerRows: 1,
						widths: [18, 75, 25, 29, 35],
						body: tabelaTroca,
					},
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 1;
						},
						paddingRight: function () {
							return 1;
						},
						paddingTop: function () {
							return 1;
						}
					},
				} : null,

				troca ? {
					text: `Troca: ${Number(venda.valorTroca).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "valores"
				} : null,

				{
					table: {
						headerRows: 1,
						widths: [18, 75, 25, 29, 35],
						body: tabelaProdutos,
					},
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 1;
						},
						paddingRight: function () {
							return 1;
						},
						paddingTop: function () {
							return 1;
						}
					},
				},

				{
					columns: [
						venda.itens.length > 0 ? {
							text: `Qtde. peças: ${(venda.itens.filter(item => item.cdTipo != "T" && item.situacao != "D").length > 0 ?
								venda.itens.filter(item => item.cdTipo != "T" && item.situacao != "D")
									.map(item => item.quantidade).reduce((t, v) => t += v) : 0)
								.toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
							style: "valores",
							alignment: "left",
							width: "40%"
						} : null,

						{
							text: `Sub-total: ${(venda.valorBruto || 0).toLocaleString("pt-BR", {
								style: "currency", currency: "BRL"
							})}`, style: "valores", alignment: "right"
						},
					]
				},

				troca ? {
					text: `Troca: ${Number(venda.valorTroca || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "valores", width: "60%"
				} : null,
				Number(venda.valorConsorcio) > 0 ? {
					text: `Consórcio: ${Number(venda.valorConsorcio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "valores",
				} : null,

				Number(venda.descontoMoeda) > 0 ? {
					text: `Desconto: ${(venda.descontoMoeda >= 0 ? venda.descontoMoeda : 0).toLocaleString("pt-BR", {
						style: "currency", currency: "BRL"
					})}`, style: "valores", alignment: "right"
				} : null,

				{ text: `Pedido: ${venda.cdVenda}`, style: "valores", alignment: "left", width: "40%" },

				!produtosEmPromocao ? {
					text: `PRAZO MÁXIMO PARA TROCA: ${new Date(
						new Date(`${venda.dataEmissao}`).getFullYear(),
						new Date(`${venda.dataEmissao}`).getMonth(),
						new Date(`${venda.dataEmissao}`).getDate() + (empresa.prazoTrocaCupom || 30)
					).toLocaleDateString("pt-BR")}`,
					style: "header", fontSize: 10, margin: [0, 2, 0, 2],
				} : {
					text: "NÃO TROCAMOS PRODUTO(S) EM PROMOÇÃO",
					style: "header", fontSize: 10, margin: [0, 2, 0, 2],
				},

				{
					text: gerarMensagemAgradecimento(new Date().getHours()),
					style: "header"
				},
				
				!venda?.financeiro[0] || temPrazo ? {
					text: "_____________________________",
					style: "descricaoCentralizada", margin: [0,25,0,0],
				} : null,
				!venda?.financeiro[0] || temPrazo ? {
					text: "Assinatura",
					style: "descricaoCentralizada",
				} : null,

				{ text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape", margin: [0, 1, 0, 0] },
				// { text: "", style: "rodape" },
				{ text: `Via: ${venda.via || 1}`, style: "rodape", alignment: "right" },
			],

			styles
		});

		// return documento.print();
		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			state.base64 = url;
		});
	} catch (error) {
		console.log(error);
		return { error };
	}
};

export const gerarRecibo = async (context, { empresa, parcelas, cliente, pagamento, conta, valorPago, pageWidth }) => {
	const emp = await context.dispatch("buscarEmpresaId", {id: conta.cdempresa});
	const tabelaParcelas = [
		[
			{ text: "Pedido.", style: "headerTabela" },
			{ text: "Nº Parc.", style: "headerTabela" },
			{ text: "Venc.", style: "headerTabela" },
			{ text: "$ Pago", style: "headerTabela" },
		]
	];

	parcelas.forEach(item => {
		tabelaParcelas.push([
			{ text: item.cdMovi, style: "itemTabela" },

			{ text: item.cdLan, style: "itemTabela" },

			{
				text: new Date(
					new Date(item.dataVenc).getFullYear(), new Date(item.dataVenc).getMonth(),
					new Date(item.dataVenc).getDate() + 1
				).toLocaleDateString("pt-BR"),
				style: "itemTabela"
			},

			{
				text: Number(Number(item.valorPagoBaixa) > 0 ? item.valorPagoBaixa : item.jurosPagoBaixa).toFixed(2).replace(".", ","),
				style: "itemTabela",
				alignment: "right"
			}
		]);
	});

	let pageSize = !pageWidth ? {
		width: 200,
		height: "auto"
	} : pageWidth;

	const documento = pdfMake.createPdf({
		info: {
			title: `Recibo ${cliente.nome}`,
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [1, 1, 1, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(new Empresa(emp)),

			{ text: "Documento não fiscal", style: "naoFiscal" },
			{ text: `Impressão: ${new Date().toLocaleString("pt-BR")}`, style: "naoFiscal" },

			{ text: "RECIBO", style: "header" },

			{
				text: `Data recebimento: ${new Date(conta.databaixa).toLocaleString("pt-BR").split(" ")[1] == "21:00:00" ?
					new Date(
						new Date(conta.databaixa).getFullYear(),
						new Date(conta.databaixa).getMonth(),
						new Date(conta.databaixa).getDate() + 1
					).toLocaleDateString("pt-BR")
					: new Date(conta.databaixa).toLocaleString("pt-BR")}`, style: "descricao"
			},

			{
				text: [
					"Recebemos de: ",
					{ text: `${cliente.nome} - Código: ${cliente.id}`, style: { bold: true, fontSize: 9 } },
				],
				style: "descricao"
			},

			{
				text: [
					"A importância de: ",
					{
						text: Number(valorPago).toLocaleString("pt-BR", {
							style: "currency", currency: "BRL",
						}),
						style: {
							bold: true,
							fontSize: 9,
						}
					},
				],
				style: "descricao",
			},

			{
				text: extenso(Number(valorPago).toFixed(2).replace(".", ","), {
					mode: "currency"
				}).toLocaleUpperCase("pt-BR"),
				style: "descricao",
				bold: true,
				fontSize: 9,
				alignment: "justify"
			},

			{ text: `Tipo de pagamento: ${pagamento}`, style: "descricao" },

			{
				table: {
					headerRows: 1,
					body: tabelaParcelas,
					widths: ["auto", "auto", "*", "*"],
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingLeft: function () {
						return 1;
					},
					paddingRight: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					}
				},
				width: 198
			},

			{
				text: `Total: ${Number(conta.vlrtitulo + ((conta.aumdesc < 0) ? (conta.aumdesc * -1) : 0)).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL",
				})}`,
				style: {
					bold: true,
					fontSize: 9,
					alignment: "right"
				}
			},

			conta.aumdesc > 0 ? {
				text: `Desconto: ${Number(conta.aumdesc).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL",
				})}`,
				style: {
					bold: true,
					fontSize: 9,
					alignment: "right"
				}
			} : null,

			{
				text: `Valor pago: ${Number(valorPago).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL",
				})}`,
				style: {
					bold: true,
					fontSize: 9,
					alignment: "right"
				}
			},

			(Number(valorPago - conta.vlrtitulo) > 0 && !conta.aumdesc) ? {
				text: `Troco: ${Number(valorPago - conta.vlrtitulo).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL",
				})}`,
				style: {
					bold: true,
					fontSize: 9,
					alignment: "right"
				}
			} : null,


			{
				text: gerarMensagemAgradecimento(new Date().getHours()),
				style: "header"
			},

			{
				qr: `http://${empresa.home}`,
				fit: 80,
				alignment: "center",
				margin: [0, 1, 0, 1]
			},

			{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
			{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },
		],

		styles
	});

	// return documento.print();
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
	});
};

export const gerarCupomTroca = async (context, { empresa, venda, pageWidth }) => {
	const emp = await context.dispatch("buscarEmpresaId", {id: empresa});
	let pageSize = !pageWidth ? {
		width: 200,
		height: "auto"
	} : pageWidth;

	const documento = pdfMake.createPdf({
		info: {
			title: `Cupom Troca ${venda.cdVenda}`,
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [1, 1, 1, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(new Empresa(emp)),
			{ text: "Documento não fiscal", style: "naoFiscal" },

			{
				columns: [
					{ text: `Pedido nº ${venda.cdVenda}`, style: "headerPedido", width: "40%" },
					{
						text: `Data: ${new Date(`${venda.dataEmissao}`).toLocaleString("pt-BR")}`,
						style: "dataDocumento"
					},
				]
			},

			{ text: "CUPOM DE TROCA", style: "header" },

			{
				text: "PREZADO CLIENTE",
				style: "header",
				fontSize: 10
			},

			{
				text: "PARA EFETUAR TROCA DE MERCADORIAS É NECESSÁRIO APRESENTAR ESTE CUPOM OU O DOCUMENTO ORIGINAL DE VENDA",
				style: "descricao",
				alignment: "center"
			},

			{
				text: `VENDEDOR: ${venda.vendedor.nome}`,
				style: "descricao",
				margin: [2, 0]
			},

			{ text: `Impressão: ${new Date().toLocaleString("pt-BR")}`, style: "naoFiscal" },

			{
				text: `PRAZO MÁXIMO PARA TROCA: ${new Date(
					new Date(`${venda.dataEmissao}`).getFullYear(),
					new Date(`${venda.dataEmissao}`).getMonth(),
					new Date(`${venda.dataEmissao}`).getDate() + (emp.prazoTrocaCupom || 30)
				).toLocaleDateString("pt-BR")}`,
				style: "header", fontSize: 10
			},
			{
				text: gerarMensagemAgradecimento(new Date().getHours()),
				style: "header"
			},

			{
				image: gerarCodigoBarras(`F${venda.cdVenda}`, 30, "CODE128"),
				alignment: "center",
				margin: [0, 1, 0, 1]
			},

			{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
			{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },
		],

		styles
	});

	// return documento.print();
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
	});
};

export const gerarExtrato = async (context, { empresa, parcelas, cliente, pageWidth, total }) => {

	const tabelaParcelas = [
		[
			{ text: "Ped.", style: "headerTabela" },
			{ text: "Parc", style: "headerTabela" },
			{ text: "Emissão", style: "headerTabela" },
			{ text: "Venc.", style: "headerTabela" },
			{ text: "$ Saldo", style: "headerTabela" },
		]
	];

	parcelas.forEach(item => {
		tabelaParcelas.push([
			{ text: item.cdMovi, style: "itemTabela" },
			{ text: item.cdLan, style: "itemTabela" },
			{
				text: new Date(
					new Date(item.dataDoc).getFullYear(), new Date(item.dataDoc).getMonth(),
					new Date(item.dataDoc).getDate() + 1
				).toLocaleDateString("pt-BR"),
				style: "itemTabela"
			},
			{
				text: new Date(
					new Date(item.dataVenc).getFullYear(), new Date(item.dataVenc).getMonth(),
					new Date(item.dataVenc).getDate() + 1
				).toLocaleDateString("pt-BR"),
				style: "itemTabela"
			},
			{
				text: Number(item.restante || 0).toFixed(2).replace(".", ","),
				style: "itemTabela",
				alignment: "right"
			},
		]);
	});

	let pageSize = !pageWidth ? {
		width: 200,
		height: "auto"
	} : pageWidth;

	const documento = pdfMake.createPdf({
		info: {
			title: `Extrato ${cliente.nome}`,
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [1, 1, 1, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(empresa),

			{ text: "EXTRATO DE DÉBITOS", style: "header" },
			{ text: `Cliente: ${cliente.id} - ${cliente.nome}`, style: { bold: true, fontSize: 9 } },

			{
				table: {
					headerRows: 1,
					body: tabelaParcelas,
					widths: ["auto", "auto", "*", "*", "*"],
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingLeft: function () {
						return 1;
					},
					paddingRight: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					}
				},
				width: 200
			},

			{
				text: `Total ${total ? "da dívida" : "selecionado"} em: ${new Date().toLocaleDateString("pt-BR")} ${Number(parcelas.map(
					parc => parc.restante
				).reduce((total, vlr) => total += vlr)).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL",
				})}`,
				style: {
					bold: true,
					fontSize: 9,
					alignment: "right"
				},
			},


			{
				text: extenso(Number(parcelas.map(
					parc => parc.restante
				).reduce((total, vlr) => total += vlr)).toFixed(2).replace(".", ","), {
					mode: "currency"
				}).toLocaleUpperCase("pt-BR"),
				style: "descricao",
				bold: true,
				fontSize: 9,
				alignment: "justify"
			},

			{
				text: gerarMensagemAgradecimento(new Date().getHours()),
				style: "header"
			},

			{
				qr: `http://${empresa.home}`,
				fit: 80,
				alignment: "center",
				margin: [0, 1, 0, 1]
			},
			{ text: "Documento não fiscal", style: "naoFiscal" },

			{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
			{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },
		],

		styles
	});

	// return documento.print();
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
	});
};


export const gerarComprovanteConsorcio = async (context, consorcio) => {
	const tabelaParcelas = [
		[
			{ text: "Nº Parc.", style: "headerTabela" },
			{ text: "Venc.", style: "headerTabela" },
			{ text: "$ Parc.", style: "headerTabela" },
			{ text: "$ Saldo", style: "headerTabela" },
		]
	];

	consorcio.parcelas.forEach(item => {
		tabelaParcelas.push([
			{ text: item.cdLan, style: "itemTabela" },
			{
				text: new Date(
					new Date(item.dataVenc).getFullYear(), new Date(item.dataVenc).getMonth(),
					new Date(item.dataVenc).getDate() + 1
				).toLocaleDateString("pt-BR"),
				style: "itemTabela"
			},
			{
				text: Number(item.contarc || 0).toFixed(2).replace(".", ","),
				style: "itemTabela",
				alignment: "right"
			},
			{
				text: Number((item.contarc || 0) - (item.valor || 0)).toFixed(2).replace(".", ","),
				style: "itemTabela",
				alignment: "right"
			}
		]);
	});

	let pageSize = !consorcio.pageWidth ? {
		width: 200,
		height: "auto"
	} : consorcio.pageWidth;

	const documento = pdfMake.createPdf({
		info: {
			title: `Consórcio de ${consorcio.cliente.nome}`,
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [1, 1, 1, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(consorcio.empresa),

			{ text: `CONSÓRCIO Nº ${consorcio.cdconsorcio}`, style: "header" },
			{ text: `Cliente: ${consorcio.cliente.id} - ${consorcio.cliente.nome}`, style: { bold: true, fontSize: 9 } },
			{ text: `Vendedor: ${consorcio.vendedor.id} - ${consorcio.vendedor.nome}`, style: { bold: true, fontSize: 9 } },
			{ text: `Data de cadastro: ${new Date(consorcio.data.split("-")).toLocaleDateString("pt-BR")}`, style: { fontSize: 9 } },

			{
				table: {
					headerRows: 1,
					body: tabelaParcelas,
					widths: ["auto", "*", "*", "*"],
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingLeft: function () {
						return 1;
					},
					paddingRight: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					}
				},
				width: 200
			},

			{
				text: `Valor total do consórcio: ${Number(consorcio.valor).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL",
				})}`,
				style: {
					bold: true,
					fontSize: 9,
					alignment: "right"
				},
			},

			{
				text: `Valor recebido: ${Number(consorcio.parcelas.map(parc => (parc.valor || 0)).reduce((t, v) => t += v)).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL",
				})}`,
				style: {
					bold: true,
					fontSize: 9,
					alignment: "right"
				},
			},

			{
				text: `Saldo devedor: ${Number(consorcio.parcelas.map(parc => ((parc.contarc || 0) - (parc.valor || 0))).reduce((t, v) => t += v)).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL",
				})}`,
				style: {
					bold: true,
					fontSize: 9,
					alignment: "right"
				},
			},

			{
				text: gerarMensagemAgradecimento(new Date().getHours()),
				style: "header"
			},

			{
				qr: `http://${consorcio.empresa.home}`,
				fit: 80,
				alignment: "center",
				margin: [0, 1, 0, 1]
			},
			{ text: "Documento não fiscal", style: "naoFiscal" },

			{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
			{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },
		],

		styles
	});

	// return documento.print();
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
	});
};
export const gerarRelatorioCompraUnica = async (context, {empresa, pageWidth, compra}) => {
	const entrada = await context.dispatch("buscarComprasUnica", {...compra, cdfornecedor: compra.fornecedor.id});
	const tabelaProdutos = [];
	let totalizador = 0;
	let totalProdutos = 0;
	const fornecedor = compra.fornecedor;
	const transporte = compra.transporte;
	function breakword(ref){
		var newStr = "";
		if(!ref) return;
		var str = ref.trim().substring(0, 41);
		let index = 21;
		const [inteiro, decimal] = (str.length / index).toString().split(".");
		var repetir = decimal > 0 ? inteiro + 1 : inteiro;
		for(let i = 0; i < repetir; i++){
			newStr += str.substring(0, index) + (i + 1 < repetir ? "\n" : "");
			str = str.substring(index);
			i++;
		}
		return newStr.trim();
		
	}

	tabelaProdutos.push([
		{text: "Cód. Produto", style:"headerTabela"},
		{text: "Desc. Produto", style:"headerTabela"},
		{text: "Qtd.", style:"headerTabela"},
		{text: "$ Unitário", style:"headerTabela"},

	]);
	entrada.estoque.forEach(item => {
		totalizador += item.vlrcompra;
		totalProdutos += item.quantidade;
		tabelaProdutos.push([
			{text: `${item.cdproduto}`, style: "itemTabela"},
			{text: `${item.descricao}`, style: "itemTabela"},
			{text: `${item.quantidade}`, style: "itemTabela"},
			{text: `${(item.vlrunitario || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}`, style: "itemTabela"},
		]);
	});
	let pageSize = !pageWidth ? {
		width: 200,
		height: "auto"
	} : pageWidth;
	const documento = pdfMake.createPdf({
		info: {
			title: "Relatório Compra",
			author: "BDS Soluções em Sistemas"
		},
		pageSize,
		pageMargins: pageWidth ? [20, 5, 20, 5] : [5, 1, 5, 5],
		pageOrientation: "portrait",
		content: [
			...gerarHeaderCupom(empresa),
			pageWidth ? {
				columns: [
					[
						{text:`Nº Compra: ${entrada.cdcompra}`, style: "headerText"},
						{text:`Data Compra: ${entrada.datacompra.split("-").reverse().join("/")}`, style: "headerText"},
						{text:`CFOP Compra: ${entrada.cfop}`, style: "headerText"},
						{text:`Situação: ${entrada.confirmada === "S" ? "Confirmada" : "Pendente"}`, style: "headerText"},
						entrada.nfechave != "" ? {text: `Chave NF-e: ${entrada?.nfechave}`, width: "auto", style: "headerText"} : {},
					],
					[
						{text: `Fornecedor: ${fornecedor.nome}`, style: "headerText"},
						transporte ? {text: `Transporte: ${breakword(transporte?.nome)}`, style: "headerText", margin: [0, 0, 20, 0]} : {}
					]
				
				],
				margin: [0, 15, 0, 0]
			} : {
				columns: [
					[
						{text:`Nº Compra: ${entrada.cdcompra}`, style: "headerText"},
						{text:`Data Compra: ${entrada.datacompra.split("-").reverse().join("/")}`, style: "headerText"},
						{text:`CFOP Compra: ${entrada.cfop}`, style: "headerText"},
						{text:`Situação: ${entrada.confirmada === "S" ? "Confirmada" : "Pendente"}`, style: "headerText"},
						entrada.nfechave != "" ? {text: `Chave NF-e: ${breakword(entrada.nfechave)}`, width: "auto", style: "headerText"} : {},
						{text: `Fornecedor: ${fornecedor.nome}`, style: "headerText"},
						transporte ? {text: `Transporte: ${breakword(transporte?.nome)}`, style: "headerText"} : {}
					],
				],
				margin: [0, 15, 0, 0]
			},
			{
				margin: [0, 10, 0, 0],
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: ["auto", "*", "auto", "auto" ],
				},
				layout: {
					fillColor: function (rowIndex, ) {
						return (rowIndex % 2 === 0) ? "#CCCCCC" : null;
					}
				}
			},
			{
				text: [
					{text: `Total Compra: ${(totalizador || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}`},
					"\n",
					{text: `Total de Produtos: ${(totalProdutos || 0)}`}
				],
				style: "total"
			}
		],
		
		styles: {
			descricao: pageWidth ? {
				fontSize: 10,
			} : {
				fontSize: 9
			},
			total: pageWidth ? {	
				bold: true,
				margin: [0, 10, 0, 0],
				fontSize: 13,
				alignment: "right"
			} : {
				bold: true,
				margin: [0, 10, 0, 0],
				fontSize: 10,
				alignment: "right"
			},
			headerText: pageWidth ? {
				alignment: "left",
				bold: true,
				fontSize: 13,
			} : {
				alignment: "left",
				bold: true,
				fontSize: 9
			},
			headerTabela: pageWidth ? {
				bold: true,
				alignment: "center",
				fontSize: 14,
				noWrap: true,
			} : {
				bold: true,
				alignment: "center",
				fontSize: 9,
			},
			itemTabela: pageWidth ? {
				alignment: "center",
			} : {
				alignment: "center",
				fontSize: 8
			}
		}
	});
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
	});
};

export const gerarRelatorioCompras = async (context, {empresa, compras, dataInicio, dataFinal, pageWidth,}) => {
	
	const tabelaCompras = [];
	let totalizador = 0;
	let tableWidths = null;
	
	if(pageWidth){
		tableWidths = ["auto", "auto", "*", "auto"];
		tabelaCompras.push([
			{text: "N° Compra", style: "headerTabela"},
			{text: "Data", style: "headerTabela"},
			{text: "Fornecedor", style: "headerTabela"},
			{text: "$ Compra", style: "headerTabela"},
		]);
		compras.forEach(compra => {
			totalizador += Number(compra.valorbrutopedido);
			tabelaCompras.push([
				{text: `${compra.cdcompra}`, style: "itemTabela", color: "#3B4599"},
				{text: `${compra.datacompra.split("-").reverse().join("/")}`, style: "itemTabela"},
				{text: `${compra.fornecedor.id} - ${compra.fornecedor.nome}`, style: "itemTabela"},
				{text: `${(compra.valorbrutopedido || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}`, style: "itemTabela"},
			]);
		});
	} else {
		tableWidths = [30, 45, 40, "*"];
		tabelaCompras.push([
			{text: "N° Compra", style: "headerTabela"},
			{text: "Data", style: "headerTabela"},
			{text: "Fornecedor", style: "headerTabela"},
			{text: "Valor Compra", style: "headerTabela"},
		]);
		compras.forEach(compra => {
			totalizador += Number(compra.valorbrutopedido);
			tabelaCompras.push([
				{text: `${compra.cdcompra}`, style: "itemTabela"},
				{text: `${compra.datacompra.split("-").reverse().join("/")}`, style: "itemTabela"},
				pageWidth ? {text: `${compra.fornecedor.id} - ${compra.fornecedor.nome}`, style: "itemTabela"} :
					{text: `${compra.fornecedor.nome}`, style: "itemTabela"},
				{text: `${(compra.valorbrutopedido || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}`, style: "itemTabela"},
			]);
		});
	}
	let pageSize = !pageWidth ? {
		width: 200,
		height: "auto"
	} : pageWidth;
	const documento = pdfMake.createPdf({
		info: {
			title: "Relatório Compras por data",
			author: "BDS Soluções em Sistemas"
		},
		pageSize,
		pageMargins: pageWidth ? [20, 5, 20, 5] : [1, 1, 1, 5],
		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(empresa),
			{
				margin: [0, 10, 0, 0],
				text: `RELATÓRIO DE COMPRAS DIA ${(dataInicio).split("-").reverse().join("/")} AO DIA ${(dataFinal).split("-").reverse().join("/")}`,
				style: "headerText"
			},
			{
				margin: [0, 10, 0, 0],
				table: {
					headerRows: 1,
					body: tabelaCompras,
					widths: tableWidths,
				},
				layout: {
					fillColor: function (rowIndex, ) {
						return (rowIndex % 2 === 0) ? "#CCCCCC" : null;
					}
				}

			},
			{
				text: `Total: ${(totalizador || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}`,
				alignment: "right",
				bold: true,
				margin: [0, 10, 0, 0]
			},
		],
		styles: {
			descricao: pageWidth ? {
				fontSize: 10,
			} : {
				fontSize: 9
			},
			headerText: pageWidth ? {
				alignment: "center",
				bold: true,
				fontSize: 15,
			} : {
				alignment: "center",
				bold: true,
				fontSize: 10
			},
			headerTabela: pageWidth ? {
				bold: true,
				alignment: "center",
				fontSize: 14,
				noWrap: true,
			} : {
				bold: true,
				alignment: "center",
				fontSize: 8
			},
			itemTabela: pageWidth ? {
				alignment: "center",
			} : {
				alignment: "center",
				fontSize: 8
			}
		}
	});
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
	});

};

export const gerarExtratoContasReceber = async (context, { empresa, parcelas, dataI, dataF, cliente, pageWidth, condicao }) => {
	const tabelaParcelas = [];
	let tableWidths = null;

	let totalizador = {};

	if (condicao == "A") {
		if (!pageWidth) {
			tabelaParcelas.push([
				{ text: "Cliente", style: "headerTabela" },
				{ text: "Venc.", style: "headerTabela" },
				{ text: "$ Saldo", style: "headerTabela" },
			]);
			parcelas.forEach((item, index) => {
				if (!Object.keys(totalizador).includes(`${item.cdPessoa}`)) {
					totalizador[item.cdPessoa] = {
						restante: item.restante
					};
				} else {
					totalizador[item.cdPessoa].restante += item.restante;
				}
				tabelaParcelas.push([
					{ text: `${item.cdPessoa} - ${item.nome}`, style: "itemTabela", alignment: "left" },
					{
						text: new Date(item.dataVenc.split("-")).toLocaleDateString("pt-BR"),
						style: "itemTabela"
					},
					{
						text: Number(item.restante || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					},
				]);
				if (index + 1 < parcelas.length) {
					if (parcelas[index + 1].cdPessoa != item.cdPessoa) {
						tabelaParcelas.push([
							{ text: `Total de ${item.nome.substring(0, 30)}`, alignment: "right", bold: true, fontSize: 10, colSpan: 2 },
							{},
							{
								text: Number(totalizador[item.cdPessoa].restante || 0).toFixed(2).replace(".", ","),
								style: "itemTabela",
								alignment: "right",
								bold: true,
								fontSize: 10
							},
						]);
					}
				} else {
					tabelaParcelas.push([
						{ text: `Total de ${item.nome}`, alignment: "right", bold: true, fontSize: 10, colSpan: 2 },
						{},
						{
							text: Number(totalizador[item.cdPessoa].restante || 0).toFixed(2).replace(".", ","),
							style: "itemTabela",
							alignment: "right",
							bold: true,
							fontSize: 12
						},
					]);
				}
			});
			tableWidths = ["*", "auto", "auto"];
		} else {
			tabelaParcelas.push([
				{ text: "Emp.", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Ped.", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Parc.", style: "headerTabela", fillColor: "#c1c1c1" },
				// { text: "Cliente", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				// { text: "Fone", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				{ text: "Emissão", style: "headerTabela", fillColor: "#c1c1c1" },
				{text: "", style: "headerTabela", fillColor: "#c1c1c1"},
				{ text: "Venc.", style: "headerTabela", fillColor: "#c1c1c1" },
				{text: "", style: "headerTabela", fillColor: "#c1c1c1"},
				{ text: "Título", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Recebido", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Atraso", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Juros", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Saldo", style: "headerTabela", fillColor: "#c1c1c1" },
			]);
			let pessoa;
			parcelas.forEach((item, index) => {
				if(!pessoa || pessoa != item.nome){
					pessoa = item.nome;
					tabelaParcelas.push([
						{ text: `${item.cdPessoa} - ${pessoa}`, alignment: "left", bold: true, fontSize: 10, colSpan: 12 },
					]);
				}
				if (!Object.keys(totalizador).includes(`${item.cdPessoa}`)) {
					totalizador[item.cdPessoa] = {
						contarc: item.contarc,
						valor: item.valor,
						juros: Number(item.jurosCalculados || 0) + Number(item.jurosAcumulado || 0),
						restante: item.restante
					};
				} else {
					totalizador[item.cdPessoa].contarc += item.contarc;
					totalizador[item.cdPessoa].valor += item.valor;
					totalizador[item.cdPessoa].juros += Number(item.jurosCalculados || 0) + Number(item.jurosAcumulado || 0);
					totalizador[item.cdPessoa].restante += item.restante;
				}
				tabelaParcelas.push([
					{ text: item.cdEmpresa, style: "itemTabela" },
					{ text: `${item.cdMovi || item.cdDoc}`, style: "itemTabela" },
					{ text: item.cdLan, style: "itemTabela" },
					// { text: `${item.cdPessoa} - ${item.nome}`, style: "itemTabela", alignment: "left" },
					// { text: item.fone, style: "itemTabela", alignment: "left" },
					{
						text: new Date(item.dataDoc.split("-")).toLocaleDateString("pt-BR"),
						style: "itemTabela"
					},
					{text: "", style: "itemTabela"},
					{
						text: new Date(item.dataVenc.split("-")).toLocaleDateString("pt-BR"),
						style: "itemTabela"
					},
					{text: "", style: "itemTabela"},
					{
						text: Number(item.contarc || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					},
					{
						text: Number(item.valor || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					},
					{
						text: (item.diasEmAtraso || 0),
						style: "itemTabela"
					},
					{
						text: Number(Number(item.jurosCalculados || 0) + Number(item.jurosAcumulado || 0)).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					},
					{
						text: Number(item.restante).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					},
				]);
				if (index + 1 < parcelas.length) {
					if (parcelas[index + 1].cdPessoa != item.cdPessoa) {
						tabelaParcelas.push([
							{ text: "Totais:", alignment: "right", bold: true, fontSize: 10, colSpan: 7 },
							{},
							{},
							{},
							{},
							{},
							{},
							{
								text: Number(totalizador[item.cdPessoa].contarc || 0).toFixed(2).replace(".", ","),
								style: "itemTabela",
								alignment: "right",
								bold: true,
								fontSize: 10
							},
							{
								text: Number(totalizador[item.cdPessoa].valor || 0).toFixed(2).replace(".", ","),
								style: "itemTabela",
								alignment: "right",
								bold: true,
								fontSize: 10
							},
							{},
							{
								text: Number(totalizador[item.cdPessoa].juros || 0).toFixed(2).replace(".", ","),
								style: "itemTabela",
								alignment: "right",
								bold: true,
								fontSize: 10
							},
							{
								text: Number(totalizador[item.cdPessoa].restante || 0).toFixed(2).replace(".", ","),
								style: "itemTabela",
								alignment: "right",
								bold: true,
								fontSize: 10
							},
						]);
					}
				} else {
					tabelaParcelas.push([
						{ text: `Total de ${item.cdPessoa} - ${item.nome}`, alignment: "right", bold: true, fontSize: 10, colSpan: 7 },
						{},
						{},
						{},
						{},
						{},
						{},
						{
							text: Number(totalizador[item.cdPessoa].contarc || 0).toFixed(2).replace(".", ","),
							style: "itemTabela",
							alignment: "right",
							bold: true,
							fontSize: 10
						},
						{
							text: Number(totalizador[item.cdPessoa].valor || 0).toFixed(2).replace(".", ","),
							style: "itemTabela",
							alignment: "right",
							bold: true,
							fontSize: 10
						},
						{},
						{
							text: Number(totalizador[item.cdPessoa].juros || 0).toFixed(2).replace(".", ","),
							style: "itemTabela",
							alignment: "right",
							bold: true,
							fontSize: 10
						},
						{
							text: Number(totalizador[item.cdPessoa].restante || 0).toFixed(2).replace(".", ","),
							style: "itemTabela",
							alignment: "right",
							bold: true,
							fontSize: 10
						},
					]);
				}
			});
			tableWidths = ["auto", "auto", "auto", "*", 62, "auto", "auto", "auto", "auto", "auto", "auto", "auto"];
		}
	} else {
		if (!pageWidth) {
			tabelaParcelas.push([
				{ text: "Cliente", style: "headerTabela" },
				{ text: "Baixa", style: "headerTabela" },
				{ text: "$ Recebido", style: "headerTabela" },
			]);
			parcelas.forEach(item => {
				tabelaParcelas.push([
					{ text: `${item.cdPessoa} - ${item.nome}`, style: "itemTabela", alignment: "left" },
					{
						text: new Date(item.dataBaixa).toLocaleDateString("pt-BR"),
						style: "itemTabela"
					},
					{
						text: Number(item.valor || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					},
				]);
			});
			tableWidths = ["*", "auto", "auto"];
		} else {
			tabelaParcelas.push([
				{ text: "Cód. Baixa", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Cliente", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				{ text: "Fone", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				{ text: "Vencimento", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Baixa", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "$ Recebido", style: "headerTabela", fillColor: "#c1c1c1" },
			]);
			parcelas.forEach(item => {
				tabelaParcelas.push([
					{ text: item.cdDig, style: "itemTabela" },
					{ text: `${item.cdPessoa} - ${item.nome}`, style: "itemTabela", alignment: "left" },
					{ text: item.fone, style: "itemTabela", alignment: "left" },
					{
						text: new Date(item.dataVenc.split("-")).toLocaleDateString("pt-BR"),
						style: "itemTabela"
					},
					{
						text: new Date(item.dataBaixa).toLocaleDateString("pt-BR"),
						style: "itemTabela"
					},
					{
						text: Number(item.valor || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					}
				]);
			});
			tableWidths = ["auto", "*", "*", "auto", "auto", "auto"];
		}
	}

	let pageSize = !pageWidth ? {
		width: 200,
		height: "auto"
	} : pageWidth;

	const documento = pdfMake.createPdf({
		info: {
			title: "Extrato Contas a Receber",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: pageWidth ? [20, 5, 20, 5] : [1, 1, 1, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(empresa),

			{ text: `EXTRATO DE CONTAS ${condicao == "A" ? "A RECEBER" : "BAIXADAS"}`, style: "header" },
			{ 	
				table: {
					widths: ["*","*"],
					body: [
						[{
							text: `${condicao == "A" ? "Vencimento" : "Data baixa"} inicial: ${new Date(dataI.split("-")).toLocaleDateString("pt-BR")}`,
							style: { bold: true, fontSize: 9, alignment: "left",}
						},
						{
							text: `${condicao == "A" ? "Vencimento" : "Data baixa"} final: ${new Date(dataF.split("-")).toLocaleDateString("pt-BR")}`,		 	
							style: { bold: true, fontSize: 9, alignment: "right",}
						},]
					]
				},
				layout: "noBorders"
			},
			cliente ? { text: `Cliente: ${cliente.id} - ${cliente.nome} - ${cliente.fone} ${cliente.celular || ""} ${cliente.fax || ""}`, style: { bold: true, fontSize: 9 } } : null,

			{
				table: {
					headerRows: 1,
					body: tabelaParcelas,
					widths: tableWidths,
				},
				layout: pageWidth ? {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				} : {
					paddingBottom: function () {
						return 1;
					},
					paddingLeft: function () {
						return 1;
					},
					paddingRight: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
				},
				margin: [0, 3, 0, 3],
			},

			{
				text: `Total selecionado em: ${new Date().toLocaleDateString("pt-BR")} ${Number(parcelas.map(
					parc => (condicao == "A" ? parc.restante : parc.valor)
				).reduce((total, vlr) => total += vlr)).toLocaleString("pt-BR", {
					style: "currency", currency: "BRL",
				})}`,
				style: {
					bold: true,
					fontSize: 9,
					alignment: "right"
				},
			},

			{
				text: gerarMensagemAgradecimento(new Date().getHours()),
				style: "header"
			},

			{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
			{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
	});
};

const gerarTabelaMovimentoVista = (Caixa) => {
	let tabelaMovimentoVista = [];

	tabelaMovimentoVista.push([
		{ text: "Saldo anterior", bold: true, style: "headerTabela" },
		{
			text: `${(Caixa.saldoAnterior || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
			colSpan: 3,
			style: "itemTabela",
			alignment: "right"
		},
		{},
		{},
	]);
	if (Caixa.movimentacaoVista.filter(movi => (movi.valor > 0 || movi.valorManual > 0)).length > 0) {
		tabelaMovimentoVista.push([
			{ text: "Vendas", bold: true, style: "headerTabela" },
			{ text: "Sistema", bold: true, style: "headerTabela" },
			{ text: "Manual", bold: true, style: "headerTabela" },
			{ text: "Diferença", bold: true, style: "headerTabela" },
		]);
		Caixa.movimentacaoVista.filter(movi => (movi.valor > 0 || movi.valorManual > 0)).forEach(movimento => {
			tabelaMovimentoVista.push([
				{ text: `${movimento.descricao}`, style: "itemTabela", alignment: "right" },
				{ text: `${(movimento.valor || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right" },
				{ text: `${(movimento.valorManual || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right" },
				{
					text: `${(
						Number(Number(movimento.valorManual || 0).toFixed(2)) - Number(Number(movimento.valor || 0).toFixed(2))
					).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right"
				},
			]);
		});
	}

	if (Caixa.movimentacaoRecebimento.filter(movi => movi.vistaprazo != 3 && (movi.valor > 0 || movi.valorManual > 0)).length > 0) {
		tabelaMovimentoVista.push([
			{ text: "Recebimentos", bold: true, style: "headerTabela" },
			{ text: "Sistema", bold: true, style: "headerTabela" },
			{ text: "Manual", bold: true, style: "headerTabela" },
			{ text: "Diferença", bold: true, style: "headerTabela" },
		]);
		Caixa.movimentacaoRecebimento.filter(movi => movi.vistaprazo != 3 && (movi.valor > 0 || movi.valorManual > 0)).forEach(movimento => {
			tabelaMovimentoVista.push([
				{ text: `${movimento.descricao}`, style: "itemTabela", alignment: "right" },
				{ text: `${(movimento.valor || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right" },
				{ text: `${(movimento.valorManual || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right" },
				{
					text: `${(
						Number(Number(movimento.valorManual || 0).toFixed(2)) - Number(Number(movimento.valor || 0).toFixed(2))
					).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right"
				},
			]);
		});
	}

	tabelaMovimentoVista.push([
		{ text: "Pagamentos", bold: true, style: "headerTabela" },
		{
			text: `${(Caixa.movimentoCaixa.pagamento || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
			colSpan: 3,
			style: "itemTabela",
			alignment: "right"
		},
		{},
		{},
	]);

	tabelaMovimentoVista.push([
		{ text: "Recargas", bold: true, style: "headerTabela" },
		{
			text: `${(Caixa.movimentoCaixa.recarga || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
			colSpan: 3,
			style: "itemTabela",
			alignment: "right"
		},
		{},
		{},
	]);

	tabelaMovimentoVista.push([
		{ text: "Transferências", bold: true, style: "headerTabela" },
		{
			text: `${(Caixa.movimentoCaixa.transferencia || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
			colSpan: 3,
			style: "itemTabela",
			alignment: "right"
		},
		{},
		{},
	]);

	tabelaMovimentoVista.push([
		{ text: "Saldo atual", bold: true, style: "headerTabela" },
		{
			text: `${(Caixa.saldoFinal || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
			colSpan: 3,
			style: "itemTabela",
			alignment: "right"
		},
		{},
		{},
	]);

	return tabelaMovimentoVista;
};

const gerarTabelaMovimntoPrazo = (Caixa) => {
	let tabelaMovimentoPrazo = [];
	if (Caixa.movimentacaoPrazo.filter(movi => (movi.valor > 0 || movi.valorManual > 0)).length > 0) {
		tabelaMovimentoPrazo.push([
			{ text: "Vendas", bold: true, style: "headerTabela" },
			{ text: "Sistema", bold: true, style: "headerTabela" },
			{ text: "Manual", bold: true, style: "headerTabela" },
			{ text: "Diferença", bold: true, style: "headerTabela" },
		]);
		Caixa.movimentacaoPrazo.filter(movi => (movi.valor > 0 || movi.valorManual > 0)).forEach(movimento => {
			tabelaMovimentoPrazo.push([
				{ text: `${movimento.descricao}`, style: "itemTabela", alignment: "right" },
				{ text: `${(movimento.valor || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right" },
				{ text: `${(movimento.valorManual || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right" },
				{
					text: `${(
						Number(Number(movimento.valorManual || 0).toFixed(2)) - Number(Number(movimento.valor || 0).toFixed(2))
					).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right"
				},
			]);
		});
	}

	if (Caixa.movimentacaoRecebimento.filter(movi => movi.vistaprazo == 3 && (movi.valor > 0 || movi.valorManual > 0)).length > 0) {
		tabelaMovimentoPrazo.push([
			{ text: "Recebimentos", bold: true, style: "headerTabela" },
			{ text: "Sistema", bold: true, style: "headerTabela" },
			{ text: "Manual", bold: true, style: "headerTabela" },
			{ text: "Diferença", bold: true, style: "headerTabela" },
		]);
		Caixa.movimentacaoRecebimento.filter(movi => movi.vistaprazo == 3 && (movi.valor > 0 || movi.valorManual > 0)).forEach(movimento => {
			tabelaMovimentoPrazo.push([
				{ text: `${movimento.descricao}`, style: "itemTabela", alignment: "right" },
				{ text: `${(movimento.valor || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right" },
				{ text: `${(movimento.valorManual || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right" },
				{
					text: `${(
						Number(Number(movimento.valorManual || 0).toFixed(2)) - Number(Number(movimento.valor || 0).toFixed(2))
					).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right"
				},
			]);
		});
	}

	if (tabelaMovimentoPrazo.length > 0) {
		tabelaMovimentoPrazo.push([
			{ text: "Sistema a prazo", bold: true, style: "headerTabela" },
			{
				text: `${(
					[
						...Caixa.movimentacaoPrazo.filter(movi => (movi.valor > 0 || movi.valorManual > 0)).map(item => item.valor),
						...Caixa.movimentacaoRecebimento.filter(movi => movi.vistaprazo == 3 && (movi.valor > 0 || movi.valorManual > 0)).map(item => item.valor)
					].reduce((t, v) => t += (v || 0))
				).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, colSpan: 3, style: "itemTabela", alignment: "right"
			},
			{},
			{},
		]);
	}
	return tabelaMovimentoPrazo;
};

const gerarTabelaVendedores = (Caixa) => {
	let tabelaVendedores = [];

	Caixa.totalVendasPorVendedor.forEach(vendedor => {
		tabelaVendedores.push([
			{ text: `${vendedor.nome.toUpperCase()}`, style: "headerTabela", bold: true, colSpan: 4 },
			{},
			{},
			{},
		]);
		tabelaVendedores.push([
			{ text: "$ Venda", style: "headerTabela" },
			{ text: "$ Condicional", style: "headerTabela" },
			{ text: "$ Troca", style: "headerTabela" },
			{ text: "$ Cancelado", style: "headerTabela" },
		]);
		tabelaVendedores.push(
			[
				{ text: `${vendedor.qtdevenda}`, style: "itemTabela" },
				{ text: `${vendedor.qtdecondicional}`, style: "itemTabela" },
				{ text: `${vendedor.qtdetroca}`, style: "itemTabela" },
				{ text: `${vendedor.qtdecancelada}`, style: "itemTabela" },
			],
			[
				{ text: `${(vendedor.valorbruto || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela" },
				{ text: `${(vendedor.valorcondicional || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela" },
				{ text: `${(vendedor.valortroca || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela" },
				{ text: `${(vendedor.valorcancelado || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela" },
			],
			[
				{ text: "Total descontos", style: "headerTabela", colSpan: 2, alignment: "right" },
				{},
				{
					text: `${(vendedor.valordesconto || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "headerTabela",
					colSpan: 2,
					alignment: "right"
				},
				{},
			],
			[
				{ text: "Total vendido", style: "headerTabela", colSpan: 2, alignment: "right" },
				{},
				{
					text: `${(vendedor.valorpago || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "headerTabela",
					colSpan: 2,
					alignment: "right"
				},
				{},
			]
		);
	});
	return tabelaVendedores;
};

export const relatorioFechamentoCaixa = async ({ state }, { Caixa, resumido, pageWidth }) => {
	try {

		let tabelaMovimentoVista = [];

		let tabelaMovimentoPrazo = [];

		let tabelaVendedores = [];

		if (!resumido) {
			tabelaMovimentoVista = gerarTabelaMovimentoVista(Caixa);

			tabelaMovimentoPrazo = gerarTabelaMovimntoPrazo(Caixa);

			tabelaVendedores = gerarTabelaVendedores(Caixa);
		} else {
			if (Caixa.movimentacaoVista.filter(movi => movi.valor > 0).length > 0) {
				tabelaMovimentoVista.push([
					{ text: "Total de vendas", bold: true, style: "headerTabela", colSpan: 2, alignment: "right" },
					{},
					{
						text: `${(
							Caixa.movimentacaoVista.filter(movi => movi.valor > 0).map(movi => movi.valor).reduce((t, v) => t += v)
						).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
						colSpan: 2,
						style: "itemTabela",
						alignment: "right"
					},
					{},
				]);
			}

			if (Caixa.movimentacaoRecebimento.filter(movi => movi.vistaprazo != 3 && (movi.valor > 0)).length > 0) {
				tabelaMovimentoVista.push([
					{ text: "Total de recebimentos", style: "headerTabela", colSpan: 2, alignment: "right" },
					{},
					{
						text: `${(
							Caixa.movimentacaoRecebimento.filter(movi => movi.vistaprazo != 3 && (movi.valor > 0)).map(movi => movi.valor)
								.reduce((t, v) => t += v)
						).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
						style: "itemTabela",
						alignment: "right",
						colSpan: 2
					},
					{},
				]);
			}

			if (Caixa.movimentacaoPrazo.filter(movi => movi.valor > 0).length > 0) {
				tabelaMovimentoPrazo.push([
					{ text: "Total de vendas", bold: true, style: "headerTabela", colSpan: 2, alignment: "right" },
					{},
					{
						text: `${(
							Caixa.movimentacaoPrazo.filter(movi => movi.valor > 0).map(movi => movi.valor).reduce((t, v) => t += v)
						).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
						colSpan: 2,
						style: "itemTabela",
						alignment: "right"
					},
					{},
				]);
			}

			if (Caixa.movimentacaoRecebimento.filter(movi => movi.vistaprazo == 3 && (movi.valor > 0)).length > 0) {
				tabelaMovimentoPrazo.push([
					{ text: "Total de recebimentos", style: "headerTabela", colSpan: 2, alignment: "right" },
					{},
					{
						text: `${(
							Caixa.movimentacaoRecebimento.filter(movi => movi.vistaprazo == 3 && (movi.valor > 0)).map(movi => movi.valor)
								.reduce((t, v) => t += v)
						).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
						colSpan: 2,
						style: "itemTabela",
						alignment: "right",
					},
					{},
				]);
			}

			if (Caixa.totalVendasPorVendedor.length > 0) {
				tabelaVendedores.push([
					{ text: "Nome", style: "headerTabela", bold: true, colSpan: 2 },
					{},
					{ text: "$ Total vendido", style: "headerTabela", bold: true, colSpan: 2, alignment: "right" },
					{},
				]);
				Caixa.totalVendasPorVendedor.forEach(vendedor => {
					tabelaVendedores.push([
						{ text: `${vendedor.nome.toUpperCase()}`, style: "itemTabela", colSpan: 2 },
						{},
						{
							text: `${(vendedor.valorpago || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
							style: "itemTabela",
							colSpan: 2,
							alignment: "right"
						},
						{},
					]);
				});
			}
		}

		let pageSize = !pageWidth ? {
			width: 200,
			height: "auto"
		} : pageWidth;

		const documento = pdfMake.createPdf({
			info: {
				title: `Fechamento de caixa nº ${Caixa.cdFechamento}`,
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: [1, 1, 1, 5],

			pageOrientation: "portrait",

			content: [
				...gerarHeaderCupom(state.Empresa),

				{
					text: `FECHAMENTO: ${Caixa.caixa.identificador.toUpperCase()}`,
					style: "header"
				},

				{
					text: `Data: ${new Date(
						new Date(Caixa.data).getFullYear(),
						new Date(Caixa.data).getMonth(),
						new Date(Caixa.data).getDate() + 1
					).toLocaleDateString("pt-BR")}`,
					style: "headerPedido"
				},

				{
					text: `Obs. Abertura: ${Caixa.observacaoAbertura ? Caixa.observacaoAbertura : "Nada consta"}`,
					style: "descricao"
				},

				{
					text: `Obs. Fechamento: ${Caixa.observacaoFinal ? Caixa.observacaoFinal : "Nada consta"}`,
					style: "descricao"
				},

				{
					text: `${Caixa.vendasCaixa.qtdevenda} - ${Caixa.vendasCaixa.qtdevenda != 1 ? "Vendas" : "Venda"} no valor total de: ${(Caixa.vendasCaixa.valorpago || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "descricao"
				},

				{
					text: `${Caixa.vendasCaixa.qtdecondicional} - ${Caixa.vendasCaixa.qtdecondicional != 1 ? "Condicionais" : "Condicional"} no valor total de: ${(Caixa.vendasCaixa.valorcondicional || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "descricao"
				},

				{
					text: `${Caixa.vendasCaixa.qtdetroca} - ${Caixa.vendasCaixa.qtdetroca != 1 ? "Trocas" : "Troca"} no valor total de: ${(Caixa.vendasCaixa.valortroca || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "descricao"
				},


				{
					text: `${Caixa.vendasCaixa.qtdecancelada} - ${Caixa.vendasCaixa.qtdecancelada != 1 ? "Cancelados" : "Cancelado"} no valor total de: ${(Caixa.vendasCaixa.valorcancelado || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
					style: "descricao"
				},

				{
					text: "Movimentação a Vista",
					style: "header"
				},

				tabelaMovimentoVista.length > 0 ? {
					table: {
						headerRows: 0,
						widths: !resumido ? ["*", "auto", "auto", "auto"] : ["*", "*", "*", "*"],
						body: tabelaMovimentoVista,
					},
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 2;
						},
						paddingRight: function () {
							return 2;
						},
						paddingTop: function () {
							return 1;
						}
					},
					width: 200,
				} :
					{
						text: "Nenhuma movimentação a vista registrada.",
						style: "descricao",
						alignment: "center"
					},

				{
					text: "Movimentação a Prazo",
					style: "header"
				},

				tabelaMovimentoPrazo.length > 0 ? {
					table: {
						headerRows: 0,
						widths: !resumido ? ["*", "auto", "auto", "auto"] : ["*", "*", "*", "*"],
						body: tabelaMovimentoPrazo,
					},
					width: 200,
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 2;
						},
						paddingRight: function () {
							return 2;
						},
						paddingTop: function () {
							return 1;
						}
					}
				} :
					{
						text: "Nenhuma movimentação a prazo registrada.",
						style: "descricao",
						alignment: "center"
					},

				{
					text: "Movimentação por Vendedor",
					style: "header"
				},

				tabelaVendedores.length > 0 ? {
					table: {
						headerRows: 0,
						widths: !resumido ? ["*", "auto", "auto", "auto"] : ["*", "*", "*", "*"],
						body: tabelaVendedores,
					},
					width: 200,
					layout: {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 2;
						},
						paddingRight: function () {
							return 2;
						},
						paddingTop: function () {
							return 1;
						}
					}
				} :
					{
						text: "Nenhuma movimentação por vendedor registrada.",
						style: "descricao",
						alignment: "center"
					},

				{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
				{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape", margin: [0, 1, 0, 0] },
				{ text: `Impressão: ${new Date().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "rodape", margin: [0, 1, 0, 0] },
			],

			styles
		});

		// return documento.print();
		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			state.base64 = url;
		});
	} catch (error) {
		console.log(error);
		return { error };
	}
};

export const gerarRelatorioVenda = async ({ commit, state }, { anexo, payload, completo, datai, dataf, mostrarCondicional }) => {
	try {
		let tabelasVendas = [];
		let tableWidths = ["*", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"];
		const {
			valorbruto,
			valorpago,
			valortroca,
			valordesconto
		} = anexo;
		if (payload.length > 0) {
			if (!completo) {
				tabelasVendas.push([
					{ text: "Pedido", style: "headerTabela", bold: true, fillColor: "#C1C1C1" },
					{ text: "Emissão", style: "headerTabela", bold: true, fillColor: "#C1C1C1" },
					{ text: "Cliente", style: "headerTabela", alignment: "left", bold: true, fillColor: "#C1C1C1", colSpan: 5 },
					{},
					{},
					{},
					{},
					{ text: "Vlr. Bruto", style: "headerTabela", bold: true, fillColor: "#C1C1C1", alignment: "right" },
					{ text: "Desconto", style: "headerTabela", bold: true, fillColor: "#C1C1C1", alignment: "right" },
					{ text: "Vlr. Nota", style: "headerTabela", bold: true, fillColor: "#C1C1C1", alignment: "right" },
				]);

				payload.forEach((venda) => {
					tabelasVendas.push([
						{ text: `${venda.cdvenda}`, style: "itemTabela", bold: true },
						{ text: `${new Date(venda.dataemissao).toLocaleDateString("pt-BR")}`, style: "itemTabela", bold: true },
						{ text: `${venda.cliente.nome}`, style: "itemTabela", alignment: "left", bold: true, colSpan: 5 },
						{},
						{},
						{},
						{},
						{ text: `${(venda.valorbruto).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
						{ text: `${(venda.desmoeda).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
						{ text: `${(venda.valorpago).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
					]);
				});
				tableWidths = ["auto", "auto", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto"];
			}
			else {
				payload.forEach((venda) => {
					
					let tabelaRelatorioVenda = [];
					if(mostrarCondicional==="S"){
						tabelaRelatorioVenda.push([
							{ text: `${venda.cliente.id} - ${venda.cliente.nome}`, style: "headerTabela", alignment: "left", bold: true, fillColor: "#C1C1C1", colSpan: 4 },
							{ text: `VENDA: ${venda.cdvenda}`, style: "headerTabela", alignment: "left", bold: true, fillColor: "#C1C1C1", colSpan: 3 },
							{},
							{ text: `TOTAL: ${(venda.valorpago).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "headerTabela", alignment: "right", fillColor: "#C1C1C1", bold: true, colSpan: 3 },
							{},
						]);
					}else{
						tabelaRelatorioVenda.push([
							{ text: `${venda.cliente.id} - ${venda.cliente.nome}`, style: "headerTabela", alignment: "left", bold: true, fillColor: "#C1C1C1", colSpan: 4 },
							{},
							{},
							{},
							{ text: `VENDA: ${venda.cdvenda}`, style: "headerTabela", alignment: "left", bold: true, fillColor: "#C1C1C1", colSpan: 3 },
							{},
							{},
							{ text: `TOTAL: ${(venda.valorpago).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "headerTabela", alignment: "right", fillColor: "#C1C1C1", bold: true, colSpan: 3 },
							{},
							{},
						]);
					}					
					let tabelaItens = [];
					tabelaItens.push([
						{ text: "Id", style: "headerTabela", bold: true },
						{ text: "Descrição", style: "headerTabela", alignment: "left", bold: true },
						{ text: "Qtde", style: "headerTabela", bold: true },
						{ text: "$ Prod", style: "headerTabela", alignment: "right", bold: true },
					]);
					venda.itens.forEach((item) => {
						tabelaItens.push([
							{ text: `${item.produto.cdproduto}`, style: "itemTabela" },
							{ text: `${item.produto.descricao} ${item.cdtipo == "T" ? "(Troca)" : ""}`, style: "itemTabela", alignment: "left" },
							{ text: `${item.quantidade}`, style: "itemTabela" },
							{ text: `${venda.desperc ? (item.quantidade * (item.valorunit - (item.valorunit * (venda.desperc / 100)))).toFixed(2).replace(".", ",") : (item.quantidade * item.valorunit).toFixed(2).replace(".", ",")}`, alignment: "right", style: "itemTabela" },
						]);
					});					
					if(mostrarCondicional!=="S"){
						let tabelaFinanceiro = [];
						tabelaFinanceiro.push([
							{ text: "Parc", style: "headerTabela", bold: true },
							{ text: "Pagamento", style: "headerTabela", bold: true, alignment: "left" },
							{ text: "Emissão", style: "headerTabela", alignment: "left", bold: true },
							{ text: "Vencimento", style: "headerTabela", alignment: "left", bold: true },
							{ text: "Dt. Baixa", style: "headerTabela", alignment: "left", bold: true },
							{ text: "$ Título", style: "headerTabela", alignment: "right", bold: true },
						]);
						venda.financeiro.forEach((financeiro) => {
							tabelaFinanceiro.push([
								{ text: `${financeiro.cdlan}`, style: "itemTabela" },
								{ text: `${financeiro.pagamento}`, style: "itemTabela", alignment: "left" },
								{ text: `${new Date(`${financeiro.datadoc}`.split("-")).toLocaleDateString("pt-BR")}`, style: "itemTabela" },
								{ text: `${new Date(`${financeiro.datavenc}`.split("-")).toLocaleDateString("pt-BR")}`, style: "itemTabela" },
								{ text: `${financeiro.databaixa ? new Date(financeiro.databaixa).toLocaleDateString("pt-BR") : ""}`, style: "itemTabela" },
								{ text: `${financeiro.credito ? (financeiro.credito).toFixed(2).replace(".", ",") : (financeiro.contarc).toFixed(2).replace(".", ",")}`, style: "itemTabela", alignment: "right" },
							]);
						});
						tabelaRelatorioVenda.push([
							{
								table: {
									body: tabelaItens,
									widths: ["auto", "*", "auto", "auto"],
								},
								colSpan: 5,
								layout: {
									paddingLeft: function () {
										return 2;
									},

									paddingRight: function () {
										return 2;
									},

									paddingTop: function () {
										return 2;
									},

									paddingBottom: function () {
										return 2;
									},

									vLineWidth: function () {
										return 0;
									},

									hLineColor: function (i) {
										return i === 1 ? "black" : "#aaa";
									},
								},
							},
							{},
							{},
							{},
							{},
							{
								table: {
									body: tabelaFinanceiro,
									widths: ["auto", "auto", "auto", "auto", "auto", 40],
								},
								colSpan: 5,
								layout: {
									paddingLeft: function () {
										return 2;
									},

									paddingRight: function () {
										return 2;
									},

									paddingTop: function () {
										return 2;
									},

									paddingBottom: function () {
										return 2;
									},

									vLineWidth: function () {
										return 0;
									},

									hLineColor: function (i) {
										return i === 1 ? "black" : "#aaa";
									},
								},
							},
							{},
							{},
							{},
							{},
						]);
					}
					else{
						tabelaRelatorioVenda.push([
							{
								table: {
									body: tabelaItens,
									widths: ["auto", "*", "auto", "auto"],
								},
								colSpan: 5,
								layout: {
									paddingLeft: function () {
										return 2;
									},

									paddingRight: function () {
										return 2;
									},

									paddingTop: function () {
										return 2;
									},

									paddingBottom: function () {
										return 2;
									},

									vLineWidth: function () {
										return 0;
									},

									hLineColor: function (i) {
										return i === 1 ? "black" : "#aaa";
									},
								},
							},
							{},
							{},
							{},
							{},
						]);
					}

					tabelasVendas.push([...tabelaRelatorioVenda]);
				});
			}
		}
		let documento;
		if(mostrarCondicional=="S"){
			documento = pdfMake.createPdf({
				info: {
					title: "Fechamento de caixa nº ",
					author: "BDS Soluções em Sistemas"
				},

				pageSize: "A4",

				pageMargins: [15, 5, 15, 5],

				pageOrientation: "portrait",

				content: [
					...gerarHeaderCupom(state.Empresa),

					{ text: "Relatório de Vendas:", style: "header" },
					{ text: `Data Inicial: ${new Date(datai.split("-")).toLocaleDateString("pt-BR")}`, style: "descricao" },
					{ text: `Data Final: ${new Date(dataf.split("-")).toLocaleDateString("pt-BR")}`, style: "descricao" },

					tabelasVendas.length > 0 ? !completo ? {
						table: {
							headerRows: 2,
							widths: ["*", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
							body: tabelasVendas
						},
						layout: {
							paddingLeft: function () {
								return 2;
							},

							paddingRight: function () {
								return 2;
							},

							paddingTop: function () {
								return 2;
							},

							paddingBottom: function () {
								return 2;
							},

							vLineWidth: function () {
								return 0;
							},

							hLineColor: function (i) {
								return i === 1 ? "black" : "#aaa";
							},
						},
					} :
						tabelasVendas.map(e => ({
							table: {
								headerRows: 2,
								widths: ["*", "*", "auto", "auto","auto"],
								body: e,
							},
							margin: [0, 0, 0, 10],
							layout: {
								paddingLeft: function () {
									return 2;
								},

								paddingRight: function () {
									return 2;
								},

								paddingTop: function () {
									return 2;
								},

								paddingBottom: function () {
									return 2;
								},

								vLineWidth: function () {
									return 0;
								},

								hLineColor: function (i) {
									return i === 1 ? "black" : "#aaa";
								},
							},
						})) :
						{
							text: "Nenhuma venda encontrada.",
							style: "descricao",
							alignment: "center"
						},
					tabelasVendas.length > 0 ? [
						{
							text: [
								{
									text: "Total Bruto: "
								},
								{
									text: Number(valorbruto || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
									style: { bold: true }
								}
							],
							alignment: "right"
						},
						{
							text: [
								{
									text: "Total Desconto: "
								},
								{
									text: Number(valordesconto || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
									style: { bold: true }
								}
							],
							alignment: "right"
						},
						{
							text: [
								{
									text: "Total Troca: "
								},
								{
									text: Number(valortroca || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
									style: { bold: true }
								}
							],
							alignment: "right"
						},
						{
							text: [
								{
									text: "Total Pago: "
								},
								{
									text: Number(valorpago || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
									style: { bold: true }
								}
							],
							alignment: "right"
						}
					] : null,

					{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
					{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape", margin: [0, 1, 0, 0] },
					{ text: `Impressão: ${new Date().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "rodape", margin: [0, 1, 0, 0] },
				],

				styles
			});
		}
		else{
			documento = pdfMake.createPdf({
				info: {
					title: "Fechamento de caixa nº ",
					author: "BDS Soluções em Sistemas"
				},

				pageSize: "A4",

				pageMargins: [15, 5, 15, 5],

				pageOrientation: "portrait",

				content: [
					...gerarHeaderCupom(state.Empresa),

					{ text: "Relatório de Vendas:", style: "header" },
					{ text: `Data Inicial: ${new Date(datai.split("-")).toLocaleDateString("pt-BR")}`, style: "descricao" },
					{ text: `Data Final: ${new Date(dataf.split("-")).toLocaleDateString("pt-BR")}`, style: "descricao" },

					tabelasVendas.length > 0 ? !completo ? {
						table: {
							headerRows: 2,
							widths: tableWidths,
							body: tabelasVendas
						},
						layout: {
							paddingLeft: function () {
								return 2;
							},

							paddingRight: function () {
								return 2;
							},

							paddingTop: function () {
								return 2;
							},

							paddingBottom: function () {
								return 2;
							},

							vLineWidth: function () {
								return 0;
							},

							hLineColor: function (i) {
								return i === 1 ? "black" : "#aaa";
							},
						},
					} :
						tabelasVendas.map(e => ({
							table: {
								headerRows: 2,
								widths: ["*", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
								body: e,
							},
							margin: [0, 0, 0, 10],
							layout: {
								paddingLeft: function () {
									return 2;
								},

								paddingRight: function () {
									return 2;
								},

								paddingTop: function () {
									return 2;
								},

								paddingBottom: function () {
									return 2;
								},

								vLineWidth: function () {
									return 0;
								},

								hLineColor: function (i) {
									return i === 1 ? "black" : "#aaa";
								},
							},
						})) :
						{
							text: "Nenhuma venda encontrada.",
							style: "descricao",
							alignment: "center"
						},
					tabelasVendas.length > 0 ? [
						{
							text: [
								{
									text: "Total Bruto: "
								},
								{
									text: Number(valorbruto || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
									style: { bold: true }
								}
							],
							alignment: "right"
						},
						{
							text: [
								{
									text: "Total Desconto: "
								},
								{
									text: Number(valordesconto || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
									style: { bold: true }
								}
							],
							alignment: "right"
						},
						{
							text: [
								{
									text: "Total Troca: "
								},
								{
									text: Number(valortroca || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
									style: { bold: true }
								}
							],
							alignment: "right"
						},
						{
							text: [
								{
									text: "Total Pago: "
								},
								{
									text: Number(valorpago || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
									style: { bold: true }
								}
							],
							alignment: "right"
						}
					] : null,

					{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
					{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape", margin: [0, 1, 0, 0] },
					{ text: `Impressão: ${new Date().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "rodape", margin: [0, 1, 0, 0] },
				],

				styles
			});
		}

		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			state.base64 = url;
		});

	} catch (error) {
		console.log(error);
		return commit("relatarErro", { mensagem: JSON.stringify(error) });
	}
};

export const relatorioVendedor = async ({ commit, state }, impressao) => {
	try {
		let tabelaVendedores = [];
		let tabelaMovimentos = [];

		if (impressao.relatorio.length > 0) {
			if (impressao.resumido) {
				tabelaVendedores.push([
					{ text: "Nome", style: "headerTabela", bold: true, colSpan: 2, alignment: "left", fillColor: impressao.pageWidth ? "#c1c1c1" : null, margin: [2, 0, 2, 0] },
					{},
					{ text: "Total vendido", style: "headerTabela", bold: true, alignment: "right", fillColor: impressao.pageWidth ? "#c1c1c1" : null, margin: [2, 0, 2, 0] },
					{ text: "Total comissão", style: "headerTabela", bold: true, alignment: "right", fillColor: impressao.pageWidth ? "#c1c1c1" : null, margin: [2, 0, 2, 0] },
				]);

				impressao.relatorio.forEach(vendedor => {
					tabelaVendedores.push([
						{ text: `${vendedor.cdvendedor} - ${vendedor.nome}`, style: "itemTabela", bold: true, colSpan: 2, alignment: "left" },
						{},
						{
							text: `${Number((vendedor.valorvista || 0) + (vendedor.valorPrazo || 0)).toLocaleString("pt-BR", {
								style: "currency", currency: "BRL"
							})}`, style: "itemTabela", bold: true, alignment: "right"
						},
						{
							text: `${Number((vendedor.comissaovista || 0) + (vendedor.comissaoprazo || 0)).toLocaleString("pt-BR", {
								style: "currency", currency: "BRL"
							})}`, style: "itemTabela", bold: true, alignment: "right"
						},
					]);
					if (impressao.movimento.length > 0) {
						if (impressao.movimento.find(item => Object.keys(item)[0] == vendedor.nome)) {
							let tabelaAux = [];
							if (impressao.pageWidth) {
								tabelaAux.push([
									{ text: `${vendedor.cdvendedor} - ${vendedor.nome}`, style: "headerTabela", bold: true, alignment: "left", colSpan: 2, fillColor: impressao.pageWidth ? "#c1c1c1" : null, margin: [2, 0, 2, 0] },
									{},
								]);
							} else {
								tabelaAux.push([
									{ text: `${vendedor.cdvendedor} - ${vendedor.nome}`, style: "headerTabela", bold: true, colSpan: 2, alignment: "left" },
									{},
								]);
							}
							impressao.movimento.find(item => Object.keys(item)[0] == vendedor.nome)[vendedor.nome].forEach(movi => {
								tabelaAux.push([
									{ text: `${movi.movimento}`, style: "itemTabela", alignment: "left" },
									{ text: `${(movi.valor || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", alignment: "right" },
								]);
							});
							if (impressao.pageWidth) {
								tabelaAux.push([
									{ text: `Total: ${(impressao.movimento.find(item => Object.keys(item)[0] == vendedor.nome)[vendedor.nome].map(v => (v.valor || 0)).reduce((t, v) => t += v) || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "headerTabela", bold: true, alignment: "right", colSpan: 2 },
									{}
								]);
							} else {
								tabelaAux.push([
									{ text: "Total", style: "headerTabela", alignment: "right", bold: true },
									{ text: `${(impressao.movimento.find(item => Object.keys(item)[0] == vendedor.nome)[vendedor.nome].map(v => (v.valor || 0)).reduce((t, v) => t += v) || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "headerTabela", bold: true, alignment: "right" },
								]);
							}
							tabelaMovimentos.push(tabelaAux);
						}
					}
				});
			} else {
				impressao.relatorio.forEach(vendedor => {
					tabelaVendedores.push([
						{ text: `${vendedor.nome.toUpperCase()}`, style: "headerTabela", bold: true, colSpan: 4 },
						{},
						{},
						{},
					]);
					tabelaVendedores.push([
						{ text: "$ Venda", style: "headerTabela" },
						{ text: "$ Condicional", style: "headerTabela" },
						{ text: "$ Troca", style: "headerTabela" },
						{ text: "$ Cancelado", style: "headerTabela" },
					]);
					tabelaVendedores.push(
						[
							{ text: `${vendedor.qtdevenda}`, style: "itemTabela" },
							{ text: `${vendedor.qtdecondicional}`, style: "itemTabela" },
							{ text: `${vendedor.qtdeTroca}`, style: "itemTabela" },
							{ text: `${vendedor.qtdeCancelada}`, style: "itemTabela" },
						],
						[
							{ text: `${(vendedor.valorpago || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela" },
							{ text: `${(vendedor.valorcondicional || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela" },
							{ text: `${(vendedor.valorTroca || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela" },
							{ text: `${(vendedor.valorcancelado || 0).toFixed(2).replace(".", ",")}`, style: "itemTabela" },
						],
						[
							{ text: "Total descontos", style: "headerTabela", colSpan: 2, alignment: "right" },
							{},
							{
								text: `${(vendedor.valordesconto || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
								style: "headerTabela",
								colSpan: 2,
								alignment: "right"
							},
							{},
						],
						[
							{ text: "Total vendido", style: "headerTabela", colSpan: 2, alignment: "right" },
							{},
							{
								text: `${(vendedor.valorpago || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
								style: "headerTabela",
								colSpan: 2,
								alignment: "right"
							},
							{},
						],
						[
							{ text: "Total a vista", style: "headerTabela", colSpan: 2, alignment: "right" },
							{},
							{
								text: `${(vendedor.valorvista || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
								style: "headerTabela",
								colSpan: 2,
								alignment: "right"
							},
							{},
						],
						[
							{ text: "Total a prazo", style: "headerTabela", colSpan: 2, alignment: "right" },
							{},
							{
								text: `${(vendedor.valorPrazo || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
								style: "headerTabela",
								colSpan: 2,
								alignment: "right"
							},
							{},
						],
						[
							{ text: "Comissão a vista", style: "headerTabela", colSpan: 2, alignment: "right" },
							{},
							{
								text: `${(vendedor.comissaovista || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
								style: "headerTabela",
								colSpan: 2,
								alignment: "right"
							},
							{},
						],
						[
							{ text: "Comissão a prazo", style: "headerTabela", colSpan: 2, alignment: "right" },

							{},
							{
								text: `${(vendedor.comissaoprazo || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
								style: "headerTabela",
								colSpan: 2,
								alignment: "right"
							},
							{},
						],
						[
							{ text: "Total de comissão", style: "headerTabela", colSpan: 2, alignment: "right" },
							{},
							{
								text: `${((vendedor.comissaovista || 0) + (vendedor.comissaoprazo || 0))
									.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
								}`,
								style: "headerTabela",
								colSpan: 2,
								alignment: "right"
							},
							{},
						],
						[
							{ text: "Métricas", style: "headerTabela", colSpan: 4, alignment: "center" },
							{},
							{},
							{},
						],
						[
							{ text: "Valor / venda", style: "headerTabela", colSpan: 2, alignment: "right" },
							{},
							{
								text: `${((vendedor.valorpago || 0) / (vendedor.qtdevenda || 1))
									.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
								}`,
								style: "headerTabela",
								colSpan: 2,
								alignment: "right"
							},
							{},
						],
						[
							{ text: "Produtos / venda", style: "headerTabela", colSpan: 2, alignment: "right" },
							{},
							{
								text: `${((vendedor.produtosporvenda || 0) / (vendedor.qtdevenda || 1))
									.toFixed(2)
								}`,
								style: "headerTabela",
								colSpan: 2,
								alignment: "right"
							},
							{},
						]
					);
				});

			}
		}

		let pageSize = !impressao.pageWidth ? {
			width: 200,
			height: "auto"
		} : impressao.pageWidth;

		const documento = pdfMake.createPdf({
			info: {
				title: "Relatório de vendedores",
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: impressao.pageWidth ? [20, 5, 20, 5] : [1, 1, 1, 5],

			pageOrientation: "portrait",

			content: [
				...gerarHeaderCupom(state.Empresa),

				{
					text: "RELATÓRIO DE COMISSÃO",
					style: "header"
				},

				{
					text: `Data inicial: ${impressao.dataI.split("-")[2]}/${impressao.dataI.split("-")[1]}/${impressao.dataI.split("-")[0]}`,
					style: "descricao"
				},

				{
					text: `Data final: ${impressao.dataF.split("-")[2]}/${impressao.dataF.split("-")[1]}/${impressao.dataF.split("-")[0]}`,
					style: "descricao"
				},

				tabelaVendedores.length > 0 ? {
					...{
						text: "Movimentação por Vendedor",
						style: "header"
					},
					...{
						table: {
							headerRows: 0,
							widths: ["*", "auto", "auto", "auto"],
							body: tabelaVendedores,
						},
						layout: impressao.pageWidth ? "lightHorizontalLines" : {
							paddingBottom: function () {
								return 1;
							},
							paddingLeft: function () {
								return 2;
							},
							paddingRight: function () {
								return 2;
							},
							paddingTop: function () {
								return 1;
							}
						},
						margin: [0, 2, 0, 5]
					}
				} :
					{
						text: "Nenhuma movimentação por vendedor registrada.",
						style: "header",
					},

				tabelaMovimentos.length > 0 ? tabelaMovimentos.map(table => ({
					table: {
						headerRows: 1,
						widths: ["*", "*"],
						body: table,
					},
					layout: impressao.pageWidth ? "lightHorizontalLines" : {
						paddingBottom: function () {
							return 1;
						},
						paddingLeft: function () {
							return 2;
						},
						paddingRight: function () {
							return 2;
						},
						paddingTop: function () {
							return 1;
						}
					},
					margin: [0, 2, 0, 5]
				})) : null,

				{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
				{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape", margin: [0, 1, 0, 0] },
				{ text: `Impressão: ${new Date().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "rodape", margin: [0, 1, 0, 0] },
			],

			styles
		});

		// return documento.print();
		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			state.base64 = url;
		});
	} catch (error) {
		console.log(error);
		return commit("relatarErro", { mensagem: JSON.stringify(error) });
	}
};

export const gerarImpressaoPix = async ($store, payload) => {
	const documento = pdfMake.createPdf({
		info: {
			title: "Pagamento Pix",
			author: "BDS Soluções em Sistemas"
		},
		pageSize: {
			width: 200,
			height: "auto"
		},
		pageOrientation: "portrait",

		content: [
			// ...gerarHeaderCupom($store.state.Empresa),

			{
				text: "Pagamento com PIX",
				style: {
					fontSize: 12,
					alignment: "center"
				}
			},
			{
				text: `Valor cobrança : ${(payload.valor || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
				style: {
					fontSize: 9,
					alignment: "center"
				}
			},
			{
				qr: `${payload.qrcode}`,
				fit: 140,
				alignment: "center",
				margin: [0, 1, 0, 1]
			},

			{
				text: gerarMensagemAgradecimento(new Date().getHours()),
				style: {
					fontSize: 9,
					alignment: "center"
				}
			}
		]
	});
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		$store.state.base64 = url;
	});
	// const documento = pdfMake.createPdf({
	// 	info: {
	// 		title: "Fechamento de caixa nº ",
	// 		author: "BDS Soluções em Sistemas"
	// 	},

	// 	pageSize: "A4",

	// 	pageMargins: [15, 5, 15, 5],

	// 	pageOrientation: "portrait",

	// 	content: [

	// 	]
	// }
};
export const relatorioCaixa = async ({ commit, state }, impressao) => {
	try {
		let tabelaCaixa = [];

		if (impressao.relatorio.length > 0) {
			if (impressao.relatorio.filter(movi => movi.condicao == "S").length > 0) {
				impressao.relatorio.filter(movi => movi.condicao == "S").forEach(movimento => {
					tabelaCaixa.push([
						{ text: `${(movimento.movimento || 0).toUpperCase()}`, style: "itemTabela", bold: true, alignment: "left" },
						{ text: `${(movimento.credito || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
					]);
				});
			}

			if (impressao.relatorio.filter(movi => movi.condicao == "V").length > 0) {
				tabelaCaixa.push([
					{ text: "VENDAS", style: "headerTabela", bold: true, colSpan: 2, alignment: "center" },
					{}
				]);
				impressao.relatorio.filter(movi => movi.condicao == "V").forEach(movimento => {
					tabelaCaixa.push([
						{ text: `${movimento.movimento.toUpperCase()}`, style: "itemTabela", bold: true, alignment: "left" },
						{ text: `${((movimento.credito || movimento.prazo) || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
					]);
				});
				tabelaCaixa.push([
					{ text: "TOTAL VENDAS", style: "headerTabela", bold: true, alignment: "left" },
					{ text: `${(impressao.relatorio.filter(movi => movi.condicao == "V").map(movi => (movi.credito || movi.prazo)).reduce((t, v) => t += v) || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
				]);
			}

			if (impressao.relatorio.filter(movi => movi.condicao == "R").length > 0) {
				tabelaCaixa.push([
					{ text: "RECEBIMENTOS", style: "headerTabela", bold: true, colSpan: 2, alignment: "center" },
					{}
				]);
				impressao.relatorio.filter(movi => movi.condicao == "R").forEach(movimento => {
					tabelaCaixa.push([
						{ text: `${movimento.movimento.toUpperCase()}`, style: "itemTabela", bold: true, alignment: "left" },
						{ text: `${((movimento.credito || movimento.prazo) || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
					]);
				});
				tabelaCaixa.push([
					{ text: "TOTAL RECEBIMENTOS", style: "headerTabela", bold: true, alignment: "left" },
					{ text: `${(impressao.relatorio.filter(movi => movi.condicao == "R").map(movi => (movi.credito || movi.prazo)).reduce((t, v) => t += v) || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
				]);
			}

			if (impressao.relatorio.filter(movi => movi.condicao == "M").length > 0) {
				tabelaCaixa.push([
					{ text: "MOVIMENTOS", style: "headerTabela", bold: true, colSpan: 2, alignment: "center" },
					{}
				]);
				impressao.relatorio.filter(movi => movi.condicao == "M").forEach(movimento => {
					tabelaCaixa.push([
						{ text: `RECARGA EM ${movimento.movimento.toUpperCase()}`, style: "itemTabela", bold: true, alignment: "left" },
						{ text: `${(movimento.credito || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
					]);
					tabelaCaixa.push([
						{ text: `SANGRIA EM ${movimento.movimento.toUpperCase()}`, style: "itemTabela", bold: true, alignment: "left" },
						{ text: `${(movimento.debito || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
					]);
				});
			}
			tabelaCaixa.push([
				{ text: "SALDO FINAL", style: "headerTabela", bold: true, alignment: "left" },
				{ text: `${(impressao.relatorio[impressao.relatorio.length - 1].saldo || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "itemTabela", bold: true, alignment: "right" },
			]);
		}

		let pageSize = !impressao.pageWidth ? {
			width: 200,
			height: "auto"
		} : impressao.pageWidth;

		const documento = pdfMake.createPdf({
			info: {
				title: "Relatório de vendedores",
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: impressao.pageWidth ? [20, 5, 20, 5] : [1, 1, 1, 5],

			pageOrientation: "portrait",

			content: [
				...gerarHeaderCupom(state.Empresa),

				{
					text: "RELATÓRIO DE CAIXA",
					style: "header"
				},

				{
					text: `Data inicial: ${impressao.dataI.split("-")[2]}/${impressao.dataI.split("-")[1]}/${impressao.dataI.split("-")[0]}`,
					style: "descricao"
				},

				{
					text: `Data final: ${impressao.dataF.split("-")[2]}/${impressao.dataF.split("-")[1]}/${impressao.dataF.split("-")[0]}`,
					style: "descricao"
				},

				tabelaCaixa.length > 0 ? {
					...{
						text: "Movimentação do Caixa",
						style: "header"
					},
					...{
						table: {
							headerRows: 0,
							widths: ["*", "auto"],
							body: tabelaCaixa,
						},
						layout: impressao.pageWidth ? "lightHorizontalLines" : {
							paddingBottom: function () {
								return 1;
							},
							paddingLeft: function () {
								return 2;
							},
							paddingRight: function () {
								return 2;
							},
							paddingTop: function () {
								return 1;
							}
						},
						margin: [0, 2, 0, 5]
					}
				} :
					{
						text: "Nenhuma movimentação encontrada.",
						style: "header",
					},

				{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
				{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape", margin: [0, 1, 0, 0] },
				{ text: `Impressão: ${new Date().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`, style: "rodape", margin: [0, 1, 0, 0] },
			],

			styles
		});

		// return documento.print();
		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			state.base64 = url;
		});
	} catch (error) {
		console.log(error);
		return commit("relatarErro", { mensagem: JSON.stringify(error) });
	}
};

export const gerarCodigoBarrasProduto = (context, payload) => {
	const Empresa = context.state.Empresa;
	const imprimirCodigo = payload.imprimirCodigo;
	const imprimirDescricao = payload.imprimirDescricao;
	const imprimirRef = payload.imprimirRef;
	const imprimirPrazo = payload.imprimirPrazo;
	const imprimirVista = payload.imprimirVista;
	const codeWidth = Empresa.tipoEtiqueta == 1 || Empresa.tipoEtiqueta == 4 ? 84 : 72;
	const codeHeight = Empresa.tipoEtiqueta === 2 ? 25 : 34;
	let index = 0;
	const columnGap = Empresa.tipoEtiqueta === 1 ? 20 : 18;
	const margemColuna = [3, 1, 3, 0];
	let tableCodigos = [
		{
			columns: [],
			columnGap,
			margin: margemColuna
			
		}
	];

	function formatToEAN(string) {
		if(string?.length > 12) {
			return string.substring(0, 12);
		}
		return string;
	}

	function breakword(ref){
		if(imprimirRef){
			let newStr = "";
			if(!ref) return;
			let str = ref.trim().substring(0, 38);
			let index = 21;
			const [inteiro, decimal] = (str.length / index).toString().split(".");
			let repetir = decimal > 0 ? inteiro + 1 : inteiro;
			for(let i = 0; i < repetir; i++){
				newStr += str.substring(0, index) + (i + 1 < repetir ? "\n" : "");
				str = str.substring(index);
				i++;
			}
			return newStr.trim().substring(0, 22);
		} else {
			let newStr = "";
			if(!ref) return;
			let str = ref.trim().substring(0, 38);
			let index = 21;
			const [inteiro, decimal] = (str.length / index).toString().split(".");
			const repetir = decimal > 0 ? inteiro + 1 : inteiro;
			for(let i = 0; i < repetir; i++){
				newStr += str.substring(0, index) + (i + 1 < repetir ? "\n" : "");
				str = str.substring(index);
				i++;
			}
			return newStr.trim();
		}
	}
	function breakword5(ref){
		let newStr = "";
		if(!ref) return;
		let str = ref.trim().substring(0, 44);
		let index = 22;
		const [inteiro, decimal] = (str.length / index).toString().split(".");
		let repetir = decimal > 0 ? inteiro + 1 : inteiro;
		for(let i = 0; i < repetir; i++){
			newStr += str.substring(0, index) + (i + 1 < repetir ? "\n" : "");
			str = str.substring(index);
		}
		return newStr.trim().substring(0, 46);		
	}
	function breakword6(ref){
		let newStr = "";
		if(!ref) return;
		let str = ref.trim().substring(0, 38);
		let index = 21;
		const [inteiro, decimal] = (str.length / index).toString().split(".");
		let repetir = decimal > 0 ? inteiro + 1 : inteiro;
		for(let i = 0; i < repetir; i++){
			newStr += str.substring(0, index) + (i + 1 < repetir ? "\n" : "");
			str = str.substring(index);
			i++;
		}
		return newStr.trim().substring(0, 22);
	}
	function preencherEtiquetaTipoUm (payload) {
		for (let i = 0; i < (!payload.quantidade ? payload.variacao.length : payload.quantidade); i++) {
			if (i % 3 == 0 && i > 0) {
				tableCodigos.push({
					columns: [
						[
							imprimirCodigo ? {
								text: `COD. ${payload.variacao[i].codigo}`,
								alignment: "left",
							} : null,

							imprimirRef && (
								!payload.variacao[i].cdReferencia 
								|| payload.variacao[i].cdreferencia
							) ? {
									text: `R-${
										payload.variacao[i].cdReferencia 
										|| payload.variacao[i].cdreferencia
									}`.substring(0, 21),
									alignment: "left",
									margin: !imprimirDescricao ? [0, 0, 0, 5] : [0, 0, 0, 0],
									style: {
										fontSize: 6
									}
								} : null,

							imprimirDescricao ? {
								text: `${(
									payload.variacao[i].descresumido ? 
										breakword(payload.variacao[i].descresumido) 
										: breakword(payload.variacao[i].descricao)
								)}`,
								alignment: "left",
								style: {
									fontSize: 6
								},
								margin: (!imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia)) ? [0, 0, 0, 0] : [0, 0, 0, 0]
							} : null,
							{
								image: gerarCodigoBarras(
									formatToEAN(payload.variacao[i].codigobarra) || payload.variacao[i].codigo, codeHeight
								),
								alignment: "center",
								width: codeWidth,
								margin: (!imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia)) && !imprimirDescricao ? [0, 0, 0, 0] : [0, 0, 0, 0]
							},
							{
								columns: [
									imprimirPrazo ? {
										text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										alignment: "left",
										noWrap: true
									} : null,
									imprimirVista ? {
										text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										alignment: "right",
										noWrap: true
									} : null,
								],
								margin: [1, 0, 1, 0],
								style: {
									fontSize: 7
								}
							}
						]
					],
					pageBreak: "before",
					// pageBreak: payload.variacao.length == (i+1) ? null : "after",
					columnGap,
					margin: margemColuna,
				});
				index++;
			} else {
				tableCodigos[index].columns.push([
					imprimirCodigo ? {
						text: `COD. ${payload.variacao[i].codigo}`,
						alignment: "left",
					} : null,

					imprimirRef && (payload.variacao[i].cdReferencia || payload.variacao[i].cdreferencia) ? {
						text: `R-${payload.variacao[i].cdReferencia || payload.variacao[i].cdreferencia}`.substring(0, 21),
						alignment: "left",
						margin: !imprimirDescricao ? [0, 0, 0, 5] : [0, 0, 0, 0],
						style: {
							fontSize: 6
						}
					} : null,

					imprimirDescricao ? {
						text: `${(payload.variacao[i].descresumido ? breakword(payload.variacao[i].descresumido) : breakword(payload.variacao[i].descricao))}`,
						alignment: "left",
						style: {
							fontSize: 6
						},
						margin: !imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia) ? [0, 0, 0, 0] : [0, 0, 0, 0]
					} : null,
					{
						image: gerarCodigoBarras(formatToEAN(payload.variacao[i].codigobarra) || payload.variacao[i].codigo, codeHeight),
						alignment: "center",
						width: codeWidth,
						margin: (!imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia)) && !imprimirDescricao ? [0, 0, 0, 0] : [0, 0, 0, 0]
					},
					{
						columns: [
							imprimirPrazo ? {
								text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
								alignment: "left",
								noWrap: true
							} : null,
							imprimirVista ? {
								text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
								alignment: "right",
								noWrap: true
							} : null,
						],
						margin: [1, 0, 1, 0],
						style: {
							fontSize: 7
						}
					}
				]);
			}
		}
	}
	
	function preencherEtiquetaTipoDois (payload) {
		for (let i = 0; i < (!payload.quantidade ? payload.variacao.length : payload.quantidade); i++) {
			if (i % 3 == 0 && i > 0) {
				tableCodigos.push({
					columns: [
						[
							imprimirDescricao ? {
								text: `${(payload.variacao[i].descresumido ? breakword(payload.variacao[i].descresumido) : breakword(payload.variacao[i].descricao))}`,
								alignment: "left",
								noWrap: true,
								style: {
									fontSize: 6
								},
								margin: (!imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia)) ? [0, 0, 0, 0] : [0, 0, 0, 0],
							} : null,

							imprimirRef && (payload.variacao[i].cdReferencia || payload.variacao[i].cdreferencia) ? {
								text: `R-${payload.variacao[i].cdReferencia || payload.variacao[i].cdreferencia}`.substring(0, 21),
								alignment: "left",
								margin: !imprimirDescricao ? [0, 0, 0, 0] : [0, 0, 0, 0],
								style: {
									fontSize: 6
								}
							} : null,
							{
								columns: [
									imprimirPrazo ? {
										text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										bold: true,
										fontSize: 8,
										noWrap: true,
									} : null,
									imprimirVista ? {
										text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										bold: true,
										fontSize: 8,
										noWrap: true
									} : null,
								],
								style: {
									fontSize: (payload.variacao[i].prazo || payload.variacao[i].vista) <= 99.99 ?  9 : (payload.variacao[i].prazo || payload.variacao[i].vista) <= 999.99 ? 8 : 7.5,
									alignment: "center"
								},
								margin: [0, 0, 0, 0],
								columnGap: 0.7
							},
							{
								image: gerarCodigoBarras(
									formatToEAN(payload.variacao[i].codigobarra) || payload.variacao[i].codigo, codeHeight
								),
								alignment: "center",
								width: codeWidth,
								margin: (!imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia)) && !imprimirDescricao ? [0, 0, 0, 0] : [0, 0, 0, 0]
							},
							imprimirCodigo ? {
								text: `${payload.variacao[i].codigo}`,
								alignment: "center",
								style: {
									fontSize: 7
								},
								margin: [0, 0, 0, 0]
							} : null,

						]
					],
					pageBreak: "before",
					// pageBreak: payload.variacao.length == (i+1) ? null : "after",
					columnGap,
					margin: margemColuna,
				});
				index++;
			} else {
				tableCodigos[index].columns.push({
					columns: [
						[
							imprimirDescricao ? {
								text:  `${(payload.variacao[i].descresumido ? breakword(payload.variacao[i].descresumido) : breakword(payload.variacao[i].descricao))}`,
								alignment: "left",
								style: {
									fontSize: 6
								},
								margin: !imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia) ? [0, 0, 0, 0] : [0, 0, 0, 0]
							} : null,
		
							imprimirRef && (payload.variacao[i].cdReferencia || payload.variacao[i].cdreferencia) ? {
								text: `R-${payload.variacao[i].cdReferencia || payload.variacao[i].cdreferencia}`.substring(0, 21),
								alignment: "left",
								margin: !imprimirDescricao ? [0, 0, 0, 0] : [0, 0, 0, 0],
								style: {
									fontSize: 6
								}
							} : null,
							{
								columns: [
									imprimirPrazo ? {
										text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										bold: true,
										fontSize: 8,
										noWrap: true,
									} : null,
									imprimirVista ? {
										text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										noWrap: true,
										fontSize: 8,
										bold: true,
									} : null,
									
								],
								style: {
									fontSize: (payload.variacao[i].prazo || payload.variacao[i].vista) <= 99.99 ?  9 : (payload.variacao[i].prazo || payload.variacao[i].vista) <= 999.99 ? 8 : 7.5,
									alignment: "center"
								},
								margin: [0, 0, 0, 1],
								columnGap: 0.7
							},
							{
								image: gerarCodigoBarras(
									formatToEAN(payload.variacao[i].codigobarra) || payload.variacao[i].codigo, codeHeight
								),
								alignment: "center",
								width: codeWidth,
								margin: (!imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia)) && !imprimirDescricao ? [0, 5, 0, 2] : [0, 0, 0, 0]
							},
							imprimirCodigo ? {
								text: `${payload.variacao[i].codigo}`,
								alignment: "center",
								style: {
									fontSize: 7
								},
								margin: [0, 0, 0, 0]
							} : null,
						],
					],
					margin: [3, 1, 3, 0],
				});

			}
		}
	}
	function preencherEtiquetaTipoQuatro (payload) {
		for (let i = 0; i < (!payload.quantidade ? payload.variacao.length : payload.quantidade); i++) {
			if (i % 3 == 0 && i > 0) {
				tableCodigos.push({
					columns: [
						[
							imprimirCodigo ? {
								text: `COD. ${payload.variacao[i].codigo}`,
								alignment: "left",
							} : null,

							imprimirDescricao ? {
								text: `${(payload.variacao[i].descresumido ? breakword(payload.variacao[i].descresumido) : breakword(payload.variacao[i].descricao.includes(";") ? payload.variacao[i].descricao.split(";")[0] : payload.variacao[i].descricao))}`,
								alignment: "left",
								style: {
									fontSize: 6
								},
							} : null,
							payload.variacao[i]?.variacao ? {
								text: `${payload.variacao[i].variacao}; [${payload.variacao[i].grade}]`,
								alignment: "left",
								style: {
									fontSize: 6
								},
							} : null,
							{
								image: gerarCodigoBarras(
									formatToEAN(
										`${payload.variacao[i].codigobarra}`.includes(".") 
											? `${payload.variacao[i].codigobarra}`.replace(".", "") 
											: `${payload.variacao[i].codigobarra}`
									)
									, codeHeight
								),
								alignment: "center",
								width: codeWidth,
								margin: [0,0,0,0],
							},
							{
								columns: [
									imprimirPrazo ? {
										text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										alignment: "left",
										noWrap: true
									} : null,
									imprimirVista ? {
										text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										alignment: "right",
										noWrap: true
									} : null,
								],
								margin: [1, 0, 1, 0],
								style: {
									fontSize: 7
								}
							}
						]
					],
					pageBreak: "before",
					// pageBreak: payload.variacao.length == (i+1) ? null : "after",
					columnGap,
					margin: margemColuna,
				});
				index++;
			} else {
				tableCodigos[index].columns.push([
					imprimirCodigo ? {
						text: `COD. ${payload.variacao[i].codigo}`,
						alignment: "left",
					} : null,


					imprimirDescricao ? {
						text: `${(payload.variacao[i].descresumido ? breakword(payload.variacao[i].descresumido) : breakword(payload.variacao[i].descricao.includes(";") ? payload.variacao[i].descricao.split(";")[0] : payload.variacao[i].descricao))}`,
						alignment: "left",
						style: {
							fontSize: 6
						},
					} : null,
					imprimirDescricao && payload.variacao[i]?.variacao ? {
						text: `${payload.variacao[i].variacao}; [${payload.variacao[i].grade}]`,
						alignment: "left",
						style: {
							fontSize: 6
						},
					
					} :  null,
					{
						image: gerarCodigoBarras(
							formatToEAN(`${payload.variacao[i].codigobarra}`.includes(".") 
								? `${payload.variacao[i].codigobarra}`.replace(".", "") 
								: `${payload.variacao[i].codigobarra}` 
							)
							// || `${payload.variacao[i].codigo}`.includes(".") 
							// 	? `${payload.variacao[i].codigo}`.replace(".", "") 
							// 	: `${payload.variacao[i].codigo}` , codeHeight
						),
						alignment: "center",
						width: codeWidth,
						margin: [0, 0, 0, 0]
					},
					{
						columns: [
							imprimirPrazo ? {
								text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
								alignment: "left",
								noWrap: true
							} : null,
							imprimirVista ? {
								text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
								alignment: "right",
								noWrap: true
							} : null,
						],
						margin: [1, 0, 1, 0],
						style: {
							fontSize: 7
						}
					}
				]);
			}
		}
	}
	function preencherEtiquetaTipoCinco (payload) {
		
		for (let i = 0; i < (!payload.quantidade ? payload.variacao.length : payload.quantidade); i++) {
			if (i % 3 == 0 && i > 0) {
				tableCodigos.push({
					columns: [
						[
							imprimirDescricao ? {
								text: `${(payload.variacao[i].descresumido ? breakword5(payload.variacao[i].descresumido) : breakword5(payload.variacao[i].descricao.includes(";") ? payload.variacao[i].descricao.split(";")[0] : payload.variacao[i].descricao))}`,
								alignment: "left",
								style: {
									fontSize: 6
								},
							} : null,
							payload.variacao[i]?.variacao ? {
								text: `${payload.variacao[i].variacao}; [${payload.variacao[i].grade}]`,
								alignment: "left",
								style: {
									fontSize: 6
								},
							} : null,
							{
								image: gerarCodigoBarras(
									formatToEAN(`${payload.variacao[i].codigobarra}`.includes(".") 
										? `${payload.variacao[i].codigobarra}`.replace(".", "") 
										: `${payload.variacao[i].codigobarra}`
									)
									// || `${payload.variacao[i].codigo}`.includes(".") 
									// 	? `${payload.variacao[i].codigo}`.replace(".", "") 
									// 	: `${payload.variacao[i].codigo}` , codeHeight
								),
								alignment: "center",
								width: codeWidth,
								margin: (!imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia)) && !imprimirDescricao ? [0, 0, 0, 0] : [0, 0, 0, 0]
							},
							{
								columns: [
									imprimirPrazo ? {
										text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										alignment: "left",
										noWrap: true
									} : null,
									imprimirVista ? {
										text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										alignment: "right",
										noWrap: true
									} : null,
								],
								margin: [1, 0, 1, 0],
								style: {
									fontSize: 7
								}
							}
						]
					],
					pageBreak: "before",
					// pageBreak: payload.variacao.length == (i+1) ? null : "after",
					columnGap,
					margin: margemColuna,
				});
				index++;
			} else {
				tableCodigos[index].columns.push([
					imprimirDescricao ? {
						text: `${(payload.variacao[i].descresumido ? breakword5(payload.variacao[i].descresumido) : breakword5(payload.variacao[i].descricao.includes(";") ? payload.variacao[i].descricao.split(";")[0] : payload.variacao[i].descricao))}`,
						alignment: "left",
						style: {
							fontSize: 6
						},
					} : null,
					imprimirDescricao && payload.variacao[i]?.variacao ? {
						text: `${payload.variacao[i].variacao}; [${payload.variacao[i].grade}]`,
						alignment: "left",
						style: {
							fontSize: 6
						},
					
					} :  null,
					{
						image: gerarCodigoBarras(
							formatToEAN(
								`${payload.variacao[i].codigobarra}`.includes(".") 
									? `${payload.variacao[i].codigobarra}`.replace(".", "") 
									: `${payload.variacao[i].codigobarra}` 
							)
							// || `${payload.variacao[i].codigo}`.includes(".") 
							// 	? `${payload.variacao[i].codigo}`.replace(".", "") 
							// 	: `${payload.variacao[i].codigo}` , codeHeight
						),
						alignment: "center",
						width: codeWidth,
						margin: (!imprimirRef || (!payload.variacao[i].cdReferencia && !payload.variacao[i].cdreferencia)) && !imprimirDescricao ? [0, 8, 0, 8] : [0, 0, 0, 0]
					},
					{
						columns: [
							imprimirPrazo ? {
								text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
								alignment: "left",
								noWrap: true
							} : null,
							imprimirVista ? {
								text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
								alignment: "right",
								noWrap: true
							} : null,
						],
						margin: [1, 0, 1, 0],
						style: {
							fontSize: 7
						}
					}
				]);
			}
		}
	}
	function preencherEtiquetaTipoSeis (payload) {
		for (let i = 0; i < (!payload.quantidade ? payload.variacao.length : payload.quantidade); i++) {
			if (i % 3 == 0 && i > 0) {
				tableCodigos.push({
					columns: [
						[
							{ text: Empresa.fantasia, alignment: "left", style: {fontSize: 5.5}, },
							{ text: `COD. ${payload.variacao[i].codigo}`, alignment: "left", style: {fontSize: 5.5}, },
							{ text: `${(payload.variacao[i].descresumido ? breakword6(payload.variacao[i].descresumido) : breakword6(payload.variacao[i].descricao.includes(";") ? payload.variacao[i].descricao.split(";")[0] : payload.variacao[i].descricao))}`,
								alignment: "left", style: {fontSize: 5.5},
							},
							payload.variacao[i]?.variacao ? {
								text: `${payload.variacao[i].variacao}; [${payload.variacao[i].grade}]`,
								alignment: "left", style: {fontSize: 5.5},
							} : null,
							{
								image: gerarCodigoBarras(
									formatToEAN(
										`${payload.variacao[i].codigobarra}`.includes(".") 
											? `${payload.variacao[i].codigobarra}`.replace(".", "") 
											: `${payload.variacao[i].codigobarra}`
									)
									, codeHeight
								),
								alignment: "center",
								width: codeWidth,
								margin: [0,0,0,0],
							},
							{
								columns: [
									imprimirPrazo ? {
										text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										alignment: "left",
										noWrap: true
									} : null,
									imprimirVista ? {
										text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										alignment: "right",
										noWrap: true
									} : null,
								],
								margin: [1, 0, 1, 0],
								style: {
									fontSize: 6
								}
							}
						]
					],
					pageBreak: "before",
					// pageBreak: payload.variacao.length == (i+1) ? null : "after",
					columnGap,
					margin: margemColuna,
				});
				index++;
			} else {
				tableCodigos[index].columns.push([
					{ text: Empresa.fantasia, alignment: "left", style: {fontSize: 5.5}, },
					{ text: `COD. ${payload.variacao[i].codigo}`,alignment: "left", style: {fontSize: 5.5}, },
					{ text: `${(payload.variacao[i].descresumido ? breakword6(payload.variacao[i].descresumido) : breakword6(payload.variacao[i].descricao.includes(";") ? payload.variacao[i].descricao.split(";")[0] : payload.variacao[i].descricao))}`,
						alignment: "left", style: {fontSize: 5.5}, 
					},
					payload.variacao[i]?.variacao ? {
						text: `${payload.variacao[i].variacao}; [${payload.variacao[i].grade}]`,
						alignment: "left", style: {fontSize: 5.5}, 
					} :  null,
					{
						image: gerarCodigoBarras(
							formatToEAN(`${payload.variacao[i].codigobarra}`.includes(".") 
								? `${payload.variacao[i].codigobarra}`.replace(".", "") 
								: `${payload.variacao[i].codigobarra}` 
							)
							// || `${payload.variacao[i].codigo}`.includes(".") 
							// 	? `${payload.variacao[i].codigo}`.replace(".", "") 
							// 	: `${payload.variacao[i].codigo}` , codeHeight
						),
						alignment: "center",
						width: codeWidth,
						margin: [0, 0, 0, 0]
					},
					{
						columns: [
							imprimirPrazo ? {
								text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
								alignment: "left",
								noWrap: true
							} : null,
							imprimirVista ? {
								text: `${(payload.variacao[i].vista?(payload.variacao[i].vista>999.99) : (payload.variacao[i].prazo>999.99))?"":"V:"} ${(payload.variacao[i].vista || payload.variacao[i].prazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
								alignment: "right",
								noWrap: true
							} : null,
						],
						margin: [1, 0, 1, 0],
						style: {
							fontSize: 6
						}
					}
				]);
			}
		}
	}
	function preencherEtiquetaTipoSete (payload) {
		console.log(payload);
		for (let i = 0; i < (!payload.quantidade ? payload.variacao.length : payload.quantidade); i++) {
			let mes = null;
			let ano = null;
			if(payload.variacao[i].dataUltCompra){
				mes = payload.variacao[i].dataUltCompra.split("-")[1];
				ano = payload.variacao[i].dataUltCompra.split("-")[0].slice(2,4);
			}
			else{
				const data = new Date().toLocaleDateString("en-GB").split("/");
				mes = data[1];
				ano = data[2].slice(2,4);
			}
			if (i % 3 == 0 && i > 0) {
				tableCodigos.push({
					columns: [
						[
							{ text: payload.variacao[i].cdreferencia, alignment: "left", style: {fontSize: 5.5}, },
							{ text: `COD. ${payload.variacao[i].codigo}`, alignment: "left", style: {fontSize: 5.5}, },
							{ text: `${(payload.variacao[i].descresumido ? breakword6(payload.variacao[i].descresumido) : breakword6(payload.variacao[i].descricao.includes(";") ? payload.variacao[i].descricao.split(";")[0] : payload.variacao[i].descricao))}`,
								alignment: "left", style: {fontSize: 5.5},
							},
							payload.variacao[i]?.variacao ? {
								text: `${payload.variacao[i].variacao}; [${payload.variacao[i].grade}]`,
								alignment: "left", style: {fontSize: 5.5},
							} : null,
							{
								image: gerarCodigoBarras(
									formatToEAN(
										`${payload.variacao[i].codigobarra}`.includes(".") 
											? `${payload.variacao[i].codigobarra}`.replace(".", "") 
											: `${payload.variacao[i].codigobarra}`
									)
									, codeHeight
								),
								alignment: "center",
								width: codeWidth,
								margin: [0,0,0,0],
							},
							{
								columns: [
									{
										text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
										alignment: "left",
										noWrap: true
									},
									{
										text: `${mes+"/"+ano+" "+Empresa.atalhoLoginPdv.slice(0,2)}`,
										alignment: "right",
										noWrap: true
									},
								],
								margin: [1, 0, 1, 0],
								style: {
									fontSize: 6
								}
							}
						]
					],
					pageBreak: "before",
					columnGap,
					margin: margemColuna,
				});
				index++;
			} else {
				tableCodigos[index].columns.push([
					{ text: payload.variacao[i].cdreferencia, alignment: "left", style: {fontSize: 5.5}, },
					{ text: `COD. ${payload.variacao[i].codigo}`,alignment: "left", style: {fontSize: 5.5}, },
					{ text: `${(payload.variacao[i].descresumido ? breakword6(payload.variacao[i].descresumido) : breakword6(payload.variacao[i].descricao.includes(";") ? payload.variacao[i].descricao.split(";")[0] : payload.variacao[i].descricao))}`,
						alignment: "left", style: {fontSize: 5.5}, 
					},
					payload.variacao[i]?.variacao ? {
						text: `${payload.variacao[i].variacao}; [${payload.variacao[i].grade}]`,
						alignment: "left", style: {fontSize: 5.5}, 
					} :  null,
					{
						image: gerarCodigoBarras(
							formatToEAN(`${payload.variacao[i].codigobarra}`.includes(".") 
								? `${payload.variacao[i].codigobarra}`.replace(".", "") 
								: `${payload.variacao[i].codigobarra}` 
							)
						),
						alignment: "center",
						width: codeWidth,
						margin: [0, 0, 0, 0]
					},
					{
						columns: [
							{
								text: `${payload.variacao[i].prazo>999.99?"":"P:"} ${(payload.variacao[i].prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
								alignment: "left",
								noWrap: true
							},
							{
								text: `${mes+"/"+ano+" "+Empresa.atalhoLoginPdv.slice(0,2)}`,
								alignment: "right",
								noWrap: true
							},
						],
						margin: [1, 0, 1, 0],
						style: {
							fontSize: 6
						}
					}
				]);
			}
		}
	}
	try {
		context.state.carregando = true;
		let pageSize = payload.pageSize ? payload.pageSize : {
			width: 308,
			height: 60
		};

		if (payload.variacao) {
			if(Empresa.tipoEtiqueta == 1){
				preencherEtiquetaTipoUm({...payload, vetorVariacao: true});
			} else if(Empresa.tipoEtiqueta == 2){
				preencherEtiquetaTipoDois(payload);
			} else if(Empresa.tipoEtiqueta == 3){
				preencherEtiquetaTipoUm({...payload, vetorVariacao: true});
			} else if(Empresa.tipoEtiqueta === 4){
				preencherEtiquetaTipoQuatro(payload);
			} else if(Empresa.tipoEtiqueta === 5){
				preencherEtiquetaTipoCinco(payload);
			} else if(Empresa.tipoEtiqueta === 6){
				preencherEtiquetaTipoSeis(payload);
			}else if(Empresa.tipoEtiqueta === 7){
				preencherEtiquetaTipoSete(payload);
			}
		} else {
			if(Empresa.tipoEtiqueta == 1){
				preencherEtiquetaTipoUm(payload);
			} else if(Empresa.tipoEtiqueta == 2){
				preencherEtiquetaTipoDois(payload);
			} else if(Empresa.tipoEtiqueta == 3){
				preencherEtiquetaTipoUm(payload);
			} else if(Empresa.tipoEtiqueta === 4){
				preencherEtiquetaTipoQuatro(payload);
			} else if(Empresa.tipoEtiqueta === 5){
				preencherEtiquetaTipoCinco(payload);
			} else if(Empresa.tipoEtiqueta === 6){
				preencherEtiquetaTipoSeis(payload);
			} else if(Empresa.tipoEtiqueta === 7){
				preencherEtiquetaTipoSete(payload);
			}
		}

		tableCodigos.forEach(row => {
			if (row.columns.length < 3) {
				for (let i = row.columns.length; i < 3; i++) {
					row.columns.push([]);
				}
			}
		});
		const documento = pdfMake.createPdf({
			info: {
				title: "Etiqueta de produtos",
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: [10, 3, 10, 0],

			pageOrientation: "landscape",

			content: tableCodigos,
			styles,
			defaultStyle: {
				fontSize: 6,
				bold: true,
				font: "Roboto"
			}
		});

		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			const a = document.createElement("a");
			a.href = url;
			a.target = "_etiquetas";
			a.download = `Etiquetas-${new Date().getTime()}.pdf`;
			document.body.appendChild(a);
			a.click();
			a.remove();
			context.commit("limparEtiquetas");
			context.state.carregando = false;
		});
	} catch (error) {
		console.log(error);
		context.state.carregando = false;
		return context.commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
	}
};
export const gerarRelatorioEntradaProduto = async (context, {empresa, itens, dataI, dataF, pageWidth} ) => {
	const tabelaProdutos = [];
	let tabelaWidths = null;
	let totalizador = 0;
	let totalizadorQuantidade = 0;
	function breakword(ref){
		var newStr = "";
		if(!ref) return;
		var str = ref.trim();
		let index = pageWidth ? 30 : 10;
		const [inteiro, decimal] = (str.length / index).toString().split(".");
		var repetir = decimal > 0 ? inteiro + 1 : inteiro;
		for(let i = 0; i < repetir; i++){
			newStr += str.substring(0, index) + (i + 1 < repetir ? "\n" : "");
			str = str.substring(index);
			i++;
		}
		return newStr.trim();
	}
	if(pageWidth){
		tabelaProdutos.push([
			{text: "Data", style: "headerTabela"},
			{text: "Cód.", style: "headerTabela"},
			{text: "Referência", style: "headerTabela"},
			{text: "Descrição", style: "headerTabela"},
			{text: "Qtd.", style: "headerTabela"},
			{text: "$ Unit.", style: "headerTabela"},
			{text: "$ Produtos", style: "headerTabela"},
		]);
		itens.forEach(produto => {
			totalizador += produto.valorprodutos;
			totalizadorQuantidade += produto.quantidade;
			tabelaProdutos.push([
				{text: `${produto.dataemissao.split("-").reverse().join("/")}`, style:"itemTabela"},
				{text: `${produto.cdproduto}`, style:"itemTabela"},
				{text: `${produto.cdreferencia}`, style:"itemTabela"},
				{text: `${produto.descricao}`, style:"itemTabela"},
				{text: `${produto.quantidade}`, style:"itemTabela"},
				{text: `${(produto.valorunitario || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}`, style:"itemTabela"},
				{text: `${(produto.valorprodutos || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}`, style:"itemTabela"}
			]);
		});
		tabelaWidths = ["auto", "auto", "auto", "*", 30, "auto", "auto"];
	} else {
		tabelaProdutos.push([
			{text: "Data", style: "headerTabela"},
			{text: "Cód.", style: "headerTabela"},
			{text: "Descrição", style: "headerTabela"},
			{text: "Qtd.", style: "headerTabela"},
			{text: "$ Unit.", style: "headerTabela"},
		]);
		itens.forEach(produto => {
			totalizador += produto.valorprodutos;
			totalizadorQuantidade += produto.quantidade;
			tabelaProdutos.push([
				{text: `${produto.dataemissao.split("-").reverse().join("/")}`, style:"itemTabela"},
				{text: `${produto.cdproduto}`, style:"itemTabela"},
				{text: `${breakword(produto.descricao)}`, style:"itemTabela"},
				{text: `${produto.quantidade}`, style:"itemTabela"},
				{text: `${(produto.valorunitario || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}`, style:"itemTabela"},
			]);
		});
		tabelaWidths = [25, 20, "*", 15, 35];
	}
	let pageSize = !pageWidth ? {
		width: 200,
		height: 1000
	} : pageWidth;
	let styles = {
		headerTabela: pageWidth ? {
			alignment: "center",
			bold: true
		} : {
			fontSize: 8,
			alignment: "center",
			bold: true
		},
		itemTabela: pageWidth ? {
			alignment: "center",
			fontSize: 11
		} : {
			alignment: "center",
			fontSize: 8
		},
		descricao: pageWidth ? {
			fontSize: 10
		} : {
			fontSize: 9
		}
	};
	
	const documento = pdfMake.createPdf({
		info: {
			title: "Extrato Movimento Produto",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: pageWidth ? [20, 5, 20, 5] : [1, 1, 1, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(empresa),
			{
				
				text: `RELATÓRIO ENTRADA DE PRODUTOS DIA ${dataI.split("-").reverse().join("/")} AO DIA ${dataF.split("-").reverse().join("/")}`,
				alignment: "center",
				bold: true,
				margin: [0, 10, 0, 0]
			},
			{
			
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tabelaWidths,
					dontBreakRows: true,
					keepWithHeaderRows: true
				},
				width: pageWidth ? "auto" : 200,
				layout: {
					fillColor: function (rowIndex, ) {
						return (rowIndex % 2 === 0) ? "#CCCCCC" : null;
					}
				},
				margin: [0, 10, 0, 1],
			},
			{
				text: `Total Qtd.: ${totalizadorQuantidade}    Total: ${(totalizador || 0).toLocaleString("pt-BR", {style: "currency", currency: "brl"})}`,
				alignment: "right",
				fontSize: 12,
				bold: true
			}
		],
		styles
	});
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
	
};
export const gerarRelatorioMovimentoProduto = async (context, { empresa, itens, dataI, dataF, cliente, vendedor, pageWidth, imprimirValor }) => {

	const tabelaProdutos = [];

	let tableWidths = null;

	let totalGeral = 0;
	let totalQuantidade = 0;
	itens.map(item => {
		totalGeral += (item.subtotal || 0);
		totalQuantidade += (item.quantidade || 0);
	});
	context.state.carregando = true;
	function breakword(ref){
		var newStr = "";
		if(!ref) return;
		var str = ref.trim();
		let index = pageWidth ? 30 : 10;
		const [inteiro, decimal] = (str.length / index).toString().split(".");
		var repetir = decimal > 0 ? inteiro + 1 : inteiro;
		for(let i = 0; i < repetir; i++){
			newStr += str.substring(0, index) + (i + 1 < repetir ? "\n" : "");
			str = str.substring(index);
			i++;
		}
		return newStr.trim();
	}
	if (cliente) {
		if (!pageWidth) {
			tabelaProdutos.push([
				{ text: "Data", style: "headerTabela" },
				{ text: "Produto", style: "headerTabela", colSpan: imprimirValor ? 1 : 2 },
				imprimirValor ? { text: "Qtd.", style: "headerTabela" } : {},
				imprimirValor ? { text: "$ Total", style: "headerTabela" } : { text: "Qtd.", style: "headerTabela" },
			]);
			itens.forEach(item => {

				tabelaProdutos.push([
					{ text: new Date(item.dataemissao.split("-")).toLocaleDateString("pt-BR"), style: "itemTabela", rolSpan: 2 },
					{ text: `R.: ${breakword(item.cdreferencia || item.cdReferencia) }; \nCód: ${item.cdproduto}\n${breakword(item.descricao)}`, style: "itemTabela", alignment: "left", colSpan: imprimirValor ? 1 : 2, rolSpan: 2 },
					imprimirValor ? {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right",
						rolSpan: 2
					} : {},
					imprimirValor ? {
						text: breakword(Number(item.subtotal).toFixed(2).replace(".", ",")),
						style: "itemTabela",
						alignment: "right",
						rolSpan: 2
					} : {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right",
						rolSpan: 2
					},
				]);
			});
			tableWidths = ["auto", "*", "auto", "auto"];
		} else {
			tabelaProdutos.push([
				{ text: "Data", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Vendedor", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				{ text: "Produto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left", colSpan: imprimirValor ? 1 : 3 },
				imprimirValor ? { text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1" } : {},
				imprimirValor ? { text: "$ Unit.", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" } : {},
				imprimirValor ? { text: "$ Subtotal", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" } : { text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1" },
			]);
			itens.forEach(item => {
				tabelaProdutos.push([
					{ text: new Date(item.dataemissao.split("-")).toLocaleDateString("pt-BR"), style: "itemTabela" },
					{ text: `${item.cdvendedor} ${item.vendedor}`, style: "itemTabela", alignment: "left" },
					{ text: `${item.cdproduto} - ${breakword(item.descricao)}`, style: "itemTabela", alignment: "left", colSpan: imprimirValor ? 1 : 3 },
					imprimirValor ? {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					} : {},
					imprimirValor ? {
						text: Number(item.valorunitario || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					} : {},
					imprimirValor ? {
						text: Number(item.subtotal).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					} : {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					},
				]);
			});
			tableWidths = ["auto", "auto", "*", "auto", "auto", "auto"];
		}
	} else if (vendedor) {
		if (!pageWidth) {
			tabelaProdutos.push([
				{ text: "Data", style: "headerTabela" },
				{ text: "Produto", style: "headerTabela", colSpan: imprimirValor ? 1 : 2 },
				imprimirValor ? { text: "Qtd.", style: "headerTabela" } : {},
				imprimirValor ? { text: "$ Total", style: "headerTabela" } : { text: "Qtd.", style: "headerTabela" },
			]);
			itens.forEach(item => {
				tabelaProdutos.push([
					{ text: new Date(item.dataemissao.split("-")).toLocaleDateString("pt-BR"), style: "itemTabela", rolSpan: 2 },
					{ text: `R.: ${breakword(item.cdreferencia || item.cdReferencia) }; \nCód: ${item.cdproduto}\n${breakword(item.descricao)}`, style: "itemTabela", alignment: "left", colSpan: imprimirValor ? 1 : 2, rolSpan: 2 },
					imprimirValor ? {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right",
						rolSpan: 2
					} : {},
					imprimirValor ? {
						text: breakword(Number(item.subtotal).toFixed(2).replace(".", ",")),
						style: "itemTabela",
						alignment: "right",
						rolSpan: 2
					} : {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right",
						rolSpan: 2
					},
				]);
			});
			tableWidths = ["auto", "*", "auto", "auto"];
		} else {
			tabelaProdutos.push([
				{ text: "Data", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Cliente", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				{ text: "Produto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left", colSpan: imprimirValor ? 1 : 3 },
				imprimirValor ? { text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1" } : {},
				imprimirValor ? { text: "$ Unit.", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" } : {},
				imprimirValor ? { text: "$ Subtotal", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" } : { text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1" },
			]);
			itens.forEach(item => {
				tabelaProdutos.push([
					{ text: new Date(item.dataemissao.split("-")).toLocaleDateString("pt-BR"), style: "itemTabela" },
					{ text: `${item.cdcliente} ${item.cliente}`, style: "itemTabela", alignment: "left" },
					{ text: `${item.cdproduto || item.cdreferencia || item.cdReferencia} - ${breakword(item.descricao)}`, style: "itemTabela", alignment: "left", colSpan: imprimirValor ? 1 : 3 },
					imprimirValor ? {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					} : {},
					imprimirValor ? {
						text: Number(item.valorunitario || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					} : {},
					imprimirValor ? {
						text: breakword(Number(item.subtotal).toFixed(2).replace(".", ",")),
						style: "itemTabela",
						alignment: "right"
					} : {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					},
				]);
			});
			tableWidths = ["auto", "auto", "*", "auto", "auto", "auto"];
		}
	} else {
		if (!pageWidth) {
			tabelaProdutos.push([
				{ text: "Data", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Produto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left", colSpan: imprimirValor ? 1 : 2 },
				imprimirValor ? { text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1" } : {},
				imprimirValor ? { text: "$ Subtotal", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" } : { text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1" },
			]);
			itens.forEach((item) => {
				tabelaProdutos.push([
					{ text: new Date(item.dataemissao.split("-")).toLocaleDateString("pt-BR"), style: "itemTabela", rolSpan: 2 },
					{ text: `R.: ${breakword(item.cdreferencia || item.cdReferencia) }; \nCód: ${item.cdproduto}\n${breakword(item.descricao)}`, style: "itemTabela", alignment: "left", colSpan: imprimirValor ? 1 : 2, rolSpan: 2 },
					imprimirValor ? {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right",
						rolSpan: 2
					} : {},
					imprimirValor ? {
						text: breakword(Number(item.subtotal).toFixed(2).replace(".", ",")),
						style: "itemTabela",
						alignment: "right",
						rolSpan: 2
					} : {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right",
						rolSpan: 2
					},
				]);
			});
			tableWidths = ["auto", "*", "auto", "auto"];
		} else {
			tabelaProdutos.push([
				{ text: "Data", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Cliente", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				{ text: "Vendedor", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				{ text: "Produto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left", colSpan: imprimirValor ? 1 : 3 },
				imprimirValor ? { text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1" } : {},
				imprimirValor ? { text: "$ Unit.", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" } : {},
				imprimirValor ? { text: "$ Subtotal", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" } : { text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1" },
			]);
			itens.forEach(item => {
				tabelaProdutos.push([
					{ text: new Date(item.dataemissao.split("-")).toLocaleDateString("pt-BR"), style: "itemTabela" },
					{ text: `${item.cdcliente} ${item.cliente}`, style: "itemTabela", alignment: "left" },
					{ text: `${item.cdvendedor} ${item.vendedor}`, style: "itemTabela", alignment: "left" },
					{ text: `${item.cdproduto || item.cdreferencia || item.cdReferencia} - ${breakword(item.descricao)}`, style: "itemTabela", alignment: "left", colSpan: imprimirValor ? 1 : 3 },
					imprimirValor ? {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					} : {},
					imprimirValor ? {
						text: Number(item.valorunitario || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					} : {},
					imprimirValor ? {
						text: Number(item.subtotal).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					} : {
						text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
						style: "itemTabela",
						alignment: "right"
					},
				]);
			});
			tableWidths = ["auto", "auto", "auto", "*", "auto", "auto", "auto"];
		}
	}

	let pageSize = !pageWidth ? {
		width: 200,
		height: 1000
	} : pageWidth;

	const documento = pdfMake.createPdf({
		info: {
			title: "Extrato Movimento Produto",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: pageWidth ? [20, 5, 20, 5] : [1, 1, 1, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(empresa),

			{ text: "MOVIMENTO DIÁRIO POR PRODUTO", style: "header" },
			{ text: `Data inicial: ${new Date(dataI.split("-")).toLocaleDateString("pt-BR")}`, style: { bold: true, fontSize: 9 } },
			{ text: `Data final: ${new Date(dataF.split("-")).toLocaleDateString("pt-BR")}`, style: { bold: true, fontSize: 9 } },

			cliente ? { text: `Cliente: ${cliente.id} - ${cliente.nome}`, style: { bold: true, fontSize: 10 } } : null,
			vendedor ? { text: `Vendedor: ${vendedor.id} - ${vendedor.nome}`, style: { bold: true, fontSize: 10 } } : null,
			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
					dontBreakRows: true,
					keepWithHeaderRows: true
				},
				width: pageWidth ? "auto" : 200,
				layout: pageWidth ? {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				} : {
					paddingBottom: function () {
						return 1;
					},
					paddingLeft: function () {
						return 1;
					},
					paddingRight: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
				},
				margin: [0, 1, 0, 1],
			},
			imprimirValor ? {
				text: `Total Qtd.: ${totalQuantidade}     Total: ${Number(totalGeral || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
				style: { bold: true },
				alignment: "right"
			} : null,

			{
				text: gerarMensagemAgradecimento(new Date().getHours()),
				style: "header"
			},

			{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
			{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
	
};

export const gerarRelatorioHistoricoProduto = async (context, { produtos, dataI, dataF, totais}) => {
	const produto = produtos[0];
	function convertDate(date) {
		return date.split("-").reverse().join("/");
	}
	const tabelaProdutos = [];

	tabelaProdutos.push([
		{
			text: "N°. Mov",
			style: "headerTable"
		},
		{
			text: "Data",
			style: "headerTable"
		},
		{
			text: "Descrição",
			style: "headerTable"
		},
		{
			text: "Qtd. Entrada",
			style: "headerTable"
		},
		{
			text: "Qtd. Saída",
			style: "headerTable"
		},
		{
			text: "% Desc",
			style: "headerTable"
		},
		{
			text: "$ Saída",
			style: "headerTable"
		},
		{
			text: "$ Entrada",
			style: "headerTable"
		},
	]);

	if(produtos.length > 0) {
		produtos.forEach(prod => {
			tabelaProdutos.push([
				{
					text: prod.documento || ""	,
					style: "itemTable"
				},
				{
					text: convertDate(prod.data || ""),
					style: "itemTable"
				},
				{
					text: prod.descricao,
					style: "itemTable",
					alignment: "left",
				},
				{
					text: (prod.qtdeentrada || 0),
					style: "itemTable"
				},
				{
					text: (prod.qtdesaida || 0),
					style: "itemTable"
				},
				{
					text: (prod.desconto || 0),
					style: "itemTable"
				},
				{
					text: (prod.valorsaida || 0).toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL"
					}),
					style: "itemTable"
				},
				{
					text: (prod.valorentrada || 0).toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL"
					}),
					style: "itemTable"
				},
			]);
		});
		tabelaProdutos.push([
			{
				text: ""	,
				style: "itemTable"
			},
			{
				text: "",
				style: "itemTable"
			},
			{
				text: "TOTAL",
				style: "headerTable",
				alignment: "right"
			},
			{
				text: totais.qtdEntrada,
				style: "headerTable"
			},
			{
				text: (totais.qtdSaida || 0),
				style: "headerTable"
			},
			{
				text: "",
				style: "headerTable"
			},
			{
				text: Number(totais.vlrSaida || 0).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL",
				}),
				style: "headerTable"
			},
			{
				text: Number(totais.vlrEntrada || 0).toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL",
				}),
				style: "headerTable"
			},
		]);
	}

	const styles = {
		itemTable: {
			fontSize: 9,
			alignment: "center"
		},
		headerTable: {
			fontSize: 11,
			alignment: "center",
			bold: true,
		}
	};
	const documento = pdfMake.createPdf({
		info: {
			title: "Extrato Histórico do Produto",
			author: "BDS Soluções em Sistemas"
		},

		pageSize: "A4",

		pageMargins: [20, 5, 20, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(context.state.Empresa),
			{
				text: `Relatório do produto ${produto?.cdproduto} - ${produto?.descricao}, do dia ${convertDate(dataI)} ao dia ${convertDate(dataF)}`,
				margin: [0, 10, 0, 10],
				bold: true,
			},
			{
				table: {
					body: tabelaProdutos,
					widths: [40, 60, 140, 40, 40, 40, 60, 60]
				},
			},
			
		],


		styles,
		

		
	});
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarRelatorioLotesVencimento = async (context, { lotes, dataI, dataF}) => {
	function convertDate(date) {
		return date.split("-").reverse().join("/");
	}
	const tabelaProdutos = [];

	tabelaProdutos.push([
		{
			text: "Código do Lote",
			style: "headerTable"
		},
		{
			text: "Código do Produto",
			style: "headerTable"
		},
		{
			text: "Descrição",
			style: "headerTable"
		},
		{
			text: "Data de Vencimento",
			style: "headerTable"
		},
		{
			text: "Data de Fabricação",
			style: "headerTable"
		},
		{
			text: "Saldo",
			style: "headerTable"
		},
	]);

	if(lotes.length > 0) {
		lotes.forEach(lote => {
			tabelaProdutos.push([
				{
					text: lote.cdlote || ""	,
					style: "itemTable"
				},
				{
					text: convertDate(lote.cdproduto || ""),
					style: "itemTable"
				},
				{
					text: lote.descricao,
					style: "itemTable",
					alignment: "left",
				},
				{
					text: (convertDate(lote.dataVenc)),
					style: "itemTable"
				},
				{
					text: (convertDate(lote.fabricacao)),
					style: "itemTable"
				},
				{
					text: (lote.saldo || 0),
					style: "itemTable"
				},
			]);
		});
	}

	const styles = {
		itemTable: {
			fontSize: 9,
			alignment: "center"
		},
		headerTable: {
			fontSize: 11,
			alignment: "center",
			bold: true,
		}
	};
	const documento = pdfMake.createPdf({
		info: {
			title: "Extrato Lotes por Vencimento",
			author: "BDS Soluções em Sistemas"
		},

		pageSize: "A4",

		pageMargins: [20, 5, 20, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(context.state.Empresa),
			{
				text: `Relatório do dia ${convertDate(dataI)} ao dia ${convertDate(dataF)}`,
				margin: [0, 10, 0, 10],
				bold: true,
			},
			{
				table: {
					body: tabelaProdutos,
					widths: [40, 60, 140, 60, 60, 60]
				},
			},
			
		],
		styles,		
	});
	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarRelatorioDesempenhoProduto = async (context, { empresa, itens, dataI, dataF, pageWidth, condicao }) => {

	const tabelaProdutos = [];

	let tableWidths = null;
	context.state.carregando = true;

	let quantidadeTotal = 0,
		totalCusto = 0,
		totalBruto = 0,
		totalDesconto = 0,
		totalTroca = 0,
		totalLiquido = 0,
		totalLucro = 0;
	function breakword(ref){
		var newStr = "";
		var str = ref.trim();
		let index = 10;
		const [inteiro, decimal] = (str.length / index).toString().split(".");
		var repetir = decimal > 0 ? inteiro + 1 : inteiro;
		for(let i = 0; i < repetir; i++){
			newStr += str.substring(0, index) + (i + 1 < repetir ? "\n" : "");
			str = str.substring(index);
			i++;
		}
		return newStr.trim();
	}
	if (!pageWidth) {
		tabelaProdutos.push([
			{ text: "Cód", style: "headerTabela" },
			{ text: "Produto", style: "headerTabela" },
			{ text: condicao == "V" ? "Qtd." : "Saldo", style: "headerTabela" },
			{ text: condicao == "V" ? "$ L/P" : "% Lucro", style: "headerTabela" },
		]);
		itens.forEach(item => {
			tabelaProdutos.push([
				{ text: item.cdproduto, style: "itemTabela" },
				{ text: item.descricao, style: "itemTabela", alignment: "left" },
				{
					text: Number(item.quantidade || 0).toFixed(2).replace(".", ","),
					style: "itemTabela",
					alignment: "right"
				},
				{
					text: breakword(Number(item.lucro || 0).toFixed(2).replace(".", ",")),
					style: "itemTabela"
				},
			]);
			quantidadeTotal += item.quantidade;
			totalCusto += Number(item.custo || 0);
			totalBruto += Number(item.bruto || 0);
			totalDesconto += Number(item.desconto || 0);
			totalTroca += Number(item.troca || 0);
			totalLiquido += Number(item.liquido || 0);
			totalLucro += Number(item.lucro || 0);
		});

		tabelaProdutos.push([

			{ text: `TOTAL Qtd.: ${quantidadeTotal.toFixed(2).replace(".", ",")} \n TOTAL Lucro: ${breakword(Number(totalLucro).toFixed(2).replace(".", ","))}`, style: "headerTabela", colSpan: 4, alignment: "right" },
			{},
		]);
		tableWidths = ["auto", "*", "auto", "auto"];
	} else {
		if (condicao == "V") {
			tabelaProdutos.push([
				{ text: "Cód", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Produto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				{ text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1", alignment: "center" },
				{ text: "$ Custo", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
				{ text: "$ Bruto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
				{ text: "$ Desconto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
				{ text: "$ Troca", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
				{ text: "$ Líquido", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
				{ text: "$ L/P", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
			]);
		} else {
			tabelaProdutos.push([
				{ text: "Cód", style: "headerTabela", fillColor: "#c1c1c1" },
				{ text: "Produto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
				{ text: "Saldo", style: "headerTabela", fillColor: "#c1c1c1", alignment: "center" },
				{ text: "$ Custo", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
				{ text: "% Margem", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
				{ text: "$ Bruto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
				{ text: "% Desconto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
				{ text: "$ Líquido", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
			]);
		}
		itens.forEach(item => {
			if (condicao == "V") {
				tabelaProdutos.push([
					{ text: item.cdproduto, style: "itemTabela" },
					{ text: item.descricao, style: "itemTabela", alignment: "left" },
					{ text: item.quantidade, style: "itemTabela" },
					{ text: Number(item.custo || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
					{ text: Number(item.bruto || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
					{ text: Number(item.desconto || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
					{ text: Number(item.troca || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
					{ text: Number(item.liquido || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
					{ text: Number(item.lucro || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
				]);
			} else {
				tabelaProdutos.push([
					{ text: item.cdproduto, style: "itemTabela" },
					{ text: item.descricao, style: "itemTabela", alignment: "left" },
					{ text: item.quantidade, style: "itemTabela" },
					{ text: Number(item.custo || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
					{ text: Number(item.lucro || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
					{ text: Number(item.bruto || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
					{ text: Number(item.desconto || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
					{ text: Number(item.liquido || 0).toFixed(2).replace(".", ","), style: "itemTabela", alignment: "right" },
				]);
			}
			quantidadeTotal += item.quantidade;
			totalCusto += Number(item.custo || 0);
			totalBruto += Number(item.bruto || 0);
			totalDesconto += Number(item.desconto || 0);
			totalTroca += Number(item.troca || 0);
			totalLiquido += Number(item.liquido || 0);
			totalLucro += Number(item.lucro || 0);
		});
		if (condicao == "V") {
			tabelaProdutos.push([
				{ text: "TOTAL: ", style: "headerTabela", colSpan: 2, alignment: "right" },
				{},
				{ text: quantidadeTotal.toFixed(2).replace(".", ","), style: "headerTabela" },
				{ text: Number(totalCusto).toFixed(2).replace(".", ","), style: "headerTabela", alignment: "right" },
				{ text: Number(totalBruto).toFixed(2).replace(".", ","), style: "headerTabela", alignment: "right" },
				{ text: Number(totalDesconto).toFixed(2).replace(".", ","), style: "headerTabela", alignment: "right" },
				{ text: Number(totalTroca).toFixed(2).replace(".", ","), style: "headerTabela", alignment: "right" },
				{ text: Number(totalLiquido).toFixed(2).replace(".", ","), style: "headerTabela", alignment: "right" },
				{ text: Number(totalLucro).toFixed(2).replace(".", ","), style: "headerTabela", alignment: "right" },
			]);
		} else {
			tabelaProdutos.push([
				{ text: "TOTAL: ", style: "headerTabela", colSpan: 3, alignment: "right" },
				{},
				{},
				{ text: Number(totalCusto).toFixed(2).replace(".", ","), style: "headerTabela", alignment: "right" },
				{},
				{ text: Number(totalBruto).toFixed(2).replace(".", ","), style: "headerTabela", alignment: "right" },
				{},
				{ text: Number(totalLiquido).toFixed(2).replace(".", ","), style: "headerTabela", alignment: "right" },
			]);
		}
		tableWidths = condicao == "V" ? ["auto", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto"] : ["auto", "*", "auto", "auto", "auto", "auto", "auto", "auto"];
	}
	let pageSize = !pageWidth ? {
		width: 200,
		height: "auto"
	} : pageWidth;

	const documento = pdfMake.createPdf({
		info: {
			title: "Extrato Desempenho Produto",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: pageWidth ? [20, 5, 20, 5] : [1, 1, 1, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(empresa),

			{ text: "DESEMPENHO POR PRODUTO", style: "header" },
			{ text: `Data inicial: ${new Date(dataI.split("-")).toLocaleDateString("pt-BR")}`, style: { bold: true, fontSize: 9 } },
			{ text: `Data final: ${new Date(dataF.split("-")).toLocaleDateString("pt-BR")}`, style: { bold: true, fontSize: 9 } },

			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				layout: pageWidth ? {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				} : {
					paddingBottom: function () {
						return 1;
					},
					paddingLeft: function () {
						return 1;
					},
					paddingRight: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
				},
				margin: [0, 3, 0, 3],
			},
			{
				text: gerarMensagemAgradecimento(new Date().getHours()),
				style: "header"
			},

			{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
			{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};
// export const imprimirNFE = async (context) => { // depois adicionar o payload
// 	const pageSize = "A4";
// 	const pageMargins = [30, 0, 30, 20];
// 	function breakword(ref, max){
// 		var newStr = "";
// 		if(!ref) return;
// 		// var str = ref.trim().substring(0, 30);
// 		var str = ref.trim();
// 		let index = max;
// 		const [inteiro, decimal] = (str.length / index).toString().split(".");
// 		var repetir = decimal > 0 ? inteiro + 1 : inteiro;
// 		for(let i = 0; i < repetir; i++){
// 			newStr += str.substring(0, index) + (i + 1 < repetir ? "\n" : "");
// 			str = str.substring(index);
// 			i++;
// 		}
// 		return newStr.trim();
// 	}
// 	const documento = pdfMake.createPdf({
// 		info: {
// 			title: "NFe",
// 			author: "BDS Soluções em Sistemas"
// 		},
// 		pageSize,
// 		pageMargins,
// 		content: [
// 			//RECEBEMOS O PRODUTO DO CLIENTE
// 			[
// 				//SUBSTITUIR O NOME DO CLIENTE POR VARIAVEL FUTURAMENTE
// 				{
// 					text: "RECEBEMOS DE ODINETE SANTANA TOLOY OS PRODUTOS/SERVIÇOS CONSTANTES DA NOTA FISCAL INDICADA AO LADO", 
// 					style: "smallTextNota",
// 					absolutePosition: {x: 35, y: 22}
// 				}
// 			],
// 			{					
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 415,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {x: 30, y: 20}

// 			},
// 			//DATA DO RECEBIMENTO
// 			[
// 				{
// 					text: "DATA DE RECEBIMENTO", 
// 					style: "smallTextNota",
// 					absolutePosition: {x: 35, y: 44}
// 				},
// 			],
// 			{
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 160,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 42
// 				}
// 			},
// 			//DENTIFICAÇÃO E ASSINATURA (RECEBEDOR)
// 			[
// 				{
// 					text: "IDENTIFICAÇÃO E ASSINATURA DO RECEBEDOR", 
// 					style: "smallTextNota",
// 					absolutePosition: {x: 195, y: 44}
// 				},
// 			],
// 			{
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 255,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 190,
// 					y: 42
// 				}
// 			},
// 			//N° NOTA E SERIE
// 			[
// 				{
// 					text: "NF-e", 
// 					style: "smallTextNota",
// 					absolutePosition: {x: 504, y: 25}
// 				},
// 			],
// 			//SUBSTITUIR POR VARIAVEL
// 			[
// 				{
// 					text: "N° 000.000.133",
// 					style: "textMediumNota",
// 					absolutePosition: {x: 455, y: 35}
// 				},
// 				{
// 					text: "SÉRIE: 1",
// 					style: "textMediumNota",
// 					absolutePosition: {x: 455, y: 50}

// 				}
// 			],
// 			{
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 127,
// 						h: 43,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 444.3,
// 					y: 20.5
// 				}
// 			},
// 			{
// 				text: "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",
// 				alignment: "center",
// 				fontSize: 5,
// 				margin: [0, 67, 0, 10],
// 			},
// 			//INFORMAÇÕES CLIENTE
// 			[
// 				{
// 					//SUBSTITUIR POR VARIAVEL
// 					text: breakword("ODINETE DUTRA SANTANA TOLOY JASPERINA", 27),
// 					width: 50,
// 					noWrap: false,
// 					bold: true,
// 					fontSize: 10,
// 					absolutePosition: {x: 50, y: 85}
// 				}
// 			],
// 			[
// 				{
// 					//SUBSTITUIR POR VARIAVEL
// 					text: breakword("RUA FORMOSA, 175 - CENTRO, Marialva, PR  CEP: 86990000", 50),
// 					width: 50,
// 					fontSize: 8,
// 					bold: true,
// 					absolutePosition: {x: 35, y: 135}
// 				}
// 			],
// 			{
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 230,
// 						h: 105,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 75
// 				}
// 			},
// 			//INFORMAÇÕES DANFE
// 			[
// 				{
// 					text: "DANFE",
// 					fontSize: 12,
// 					bold: true,
// 					absolutePosition: {
// 						x: 300, 
// 						y: 75
// 					}
// 				}
// 			],
// 			{
// 				text: breakword("Documento Auxiliar da Nota Fiscal Eletrônica", 26),
// 				alignment: "center",
// 				fontSize: 9,
// 				absolutePosition: {
// 					x: 70, 
// 					y: 87
// 				}
// 			},
// 			{
// 				text:[
// 					{
// 						text: "0 - Entrada",
// 					},
// 					"\n",
// 					{
// 						text: "1 - Saida"
// 					}
// 				],
// 				absolutePosition: {
// 					x: 263,
// 					y: 110
// 				},
// 				fontSize: 9,
// 			},
// 			{
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 30,
// 						h: 23,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 325,
// 					y: 110
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "1",
// 				absolutePosition: {
// 					x: 336,
// 					y: 115
// 				}
// 			},
// 			{
// 				text: [
// 					{
// 						//SUBSTITUIR POR VARIAVEL
// 						text: "N° 000.000.133"
// 					},
// 					"\n",
// 					{
// 						//SUBSTITUIR POR VARIAVEL
// 						text: "SÉRIE: 1"
// 					}
// 				],
// 				fontSize: 11,
// 				bold: true,
// 				absolutePosition: {
// 					x: 263,
// 					y: 135
// 				},	
// 			},
// 			{
// 				//SUBSTITUIR POR CONTADOR DE PAGINA FUTURAMENTE
// 				text: "Pagina 1 de 2",
// 				fontSize: 11,
// 				bold: true,
// 				absolutePosition: {
// 					x: 286,
// 					y: 165
// 				},	
// 			},
// 			//CONTROLE DO FISCO
// 			{
// 				text: "CONTROLE DO FISCO",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 382, y: 77}
// 			},
// 			{
// 				//SUBSTITUIR O CDBARRA POR VARIAVEL DEPOIS
// 				image: gerarCodigoBarras("00000000000000000000000000000000000000000000", 55, "CODE128"),
// 				fit: [185, 300],
// 				absolutePosition: {x: 382, y: 83},
// 				margin: [0, 5, 0, 0]
// 			},
// 			{
// 				text: "CHAVE DE ACESSO",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 385, y: 114}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "4123 0180 2398 8200 0181 5500 1000 0001 3313 0006 0797",
// 				fontSize: 7,
// 				bold: true,
// 				alignment: "center",
// 				absolutePosition: { 
// 					x: 386, 
// 					y: 120
// 				}
// 			},
// 			{
// 				text: "Consulta de autenticidade no portal nacional da NF-e www.nfe.fazenda.gov.br/portal ou no site da Sefaz Autorizada",
// 				fontSize: 9,
// 				alignment: "justify",
// 				absolutePosition: { 
// 					x: 383, 
// 					y: 145
// 				}
// 			},
// 			{
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 188,
// 						h: 30,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 380,
// 					y: 112
// 				}
// 			},
// 			{
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 198,
// 						h: 105,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 375,
// 					y: 75
// 				}
// 			},

// 			//1° LINHA
// 			{
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 347,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 180
// 				}
// 			},
// 			{
// 				//NATUREZA DE OPERAÇÃO
// 				text: "NATUREZA DE OPERAÇÃO",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 182
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "DEVOLUCAO DE MERCADORIA",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 189
// 				}
// 			},
// 			{
// 				//PROTOCOLO DE AUTORIZACAO
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 195,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 378,
// 					y: 180
// 				}
// 			},
// 			{
// 				//PROTOCOLO DE AUTORIZAÇÃO
// 				text: "PROTOCOLO DE AUTORIZAÇÃO DE USO",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 384,
// 					y: 182
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL (autorizacao e data)
// 				text: "141230025846546 - 31/01/2023 10:26",
// 				style: "textVariavelNota",
// 				fontSize: 7,
// 				absolutePosition: {
// 					x: 415,
// 					y: 189
// 				}
// 			},
// 			//2° LINHA
// 			{
// 				//INSCRICAO ESTADUAL
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 170,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 201
// 				}
// 			},
// 			{
// 				//2
// 				//INSCRIÇÃO ESTADUAL
// 				text: "INSCRIÇÃO ESTADUAL",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 203
// 				}
// 			},
// 			{
// 				//7
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "7030024553",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 210
// 				}
// 			},
// 			{
// 				//INSCRICAO ESTADUAL DO SUBST. TRIB
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 150,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 200,
// 					y: 201
// 				}
// 			},
// 			{
// 				//INSCRICAO ESTADUAL DO SUBST. TRIB
// 				text: "INSCRICAO ESTADUAL DO SUBST. TRIB",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 205,
// 					y: 203
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "7030024553",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 205,
// 					y: 210
// 				}
// 			},
// 			{
// 				//CNPJ/CPF
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 223,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 350,
// 					y: 201
// 				}
// 			},
// 			{
// 				//CNPJ / CPF
// 				text: "CNPJ / CPF",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 355,
// 					y: 203
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "100.488.429-07",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 355,
// 					y: 210
// 				}
// 			},
// 			//3° LINHA
// 			{
// 				// DESTINATÁRIO/REMETENTE
// 				text: "DESTINATÁRIO/REMETENTE",
// 				fontSize:  7,
// 				bold: true,
// 				absolutePosition: {x: 33, y: 225}
// 			},
// 			{
// 				// DESTINATÁRIO/REMETENTE
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 300,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 235
// 				}
// 			},
// 			{
// 				//NOME/RAZÃO SOCIAL
// 				text: "NOME/RAZÃO SOCIAL",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 237
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVAL
// 				text: "CALCADOS BEIRA RIO S/A",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 244
// 				}
// 			},
// 			{
// 				// DESTINATÁRIO/REMETENTE
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 130,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 330,
// 					y: 235
// 				}
// 			},
// 			{
// 				//CNPJ / CPF
// 				text: "CNPJ / CPF",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 335,
// 					y: 237
// 				}
// 			},
// 			{
// 				//substituir por variaval
// 				text: "100.488.429.07",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 335,
// 					y: 244
// 				}
// 			},
// 			{
// 				//DATA EMISSAO
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 113,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 460,
// 					y: 235
// 				}
// 			},
// 			{
// 				//DATA DA EMISSAO - DEST/REMET
// 				text: "DATA DA EMISSAO",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 465,
// 					y: 237
// 				}
// 			},
// 			{
// 				//DATA DA EMISSAO - DEST/REMET
// 				text: "06/02/2023",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 465,
// 					y: 244
// 				}
// 			},
// 			//4° Linha 
// 			{
// 				//ENDEREÇO
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 255,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 256.5
// 				}
// 			},
// 			{
// 				//ENDERECO DEST-REMET
// 				text: "ENDEREÇO",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 258.5
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "Rua Washington Luiz, N. 1129, Centro Comercial Cristal",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 265.5
// 				}
// 			},
// 			{
// 				//BAIRRO
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 90,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 285,
// 					y: 256.5
// 				}
// 			},
// 			{
// 				// BAIRRO/DISTRITO
// 				text: "BAIRRO/DISTRITO",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 290,
// 					y: 258.5
// 				}
// 			},
// 			{
// 				text: "CENTRO",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 290,
// 					y: 265.5
// 				}
// 			},

// 			{
// 				// CEP
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 100,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 375,
// 					y: 256.5
// 				}
// 			},
// 			//CEP
// 			{
// 				text: "CEP",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 380,
// 					y: 258.5
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "86.990-000",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 380,
// 					y: 265.5
// 				}
// 			},
// 			{
// 				//DATA ENTRADA SAIDA
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 98,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 475,
// 					y: 256.5
// 				}
// 			},
// 			{
// 				// DATA ENTRADA/SAIDA
// 				text: "DATA ENTRADA/SAIDA",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 480,
// 					y: 258.5
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "06/02/2023",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 480,
// 					y: 265.5
// 				}
// 			},
// 			//5° Linha
// 			{
// 				//MUNICIPIO
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 170,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 278
// 				}
// 			},
// 			{
// 				// MUNICIPIO
// 				text: "MUNICÍPIO",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 280
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "Marialva",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 35,
// 					y: 287
// 				}
// 			},
// 			{
// 				//FONE/FAX
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 100,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 200,
// 					y: 278
// 				}
// 			},
// 			{
// 				// MUNICIPIO
// 				text: "FONE/FAX",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 205,
// 					y: 280
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "(44) 9961-5260",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 205,
// 					y: 287
// 				}
// 			},
// 			{
// 				//UF
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 40,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 300,
// 					y: 278
// 				}
// 			},
// 			{
// 				// UF
// 				text: "UF",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 305,
// 					y: 280
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "PR",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 305,
// 					y: 287
// 				}
// 			},
// 			{
// 				//INSCRICAO ESTADUAL
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 150,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 340,
// 					y: 278
// 				}
// 			},
// 			{
// 				// INSCRICAO ESTADUAL
// 				text: "INSCRIÇÃO ESTADUAL",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 345,
// 					y: 280
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 305,
// 					y: 278
// 				}
// 			},
// 			{
// 				//HORA SAIDA
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 83,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 490,
// 					y: 278
// 				}
// 			},
// 			{
// 				// HORA SAIDA
// 				text: "HORA DA SAÍDA",
// 				style: "smallTextNota",
// 				absolutePosition: {
// 					x: 495,
// 					y: 280
// 				}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "16:28:40",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 495,
// 					y: 287
// 				}
// 			},
// 			{
// 				//FATURA/DUPLICATA
// 				text: "FATURA/DUPLICATA",
// 				fontSize: 7,
// 				bold: true,
// 				absolutePosition: {x: 35, y: 303}
// 			},
// 			{
// 				//FATURA/DUPLICATA
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 543,
// 						h: 30,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 313
// 				}
// 			},
// 			//7° Linha
// 			{
// 				//CÁLCULO DE IMPOSTO
// 				text: "CÁLCULO DE IMPOSTO",
// 				fontSize: 7,
// 				bold: true,
// 				absolutePosition: {x: 35, y: 348}
// 			},
// 			{
// 				//BASE DE CÁLCULO DO ICMS
// 				text: "BASE DE CÁLCULO DO ICMS",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 35, y: 360}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "716,04",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 95,
// 					y: 367
// 				}
// 			},
// 			{
// 				//BASE DE CÁLCULO DO ICMS
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 100,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 358
// 				}
// 			},
// 			{
// 				//VALOR DO ICMS
// 				text: "VALOR DO ICMS",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 135, y: 360}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "1111,04",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 190,
// 					y: 367
// 				}
// 			},
// 			{
// 				//VALOR DO ICMS
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 100,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 130,
// 					y: 358
// 				}
// 			},
// 			{
// 				//BASE DE CÁLCULO DO ICMS ST
// 				text: "BASE DE CÁLCULO DO ICMS ST",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 235, y: 360}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "716,04",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 295,
// 					y: 367
// 				}
// 			},
// 			{
// 				//BASE DE CÁLCULO DO ICMS ST
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 100,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 230,
// 					y: 358
// 				}
// 			},
// 			{
// 				//VALOR DO ICMS ST
// 				text: "VALOR DO ICMS ST",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 335, y: 360}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "716,04",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 399,
// 					y: 367
// 				}
// 			},
// 			{
// 				//VALOR DO ICMS ST
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 100,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 330,
// 					y: 358
// 				}
// 			},
// 			{
// 				//VALOR TOTAL DOS PRODUTOS
// 				text: "VALOR TOTAL DOS PRODUTOS",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 435, y: 360}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "716,04",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 520,
// 					y: 367
// 				}
// 			},
// 			{
// 				//VALOR TOTAL DOS PRODUTOS
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 143,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 430,
// 					y: 358
// 				}
// 			},
// 			//8° Linha
// 			{
// 				//VALOR DO FRETE
// 				text: "VALOR DO FRETE",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 35, y: 382}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "716,04",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 85,
// 					y: 388
// 				}
// 			},
// 			{
// 				//VALOR DO FRETE
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 90,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 30,
// 					y: 380
// 				}
// 			},
// 			{
// 				//VALOR DO SEGURO
// 				text: "VALOR DO SEGURO",
// 				style: "smallTextNota",
// 				aligment: "right",
// 				absolutePosition: {x: 125, y: 382}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "716,04",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 180,
// 					y: 388
// 				}
// 			},
// 			{
// 				//VALOR DO SEGURO
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 90,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 120,
// 					y: 380
// 				}
// 			},
// 			{
// 				//DESCONTO
// 				text: "DESCONTO",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 215, y: 382}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "716,04",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 250,
// 					y: 388
// 				}
// 			},
// 			{
// 				//DESCONTO
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 70,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 210,
// 					y: 380
// 				}
// 			},
// 			{
// 				//OUTRAS DESPESAS ACESSÓRIAS
// 				text: "OUTRAS DESPESAS ACESSÓRIAS",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 285, y: 382}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "0,00",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 370,
// 					y: 388
// 				}
// 			},
// 			{
// 				//OUTRAS DESPESAS ACESSÓRIAS
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 120,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 280,
// 					y: 380
// 				}
// 			},
// 			{
// 				//VALOR DO IPI
// 				text: "VALOR DO IPI",
// 				style: "smallTextNota",
// 				absolutePosition: {x: 405, y: 382}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "0,00",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 455,
// 					y: 388
// 				}
// 			},
// 			{
// 				//VALOR DO IPI
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 80,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 400,
// 					y: 380
// 				}
// 			},
// 			{
// 				//VALOR TOTAL DA NOTA
// 				text: "VALOR TOTAL DA NOTA",
// 				bold: true,
// 				style: "smallTextNota",
// 				absolutePosition: {x: 485, y: 382}
// 			},
// 			{
// 				//SUBSTITUIR POR VARIAVEL
// 				text: "0,00",
// 				style: "textVariavelNota",
// 				absolutePosition: {
// 					x: 540,
// 					y: 388
// 				}
// 			},
// 			{
// 				//VALOR TOTAL DA NOTA
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 93,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 480,
// 					y: 380
// 				}
// 			},
// 			{
// 				//TRANSPORTADOR
// 				text: "TRANSPORTADOR/VOLUMES TRANSPORTADOS",
// 				fontSize: 7,
// 				bold: true,
// 				absolutePosition: {x: 35, y: 410}
// 			},
// 			{
// 				//TRANSPORTADOR
// 				canvas: [
// 					{
// 						type: "rect",
// 						x: 0,
// 						y: 0,
// 						w: 110,
// 						h: 21,
// 						r: 3,
// 						lineColor: "black",
// 					},
// 				],
// 				absolutePosition: {
// 					x: 480,
// 					y: 380
// 				}
// 			},
// 		],
// 		styles: {
// 			smallTextNota: {
// 				fontSize: 6
// 			},
// 			textVariavelNota: {
// 				fontSize: 8,
// 				bold: true,

// 			},
// 			textMediumNota: {
// 				fontSize: 8
// 			}
// 		}
// 	});

// 	documento.getBlob((pdfBlob) => {
// 		if (!pdfBlob) {
// 			return;
// 		}
// 		const url = window.URL.createObjectURL(pdfBlob);
// 		context.state.base64 = url;
// 		context.state.carregando = false;
// 	});

// };

export const imprimirNFCE = async (context, {pagamentos, produtos, consumidor, emitente, infoNota}) => {
	let pageSize = {
		width: 200,
		height: "auto"
	};
	let optionsDate = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit"
	};
	let qtdItens = 0;
	let generateBodyColumns = [];
	let columnBodyPagamentos = [];
	let verificarConsumidor = [];
	if(consumidor?.cpf){
		verificarConsumidor.push([
			{
				text: "-----------------------------------------------------",
				alignment: "center",
				margin: [0, 3, 0, 3]
			},
			{
				text: "CONSUMIDOR",
				fontSize: 10,
				alignment: "center",
				bold: true,
			},
			{
				text: `CPF: ${consumidor.cpf}`,
				alignment: "left",
				bold: true,
				fontSize: 10,
				margin: [5, 5, 0, 5]
			},
			{
				text: `NOME: ${consumidor.nome}`,
				alignment: "left",
				fontSize: 10,
				bold: true,
				margin: [5, 0, 0 ,0]
			},
			{
				text: "-----------------------------------------------------",
				alignment: "center",
				margin: [0, 3, 0, 3]
			},
		]);
	}
	pagamentos.forEach(pagamento => {
		columnBodyPagamentos.push(
			{
				columns: [
					{
						width: 140,
						text: pagamento.tipoPgto
					},
					{
						width: 30,
						text: (pagamento.valor || 0).toFixed(2).replace(".", ",")
					}
				],
				fontSize: 9,
				margin: [5, 0, 10, 0]
			}
		);
	});
	produtos.forEach((produto, index) => {
		qtdItens += produto.quantidade,
		generateBodyColumns.push(
			{
				columns: [
					{
						width: 10,
						text: index + 1
					},
					{
						width: 35,
						text: produto.cdproduto
					},
					{
						width: 200,
						text: produto.descricao
					},
					
				],
				margin: [0, 2, 0, 2],
				style: {
					fontSize: 8,
					alignment: "left",
				}
			},
			{
				columns: [
					{
						width: 10,
						text: "",
					},
					{
						width: 25,
						text: "",
					},
					{
						width: 60,
						text: "",
					},
					{
						width: 25,
						text: produto.quantidade
					},
					{
						width: 40,
						text: (produto.vlrunitario).toFixed(2).replace(".", ",")
					},
					{
						width: 35,
						text: (produto.vlrunitario * produto.quantidade).toFixed(2).replace(".", ",")
					}
				],
				margin: [0, 2, 0, 0],
				style: {
					fontSize: 8,
					alignment: "justify"
				}
			},
		
		);
	});

	const documento = pdfMake.createPdf({
		info: {
			title: `${infoNota.chNFe}`,
			author: "BDS Soluções em Sistemas"
		},
		pageSize,
		pageMargins: [10, 20, 10, 10],
		content: [
			[
				{
					text: emitente.nome,
					style: {
						fontSize: 12,
						bold: true,
						alignment: "center"
					},
					margin: [0, 10, 0, 0],
				},
				{
					text: [
						`CNPJ: ${emitente.cnpj}  IE: ${emitente.ie}`,
					],
					style: {
						fontSize: 9,
						bold: true,
						alignment: "center",
					},
					margin: [0, 5, 0, 0]
				},
				{
					text: `${emitente.logradouro}, ${emitente.numero} - ${emitente.bairro} - ${emitente.municipio} - ${emitente.uf}`,
					margin: [0, 5, 0, 0],
					style: {
						fontSize: 9,
						bold: true,
						alignment: "center",
					}

				},
				{
					text: "-----------------------------------------------------",
					alignment: "center",
					margin: [0, 3, 0, 3]
				},
				{
					text: "DANFE NFC-e Documento Auxiliar de Nota Fiscal Eletrônica para Consumidor Final",
					style: {
						alignment: "center",
						bold: true,
						fontSize: 9
					}
				},
				{
					text: "-----------------------------------------------------",
					alignment: "center",
					margin: [0, 3, 0, 3]
				},
				{
					text: "DETALHES DA VENDA",
					bold: true,
					fontSize: 9,
					alignment: "center"
				},
				{
					text: "-----------------------------------------------------",
					alignment: "center",
					margin: [0, 3, 0, 3]
				},
			],
			{
				columns: [
					{
						width: 10,
						text: "#"
					},
					{
						width: 25,
						text: "Cód."
					},
					{
						width: 55,
						text: "Descrição"
					},
					{
						width: 23,
						text: "Qtd."
					},
					{
						width: 35,
						text: "Vl. Unit"
					},
					{
						width: 35,
						text: "Vl. Total"
					}
				],
				margin: [0, 0, 0, 3],
				style: {
					fontSize: 9,
					bold: true,
					alignment: "left",
				}
			},
			generateBodyColumns,
			{
				text: "-----------------------------------------------------",
				alignment: "center",
				margin: [0, 3, 0, 3]
			},
			{
				columns: [
					{
						width: 160,
						text: "QTD. TOTAL DE ITENS",
					},
					{
						width: 20,
						text: qtdItens,
					}
				],
				bold: true,
				fontSize: 10,
				margin: [5, 0, 10, 0]
			},
			{
				columns: [
					{
						width: 140,
						text: "VALOR TOTAL R$",
					},
					{
						width: 100,
						text: (infoNota.totalProduto || 0).toFixed(2).replace(".", ",")
					}
				],
				bold: true,
				margin: [5, 0, 10, 0],
				fontSize: 10,
			},
			{
				text: "-----------------------------------------------------",
				alignment: "center",
				margin: [0, 3, 0, 3]
			},
			{
				columns: [
					{
						width: 120,
						text: "FORMA PAGAMENTO",
						bold: true,
						fontSize: 10,
						margin: [5, 0, 0, 0]
					},
					{
						width: 80,
						text: "VALOR PAGO",
						bold: true,
						alignment: "right",
						fontSize: 10,
						margin: [0, 0, 20, 0]
					}
				]
			},
			columnBodyPagamentos,
			{
				text: "-----------------------------------------------------",
				alignment: "center",
				margin: [0, 3, 0, 3]
			},
			{
				text: `consulta pela chave de acesso em ${infoNota.urlConsulta}`,
				fontSize: 10,
				alignment: "center",
				bold: true
			},
			{
				text: "CHAVE DE ACESSO",
				fontSize: 10,
				alignment: "center",
				bold: true,
				margin: [0, 5, 0, 0]
			},
			{
				text: infoNota.chNFe,
				fontSize: 10,
				alignment: "center",
				bold: true,
				margin: [0, 5, 0, 0]
			},
			verificarConsumidor,			
			{
				text: `NFC-e n° ${infoNota.nmrNF} Série ${infoNota.serie}`,
				alignment: "center",
				fontSize: 11,
				bold: true,
			},
			{
				text: `${new Date(infoNota.dataEmissao).toLocaleDateString("pt-BR", optionsDate)}`,
				fontSize: 11,
				alignment: "center",
				bold: true,
				margin: [0, 5, 0, 0]
			},
			{
				text: "PROTOCOLO DE AUTORIZAÇÃO",
				alignment: "center",
				fontSize: 11,
				bold: true,
				margin: [0, 5, 0, 0]
			},
			{
				text: `${infoNota.nmrProtocolo} ${new Date(infoNota.dataAuth).toLocaleDateString("pt-BR", optionsDate)}`,
				alignment: "center",
				bold: true,
				fontSize: 10,
			},
			{
				text: "-----------------------------------------------------",
				alignment: "center",
				margin: [0, 3, 0, 3]
			},
			{
				text: "Consulta via QR Code",
				alignment: "center",
				fontSize: 12,
				bold: true,
			},
			{
				qr: `${infoNota.qrcode}`,
				fit: 140,
				alignment: "center",
				margin: [0, 10, 0, 1]
			},
			{
				text: "-",
				alignment: "center",
				width: 200,
				margin: [0, 10, 0, 3]
			},
		],
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});


};
export const gerarRelatorioSaldoEstoque = async (context, { empresa, itens, completo }) => {

	const tabelaProdutos = [];

	let tableWidths = null;

	let quantidadeTotal = 0,
		totalCusto = 0,
		totalVista = 0,
		totalPrazo = 0;

	if (completo) {
		tabelaProdutos.push([
			{ text: "Cód", style: "headerTabela", fillColor: "#c1c1c1" },
			{ text: "Produto", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
			{ text: "Saldo", style: "headerTabela", fillColor: "#c1c1c1", alignment: "center" },
			{ text: "$ Custo", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
			{ text: "$ Vista", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
			{ text: "$ Prazo", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		]);
		itens.forEach(item => {
			tabelaProdutos.push([
				{ text: item.cdproduto, style: "itemTabela" },
				{ text: item.descricao, style: "itemTabela", alignment: "left" },
				{ text: (item.saldo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
				{ text: Number(item.custo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela", alignment: "right" },
				{ text: Number(item.vista || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela", alignment: "right" },
				{ text: Number(item.prazo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela", alignment: "right" },
			]);
			quantidadeTotal += (item.saldo || 0);
			totalCusto += Number(item.custo || 0);
			totalVista += Number(item.vista || 0);
			totalPrazo += Number(item.prazo || 0);
		});
		tabelaProdutos.push([
			{ text: "TOTAL: ", style: "headerTabela", colSpan: 2, alignment: "right" },
			{},
			{ text: (quantidadeTotal).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela" },
			{ text: Number(totalCusto).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
			{ text: Number(totalVista).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
			{ text: Number(totalPrazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
		]);
		tableWidths = ["auto", "*", "auto", "auto", "auto", "auto"];
	} else {
		tabelaProdutos.push([
			{ text: "ITENS", style: "headerTabela", alignment: "center" },
			{ text: "$ CUSTO", style: "headerTabela", alignment: "right" },
			{ text: "$ VISTA", style: "headerTabela", alignment: "right" },
			{ text: "$ PRAZO", style: "headerTabela", alignment: "right" },
		]);
		itens.forEach(item => {
			quantidadeTotal += (item.saldo || 0);
			totalCusto += Number(item.custo || 0);
			totalVista += Number(item.vista || 0);
			totalPrazo += Number(item.prazo || 0);

		});
		tabelaProdutos.push([
			{ text: (quantidadeTotal).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela" },
			{ text: Number(totalCusto).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
			{ text: Number(totalVista).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
			{ text: Number(totalPrazo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
		]);
		tableWidths = ["*", "*", "*", "*"];
	}

	let pageSize = !completo ? {
		width: 200,
		height: "auto"
	} : "A4";

	const documento = pdfMake.createPdf({
		info: {
			title: "Extrato Saldo e Estoque",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: completo ? [20, 5, 20, 5] : [1, 1, 1, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(empresa),

			{ text: "SALDO E ESTOQUE", style: "header" },

			completo ? {
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				layout: completo ? {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				} : {
					paddingBottom: function () {
						return 1;
					},
					paddingLeft: function () {
						return 1;
					},
					paddingRight: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
				},
				margin: [0, 3, 0, 3],
			} : {
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				width: 200,
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingLeft: function () {
						return 1;
					},
					paddingRight: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
				},
				margin: [0, 3, 0, 3],
			},

			{
				text: gerarMensagemAgradecimento(new Date().getHours()),
				style: "header",
			},
			//test
			{ text: "BDS Soluções em Sistemas", style: "rodape", margin: [0, 1, 0, 0] },
			{ text: "(44) 3232-6083 / (44) 99972-8673", style: "rodape" },
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};


export const gerarRelatorioPosicaoEstoque = async (context, payload) => {
	const tabelaProdutos = [];
	tabelaProdutos.push([
		{ text: "Emp", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Cód.", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Desc.", style: "headerTabela", fillColor: "#c1c1c1", alignment: "center" },
		{ text: "Qtd. Compra", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		{ text: "$ Compra", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		{ text: "Custo", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		{ text: "últ. compra", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		{ text: "Qtd. vendido", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		{ text: "últ. venda", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
	]);

	if (payload?.forEach) {
		payload.forEach(prod => {
			tabelaProdutos.push([
				{
					text: prod.cdEmpresa,
					style: "itemTabela",
				},
				{
					text: prod.cdproduto,
					style: "itemTabela",
				},
				{
					text: prod.descricao,
					style: "itemTabela",
					alignment: "left"
				},
				{
					text: prod.quantidadeComprada,
					style: "itemTabela"
				},
				{
					text: Number(prod.totalCompra || 0).toLocaleString("pt-BR",{
						style: "currency",
						currency: "BRL"
					}),
					style: "itemTabela"
				},
				{
					text: Number(prod.customedio || 0).toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL"
					}),
					style: "itemTabela"
				},
				{
					text: `${prod.ultimaCompra || ""}`.split("-").reverse().join("/"),
					style: "itemTabela"
				},
				{
					text: prod.qtdeVendido,
					style: "itemTabela"
				},
				{
					text: `${prod.dataultimavenda || ""}`.split("-").reverse().join("/"),
					style: "itemTabela"
				},
			]);
		});
	}

	let pageSize = "A4";

	const tableWidths = [20, 40, "*", "auto", "auto", "auto", "auto", "auto", 45];

	const documento = pdfMake.createPdf({
		info: {
			title: "Relatório de Posição de Estoque do Produto",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [20, 5, 20, 5],

		pageOrientation: "portrait",

		content: [
			{
				text: "Relatório de Posição de Estoque do Produto",
				fontSize: 18,
				padding: [0, 15, 0, 15],
				margin: [0, 10, 0, 10],
				bold: true,
				alignment: "center"
			},
			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
			},
			{ text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape", absolutePosition: {x: 10, y:820}},
		],
		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarRelatorioFaturamentoDiasXHoras = async (context, { empresa, itens, dataI, dataF, total }) => {

	const tabelaProdutos = [];

	let tableWidths = null;
	tabelaProdutos.push([
		{ text: "Hora", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Domingo", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Segunda", style: "headerTabela", fillColor: "#c1c1c1", alignment: "center" },
		{ text: "Terça", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		{ text: "Qaurta", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		{ text: "Quinta", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		{ text: "Sexta", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
		{ text: "Sábado", style: "headerTabela", fillColor: "#c1c1c1", alignment: "right" },
	]);
	itens.forEach(item => {
		tabelaProdutos.push([
			{ text: item.hora + ":00", style: "itemTabela" },
			{ text: "R$ " + Number(item.domingo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: "R$ " + Number(item.segunda || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela", alignment: "right" },
			{ text: "R$ " + Number(item.terca || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela", alignment: "right" },
			{ text: "R$ " + Number(item.quarta || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela", alignment: "right" },
			{ text: "R$ " + Number(item.quinta || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela", alignment: "right" },
			{ text: "R$ " + Number(item.sexta || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela", alignment: "right" },
			{ text: "R$ " + Number(item.sabado || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela", alignment: "right" },
		]);
	});
	tabelaProdutos.push([
		{ text: "TOTAL: R$ "+(total).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", colSpan: 8, alignment: "right" },
	]);
	tableWidths = ["*", "*", "*", "*", "*", "*", "*", "*"];

	let pageSize = "A4";

	const documento = pdfMake.createPdf({
		info: {
			title: "Faturamento Dias X Horas",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [20, 5, 20, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(empresa),

			{ text: "Faturamento Dias X Horas", style: "header" },
			{
				table: {
					widths: ["*","*"],
					body: [
						[{
							text: `Data inicial: ${new Date(dataI.split("-")).toLocaleDateString("pt-BR")}`,
							style: { bold: true, fontSize: 9, alignment: "left",}
						},
						{
							text: `Data final: ${new Date(dataF.split("-")).toLocaleDateString("pt-BR")}`,		 	
							style: { bold: true, fontSize: 9, alignment: "right",}
						},]
					]
				},
				layout: "noBorders"
			},
			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				},
				margin: [0, 3, 0, 3],
			},
			{ text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape", absolutePosition: {x: 10, y:820}},
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarRelatorioAniversarios = async (context, { empresa, clientes, mes }) => {

	const tabelaProdutos = [];

	let tableWidths = null;
	tabelaProdutos.push([
		{ text: "Cód.", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Nome", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Aniversário", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Telefone", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Celular", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Cidade", style: "headerTabela", fillColor: "#c1c1c1" },
	]);
	clientes.forEach(item => {
		tabelaProdutos.push([
			{ text: item.id, style: "itemTabela" },
			{ text: item.nome, style: "itemTabela" },
			{ text: item.dataNascimento.split("-").reverse().join("/"), style: "itemTabela" },
			{ text: item.fone, style: "itemTabela" },
			{ text: item.celular, style: "itemTabela" },
			{ text: item.cidade? item.cidade.nomecidade : "", style: "itemTabela" },
		]);
	});
	tableWidths = ["auto", "auto", "auto", "auto", "auto", "auto"];

	let pageSize = "A4";

	const documento = pdfMake.createPdf({
		info: {
			title: "Aniversários",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [20, 5, 20, 5],

		pageOrientation: "portrait",

		content: [
			...gerarHeaderCupom(empresa),

			{ text: "Relatorios de Aniversariantes do mês: "+mes, style: "header" },
			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				},
				margin: [0, 3, 0, 3],
			},
			{ text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape", absolutePosition: {x: 10, y:820}},
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarRelatorioVendasPorFornecedorPDF = async (context, { empresa, vendas, totais, datas }) => {
	totais;
	const tabelaProdutos = [];

	let tableWidths = null;
	tabelaProdutos.push([
		{ text: "Venda", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Fornecedor", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Produto", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "$ Custo", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Emissão", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "$ Bruto", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "$ Desconto", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "$ Troca", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "$ Valor", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Pagamento", style: "headerTabela", fillColor: "#c1c1c1" },
	]);
	vendas.forEach(item => {
		tabelaProdutos.push([
			{ text: item.venda, style: "itemTabela" },
			{ text: (item.codigo?item.codigo+"-":"")+item.fornecedor, style: "itemTabela" },
			{ text: item.produ+"-"+item.produto, style: "itemTabela" },
			{ text: item.quantidade, style: "itemTabela" },
			{ text: "R$ " + Number(item.custo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: item.emissao.split("-").reverse().join("/"), style: "itemTabela" },
			{ text: "R$ " + Number(item.bruto || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: "R$ " + Number(item.desconto || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: "R$ " + Number(item.troca || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: "R$ " + Number(item.valor || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: item.pagamento, style: "itemTabela" },
		]);
	});
	tabelaProdutos.push([
		{ text: "TOTAL: ", style: "headerTabela", colSpan: 2, alignment: "right" },
		{},
		{},
		{ text: totais.quantidade, style: "headerTabela" },
		{},
		{},
		{ text: "R$: "+Number(totais.bruto).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
		{ text: "R$: "+Number(totais.desconto).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
		{ text: "R$: "+Number(totais.troca).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
		{ text: "R$: "+Number(totais.valor).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
		{},
	]);
	tableWidths = ["auto", 95, 95, "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"];

	let pageSize = "A4";

	const documento = pdfMake.createPdf({
		info: {
			title: "Aniversários",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [20, 20, 20, 20],

		pageOrientation: "portrait",

		footer: {
			text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape"
		},
		content: [
			...gerarHeaderCupom(empresa),

			{ text: "Relatorios de Venda por Fornecedor entre "+datas.dataI.split("-").reverse().join("/")+" e "+datas.dataF.split("-").reverse().join("/"), style: "header" },
			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				},
				margin: [0, 3, 0, 3],
			},
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarRelatorioContasPagar = async (context, { empresa, itens, tipo, dataI, dataF, filtro }) => {
	const tabelaProdutos = [];
	let tableWidths = null;
	let totalizador = {
		titulo:0,
		parcela:0,
		pago:0,
		restante:0,
	};
	tabelaProdutos.push([
		{ text: "Emp", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Número", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Parcela", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Pessoa", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Valor Título", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Emissão", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Vecimento", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "$ Parcela", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "$ Pago", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "$ Saldo", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Baixa", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Observação", style: "headerTabela", fillColor: "#c1c1c1" },

	]);
	itens.forEach(item => {
		totalizador.titulo += item.valorTitulo;
		totalizador.parcela += item.valorParcela;
		totalizador.pago += item.valorPago;
		totalizador.restante += item.restante || item.valorParcela;
		tabelaProdutos.push([
			{ text: item.cdempresa, style: "itemTabela" },
			{ text: item.cdmovi, style: "itemTabela" },
			{ text: item.cdlan, style: "itemTabela" },
			{ text: item.nome, style: {alignment: "left", fontSize: 8} },
			{ text: Number(item.valorTitulo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: item.dataEmissao, style: "itemTabela" },
			{ text: item.dataVenc, style: "itemTabela" },
			{ text: Number(item.valorParcela || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: Number(item.valorPago || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: Number(item.restante || item.valorParcela || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: item.dataBaixa, style: "itemTabela" },
			{ text: item.observacaoCompra, style: {alignment: "left", fontSize: 8} },

		]);
	});
	tabelaProdutos.push([
		{ text: "TOTAL: ", style: "headerTabela", alignment: "right" },
		{},
		{},
		{},
		{},//ocupa lugar do totalizador \/
		// { text: (totalizador.titulo).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela" },
		{},
		{},
		{ text: Number(totalizador.parcela).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
		{ text: Number(totalizador.pago).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
		{ text: Number(totalizador.restante).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "headerTabela", alignment: "right" },
		{},
		{},
	]);
	tableWidths = ["auto", "auto", "auto", 150, "auto", "auto", "auto", "auto", "auto", "auto", "auto", "*"];

	let pageSize = "A4";

	const documento = pdfMake.createPdf({
		info: {
			title: "Contas a Pagar",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [20, 20, 20, 20],

		pageOrientation: "landscape",

		footer: {
			text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape"
		},
		content: [
			...gerarHeaderCupom(empresa),

			tipo == "dataVenc" ? 
				{ text: "Relatorios de Contas a Pagar com vencimento entre "+dataI.split("-").reverse().join("/")+" e "+dataF.split("-").reverse().join("/"), style: "header" }
				: null,
			tipo == "dataBaixa" ? 
				{ text: "Relatorios de Contas a Pagar com baixa entre "+dataI.split("-").reverse().join("/")+" e "+dataF.split("-").reverse().join("/"), style: "header" }
				: null,
			tipo == "dataEmissao" ? 
				{ text: "Relatorios de Contas a Pagar com emissão entre "+dataI.split("-").reverse().join("/")+" e "+dataF.split("-").reverse().join("/"), style: "header" }
				: null,
			tipo == "fornecedor" ? 
				{ text: "Relatorios de Contas a Pagar do fornecedor "+filtro, style: "header" }
				: null,
			tipo == "cdPedido" ? 
				{ text: "Relatorios de Contas a Pagar do pedido "+filtro, style: "header" }
				: null,
			tipo == "empresa" ? 
				{ text: "Relatorios de Contas a Pagar "+(filtro == "T"?"de todas empresas":"da empresa "+filtro), style: "header" }
				: null,
			tipo == "cdNota" ? 
				{ text: "Relatorios de Contas a Pagar da compra "+filtro, style: "header" }
				: null,
			tipo == "valor" ? 
				{ text: "Relatorios de Contas a Pagar de valor "+filtro, style: "header" }
				: null,
			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				},
				margin: [0, 3, 0, 3],
			},
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarRelatorioFluxoCaixa = async (context, { empresa, itens, filtro }) => {
	const tabelaProdutos = [];
	tabelaProdutos.push([
		{ text: "Nível", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Valor", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Grupo", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Total", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Saldo", style: "headerTabela", fillColor: "#c1c1c1" },
	]);
	itens.forEach(item => {
		tabelaProdutos.push([
			{ text: item.nivel, style: {alignment: "left", fontSize: 8}, margin: [item.nivel.split(".").length*10,0,0,0] },
			{ text: Number(item.valor || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: Number(item.grupo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: Number(item.total || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: Number(item.saldo || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
		]);
	});
	const tableWidths = ["*", "auto", "auto", "auto", "auto"];

	let pageSize = "A4";

	const documento = pdfMake.createPdf({
		info: {
			title: "Contas a Pagar",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [20, 20, 20, 20],

		pageOrientation: "portrait",

		footer: {
			text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape"
		},
		content: [
			...gerarHeaderCupom(empresa),

			{ text: "Relatorios de Fluxo de Caixa da empresa "+filtro.empresa+" de: "+filtro.dataI.split("-").reverse().join("/")+" até: "+filtro.dataF.split("-").reverse().join("/"), style: "header" },

			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				},
				margin: [0, 3, 0, 3],
			},
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarRelatorioCrmPDF = async (context, { empresa, itens, filtro }) => {
	const tabelaProdutos = [];
	tabelaProdutos.push([
		{ text: "Cód.", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Nome", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Celular", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Telefone", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Nascimento", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Primeira", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Ultima", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Qtd.", style: "headerTabela", fillColor: "#c1c1c1"},
		{ text: "Valor", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Endereco", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Bairro", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Cidade", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "UF", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
	]);
	itens.forEach(item => {
		tabelaProdutos.push([
			{ text: item.cdcliente, style: "itemTabela" },
			{ text: item.nome, style: "itemTabela" },
			{ text: item.celular, style: "itemTabela" },
			{ text: item.fone, style: "itemTabela" },
			{ text: item.nascimento?item.nascimento.split("-").reverse().join("/"):"", style: "itemTabela" },
			{ text: item.primeira?item.primeira.split("-").reverse().join("/"):"", style: "itemTabela" },
			{ text: item.ultima?item.ultima.split("-").reverse().join("/"):"", style: "itemTabela" },
			{ text: item.qtdade, style: "itemTabela" },
			{ text: Number(item.valor || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: item.endereco, style: "itemTabela" },
			{ text: item.bairro, style: "itemTabela" },
			{ text: item.nomecidade, style: "itemTabela" },
			{ text: item.ufcidade, style: "itemTabela" },
		]);
	});
	const tableWidths = ["auto", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"];

	let pageSize = "A4";

	const documento = pdfMake.createPdf({
		info: {
			title: "Contas a Pagar",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [20, 20, 20, 20],

		pageOrientation: "landscape",

		footer: {
			text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape"
		},
		content: [
			...gerarHeaderCupom(empresa),

			{ text: filtro.tipo === "D"?"Relatorios CRM dos ultimos "+(filtro.dias/30)+" mêses" :"Relatorios CRM de: "+filtro.dataI.split("-").reverse().join("/")+" até: "+filtro.dataF.split("-").reverse().join("/"), style: "header" },

			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				},
				margin: [0, 3, 0, 3],
			},
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarRelatorioDetalhesFluxoCaixa = async (context, { empresa, itens, filtro }) => {
	const tabelaProdutos = [];
	tabelaProdutos.push([
		{ text: "Favorecido", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Nível", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Valor", style: "headerTabela", fillColor: "#c1c1c1" },
		{ text: "Data Baixa", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
		{ text: "Observação", style: "headerTabela", fillColor: "#c1c1c1", alignment: "left" },
	]);
	itens.forEach(item => {
		tabelaProdutos.push([
			{ text: item.favorecido, style: {alignment: "left", fontSize: 8} },
			{ text: item.nivel, style: {alignment: "left", fontSize: 8} },
			{ text: Number(item.valor || 0).toLocaleString("pt-BR", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: "itemTabela" },
			{ text: item.databaixa?item.databaixa.split("-").reverse().join("/"):"", style: "itemTabela" },
			{ text: item.observacao, style: {alignment: "left", fontSize: 8} },
		]);
	});
	const tableWidths = ["auto", "auto", "auto", "auto", "*"];

	let pageSize = "A4";

	const documento = pdfMake.createPdf({
		info: {
			title: "Contas a Pagar",
			author: "BDS Soluções em Sistemas"
		},

		pageSize,

		pageMargins: [20, 20, 20, 20],

		pageOrientation: "portrait",

		footer: {
			text: "BDS Soluções em Sistemas (44) 3232-6083 / (44) 99972-8673", style: "rodape"
		},
		content: [
			...gerarHeaderCupom(empresa),

			{ text: "Relatorios detalhado de Fluxo de Caixa da empresa "+filtro.empresa+" de: "+filtro.dataI.split("-").reverse().join("/")+" até: "+filtro.dataF.split("-").reverse().join("/")+" do nível "+itens[0].nivel, style: "header" },

			{
				table: {
					headerRows: 1,
					body: tabelaProdutos,
					widths: tableWidths,
				},
				layout: {
					paddingBottom: function () {
						return 1;
					},
					paddingTop: function () {
						return 1;
					},
					paddingLeft: function () {
						return 3;
					},
					paddingRight: function () {
						return 3;
					},
					vLineWidth: function () {
						return 0;
					},
					hLineColor: function (i) {
						return i === 1 ? "black" : "#aaa";
					}
				},
				margin: [0, 3, 0, 3],
			},
		],

		styles
	});

	documento.getBlob((pdfBlob) => {
		if (!pdfBlob) {
			return;
		}
		const url = window.URL.createObjectURL(pdfBlob);
		context.state.base64 = url;
		context.state.carregando = false;
	});
};

export const gerarEtiquetaVitrineValor = (context, payload) => {
	const margemColuna = [3, 1, 3, 0];
	let tableCodigos = [];

	for (const [index, etiqueta] of payload.etiquetas.entries()) {
		tableCodigos.push({
			columns:[
				[
					{ text: etiqueta.descricao.toUpperCase(), alignment: "center", style: {fontSize: 25},margin:[0,0,0,5] },
					{ text: `A PRAZO ${(etiqueta.prazo || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`, alignment: "left", style: {fontSize: 19}, },
					etiqueta.qtdParcela > 1?{ text: `OU ${etiqueta.qtdParcela} X de ${(etiqueta.parcela || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`, alignment: "left", style: {fontSize: 19}, }:null,
					{ text: `A VISTA ${(etiqueta.vista || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`, alignment: "left", style: {fontSize: 19}, },
					etiqueta.desconto && etiqueta.desconto>0?{ text: `Desc. de ${etiqueta.desconto}% a vista`, alignment: "left", style: {fontSize: 10,margin:[0,10,0,0]}, }:null,
				]
			],
			pageBreak: index>0?"before":"",
			margin: margemColuna,
		});
	}

	try {
		context.state.carregando = true;
		let pageSize = payload.pageSize ? payload.pageSize : {
			width: 263,
			height: 120
		};
		const documento = pdfMake.createPdf({
			info: {
				title: "Etiqueta de produtos",
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: [10, 3, 10, 0],

			pageOrientation: "landscape",

			content: tableCodigos,
			styles,
			defaultStyle: {
				fontSize: 6,
				font: "Roboto"
			}
		});

		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			const a = document.createElement("a");
			a.href = url;
			a.target = "_etiquetas";
			a.download = `EtiquetasVitrine-${new Date().getTime()}.pdf`;
			document.body.appendChild(a);
			a.click();
			a.remove();
			context.state.carregando = false;
		});
	} catch (error) {
		console.log(error);
		context.state.carregando = false;
		return context.commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
	}
};

export const gerarEtiquetaVitrinePromocao = (context, payload) => {
	const margemColuna = [3, 1, 3, 0];
	let tableCodigos = [];

	for (const [index, etiqueta] of payload.etiquetas.entries()) {
		tableCodigos.push({
			columns:[
				[
					{ text: etiqueta.descricao.toUpperCase(), alignment: "center", style: {fontSize: 25},margin:[0,0,0,5] },
					{ text:[{text: "De      ", alignment: "left", style: {fontSize: 19}}, {text: `${(Number(etiqueta.de.replace(",",".")) || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`, alignment: "center", style: {fontSize: 19},},{text:""}]},
					{ text:[{text: "Por     ", alignment: "left", style: {fontSize: 19}}, {text: `${(Number(etiqueta.por.replace(",",".")) || 0).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}`, alignment: "center", style: {fontSize: 19},},{text: " A Vista ", alignment: "left", style: {fontSize: 19}},]},
					etiqueta.desconto && etiqueta.desconto>0?{ text: `Desc. de ${etiqueta.desconto}% a vista`, alignment: "left", style: {fontSize: 10, margin:[0,5,0,0]}, }:null,
				]
			],
			pageBreak: index>0?"before":"",
			margin: margemColuna,
		});
	}

	try {
		context.state.carregando = true;
		let pageSize = payload.pageSize ? payload.pageSize : {
			width: 263,
			height: 120
		};
		const documento = pdfMake.createPdf({
			info: {
				title: "Etiqueta de produtos",
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: [10, 3, 10, 0],

			pageOrientation: "landscape",

			content: tableCodigos,
			styles,
			defaultStyle: {
				fontSize: 6,
				font: "Roboto"
			}
		});

		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			const a = document.createElement("a");
			a.href = url;
			a.target = "_etiquetas";
			a.download = `EtiquetasVitrineDePor-${new Date().getTime()}.pdf`;
			document.body.appendChild(a);
			a.click();
			a.remove();
			context.state.carregando = false;
		});
	} catch (error) {
		console.log(error);
		context.state.carregando = false;
		return context.commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
	}
};

export const geraPromissorias = (context, payload) => {
	const meses = [
		"Janeiro", 
		"Fevereiro", 
		"Março", 
		"Abril", 
		"Maio", 
		"Junho", 
		"Julho", 
		"Agosto", 
		"Setembro", 
		"Outubro", 
		"Novembro", 
		"Dezembro"
	];
	const {
		parcelas,
		data,
		nVenda,
		cliente,
		qtdParcelas,
	} = payload;
	const dataMes = meses[Number(data.mes)-1];
	let promissoria = [];
	const empresa = {
		nome: context.state.Empresa.nome,
		cnpj: context.state.Empresa.cnpj,
		cidade: context.state.Empresa.nomeCidade + " - " + context.state.Empresa.ufCidade
	};
	parcelas.forEach(parcela => {
		const vencimentoMes = meses[Number(parcela.vencimento.mes)-1];
		promissoria.push({
			stack: [	
				{ table: {
					widths:"*",
					body: [
						[{
							border: [true, true, true, true],
							text: "Vencimento: " + parcela.vencimento.dia + " de " + vencimentoMes + " de " + parcela.vencimento.ano, 
							alignment: "left", 
							style: {fontSize: 9},
						}]
					]
				}},
				{ table: {
					widths:["*","*"],
					body: [
						[{
							border: [false, false, false, false],
							fillColor: "#D3D3D3",
							text: "Nota Promissória nº " + nVenda.toString().padStart(3,"0") + " - " + parcela.numero + "/" + qtdParcelas, 
							alignment: "left", 
							style: {fontSize: 13, bold: true},
						},
						{
							border: [false, false, false, false],
							fillColor: "#D3D3D3",
							text: "valor: " + parcela.valorParcela.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}), 
							alignment: "right", 
							style: {fontSize: 13, bold: true},
						}]
					]
				}},
				{ table: {
					widths:"*",
					body: [
						[
							{
								border: [true, true, true, false],
								text: "A (os) " + data.dia + " do mês de " + dataMes + " do ano " + data.ano, 
								alignment: "left", 
								style: {fontSize: 9},
							},
						],
						[
							{
								border: [true, false, true, false],
								text: [
									{ 
										text: "Pagarei por esta nota promissória à ",
										alignment: "left", 									
										style: {fontSize: 9},
									},
									{
										text: empresa.nome,
										alignment: "left", 									
										style: {fontSize: 9, bold:true},
									},
									{
										text:  ". CNPJ - " + empresa.cnpj + " ou a sua ordem a quantia de " + extenso(parcela.valorParcela, {mode: "currency"}) + " em moeda corrente deste país, pagável em " + empresa.cidade,
										alignment: "left", 									
										style: {fontSize: 9},
									},
								],	 
								alignment: "left", 
								style: {fontSize: 9},
							},
						],
						[
							{
								border: [true, false, true, false],
								text: "Emitente " + cliente.nome, 
								alignment: "left", 
								style: {fontSize: 9},
							},
						],
						[
							{
								border: [true, false, true, false],
								text: "CPF " + cliente.cpf, 
								alignment: "left", 
								style: {fontSize: 9},
							},
						],
						[
							{
								border: [true, false, true, false],
								text: "Endereço " + cliente.endereco, 
								alignment: "left", 
								style: {fontSize: 9},
							},
						],
						[
							{
								border: [true, false, true, false],
								text: "Assinatura______________________________________________Data______________________", 
								alignment: "left", 
								style: {fontSize: 9},
							},
						],
						[{
							border: [true, false, true, true],
							fillColor: "#D3D3D3",
							text: "Não havendo quitação total na data do vencimento desta nota promissória, juros de moras serão aplicados conforme o disposto no artigo 397 do Código Civil. Após 40 dias sem quitação, esta nota será automaticamente protestada.", 
							alignment: "left", 
							style: {fontSize: 9, bold: true}
						}]
					],
				}},
				{ text:"\n", alignment: "left", style: {fontSize: 9}},
				{ text:"\n", alignment: "left", style: {fontSize: 9}},
			],
			unbreakable: true
		});
	});

	try {
		let pageSize = "A4";
		context.state.carregando = true;
		const documento = pdfMake.createPdf({
			info: {
				title: "Promissórias",
				author: "BDS Soluções em Sistemas"
			},

			pageSize,

			pageMargins: [80, 20, 80, 20],

			pageOrientation: "portrait",

			content: promissoria,

			styles
		});

		documento.getBlob((pdfBlob) => {
			if (!pdfBlob) {
				return;
			}
			const url = window.URL.createObjectURL(pdfBlob);
			context.state.base64 = url;
			context.state.carregando = false;
		});

		context.state.carregando = false;
	} catch (error) {
		console.log(error);
		context.state.carregando = false;
		return context.commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
	}
};