<template>
	<nav class="navBar" @mouseleave="alterarConfiguracoes('navBar')">
		<div class="container">
			<i v-if="!mostrarMenu || !usuario" @click="abrirMenu" class="fas fa-bars js-iconeMenu"></i>
			<i v-else @click="abrirMenu" class="fas fa-times js-iconeMenu"></i>
			<div class="identificar-empresa">
				<i class="fas fa-chevron-left icones visivel mx-5 text-black" v-if="!mostrarVoltar" @click="handleControlePagina"></i>
				<div @click="resetarLocalStorage">
					<router-link class="logo mx-5" to="/" @click.prevent="">
						<img id="navImg"
							:src="Empresa ? Empresa.logo ? `data:image/png;base64,${Empresa.logo}` : require('../assets/logo-transp-tidoc.png') : require('../assets/logo-transp-tidoc.png')"
							alt="LogoBDS">
					</router-link>
				</div>
				
				<div class="container-dropdown desktop" v-if="usuario">
					<div 
						class="container-dropdown-item"
						v-for="(rota, index) in rotasControle"
						:key="index"
						@mouseover="handleFocusDropdown(rota.id)"
						@mouseout="handleBlurDropdown(rota.id)"
					>
						<div 
							class="dropdown-title"
							
						>
							<span> {{ rota.name }} </span>
							<i class="fas fa-chevron-down"></i>
						</div>
						<div
							class="dropdown-list"
							:class="{'dropdown-list-active': currentOpenedDropdown === rota.id}"
							:id="rota.id"
						>
							<li
								v-for="rotaFilho in rota.subItems"
								:key="rotaFilho.name"
								@mouseover="handleFocusChildrenDropDown(rotaFilho.idActive, rota.id)"
								@mouseleave="handleBlurChildrenDropDown(rotaFilho.idActive)"
							>
								
								<div class="rotafilho-title" @click="handleChangeRouter({path: rotaFilho.path, query: rotaFilho.query})">
									<span>{{ rotaFilho.name }}</span>
									<i
										class="fas fa-chevron-down"
										v-if="rotaFilho.subItems && rotaFilho.subItems.length > 0"
									/>
									<i class="far fa-bookmark" v-else @click.prevent="handleFavoriteRoute" />
								</div>
								<!-- <router-link
									:to="{name: rotaFilho.path, query: rotaFilho.query || {}}"
									style="text-decoration: none; color: inherit"
								></router-link> -->
								<div 
									class="rotafilho-dropdown-list" 
									:class="{'dropdown-list-children-active': currentOpenedChildDropdown === rotaFilho.idActive}"
									:id="rotaFilho.idActive"
								>
									<li 
										v-for="rotaNeto in rotaFilho.subItems"
										:key="rotaNeto.name"
										@click="handleChangeRouter({path: rotaNeto.path, query: rotaNeto.query})"
									>
									
										{{ rotaNeto.name }}
									</li>
								</div>
							</li>
						</div>
					</div>
				</div>
				<div class="container-empresa" v-if="Empresa">
					<div class="container-empresa-caixa">
						<span class="desktop">
							<h4>{{Empresa.id + " - " + Empresa.nome}}</h4>
							<i 
								class="fas fa-sign-out-alt visivel mx-5 text-black"
								@click="mudarEmpresa"
								v-if="usuario && !usuario.empresa"
								style="font-size: 12px;"
							/>
						</span>
						<span class="tablet">
							<h4>{{Empresa.id + " - " + Empresa.nome}}</h4>
							<i 
								class="fas fa-sign-out-alt visivel mx-5 text-black"
								@click="mudarEmpresa"
								v-if="usuario && !usuario.empresa"
								style="font-size: 12px;"
							/>
						</span>
						<span class="smartphone">
							<h4>{{Empresa.id + " - " + Empresa.nome.substring(0, 10) + "..."}}</h4>
							<i 
								class="fas fa-sign-out-alt visivel mx-5 text-black"
								@click="mudarEmpresa"
								v-if="usuario && !usuario.empresa"
								style="font-size: 12px;"
							/>
						</span>
						<span>
							<span>{{ agora }}</span>
							<i
								class="fas fa-sync visivel pointer text-black"
								v-if="Empresa"
								@click="$store.dispatch('buscarStateInicial', { atualizar: true })" 
							/>

						</span>
						<span v-if="$store.state.Caixa">
							<span>
								{{ $store.state.Caixa.caixa.cdcaixa }} - {{ $store.state.Caixa.caixa.identificador }}
							</span>
							<i
								class="fas fa-sign-out-alt visivel mx-5 pointer"
								style="font-size: 8pt; align-self: flex-end; padding-bottom: 4px;"
								@click="$router.replace({ name: 'SelecionaCaixa' })"
								v-if="(usuario.supervisor || usuario.caixa) && $store.state.Caixas"
							/>
						</span>
					</div>
				</div>
			</div>

			<div v-if="usuario" id="usuario-controle" class="mx-10"
				@click="alterarConfiguracoes(timerConfiguracao == null, 'usuario')">
				<i class="fas fa-user icones visivel"></i>
				<span class="usuario-nome">{{ `${usuario.nome}`.split(" ")[0] }}</span>
			</div>
			<ul v-if="usuario" class="links">
				<i class="fas fa-cog icones visivel mx-10" @click="alterarConfiguracoes(true, 'rotas')"></i>
				<i class="fas fa-sign-out-alt icones visivel mx-10" @click="logOut"></i>
			</ul>

			<div class="configuracoes" v-if="usuario" @mouseleave="alterarConfiguracoes(false)"
				@mouseenter="alterarConfiguracoes(true, mostrarConfiguracoes)">
				<ul @click="alterarConfiguracoes(false)">
					<li @click="alterarConfiguracoes(false)" class="configuracoes-item"
						v-for="(link, index) in links.filter(link => link.nome != paginaAtual)" :key="index">
						<router-link :to="{name: link.nome, query: link.query || {}}">{{ link.texto }}</router-link>
					</li>
				</ul>
			</div>

		</div>
		


		<ul v-if="usuario" class="vlinks">
			<div class="accordion">
				<div v-for="(item, index) in rotasControle" :key="index" class="accordion-item">
					<button class="accordion-header" @click="toggleAccordion(index)">
						{{ item.name }}
					</button>
					<div class="accordion-content" :class="{ 'active': activeIndex === index }">
						<ul>
							<li v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
								<span @click="handleChangeRouter({path: subItem.path, query: subItem.query})">{{ subItem.name }}</span>
								<ul v-if="subItem.subItems">
									<li
										v-for="(subSubItem, subSubIndex) in subItem.subItems"
										:key="subSubIndex"
										@click="handleChangeRouter({path: subSubItem.path, query: subSubItem.query})"
									>
										{{ subSubItem.name }}
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- <li
				@click="abrirMenu"
				class="configuracoes-item"
				v-for="(link, index) in links.filter(link => link.nome != paginaAtual)"
				:key="index"
			>
				<router-link :to="{name: link.nome, query: link.query || {}}">{{ link.texto }}</router-link>
			</li>
			<li @click="abrirMenu" class="configuracoes-item">
				<a @click="logOut">Sair</a>
			</li>
			<span class="versao-vlinks">v.{{ $store.state.versao }}</span> -->
		</ul>
		<span class="versao">v.{{ $store.state.versao }}</span>
	</nav>
</template>
<script>
import { localStorageReset } from "../service/localStorage";


export default {
	name: "NavBar",

	props: ["texto"],

	computed: {
		paginaAtual() {
			return this.$route.name;
		},

		usuario() {
			return this.$store.state.Usuario;
		},

		Empresa() {
			return this.$store.state.Empresa;
		},

		mostrarVoltar() {
			return this.rotasVoltar.includes(this.$route.name);
		},
		links() {
			const links = [];
			if (this.usuario) {
				if (this.usuario.caixa) {
					const rotas = this.rotasCaixa.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true);
					links.push(...rotas);
				}
				if(this.usuario && this.usuario?.usuarioPrivilegiado) {
					links.push({
						...this.rotasComum[0],
						query: {
							menu: "Dashboard"
						}
					});
					links.push({
						...this.rotasComum[1],
					});
					links.push({
						...this.rotasComum[2],
					});
				} else {
					links.push(...this.rotasComum);
				}
				if (this.usuario.supervisor) {
					if (!this.usuario.caixa) {
						links.push(...this.rotasCaixa.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true));
					}
					const rotas = this.rotasSupervisor.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true);
					links.push(...rotas);
				}
				if (this.usuario.usuarioPrivilegiado) {
					if (!this.usuario.caixa) {
						links.push(...this.rotasCaixa.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true));
					}
					if (!this.usuario.supervisor) {
						links.push(...this.rotasSupervisor.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true));
					}
					const rotas = this.rotasUsuarioPrivilegiado.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true);
					links.push(...rotas);
				}
				const rotas = this.rotasVendedor.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true);
				links.push(...rotas);
			}
			return links;
		}
	},

	data() {
		return {
			mostrarMenu: false,
			mostrarConfiguracoes: false,
			rotasControle: [
				{
					name: "Cadastros",
					id: "cadastro-list",
					
					subItems: [
						{
							name: "Clientes",
							condicao: "",
							path: "Gerenciamento",
							query: {
								menu: "Clientes",
							}
						},
						{
							name: "Vendedores",
							condicao: "",
							path: "Gerenciamento",
							query: {
								menu: "Vendedores",
							}
						},
						{
							name: "Produtos",
							condicao: "",
							path: "Gerenciamento",
							query: {
								menu: "Produtos",
							}
						},
						{
							name: "Classificação",
							condicao: "",
							path: "Gerenciamento",
							query: {
								menu: "CadastroClassificacao",
							}
						},
						{
							name: "Grupos",
							condicao: "",
							path: "Gerenciamento",
							query: {
								menu: "Grupos",
							}
						},
						{
							name: "Caixas",
							condicao: "",
							path: "Gerenciamento",
							query: {
								menu: "Caixas",
							}
						},
						{
							name: "Forma pagamento",
							condicao: "",
							path: "Gerenciamento",
							query: {
								menu: "FormasPagamento",
							}
						},
						{
							name: "Bandeiras",
							condicao: "",
							path: "Gerenciamento",
							query: {
								menu: "Bandeiras",
							}
						},
						{
							name: "Conciliadora",
							condicao: "",
							path: "Gerenciamento",
							query: {
								menu: "Conciliadora",
							}
						},
						{
							name: "Relatórios",
							path: "",
							condicao: "",
							idActive: "relatorios-cadastro-list",
							subItems: [
								{
									name: "Aniversários",
									idActive: "aniversarios-list",
									path: "Relatorios",
									query: {
										menu: "Aniversarios"
									}
								},
							]
						},
					],
				},
				{
					name: "Estoque",
					id: "estoque-list",
					subItems: [
						{
							name: "Previsão Compra",
							path: "Gerenciamento",
							query: {
								menu: "PrevisaoCompra"
							},
							condicao: "",
						},
						{
							name: "Compras",
							path: "Gerenciamento",
							query: {
								menu: "Compras"
							},
							condicao: "",
						},
						{
							name: "Transferir Estoque",
							path: "Gerenciamento",
							query: {
								menu: "TransferirEstoque"
							},
							condicao: "",
						},
						{
							name: "Gerenciar Lotes",
							path: "",
							condicao: "",
						},
						{
							name: "Relatórios",
							path: "",
							condicao: "",
							idActive: "relatorio-estoque-list",
							subItems: [
								{
									name: "Consultar NCM",
									path: "Gerenciamento",
									query: {
										menu: "ConsultarNcm"
									},
									condicao: "",
								},
								{
									name: "Posição Estoque",
									path: "Relatorios",
									query: {
										menu: "PosicaoEstoque"
									},
									condicao: "",
								},
								{
									name: "Saldo de Estoque",
									path: "Relatorios",
									query: {
										menu: "Estoque"
									},
									condicao: "",
								},
								{
									name: "Desempenho de Produtos",
									path: "Relatorios",
									query: {
										menu: "LucroProduto"
									},
									condicao: "",
								},
								{
									name: "Entrada de Produtos",
									path: "Relatorios",
									query: {
										menu: "Entrada"
									},
									condicao: "",
								},
								{
									name: "Histórico de Produtos",
									path: "Relatorios",
									query: {
										menu: "HistoricoProduto"
									},
									condicao: "",
								},
								{
									name: "Lotes por Vencimento",
									path: "Relatorios",
									query: {
										menu: "LotesVencimento"
									},
									condicao: "",
								},
							]
						},
					]
				},
				{
					name: "Vendas",
					condicao: "",
					id: "vendas-list",
					subItems: [
						{
							name: "Vendas em aberto",
							path: "Principal",
							condicao: ""
						},
						{
							name: "Iniciar venda",
							path: "Lancamento",
							query: {
								tipoVenda: "S",
							},
							condicao: ""
						},
						{
							name: "Iniciar condicional",
							path: "Lancamento",
							query: {
								tipoVenda: "C",
							},
							condicao: ""
						},
						{
							name: "Iniciar troca",
							path: "Principal",
							query: {
								menu: "IniciarTroca",
								params: { code: "KeyT", altKey: true }
							},
							condicao: ""
						},
						{
							name: "Nota fiscal",
							path: "Gerenciamento",
							query: {
								menu: "ConsultarNFE",
							},
							condicao: ""
						},
						{
							name: "Sangria",
							path: "Principal",
							query: {
								menu: "Sangria",
								tipo: "Sangria",
							},
							condicao: ""
						},
						{
							name: "Recarregar caixa",
							path: "Principal",
							query: {
								menu: "Recarga",
								tipo: "Recarga",
							},
							condicao: ""
						},
						{
							name: "Promoção",
							path: "Gerenciamento",
							query: {
								menu: "Promocao"
							},
							condicao: ""
						},
						{
							name: "Consorcio",
							path: "Gerenciamento",
							query: {
								menu: "Consorcio"
							},
							condicao: ""
						},
						{
							name: "Crédito Cliente",
							path: "Gerenciamento",
							query: {
								menu: "Credito"
							},
							condicao: ""
						},
						{
							name: "Consultar Pix",
							path: "Gerenciamento",
							query: {
								menu: "ConsultarPix"
							},
							condicao: ""
						},
						{
							name: "Gerenciar vendas",
							path: "Vendas",
							condicao: ""
						},
						{
							name: "Relatórios",
							path: "",
							condicao: "",
							idActive: "relatorio-vendas-list",
							subItems: [
								{
									name: "Dashboard",
									path: "Relatorios",
									query: {
										menu: "Dashboard"
									},
									condicao: ""
								},
								{
									name: "Comissão",
									path: "Relatorios",
									query: {
										menu: "Comissao"
									},
									condicao: ""
								},
								{
									name: "Caixas",
									path: "Relatorios",
									query: {
										menu: "Caixas"
									},
									condicao: ""
								},
								{
									name: "Faturamento DiasXHoras",
									path: "Relatorios",
									query: {
										menu: "FaturamentoDiasXHoras"
									},
									condicao: ""
								},
								{
									name: "Histórico de vendas",
									path: "Relatorios",
									query: {
										menu: "Fornecedor"
									},
									condicao: ""
								},
							]
						},
					]
				},
				{
					name: "Financeiro",
					id: "financeiro-list",
					condicao: "",
					subItems: [
						{
							name: "Contas",
							path: "Gerenciamento",
							query: {
								menu: "Contas"
							},
							condicao: ""
						},
						{
							name: "Contas a Receber",
							path: "Principal",
							query: {
								menu: "ContasReceber",
								params: { code: "KeyR", altKey: true }
							},
							
							condicao: ""
						},
						{
							name: "Contas a Pagar",
							path: "Financeiro",
							query: {
								menu: "ContasPagar"
							},
							condicao: ""
						},
						{
							name: "Despesas",	
							path: "Financeiro",
							query: {
								menu: "Despesas"
							},
							condicao: ""
						},
						{
							name: "Receitas",
							path: "Financeiro",
							query: {
								menu: "Receitas"
							},
							condicao: ""
						},
						{
							name: "Plano de Contas",
							path: "Financeiro",
							query: {
								menu: "PlanoContas"
							},
							condicao: ""
						},
						{
							name: "Centro de Custo",
							path: "Financeiro",
							query: {
								menu: "CentroCustos"
							},
							condicao: ""
						},
						{
							name: "Extrato",
							path: "Financeiro",
							query: {
								menu: "Extrato"
							},
							condicao: ""
						},
						{
							name: "Conciliar Banco",
							path: "Financeiro",
							query: {
								menu: "Importacao"
							},
							condicao: ""
						},
						{
							name: "Relatórios",
							path: "",
							condicao: "",
							idActive: "relatorio-financeiro-list",
							subItems: [
								{
									name: "Agenda",
									path: "Financeiro",
									query: {
										menu: "AgendaContas"
									},
									condicao: ""
								},
								{
									name: "Fluxo de caixa",
									path: "Financeiro",
									query: {
										menu: "FluxoCaixa"
									},
									condicao: ""
								},
							]
						},
					]
				},

			],

			rotasVoltar: [
				"Principal",
				"Lancamento",
				"TrocarSenha",
				"Autenticacao",
				"Impressao",
			],
			rotasUsuarioPrivilegiado: [
				{
					nome: "Acessos",
					texto: "Acessos",
					condicao: "incluiUsuario"
				},
				{
					nome:"Logs",
					texto: "Logs",
					condicao: "incluiUsuario"
				}
			],
			rotasSupervisor: [
				{
					nome: "Empresas",
					texto: "Empresas",
					condicao: "incluiEmpresa"
				},
			],
			rotasCaixa: [				
				{
					nome: "Gerenciamento",
					query: {
						menu: "Clientes"
					},
					texto: "Gerenciamento",
				},
			],
			rotasComum: [				
				{
					nome: "Relatorios",
					query: {
						menu: "Comissao"
					},
					texto: "Relatorios"
				},
				{
					nome: "Financeiro",
					query: {
						menu: "ContasPagar"
					},
					texto: "Financeiro"
				},
				{
					nome: "etiqueta",
					texto: "Etiqueta"
				}
			],
			rotasVendedor: [
				{	
					nome: "FecharCaixa",
					texto: "Consultar caixa",
					condicao: "consultarCaixa"
				},
				{
					nome: "TrocarSenha",
					texto: "Trocar senha"
				},
				{
					nome: "Vendas",
					texto: "Vendas"
				},
				{
					nome: "CardapioOnline",
					texto: "Cardápio"
				}
			],

			currentOpenedDropdown: null,
			currentOpenedChildDropdown: null,

			timerConfiguracao: null,
			agora: new Date().toLocaleString("pt-BR"),
			atualizarHora: null,
			activeIndex: null,
		};
	},

	methods: {
		handleFavoriteRoute(e) {
			console.log(e);
			e.preventDefault();
		},

		toggleAccordion(index) {
			this.activeIndex = this.activeIndex === index ? null : index;
		},

		async handleChangeRouter({path, query}) {
			if(!path && !query) return;
			if(this.$router.currentRoute.name === path && this.$router.currentRoute.query.menu === query.menu) return;
			await this.$router.replace({name: path, query: query});
			if(path === "Principal") {
				this.$bus.$emit("Principal/MudarMenu", query);
			}
			if(path === "Gerenciamento") {
				this.$bus.$emit("Gerenciamento/MudarMenu", query.menu);
			}
			if(path === "Relatorios") {
				this.$bus.$emit("Relatorios/MudarMenu", query.menu);
			}
			if(path === "Financeiro") {
				this.$bus.$emit("Financeiro/MudarMenu", query.menu);
			}
			if(this.mostrarMenu) {
				const div = document.querySelector(".vlinks");
				
				div.classList.toggle("open");
				this.mostrarMenu = false;
			}
			this.$forceUpdate();
		},

		handleFocusDropdown(id) {
			this.currentOpenedDropdown = id;
		},

		handleBlurDropdown(id) {
			if (this.currentOpenedDropdown === id) {
				this.currentOpenedDropdown = null;
			}
		},

		handleFocusChildrenDropDown(id, idPai) {
			this.currentOpenedChildDropdown = id;

			const elem = document.getElementById(idPai);
			const height = window.getComputedStyle(elem,null).getPropertyValue("height");

			const childDropDown = document.getElementById(id);
			if(childDropDown) {
				const splittedHeight = height.split("p")[0];
				const calcHeight = Number(splittedHeight) - 30;
				childDropDown.style.top = calcHeight + "px";
			}
		},

		handleBlurChildrenDropDown(id) {
			if (this.currentOpenedChildDropdown === id) {
				this.currentOpenedChildDropdown = null;
			}
		},
		
		handleControlePagina() {
			if(!this.usuario.usaCelular && this.Empresa.tiposistema === "L") {
				this.$router.push({name: "LancamentoLanchonete"});
			} else if (this.usuario.usaCelular) {
				this.$router.push({name: "Principal"});
			} else {
				this.$router.go(- 1);
			}
		},
		resetarLocalStorage(){
			localStorageReset();
		},
		async mudarEmpresa() {
			if (!this.usuario.empresa) {
				await this.$store.dispatch("buscarEmpresas");
				this.$router.replace({ name: "SelecionaEmpresa" });
				this.$store.state.Empresa = null;
			}
		},
		atualizarAgora() {
			this.agora = new Date().toLocaleString("pt-BR");
			if (this.atualizarHora) {
				clearTimeout(this.atualizarHora);
			}
			this.atualizarHora = setTimeout(this.atualizarAgora, 1000);
		},

		abrirMenu() {
			this.mostrarMenu = document.querySelector(".vlinks") ? !document.querySelector(".vlinks").classList.contains("open") : false;
			document.querySelector(".vlinks").classList.toggle("open");
		},

		alterarConfiguracoes(abrir, tipo) {
			if (abrir == "navBar") {
				clearTimeout(this.timerConfiguracao);
				return this.timerConfiguracao = setTimeout(() => {
					document.querySelector(".configuracoes")?.classList.remove("open");
					this.timerConfiguracao = null;
				}, 350);
			}
			if (abrir) {
				if (!tipo && !this.timerConfiguracao) {
					return document.querySelector(".configuracoes").classList.remove("open");
				}
				document.querySelector(".configuracoes").classList.add("open");
				this.mostrarConfiguracoes = tipo;
				clearTimeout(this.timerConfiguracao);
			} else {
				clearTimeout(this.timerConfiguracao);
				this.timerConfiguracao = setTimeout(() => {
					document.querySelector(".configuracoes").classList.remove("open");
					this.timerConfiguracao = null;
				}, 200);
			}
		},

		logOut() {
			this.$store.dispatch("logOut");
		},

		inicio() {
			if (this.$route.name != "Principal") {
				this.$router.push({ name: "Principal" });
			}
		},
	},
	mounted() {
		this.atualizarAgora();
	},
	destroyed() {
		if (this.atualizarHora) {
			clearTimeout(this.atualizarHora);
		}
	}
};

</script>

<style scoped>
.accordion {
	width: 100%;
}


.accordion-header {
	padding: 5px;
	cursor: pointer;
	border: none;
	width: 100%;
	text-align: left;
	font-size: 14px;
	font-weight: bold;
	outline: none;
	background-color: transparent;
}

.accordion-content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-out;
}

.accordion-content.active {
	max-height: 500px;
}


.accordion-content ul {
	list-style-type: none;
	padding-left: 20px;
}

ul li {
	margin: 5px 0;
	font-size: 13px;
	font-weight: bold;
}

.container-empresa {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	width: 100%;
}

.container-empresa-caixa span{
	display: flex;
	font-size: 11px;
	font-weight: bold;
	align-items: center;
}

.container-dropdown-item {
    position: relative;
}

.rotafilho-dropdown-list {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    overflow: auto;
    width: 0; 
    position: absolute;
    left: 100%; 
	top: 0;
	opacity: 0;
    transform: translateX(-10px); 
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.dropdown-list-children-active {
    width: 200px;
    opacity: 1;
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
    pointer-events: auto;
}

.dropdown-list {
    position: absolute;
    flex-direction: column;
    list-style: none;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 14px;
    top: 100%;
    left: 0;
    width: 200px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s cubic-bezier(0.4, 0.0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1); 
    pointer-events: none;
}

.dropdown-list-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s cubic-bezier(0.4, 0.0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
    width: 200px;
    pointer-events: auto;
}


.rotafilho-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 2px;
}
.rotafilho-title i {
	color: #000;
	font-size: 13px;
}

/* .rotafilho-dropdown-list{
	background-color: #fff;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	overflow: auto;
	width: 0px;
	position: absolute;
	left: 200px;
	bottom: -30px;
	right: 0px;
	transition: all .1s ease-in;
} */

/*.dropdown-list-children-active{
	left: 200px;
	bottom: -30px;
	width: 200px;
	transition: all .1s ease-in;
}*/

.container-dropdown{
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	gap: 10px;
}
.dropdown-title{
	font-size: 14px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	transition: all .2s ease-in-out;
	padding: 7px;
	user-select: none;
}

.dropdown-title:hover{
	background-color: #cecece8a;
	border-radius: 5px;
}

.dropdown-title i {
	color: #000;
	font-size: 12px;
	font-weight: 600;
}
/*.dropdown-list{
	position: absolute;
	display: flex;
	flex-direction: column;
	list-style: none;
	
	background-color: #fff;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	font-size: 14px;
	top: -9999px;
	left: 0px;
	opacity: 0;
	transition: all .3s ease-in;
} */


.dropdown-list li {
	padding: 5px 5px;
	cursor: pointer;
	color: #000 !important;
}


.dropdown-list li:hover{
	background-color: #cecece8a;
	border-radius: 8px;
}

.text-black{
	color: #000;
	font-weight: bold;
}

.navBar {
	width: 100%;
	/*background: var(--bg-primario);*/
	background-color: #FFF;
	position: fixed;
	height: 100%;
	/*box-shadow: 0px 1px 2px 3px rgba(0, 0, 0, 0.6);*/
	/*border-bottom: 1px solid #000;*/
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	z-index: 5;
}

.container {
	padding: 0px 50px;
	width: 100%;
	margin: 0px;
	display: flex;
	height: 100%;
	background-color: #dcdcdc;
	/*background: var(--bg-primario);*/
}

.links {
	list-style: none;
	display: flex;
	justify-content: flex-end;
}

.logo {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.logo #navImg {
	border-radius: 10px;
	width: auto;
	object-fit: cover;
	max-height: 75px;
}

.configuracoes {
	top: -100vh;
	position: absolute;
	right: 1%;
	width: 260px;
	background: #FFF;
	z-index: -1;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-timing-function: all;
	box-shadow: 0px 6px 6px 2px rgba(0, 0, 0, 0.6);
}

.configuracoes.open {
	transform: translateY(110vh);
	transition-timing-function: ease-in-out;
	transition-delay: 0ms;
	transition-duration: 100ms;
	transition-timing-function: all;
}

.configuracoes-item {
	width: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-timing-function: all;
}

.configuracoes-item:hover {
	cursor: pointer;
}

.configuracoes-item a {
	width: 100%;
	height: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bolder;
	font-size: 18px;
	color: #000;
	padding: 15px;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-timing-function: all;
}

.configuracoes-item:hover a {
	background: #dfdfdf;
	border-radius: 8px;
}

.configuracoes-item a i {
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-timing-function: all;
}

.configuracoes-item:hover a i {
	color: var(--primario);
}

#usuario-controle {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

#usuario-controle i {
	padding: 7px;
	border-radius: 25%;
	box-shadow: 0px 0px 2px #161616;
	background-color: rgba(180, 180, 180, 0.7);
}

.usuario-nome {
	color: #000;
	font-weight: 700;
	margin: auto;
}

.btn-configuracoes {
	color: var(--branco);
	margin: 0 5px;
	background: var(--bg-primario);
	padding: 10px;
	border-radius: 10px;
	font-weight: bolder;
	font-size: 18px;
	cursor: pointer;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.btn-configuracoes:hover {
	color: var(--primario);
	background: var(--bg-app);
}

.vlinks {
	display: none;
}

.visivel {
	display: flex;
	align-self: center;
	font-size: 24px;
	color: #000;
}
.identificar-empresa {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
}
.empresa-atual {
	height: 100%;
	text-align: left;
	display: flex;
	align-items: center;
	color: #000;
	font-weight: bold;
	font-size: 8pt;
	cursor: default;
}

.fas.fa-sync {
	font-size: 8pt;
}

.tablet {
	display: none !important;
}

.smartphone {
	display: none !important;
}



.versao {
	color: #000;
	font-weight: 700;
	font-size: 7pt;
	position: absolute;
	right: 5px;
	bottom: 0px;
	user-select: none;
	cursor: default;
}

.versao-vlinks {
	color: #000;
	font-weight: 700;
	font-size: 12pt;
	user-select: none;
	cursor: default;
	position: fixed;
	right: 8px;
	bottom: 8px;
	display: none;
}

.vlinks.open .versao-vlinks {
	display: block;
}

.js-iconeMenu {
	color: #dcdcdc;
}

.smartphone h4{
	font-size: 11px;
}

@media screen and (max-width: 768px) {
	.links {
		display: none;
	}

	#usuario-controle {
		display: none;
	}

	i {
		display: flex;
		align-items: center;
		font-size: 24px;
	}

	.container {
		padding: 0px 20px;
		flex-direction: row-reverse;
		justify-content: space-between;
		flex-wrap: nowrap;
		overflow: hidden;
	}

	.vlinks {
		margin-top: 9vh;
		display: block;
		position: fixed;
		left: -100vw;
		height: 100%;
		width: 100vw;
		/* background: var(--bg-primario); */
		transition: all 1s;
		background-color: #dcdcdc;
		z-index: 5;
	}

	.js-iconeMenu{
		color: #000;
	}

	.vlinks.open {
		transform: translateX(100vw);
	}

	#navImg {
		max-width: 100px;
	}

	.configuracoes {
		display: none !important;
	}

	.desktop {
		display: none !important;
	}

	.tablet {
		display: flex !important;
	}

	.versao {
		display: none !important;
	}


	.container-empresa {
		justify-content: flex-start;
		align-items: flex-start;
	}
}

@media screen and (max-width: 560px) {
	.container {
		padding: 0px 10px;
	}

	.tablet {
		display: none !important;
	}

	.smartphone {
		display: flex !important;
		
	}
}

@media screen and (max-height: 560px) {
	.vlinks {
		justify-content: center;
		overflow: auto;
	}

	.container {
		margin: 0px auto;
	}
}
</style>
