<template>
	<div style="height: 100%;display: flex;flex-direction: column">
		<div class="dados-nota-item">
			<h3 class="dados-nota-titulo">Carta de Correção</h3>
		</div>
		<div class="row">
			<div class="col" style="gap:10px;width: 50%">
				<div class="row" style="width: 90%;margin: 0px 10px">
					<div class="input-group" style="width: 100%;margin: 0px">
						<input type="text" id="numeroCorrecao"
							v-model="cartaCorrecaoDados.numero"	readonly/>
						<label for="numeroCorrecao">Nº Correção:</label>
					</div>
					<div class="input-group" style="width: 100%;">
						<input type="date" id="dataCorrecao" readonly
							v-model="cartaCorrecaoDados.dataCorrecao">
						<label for="dataCorrecao">Data Correção:</label>
					</div>
					<div class="input-group" style="width: 100%;margin-right: 0px;">
						<input type="text" id="clienteCorrecao" readonly
							v-model="cartaCorrecaoDados.cdVenda">
						<label for="clienteCorrecao">Nº Venda:</label>
					</div>
				</div>
				<div class="input-group" style="width: 90%;">
					<input type="text" id="marca" readonly
						v-model="cartaCorrecaoDados.cliente">
					<label for="marca">Cliente:</label>
				</div>
				<div class="row" style="width: 90%;margin: 0px 10px">
					<div class="input-group" style="width: 30%;margin: 0px">
						<input type="text" id="marca" readonly
							v-model="cartaCorrecaoDados.valorVenda">
						<label for="marca">Valor da Venda:</label>
					</div>
					<div class="input-group" style="width: 70%;margin-right: 0px">
						<input type="text" id="marca" readonly
							v-model="cartaCorrecaoDados.id" @keydown.enter="proximoCampo('observacao')">
						<label for="marca">Numero ID:</label>
					</div>
				</div>
				<div class="textarea-group" style="width: 90%;">
					<textarea type="text" id="observacao" autocomplete="off"
						v-model="cartaCorrecaoDados.justificativa" @keydown.enter="proximoCampo('valorFrete')"></textarea>
					<label for="observacao">Justificativa:</label>
				</div>
				<div class="row" style="width: 90%;margin: 0px 10px">
					<div class="input-group" style="width: 100%;margin: 0px">
						<input type="text" id="valorFrete" autocomplete="off"
							v-model="cartaCorrecaoDados.nProtocolo" readonly>
						<label for="valorFrete">Nº Protocolo:</label>
					</div>
					<div class="input-group" style="width: 100%;margin-right: 0px">
						<input type="date" id="valorFrete" autocomplete="off"
							v-model="cartaCorrecaoDados.dataProtocolo" readonly>
						<label for="valorFrete">Data Protocolo:</label>
					</div>	
				</div>
			</div>
			<div class="col" style="width: 50%;gap:10px">
				<div class="row" style="gap:10px">
					<input 
						type="checkbox" placeholder="" 
						id="chaveDevolucao"
						v-model="cartaCorrecaoDados.ehEmail"
					/>
					<div class="input-group" style="width: 100%;margin: 0px">
						<input 
							type="text" placeholder=" " 
							id="chaveDevolucao"
							v-model="cartaCorrecaoDados.email"
							style="text-transform:none;"
						/>
						<label for="chaveDevolucao">
							E-mail:
						</label>
					</div>
				</div>
				<div class="row" style="gap:10px">
					<input 
						type="checkbox" placeholder="" 
						id="chaveDevolucao"
						v-model="cartaCorrecaoDados.ehComercial"
					/>
					<div class="input-group" style="width: 100%;margin: 0px">
						<input 
							type="text" placeholder=" " 
							id="chaveDevolucao"
							v-model="cartaCorrecaoDados.comercial"
						/>
						<label for="chaveDevolucao">
							Comercial:
						</label>
					</div>
				</div>
				<div class="row" style="gap:10px">
					<input 
						type="checkbox" placeholder="" 
						id="chaveDevolucao"
						v-model="cartaCorrecaoDados.ehNfe"
					/>
					<div class="input-group" style="width: 100%;margin: 0px">
						<input 
							type="text" placeholder=" " 
							id="chaveDevolucao"
							v-model="cartaCorrecaoDados.nfe"
						/>
						<label for="chaveDevolucao">
							NF-e:
						</label>
					</div>
				</div>
				<div class="row" style="gap:10px">
					<input 
						type="checkbox" placeholder="" 
						id="chaveDevolucao"
						v-model="cartaCorrecaoDados.ehFinanceiro"
					/>
					<div class="input-group" style="width: 100%;margin: 0px">
						<input 
							type="text" placeholder=" " 
							id="chaveDevolucao"
							v-model="cartaCorrecaoDados.financeiro"
						/>
						<label for="chaveDevolucao">
							Financeiro:
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="col" style="justify-content:end; height: 100%;">
			<div class="botoes" style="gap: 20px;">
				<button class="botao botao-cancelar" @click="$emit('cancelarCarta')">Cancelar</button>
				<button class="botao botao-adicionar" @click="salvarCarta" v-if="carta.emitir != 'S'">Salvar</button>
				<button class="botao botao-adicionar" @click="enviaCarta" v-if="carta.emitir != 'S'">Enviar</button>
			</div>                           
		</div>
	</div>
</template>
<script>
export default {
	name: "CartaCorrecao",

	components: {
	},

	props: {
		venda:Object,
		carta:Object,
	},

	data() {
		return {
			cartaCorrecaoDados:{
				numero:1,
				dataCorrecao:new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
				cdVenda:1,
				cliente:"",
				valorVenda:"",
				id:"",
				justificativa:"",
				nProtocolo:"",
				dataProtocolo:"",
				ehEmail:false,
				email:"",
				ehComercial:false,
				comercial:"",
				ehNfe:false,
				nfe:"",
				ehFinanceiro:false,
				financeiro:"",
			},
			salvou:false,
		};
	},
	computed: {
		Empresa () {
			return this.$store.state.Empresa;
		},
		Clientes(){
			return this.$store.state.Clientes;
		},
	},
	methods: {
		async salvarCarta(){
			const payload = {
				cdEmpresa:this.Empresa.id,
				cdvenda:this.venda.cdVenda,
				justificativa:this.cartaCorrecaoDados.justificativa,
				data:this.carta.novo? new Date().toISOString().split("Z")[0]: this.carta.data,
				tipo:"E",
				cdcorrecao:this.carta.novo?null: this.carta.cdcorrecao,
				xml:this.venda.nfeid,
			};
			if(this.carta.novo){
				const  resp = await this.$store.dispatch("salvaCartaCorrecao",payload);
				if(!resp.cdcorrecao){
					return this.$store.commit("relatarErro", {
						mensagem: "Houve um erro ao salvar a carta de correção por favor tente novamente!"
					});
				}
				this.cartaCorrecaoDados.numero = resp.cdcorrecao;
				this.salvou = true;
			}
			else{
				this.$store.dispatch("editaCartaCorrecao",payload);
			}
		},
		enviaCarta(){		
			if(this.carta.novo && !this.salvou){
				return this.$store.commit("relatarErro", {
					mensagem: "É nescessario salvar a carta de correção antes de envia-la!"
				});
			}
			const payload = {
				cdEmpresa:this.Empresa.id,
				cdvenda:this.venda.cdVenda,
			};
			this.$store.dispatch("emitirCartaCorrecao",payload);
			this.$emit("cancelarCarta");
		}
	},

	mounted() {	
		this.cartaCorrecaoDados.cdVenda = this.venda.cdVenda;
		this.cartaCorrecaoDados.cliente = this.venda.cliente.nome;
		this.cartaCorrecaoDados.valorVenda = this.venda.valorPago.toLocaleString("pt-BR", {style: "currency", currency: "BRL"});
		this.cartaCorrecaoDados.id = this.venda.webnfeid;
		this.cartaCorrecaoDados.numero = this.carta.cdcorrecao;
		if(!this.carta.novo){
			this.cartaCorrecaoDados.justificativa = this.carta.correcao;
			this.cartaCorrecaoDados.dataProtocolo = this.carta.dataProtocolo;
			this.cartaCorrecaoDados.nProtocolo = this.carta.protocolo;
			this.cartaCorrecaoDados.dataCorrecao = this.cartaData.split("T")[0];
		}
	},
};
</script>
<style scoped>
.dados-nota-titulo {
	width: 100%;
}
.dados-nota-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 95%;
	margin: 5px 0px;
}
.dados-nota-item input,
.dados-nota-item select {
	display: flex;
	width: 100%;
	height: max-content;
	padding: 3px;
}
.dados-nota-item.botoes {
	flex-direction: row;
	justify-content: space-between;
}
</style>