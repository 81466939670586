<template>
    <div class="parametros">
        <div class="parametro-usuario"  v-for="opcao in Object.keys(parametroCadastrar.opcoes)" :key="opcao">
			<label class="mensagem-opcao" :for="opcao">{{parametroCadastrar.opcoes[opcao].mensagem}}</label>
            <div class="checked">
                <div class="selecionar row">
                    <input v-model="parametroCadastrar.opcoes[opcao].valor" :value="true" :id="opcao" @change="passarDados()" type="checkbox"/>
                    <label :for="opcao" class="check"></label>
                    <span v-if="parametroCadastrar.opcoes[opcao].valor">&nbsp;SIM</span>
                    <span v-else>&nbsp;NÃO</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default{
	name:"ParametrosUsuarios",
	props:{
		Parametro: Object,
	},
	data(){
		return {
			parametroCadastrar: {
				opcoes: {
					"usuarioPrivilegiado": {
						mensagem: "Usuário com mais altos privilégios",
						valor: false
					},
					"supervisor": {
						mensagem: "Usuário supervisor",
						valor: false
					},
					"caixa": {
						mensagem: "Usuário caixa",
						valor: false
					},
					"permiteDescontoAvista":{
						mensagem: "Permite desconto no pedido a vista",
						valor: false
					},
					"descontoRecebimento":{
						mensagem: "Permite desconto no recebimento de contas pendentes",
						valor: false
					},
					"liberaDesconto":{
						mensagem: "Permite liberar desconto de usuários com limite de desconto inferior",
						valor: false
					},
					"controlaCliente":{
						mensagem: "Valida dados ao cadastrar cliente",
						valor: false
					},
					"incluiCliente":{
						mensagem: "Permite cadastrar cliente",
						valor: false
					},
					"incluiVendedor":{
						mensagem: "Permite cadastrar vendedor",
						valor: false
					},
					"incluiProduto":{
						mensagem: "Permite cadastro de produtos, grupos e promoções",
						valor: false
					},
					"incluiConta":{
						mensagem: "Permite cadastro de contas, caixas e bandeiras",
						valor: false
					},
					"incluiCompra":{
						mensagem: "Permite cadastro de compras",
						valor: false
					},
					"incluiFormaPagamento":{
						mensagem: "Permite cadastro de formas de pagamento",
						valor: false
					},
					"incluiEmpresa":{
						mensagem: "Permite cadastro de empresas",
						valor: false
					},
					"incluiUsuario":{
						mensagem: "Permite cadastro de usuários",
						valor: false
					},
					"liberaCliente":{
						mensagem: "Permite liberar venda para cliente devedor",
						valor: false
					},
					"permiteVenderSaldoNegativo":{
						mensagem: "Permite vender produto sem saldo",
						valor: false
					},
					"alteraEstoque":{
						mensagem: "Permite alterar estoque do produto",
						valor: false
					},
					"permiteAlterarVenda":{
						mensagem: "Permite alterar venda dentro do tempo(30 minutos)",
						valor: false
					},
					"caixaConsultaVendas": {
						mensagem: "Permite consultar vendas completo(ver vendas de outros vendedores)",
						valor: false
					},
					"permiteBaixarCondicional":{
						mensagem: "Permite visualizar e baixar condicionais em aberto",
						valor: false
					},
					"vendeParaOutroVendedor":{
						mensagem: "Permite trocar vendedor do pedido e do consorcio",
						valor: false
					},
					"solicitaVendedorNoPedido": {
						mensagem: "Solicita vendedor ao iniciar pedido",
						valor: false
					},
					"mostrarNoPedido": {
						mensagem: "Permite mostrar como vendedor no pedido",
						valor: false
					},
					"vendedorRecebePrazo":{
						mensagem: "Permite lançar financeiro do pedido",
						valor: false
					},
					"somenteVendaSemCaixa":{
						mensagem: "Sempre aprovar vendas desse usuário no caixa",
						valor: false
					},
					"multiTroca": {
						mensagem: "Permite trocar produtos sem selecionar venda",
						valor: false
					},
					"condicionalConsumidorFinal":{
						mensagem: "Permite realizar condicional para o consumidor final",
						valor: false
					},
					"solicitaNomeConsumidorFinal":{
						mensagem: "Permite não validar dados do consumidor final na venda",
						valor: false
					},
					"validarCnpj":{
						mensagem: "Valida CPF/CNPJ do cadastro de cliente simplificado",
						valor: false
					},
					"controlaCartao": {
						mensagem: "Validar dados do cartão para recebimentos",
						valor: false
					},
					"usaCelular": {
						mensagem: "Permite lançamento de pedidos pelo celular",
						valor: false
					},		
					"verTodasVendas": {
						mensagem: "Permite ver todas as comissões de vendas ",
						valor: false
					},
					"consultarCaixa":{
						mensagem: "Permite consultar Caixa",
						valor: false
					},
					"vendarapida":{
						mensagem: "Habilita venda rápida",
						valor: false
					}	
				},
			},
		};
	},
	methods: {
		preencherParametros(parametros) {
			if (!parametros) return;
			Object.keys(parametros).forEach(key => {
				if (!this.parametroCadastrar.opcoes[key]) {
					console.log("Falta a key", key);
				} else {
					this.parametroCadastrar.opcoes[key].valor = parametros[key] == "S";
				}
			});
			this.passarDados();
		},
		passarDados(){			
			Object.keys(this.parametroCadastrar.opcoes).forEach(key => {
				this.parametroCadastrar[key] = this.parametroCadastrar.opcoes[key].valor;
			});
			this.$emit("passarDados", this.parametroCadastrar);
		},
	},
	mounted(){
		this.$bus.$on("preencherParametros", this.preencherParametros);
		if(this.Parametro != null){
			this.preencherParametros(this.Parametro);
		}
	},

	destroyed() {
		this.$bus.$off("preencherParametros", this.preencherParametros);
	}
};
</script>
<style scoped>
.parametros{
	display: grid;
	grid-template-columns: repeat(3,1fr);	
	justify-content: space-around;
	width: 100%;
	height: 100%;
	font-size: 12px;
	overflow: auto;
}
/* .parametros{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
	height: 100%;
	font-size: 12px;
	overflow: auto;
} */
.parametro-usuario{
	display: flex;
	flex-direction: column;
	padding-bottom: 9px;
	justify-content: flex-end;
	font-size: 12px;
}
.checked {
	display: flex;
	align-content: flex-start;
	justify-content: flex-start;
}
.check {
	display: block;
	position: relative;
	width: 40px;
	height: 20px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}
.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}
</style>