export class Empresa {
	ativo;
	id;
	nome;
	fantasia;
	endereco;
	numero;
	bairro;
	complemento;
	cep;
	cdCidade;
	fone;
	fax;
	celular;
	ie;
	cnpj;
	email;
	home;
	atalhoLoginPdv;
	nomeCidade;
	ufCidade;
	carenciaCliente;
	logo;
	prefixcodproduto;
	responsavel;
	dataCadastro;
	sittribest;
	cfopestacompra;
	cfopesta;
	sittribint;
	cfopintercompra;
	cfopinter;
	pissittrib;
	pis;
	cofinssittrib;
	cofins;
	ipisittrib;
	ipi;
	tipoJuros;
	multa;
	financeiro;
	carenciaJuros;
	desrevenda;
	customedio;
	prazoTrocaCupom;
	minutosExpiraSenha;
	cdconsumidorfinal;
	icmsestsittrib;
	icmsintsittrib;
	tipoEtiqueta;
	geraCodBarraProduto;
	agruparProdutoIguaisCompra;
	usarCodigoBarraFornecedor;
	validaAdicionalCliente;
	//NF-E
	caminhoAPI;
	nfeCaminhoCertificado;
	nfeSenhaCertificado;
	fileCertificado;
	carenciaCliente;


	transfereProdutoAutomatico;


	nfeIdTokenPro;
	nfeTokenPro;
	nfeIdTokenHom;
	nfeTokenHom;

	tiposistema;

	cadEan;
	tipoCodProduto;
	tamCodProduto;
	nfedir;
	constructor(empresa) {
		this.ativo = empresa.ativo;
		this.atalhoLoginPdv = empresa.atalhoLoginPdv;
		this.bairro = empresa.bairro;
		this.carenciaCliente = empresa.carenciaCliente;
		this.cdCidade = empresa.cdcidade;
		this.id = empresa.cdempresa;
		this.celular = empresa.celular;
		this.cep = empresa.cep;
		this.cnpj = empresa.cnpj;
		this.complemento = empresa.complemento;
		this.customedio = (empresa.customedio) == "S";
		this.dataCadastro = empresa.dataCadastro;
		this.email = empresa.email;
		this.nome = empresa.empresa;
		this.endereco = empresa.endereco;
		this.fantasia = empresa.fantasia;
		this.fax = empresa.fax;
		this.fone = empresa.fone;
		this.home = empresa.home;
		this.ie = empresa.ie;
		this.logo = empresa.logo;
		this.minutosExpiraSenha = empresa.minutosExpiraSenha;
		this.nomeCidade = empresa.nomeCidade;
		this.numero = empresa.numero;
		this.prazoTrocaCupom = empresa.prazoTrocaCupom;
		this.prefixcodproduto = empresa.prefixcodproduto;
		this.responsavel = empresa.responsavel;
		this.ufCidade = empresa.ufCidade;
		this.sittribest = empresa.sittribest;
		this.cfopestacompra = empresa.cfopestacompra;
		this.cfopesta = empresa.cfopesta;
		this.sittribint = empresa.sittribint;
		this.cfopintercompra = empresa.cfopintercompra;
		this.cfopinter = empresa.cfopinter;
		this.pissittrib = empresa.pissittrib;
		this.pis = empresa.pis;
		this.cofinssittrib = empresa.cofinssittrib;
		this.cofins = empresa.cofins;
		this.ipisittrib = empresa.ipisittrib;
		this.ipi = empresa.ipi;
		this.tipoJuros = (empresa.tipoJuros) == "C";
		this.multa = empresa.multa;
		this.financeiro = empresa.financeiro;
		this.carenciaJuros = empresa.carenciaJuros;
		this.desrevenda = empresa.desrevenda;
		this.cdconsumidorfinal = empresa.cdconsumidorfinal;
		this.icmsestsittrib = empresa.icmsestsittrib;
		this.icmsintsittrib = empresa.icmsintsittrib;

		//NF-E
		this.caminhoAPI = empresa.caminhoAPI;
		this.nfeCaminhoCertificado = empresa.nfeCaminhoCertificado;
		this.nfeSenhaCertificado = empresa.nfeSenhaCertificado;
		this.fileCertificado = empresa.fileCertificado;
		this.tipoEtiqueta = empresa.tipoetiqueta;
		this.geraCodBarraProduto = empresa.geracodbarraproduto;
		this.carenciaCliente = empresa.carenciaCliente;
		// Conciliadora
		this.conciliadora = {
			dataCadastroConciliadora: empresa.datacadastroconciliadora,
			dataUltConciliacao: empresa.dataultconciliacao,
			usuarioCadastroConciliadora: empresa.usuariocadastroconciliadora,
			idEmpresaConciliadora: empresa.idEmpresaConciliadora,
			senhaEmpresaConciliadora: empresa.senhaEmpresaConciliadora,
			apiKeyConciliadora: empresa.apikeyconciliadora
		};
		this.usarCodigoBarraFornecedor = empresa.usarCodigoBarraFornecedor;
		this.validaAdicionalCliente = empresa.validaAdicionalCliente == "S";
		this.transfereProdutoAutomatico = empresa.transfereprodutoautomatico;
		//Aba {CONFIGURAÇÃO NFE}
		this.nfeIdTokenPro = empresa.nfeIdTokenPro;
		this.nfeTokenPro = empresa.nfeTokenPro;
		this.nfeIdTokenHom = empresa.nfeIdTokenHom;
		this.nfeTokenHom = empresa.nfeTokenHom;

		this.tiposistema = empresa.tiposistema;

		this.cadEan = empresa.cadEan;
		this.tipoCodProduto = empresa.tipoCodProduto;
		this.tamCodProduto = empresa.tamCodProduto;
		this.nfedir = empresa.nfedir;

		this.agruparProdutoIguaisCompra = empresa.agruparProdutoIguaisCompra;
	}
}

export class StateEmpresa {
	Empresas = [];
	Empresa = null;
	paginaEmpresasAtual = -1;
	totalPaginasEmpresas = 0;
	EmpresaLiberada = true;
}

export class EmpresaAPI {
	ativo;
	atalhoLoginPdv;
	bairro;
	carenciaCliente;
	carenciaJuros;
	cdCidade;
	cdconsumidorfinal;
	celular;
	cep;
	cfopesta;
	cfopestacompra;
	cfopinter;
	cfopintercompra;
	cnpj;
	cofins;
	cofinssittrib;
	complemento;
	customedio;
	desrevenda;
	email;
	empresa;
	endereco;
	fantasia;
	fax;
	financeiro;
	fone;
	home;
	icmsestsittrib;
	icmsintsittrib;
	ie;
	ipi;
	ipisittrib;
	logo;
	minutosExpiraSenha;
	multa;
	numero;
	pis;
	pissittrib;
	prazoTrocaCupom;
	responsavel;
	sittribest;
	sittribint;
	tipoJuros;
	//NF-E
	caminhoAPI;
	nfeCaminhoCertificado;
	nfeSenhaCertificado;
	usarCodigoBarraFornecedor;
	validaAdicionalCliente;
	geracodbarraproduto;
	agruparProdutoIguaisCompra;
	tipoetiqueta;
	conciliadora;
	carenciaCliene;
	nfedir;

	//ABA {CONFIGURAÇÕES NFE}
	nfeIdTokenPro;
	nfeTokenPro;
	nfeIdTokenHom;
	nfeTokenHom;

	tiposistema;

	cadEan;
	tipoCodProduto;
	tamCodProduto;

	constructor(empresa = new Empresa({})) {
		this.ativo = empresa.ativo;
		this.atalhoLoginPdv = empresa.atalhoLoginPdv ? `${empresa.atalhoLoginPdv}`.toUpperCase() : null;
		this.bairro = `${empresa.bairro}`.toUpperCase();
		this.carenciaCliente = empresa.carenciaCliente;
		this.carenciaJuros = empresa.carenciaJuros;
		this.cdCidade = empresa.cdCidade;
		this.cdconsumidorfinal = empresa.cdconsumidorfinal;
		this.celular = empresa.celular;
		this.cep = empresa.cep;
		this.cfopesta = empresa.cfopesta;
		this.cfopestacompra = empresa.cfopestacompra;
		this.cfopinter = empresa.cfopinter;
		this.cfopintercompra = empresa.cfopintercompra;
		this.cnpj = empresa.cnpj;
		this.complemento = empresa.complemento;
		this.customedio = empresa.customedio ? "S" : "N";
		this.desrevenda = empresa.desrevenda;
		this.email = empresa.email ? `${empresa.email}`.toLowerCase() : null;
		this.empresa = `${empresa.nome}`.toUpperCase();
		this.endereco = `${empresa.endereco}`.toUpperCase();
		this.fantasia = `${empresa.fantasia}`.toUpperCase();
		this.fax = empresa.fax;
		this.financeiro = empresa.financeiro;
		this.fone = empresa.fone;
		this.home = `${empresa.home}`.toLowerCase();
		this.icmsestsittrib = empresa.icmsestsittrib;
		this.icmsintsittrib = empresa.icmsintsittrib;
		this.ie = empresa.ie;
		
		this.logo = empresa.logo;
		this.minutosExpiraSenha = empresa.minutosExpiraSenha;
		this.multa = empresa.multa;
		this.numero = empresa.numero;
		
		
		this.prazoTrocaCupom = empresa.prazoTrocaCupom;
		this.responsavel = `${empresa.responsavel}`.toUpperCase();

		this.ipisittrib = empresa.ipisittrib || 0;
		this.ipi = empresa.ipi || 0;
		
		this.pissittrib = empresa.pissittrib || 0;
		this.pis = empresa.pis || 0;

		this.sittribest = empresa.sittribest || 0;
		this.sittribint = empresa.sittribint || 0;

		this.cofins = empresa.cofins || 0;
		this.cofinssittrib = empresa.cofinssittrib || 0;
		
		this.tipoJuros = empresa.tipoJuros ? "C" : "N";
		this.tipoetiqueta = empresa.tipoEtiqueta;
		this.geracodbarraproduto = empresa.geraCodBarraProduto;
		this.agruparProdutoIguaisCompra = empresa.agruparProdutoIguaisCompra;
		//NF-E
		this.caminhoAPI = empresa.caminhoAPI;
		this.nfeCaminhoCertificado = empresa.nfeCaminhoCertificado;
		this.nfeSenhaCertificado = empresa.nfeSenhaCertificado;

		this.carenciaCliente = empresa.carenciaCliente;


		this.transfereprodutoautomatico = empresa.transfereProdutoAutomatico;
		
		this.usarCodigoBarraFornecedor = empresa.usarCodigoBarraFornecedor;
		this.validaAdicionalCliente = empresa.validaAdicionalCliente? "S" : "N";
		this.conciliadora = {
			dataCadastroConciliadora: empresa.dataCadastroConciliadora,
			dataUltConciliacao: empresa.dataUltConciliacao,
			apiKeyConciliadora: empresa.apiKeyConciliadora,
			usuarioCadastroConciliadora: empresa.usuarioCadastroConciliadora,
			senhaEmpresa: empresa.senhaEmpresa,
			idEmpresa: empresa.idEmpresa
		};

		//Aba {CONFIGURAÇÃO NFE}
		this.nfeIdTokenPro = empresa.nfeIdTokenPro;
		this.nfeTokenPro = empresa.nfeTokenPro;
		this.nfeIdTokenHom = empresa.nfeIdTokenHom;
		this.nfeTokenHom = empresa.nfeTokenHom;

		this.tiposistema = empresa.tiposistema;

		this.cadean = empresa.cadEan;
		this.tipoCodProduto = empresa.tipoCodProduto;
		this.tamCodProduto = empresa.tamCodProduto;
		this.nfedir = empresa.nfedir;
	}
}
