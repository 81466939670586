// import router from "../router/index.js";

import { doRequest } from "./http";

export default {
	state: {
		Cidades: [],
		paginaCidadesAtual: -1,
		totalPaginasCidades: 0
	},

	actions: {
		async buscarCidadeViaCep({commit}, payload) {
			try {
				const {cep} = payload;
				let cepCidade = cep.replace("-", "");
				if (cepCidade.length != 8) return;
				let response = await fetch(`https://viacep.com.br/ws/${cepCidade}/json/`);
				const data = await response.json();
				if (!data.ibge) {
					commit("relatarErro", {mensagem: "CEP Inváliado!"});
					return data;
				}
				let url = `cidade/ibge?ibge=${data.ibge}`;
				response = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if(response.length) {
					return {
						...response[0],
						...data
					};
				}
				commit("relatarErro", {mensagem: response.mensagem || JSON.stringify(response)});
				return {erro: true};
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},

		async buscarCidadeViaCepSemBanco({commit}, payload) {
			try {
				const {cep} = payload;
				let cepCidade = cep.replace("-", "");
				if (cepCidade.length != 8) return;
				let response = await fetch(`https://viacep.com.br/ws/${cepCidade}/json/`);
				const data = await response.json();
				if (!data.ibge) {
					commit("relatarErro", {mensagem: "CEP Inváliado!"});
					return data;
				}
				else{
					return data;
				}
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
		},
		
		async buscarIbge({commit}, payload) {
			try {
				let url = `cidade/ibge?ibge=${payload}`;
				if (!payload) {
					return {erro: true, mensagem: "IBGE Inváliado!"};
				}
				const response = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if(response.cdcidade) {
					return response;
				}
				if (response.length) {
					return response[0];
				}
				return {erro: true};
			} catch (error) {
				commit("relatarErro", {mensagem: error});
				return {erro: true, mensagem: error.message || error.mensagem || JSON.stringify(error)};
			}
		},

		async filtrarCidades({commit, state}, payload) {
			try {
				state.carregando = true;
				const {
					query
				} = payload;
				let url = `cidade/filterCidade?query=${query}`;
				const response = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (response.content) {
					commit("atualizarListaCidades", response);
				} else {
					throw new Error(response.mensagem || JSON.stringify(response.details));
				}
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || error.mensagem || JSON.stringify(error.details)});
				commit("atualizarListaCidades", {
					content: [],
					page: -1,
					totalPages: 0
				});
				state.carregando = false;
			}
		},

		async buscarCidades({commit, state}) {
			try {
				state.carregando = true;
				let url = "cidade/filterCidade?query=";
				const response = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (response.content) {
					commit("atualizarListaCidades", response);
				} else {
					throw new Error(response.mensagem || JSON.stringify(response.details));
				}
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || error.mensagem || JSON.stringify(error.details)});
				commit("atualizarListaCidades", {
					content: [],
					page: -1,
					totalPages: 0
				});
				state.carregando = false;
			}
		},

		async buscarCidade({commit, state}, payload) {
			try {
				state.carregando = true;
				const url = "cidade?id="+payload;
				const response = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (response.cdcidade) {
					return response;
				} else {
					return null;
				}
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || error.mensagem || JSON.stringify(error.details)});
				state.carregando = false;
			}
		},
	},
	mutations: {
		atualizarListaCidades(state, payload) {
			state.Cidades = [];
			state.Cidades = payload.content;
			state.paginaCidadesAtual = payload.page;
			state.totalPaginasCidades = payload.totalPages;
		}
	}
};