<template>
	<div id="TelaConta">
		<div class="titulo">
			<h1>Dados da Forma de Pagamento</h1>
		</div>
		<div class="box-cadastro">
			<div class="row w-100 wrap">
				<div class="cel row mb-5 h-30px w-100 campos">
					<div class="input-group">
						<input
							v-model="FormaPagamentoCadastrado.cdtipo"
							@input="setUpperCase"
							autocomplete="off"
							maxlength="30"
							id="cdtipo"
							type="text"
							readonly
						/>
						<label for="codigo">Código:</label>
					</div>
					<div class="input-group">
						<input
							v-model="FormaPagamentoCadastrado.descricao"
							@input="setUpperCase"
							autocomplete="off"
							maxlength="20"
							id="descricao"
							type="text"
						/>
						<label for="mtpgto">Metodo de Pagamento:</label>
					</div>
					<div class="input-group" v-if="!FormaPagamentoCadastrado.vistaPrazo">
						<input
							v-model="FormaPagamentoCadastrado.qtdeParcela"
							@input="setUpperCase"
							autocomplete="off"
							maxlength="30"
							id="qtdeParcela"
							type="text"
						/>
						<label for="qtdeParcela"> Qtd. Parcelas:</label>
					</div>
					<div
						id="ativo"
						@click="mudarAtivo('ativo', 'A', 'I')"
						class="campos row w-15 nowrap"
					>
						<label class="mx-5 text-right w-auto" for="ativo"
							>Ativo:
						</label>
						<i
							class="far fa-times-circle w-100 text-left"
							v-if="FormaPagamentoCadastrado.ativo == 'I'"
							style="color: red;"
						>
							Não</i
						>
						<i
							class="far fa-check-circle w-100 text-left"
							v-else
							style="color: green"
						>
							Sim</i
						>
					</div>
				</div>
				<div class="aba-controle" style="margin-top:7px">
					<h3 :class="{'aba':true, 'abaSelecionada': abaSelecionada == 'parametros'}" @click="mudarAba('parametros')">
						Parametros
					</h3>
					<h3 :class="{'aba':true, 'abaSelecionada': abaSelecionada == 'planoContas'}" @click="mudarAba('planoContas')">
						Financeiro
					</h3>
				</div>
				<div class="overflow abaParametros" v-if="abaSelecionada == 'parametros'">
					<div class="column">
						<!-- MOSTRA VENDA STAR -->
						<div class="parametro">
							<div>
								<label for="mostraVenda">Mostra no recebimento da venda</label>
							</div>
							<div class="checked">
								<div class="selecionar">
									<input
										v-model="
											FormaPagamentoCadastrado.mostraVenda
										"
										:value="true"
										id="mostraVenda"
										type="checkbox"
									/>
									<label for="mostraVenda" class="check"></label>
									<span
										v-if="FormaPagamentoCadastrado.mostraVenda"
										>SIM</span
									>
									<span v-else>NÃO</span>
								</div>
							</div>
						</div>
						<!-- MOSTRA VENDA END -->
						<!-- MOSTRA RECEBIMENTO STAR -->
						<div class="parametro">
							<div>
								<label for="mostraRecebimento"
									>Mostra recebimento no contas a receber</label
								>
							</div>
							<div class="checked">
								<div class="selecionar">
									<input
										v-model="
											FormaPagamentoCadastrado.mostraRecebimento
										"
										:value="true"
										id="mostraRecebimento"
										type="checkbox"
									/>
									<label
										for="mostraRecebimento"
										class="check"
									></label>
									<span
										v-if="
											FormaPagamentoCadastrado.mostraRecebimento
										"
										>SIM</span
									>
									<span v-else>NÃO</span>
								</div>
							</div>
						</div>
						<!-- MOSTRA RECEBIMENTO END -->
						<!-- MOSTRA COMPRA STAR -->
						<div class="parametro">
							<div>
								<label for="mostraCompra">Mostra no financeiro da compra</label>
							</div>
							<div class="checked">
								<div class="selecionar">
									<input
										v-model="
											FormaPagamentoCadastrado.mostraCompra
										"
										:value="true"
										id="mostraCompra"
										type="checkbox"
									/>
									<label for="mostraCompra" class="check"></label>
									<span
										v-if="FormaPagamentoCadastrado.mostraCompra"
										>SIM</span
									>
									<span v-else>NÃO</span>
								</div>
							</div>
						</div>
						<!-- MOSTRA COMPRA END -->
						<!-- CALCULA JUROS STAR -->
						<div class="parametro">
							<div>
								<label for="calculaJuros">Calcula juros configurados na empresa</label>
							</div>
							<div class="checked">
								<div class="selecionar">
									<input
										v-model="
											FormaPagamentoCadastrado.calculaJuros
										"
										:value="true"
										id="calculaJuros"
										type="checkbox"
									/>
									<label for="calculaJuros" class="check"></label>
									<span
										v-if="FormaPagamentoCadastrado.calculaJuros"
										>SIM</span
									>
									<span v-else>NÃO</span>
								</div>
							</div>
						</div>
						<!-- CALCULA JUROS END -->
						<!-- PERMITE CONSUMIDOR FINAL STAR -->
						<div class="parametro">
							<div>
								<label for="permiteConsumidorFinal"
									>Permite venda para consumidor final</label
								>
							</div>
							<div class="checked">
								<div class="selecionar">
									<input
										v-model="
											FormaPagamentoCadastrado.permiteConsumidorFinal
										"
										:value="true"
										id="permiteConsumidorFinal"
										type="checkbox"
									/>
									<label
										for="permiteConsumidorFinal"
										class="check"
									></label>
									<span
										v-if="
											FormaPagamentoCadastrado.permiteConsumidorFinal
										"
										>SIM</span
									>
									<span v-else>NÃO</span>
								</div>
							</div>
						</div>
						<!-- PERMITE CONSUMIDOR FINAL END -->
						<!-- VERIFICA PENDÊNCIAS STAR -->
						<div class="parametro">
							<div>
								<label for="verificaSituacao"
									>Verifica pendências do cliente na venda</label
								>
							</div>
							<div class="checked">
								<div class="selecionar">
									<input
										v-model="
											FormaPagamentoCadastrado.verificaSituacao
										"
										:value="true"
										id="verificaSituacao"
										type="checkbox"
									/>
									<label
										for="verificaSituacao"
										class="check"
									></label>
									<span
										v-if="
											FormaPagamentoCadastrado.verificaSituacao
										"
										>SIM</span
									>
									<span v-else>NÃO</span>
								</div>
							</div>
						</div>
						<!-- VERIFICA PENDÊNCIAS END -->
					</div>
					<div class="column">
						<fieldset>
							<legend>
								Forma de pagamento ou recebimento
							</legend>
							<!-- Formas de pagamento:-->
							<!-- VISTA PRAZO STAR -->
							<div class="parametro">
								<div>
									<label for="vistaprazo"
										></label
									>
								</div>
								<div class="checked">
									<div class="selecionar">
										<input
											v-model="
												FormaPagamentoCadastrado.vistaPrazo
											"
											:value="true"
											@change="selecionarForma('vistaprazo')"
											id="vistaprazo"
											type="checkbox"
										/>
										<label for="vistaprazo" class="check"></label>
										<span>À VISTA</span
										>
									</div>
									<div class="selecionar">
										<input
											v-model="
												FormaPagamentoCadastrado.vistaPrazo
											"
											:value="FormaPagamentoCadastrado.vistaPrazo"
											id="vistaprazo"
											type="checkbox"
											@change="selecionarForma('vistaprazo')"
											:true-value="false"
											:false-value="true"
										/>
										<label for="vistaprazo" class="check"></label>
										<span>À PRAZO</span>
									</div>
								</div>
							</div>
							<!-- VISTA PRAZO END -->
							<!-- CARTÃO STAR -->
							<div class="row"  v-if="!FormaPagamentoCadastrado.vistaPrazo">
								<div class="parametro">
									<div class="containerCard">
										<label for="cartao">Cartão</label>
										<i class="fas fa-credit-card creditCard" @click="selecionarProdutoConciliadora" v-if="FormaPagamentoCadastrado.cartao && $store.state.Empresa.conciliadora.apiKeyConciliadora"></i>
									</div>
									<div class="checked">
										<div class="selecionar">
											<input
												v-model="FormaPagamentoCadastrado.cartao"
												:value="true"
												@change="selecionarForma('cartao')"
												id="cartao"
												type="checkbox"
											/>
											<label for="cartao" class="check"></label>
											<span v-if="FormaPagamentoCadastrado.cartao"
												>SIM</span
											>
											<span v-else>NÃO</span>
										</div>
									</div>
								</div>
								<div class="parametro" v-if="FormaPagamentoCadastrado.cartao">
									<div class="containerCard">
										<label for="cartao" class="sr-only">Cartão do tipo a prazo</label>
									</div>
									<div class="checked">
										<div class="selecionar">
											<input
												v-model="FormaPagamentoCadastrado.qtdDiasPrazo"
												:value="true"
												id="cartaoPrazo"
												type="checkbox"
												:disabled="!FormaPagamentoCadastrado.cartao"
											/>
											<label for="cartaoPrazo" class="check"></label>
											<span v-if="FormaPagamentoCadastrado.qtdDiasPrazo"
												>Crédito</span
											>
											<span v-else>Débito</span>
										</div>
									</div>
								</div>
							</div>
							<!-- CARTÃO END -->
							<!-- CONSORCIO STAR -->
							<div class="parametro"  v-if="!FormaPagamentoCadastrado.vistaPrazo">
								<div>
									<label for="consorcio">Consorcio</label>
								</div>
								<div class="checked">
									<div class="selecionar">
										<input
											v-model="FormaPagamentoCadastrado.consorcio"
											:value="true"
											@change="selecionarForma('consorcio')"
											id="consorcio"
											type="checkbox"
										/>
										<label for="consorcio" class="check"></label>
										<span v-if="FormaPagamentoCadastrado.consorcio"
											>SIM</span
										>
										<span v-else>NÃO</span>
									</div>
								</div>
							</div>
							<!-- CONSORCIO END -->
							<!-- PIX START -->
							<div class="row"  v-if="FormaPagamentoCadastrado.vistaPrazo">
								<div class="parametro">
									<div>
										<label for="cheque">PIX</label>
									</div>
									<div class="checked">
										<div class="selecionar">
											<input
												v-model="FormaPagamentoCadastrado.ehPix"
												:value="true"
												@change="selecionarForma('pix')"
												id="pix"
												type="checkbox"
											/>
											<label for="pix" class="check"></label>
											<span v-if="FormaPagamentoCadastrado.ehPix"
												>SIM</span
											>
											<span v-else>NÃO</span>
										</div>
									</div>
								</div>
								<div class="parametro" v-if="FormaPagamentoCadastrado.ehPix">
									<span @click="abrirConfiguracaoPix">Configurar PIX</span>
									<i class="fas fa-credit-card creditCard" @click="abrirConfiguracaoPix"></i>
								</div>
							</div>
							<!-- PIX END -->
							<!-- CHEQUE STAR -->
							<div class="parametro"  v-if="FormaPagamentoCadastrado.vistaPrazo">
								<div>
									<label for="cheque">Cheque</label>
								</div>
								<div class="checked">
									<div class="selecionar">
										<input
											v-model="FormaPagamentoCadastrado.cheque"
											:value="true"
											@change="selecionarForma('cheque')"
											id="cheque"
											type="checkbox"
										/>
										<label for="cheque" class="check"></label>
										<span v-if="FormaPagamentoCadastrado.cheque"
											>SIM</span
										>
										<span v-else>NÃO</span>
									</div>
								</div>
							</div>
							<!-- CHEQUE END -->
							<!-- DEPOSITO STAR -->
							<div class="parametro" v-if="FormaPagamentoCadastrado.vistaPrazo">
								<div>
									<label for="deposito">Antecipação</label>
								</div>
								<div class="checked">
									<div class="selecionar">
										<input
											v-model="FormaPagamentoCadastrado.deposito"
											:value="true"
											@change="selecionarForma('deposito')"
											id="deposito"
											type="checkbox"
										/>
										<label for="deposito" class="check"></label>
										<span v-if="FormaPagamentoCadastrado.deposito"
											>SIM</span
										>
										<span v-else>NÃO</span>
									</div>
								</div>
							</div>
							<!-- DEPOSITO END -->
						</fieldset>
					</div>
					<fieldset v-if="produtosConciliadora.length > 0">
						<legend>Produtos Conciliadora</legend>
						<table>
							<thead>
								<tr>
									<th>Cód</th>
									<th>Nome Produto</th>
									<th>Cód Modalidade</th>
									<th>Modalidade</th>
									<th>Ação</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(produtoconci, index) in produtosConciliadora" :key="produtoconci.codigo">
									<td style="color: var(--primario)">{{produtoconci.codigo}}</td>
									<td>{{produtoconci.nome}}</td>
									<td>{{produtoconci.codmodalidade}}</td>
									<td>{{produtoconci.modalidade}}</td>
									<td @click="solicitarDesvincularProduto(produtoconci, index)"><i class="fas fa-trash" style="color: var(--deletar)"></i></td>
								</tr>
							</tbody>
						</table>
					</fieldset>
				</div>
				<div class="overflow abaPlanoConta w-100" v-if="abaSelecionada == 'planoContas'">
					<div class="row" style="margin-top: 10px;">
						<div class="input-group" style="width: 100%;">
							<select
								id="planocontas" 
								v-model="FormaPagamentoCadastrado.planoContasFixo[1].cdplano">
								<option disabled value="SEL">
									SELECIONE UMA OPÇÃO
								</option>
								<option 
									v-for="plano in PlanoContasCompra"
									:key="plano.vid"
									:value="plano.vid"
									:disabled="plano.alterarValor === 0"
									:class="`${plano.alterarValor === 0 ? 'plano-disabled' : ''}`"
								>
									{{ plano.nome }}
								</option>
							</select>
							<label for="planocontas">Plano de contas da Compra</label>
						</div>
						<div class="input-group" style="width: 100%;">
							<select
								id="planocontasvenda" 
								v-model="FormaPagamentoCadastrado.planoContasFixo[0].cdplano">
								<option disabled value="SEL">
									SELECIONE UMA OPÇÃO
								</option>
								<option 
									v-for="plano in PlanoContasVenda"
									:key="plano.vid"
									:value="plano.vid"
									:disabled="plano.alterarValor === 0"
									:class="`${plano.alterarValor === 0 ? 'plano-disabled' : ''}`"
								>
									{{ plano.nome }}
								</option>
							</select>
							<label for="planocontasvenda">Plano de contas da Venda</label>
						</div>
					</div>
					<div class="row" style="margin-top: 10px;">
						<div class="input-group" style="width: 100%;">
							<select
								id="centroCustoCompra" 
								v-model="FormaPagamentoCadastrado.planoContasFixo[1].cdCentroCusto">
								<option disabled value="SEL">
									SELECIONE UMA OPÇÃO
								</option>
								<option 
									v-for="centro in CentroDeCustos"
									:key="centro.cdcusto"
									:value="centro.cdcusto"
								>
									{{ centro.descricao }}
								</option>
							</select>
							<label for="centroCustoCompra">Centro de Custo da Compra</label>
						</div>
						<div class="input-group" style="width: 100%;">
							<select
								id="centroCustovenda" 
								v-model="FormaPagamentoCadastrado.planoContasFixo[0].cdCentroCusto">
								<option disabled value="SEL">
									SELECIONE UMA OPÇÃO
								</option>
								<option 
									v-for="centro in CentroDeCustos"
									:key="centro.cdcusto"
									:value="centro.cdcusto"
								>
									{{ centro.descricao }}
								</option>
							</select>
							<label for="centroCustoVenda">Centro de Custo da Venda</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="botoes">
			<button @click="cancelarEdicao" class="botao botao-cancelar">
				<label for="botaoCancelar" class="lg-view">Cancelar [Alt + C]</label>
				<label for="botaoCancelar" class="sm-view">Cancelar</label>
			</button>
			<button
				@click="salvarFormaPagamento"
				class="botao botao-adicionar"
			>
				<label for="botaoAdicionar" class="lg-view">Salvar [Alt + S]</label>
				<label for="botaoAdicionar" class="sm-view">Salvar</label>
			</button>
		</div>
		<Modal @fecharModal="abrirModalProduto = false" v-if="abrirModalProduto">
			<div class="containerModalProduto">
				<div class="filtroPesquisa">
					<input type="text" placeholder="Pesquisa por nome" style="padding: 5px;" v-model="campoPesquisa" @input="filtrarProdutosConciliadora" />
				</div>
				<div class="containerTabela">
					<table v-if="listaproduto!=undefined">
						<thead>
							<tr>
								<th>Cód.</th>
								<th>Nome Produto</th>
								<th>Cód Modalidade</th>
								<th>Modalidade</th>
								<th>Adicionar</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="produto in listaproduto" :key="produto.id">
								<td style="color: var(--primario)">
									{{produto.codigo}}
								</td>
								<td>
									{{produto.nome}}
								</td>
								<td>
									{{produto.codmodalidade}}
								</td>
								<td>
									{{produto.modalidade}}
								</td>
								<td for="checkModalidades">
									<i class="far fa-plus-square" style="color: var(--salvar-texto);" @click="selecionarProduto(produto)"></i>
								</td>
							</tr>
						</tbody>
					</table>
					<div v-else class="col center" id="warning">
						<i class="fas fa-exclamation-triangle" style="color: yellow; font-size: 100pt"></i>
						<h1>Nenhum registro encontrado.</h1>
						<h2>Tente alterar ou diminuir os parâmetros de busca.</h2>
					</div>		
				</div>
				<div class="containerTabela" style="display:flex;flex-direction:column;justify-content:flex-start" v-if="listaProdutoSelecionado.length>0">
					<h1>Produtos Selecionado</h1>
					<table>
						<thead>
							<tr>
								<th>Cód.</th>
								<th>Nome Produto</th>
								<th>Cód Modalidade</th>
								<th>Modalidade</th>
								<th>Remover</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(produto, index) in listaProdutoSelecionado" :key="produto.id">
								<td style="color: var(--primario)">
									{{produto.codigo}}
								</td>
								<td>
									{{produto.nome}}
								</td>
								<td>
									{{produto.codmodalidade}}
								</td>
								<td>
									{{produto.modalidade}}
								</td>
								<td for="checkModalidades">
									<i class="fas fa-trash-alt" style="color: var(--cancelar-texto);" @click="removerProduto(index)"></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="flex row w-100 center">
				<div class="paginacao">
					<span @click="filtrarProdutosConciliadora(0)" v-if="paginacao.pageAtual > 1">1</span>
					<span @click="filtrarProdutosConciliadora(paginacao.page - 1)" v-if="paginacao.page > 0">
						{{paginacao.page}}
					</span>
					<span class="ativa">
						{{paginacao.page + 1}}
					</span>
					<span @click="filtrarProdutosConciliadora(paginacao.page + 1)" v-if="paginacao.page + 1 < paginacao.totalPages">
						{{paginacao.page + 2}}
					</span>
					<span @click="filtrarProdutosConciliadora(paginacao.totalPages - 1) " v-if="paginacao.page + 2 < paginacao.totalPages">
						{{paginacao.totalPages }}
					</span>
				</div>
				<div class="button" v-if="listaProdutoSelecionado.length>0">
					<button class="botao botao-adicionar" @click="adicionarSelecionados">Salvar</button>
				</div>
			</div>
		</Modal>
		<Modal v-if="modalConfigPix" @fecharModal="modalConfigPix = false" :dimensoes="{width: 'auto', height: 'auto'}">
			<div class="containerModalPix">
				<h2>Configuração de Emissor do Pix</h2>
				<div class="contentPix">
					<div class="pixLinha">
						<div class="input-group">
							<input type="text" id="nomeRecebedor" v-model="form.nomeRecebedor">
							<label for="nomeRecebedor">Nome recebedor:</label>
						</div>
						<div class="campo">
							<div class="input-group">
								<label for="clienteId">Cliente Id:</label>
								<input type="text" id="clienteId" v-model="form.clienteid"/>
							</div>
							<div class="row" style="width: 100%; align-items: center;">
								<div class="tooltip">
									<span class="tooltiptext">O banco informará os dados do cliente</span>
									<i class="fas fa-info-circle infobtn" style="color: var(--primario)"></i>
								</div>
								<div class="input-group" style="width: 100%;">
									<input type="text" id="clienteSecret" v-model="form.clientesecret" placeholder=" "/>
									<label for="clienteSecret">Cliente Secret:</label>
								</div>
							</div>
						</div>
						<div class="campo" style="justify-content: flex-start;">
							<div class="input-group">
								<select v-model="form.selectTipoChave">
									<option value="tchEmail">EMAIL</option>
									<option value="tchCPF">CPF</option>
									<option value="tchCNPJ">CNPJ</option>
									<option value="tchCelular">CELULAR</option>
									<option value="tchAleatoria">CHAVE ALEATORIA</option>
								</select>
								<label>Tipo Chave:</label>
							</div>
							<div class="input-group" style="width: 100%;">
								<input type="text" id="chavepix" v-model="form.inputChavePix" placeholder=" "/>
								<label for="chavepix">Chave Pix:</label>
							</div>
						</div>
						<div class="campo" style="justify-content: flex-start;">
							<div class="input-group">
								<select v-model="form.selectBanco">
									<option value="-1">Selecione o banco</option>
									<option value="0">Banco do Brasil</option>
									<option value="1">Itaú</option>
									<option value="2">Santander</option>
									<option value="3">Sicredi</option>
									<option value="4">Sicob</option>
								</select>
								<label>Banco:</label>
							</div>
							<div class="tooltip">
								<span class="tooltiptext">Contate o banco para adiquirir a chave</span>
								<i class="fas fa-info-circle infobtn" style="color: var(--primario)" v-if="(form.selectBanco == 0)"></i>
							</div>
							<div class="input-group" v-if="(form.selectBanco == 0)" style="width: 100%;">
								<input id="chaveAplicacao" type="text" placeholder=" " v-model="form.applicationkey"/>
								<label for="chaveAplicacao">Chave da Aplicação:</label>
							</div>
						</div>

					</div>
				</div>
				<div class="botoes" style="margin-top: 10px;">
					<button class="botao botao-cancelar" @click="modalConfigPix = false">
						Cancelar Cadastro
					</button>
					<button class="botao botao-acao" @click="cadastrarEmissor">
						Cadastrar
					</button>
				</div>
			</div>
		</Modal>
	</div>
</template>
<script>
import Modal from "./Modal.vue";

class FormaPagamentoComponent {
	constructor(forma) {
		this.cdtipo = forma.cdtipo;
		this.descricao = forma.descricao;
		this.ativo = forma.ativo;
		this.mostraVenda = forma.mostravenda == "S";
		this.mostraRecebimento = forma.mostrarecebimento == "S";
		this.mostraCompra = forma.mostracompra == "S";
		this.deposito = forma.deposito == "S";
		this.calculaJuros = forma.calculaJuros == "S";
		this.permiteConsumidorFinal = forma.permiteconsumidorfinal == "S";
		this.verificaSituacao = forma.verificaSituacao == "S";
		this.cartao = forma.cartao == "S";
		this.consorcio = forma.consorcio == "C";
		this.cheque = forma.cheque == "S";
		this.vistaPrazo = forma.vistaprazo == 1;
		this.ehPix = forma.ehPix == "S";
		this.qtdeParcela = forma.qtdeParcela;
		this.qtdDiasPrazo = forma.quantidadediasdeprazo === 1 ? true : false;
		this.planoContasFixo = [forma.planoContaFixoVenda.length != 0? 
			{cdTipoPagamento: forma.cdtipo, cdplano: forma.planoContaFixoVenda[0].idPlanoConta, percentual: 100, tipo: "S", cdCentroCusto:  (forma.planoContaFixoVenda[0].cdcentrocusto ? forma.planoContaFixoVenda[0].cdcentrocusto : "SEL"), cdempresa: forma.cdempresa, id: (forma.planoContaFixoVenda[0].id? forma.planoContaFixoVenda[0].id : null)} : 
			{cdTipoPagamento: forma.cdtipo, cdplano: "SEL", percentual: 100, tipo: "S", cdCentroCusto: "SEL", cdempresa: forma.cdempresa}];
		this.planoContasFixo[1] = forma.planoContaFixoCompra.length != 0? 
			{cdTipoPagamento: forma.cdtipo, cdplano: forma.planoContaFixoCompra[0].idPlanoConta, percentual: 100, tipo: "E", cdCentroCusto: (forma.planoContaFixoCompra[0].cdcentrocusto ? forma.planoContaFixoCompra[0].cdcentrocusto : "SEL"), cdempresa: forma.cdempresa, id: (forma.planoContaFixoCompra[0].id? forma.planoContaFixoCompra[0].id : null)} : 
			{cdTipoPagamento: forma.cdtipo, cdplano: "SEL", percentual: 100, tipo: "E", cdCentroCusto: "SEL", cdempresa: forma.cdempresa};
	}
}
export default {
	name: "CadastroFormaPagamento",
	props: {
		FormaPagamento: Object,
		alteraFormaPagamento: Object
	},
	components: {Modal},
	data() {
		return {
			abaSelecionada: "parametros",
			listaProdutoSelecionado:[],
			FormaPagamentoCadastrado: {
				cdtipo: null,
				calculaJuros: false,
				cartao: false,
				consorcio: false,
				deposito: false,
				descricao: "",
				mostraCompra: false,
				mostraRecebimento: true,
				mostraVenda: true,
				permiteConsumidorFinal: true,
				verificaSituacao: false,
				vistaPrazo: 1,
				cheque: false,
				ativo: "A",
				qtdeParcela: 1,
				qtdDiasPrazo: 1,
				planoContasFixo: [
					{
						cdTipoPagamento: null,
						cdplano: "SEL",
						percentual: 100,
						tipo: "S",
						cdCentroCusto: "SEL",
						cdempresa: this.$store.state.Empresa.id,
					},
					{
						cdTipoPagamento: null,
						cdplano: "SEL",
						percentual: 100,
						tipo: "E",
						cdCentroCusto: "SEL",
						cdempresa: this.$store.state.Empresa.id,
					}
				]
			},
			produtosSelecionados: [],
			abrirModalProduto: false,
			campoPesquisa: "",
			selecionou: false,
			produtosConciliadora: [],
			modalConfigPix: false,
			form: {
				selectBanco: -1,
				selectTipoChave: "tchEmail",
				inputChavePix: "",
				applicationkey: "",
				nomeRecebedor: `${this.$store.state.Empresa.fantasia}`
			}
		};
	},
	computed: {
		CentroDeCustos(){
			return this.$store.state.CentroCustos.Lista;
		},
		PlanoContasCompra(){
			return this.$store.state.PlanoContas.ListaPlanoContasVisualizacao.filter(pc => 
				pc.tconta === "(-)");
		},
		PlanoContasVenda(){
			return this.$store.state.PlanoContas.ListaPlanoContasVisualizacao.filter(pc => 
				pc.tconta === "(+)");
		},
		paginacao(){
			return this.$store.state.Conciliadora.paginacao;
		},
		listaproduto(){
			return this.$store.state.Conciliadora.conteudo;
		},
		formaspagamento() {
			return this.$store.state.formaspagamento;
		},
		Empresas() {
			return this.$store.state.Empresas;
		}
	},
	methods: {
		mudarAba(aba){
			if(aba == "planoContas" && !this.$store.state.PlanoContas.ListaPlanoContasVisualizacao.length || this.$store.state.PlanoContas.ListaPlanoContasVisualizacao.length < 1){
				return this.$store.commit("relatarErro", {mensagem: "Nenhum plano de contas cadastrado! Cadastre um plano de contas antes de acessar a aba Financeiro!", alerta: true});
			}
			this.abaSelecionada = aba;
		},
		selecionarProduto(p){
			let jaAdicionado = false;
			this.listaProdutoSelecionado.forEach(e=>{
				if(e.id===p.id){
					jaAdicionado=true;
				}
			});
			if(jaAdicionado){
				this.$store.commit("relatarErro", {mensagem: "Este item já foi adicionado aos produtos selecionados!"});
			}else{
				this.listaProdutoSelecionado.push(p);
			}
		},
		removerProduto(index){
			this.listaProdutoSelecionado.splice(index,1);
		},
		async cadastrarEmissor(){
			try {
				if(this.form.selectBanco === -1 ) {
					throw new Error("Para cadastrar o Emissor é necessário informar o banco");
				} else if(!this.form.inputChavePix) {
					throw new Error("Para cadastrar o Emissor é necessário informar a chave pix");
				} else if (this.form.selectBanco === 0 && !this.applicationkey){
					throw new Error("Para cadastrar o Emissor é necessário informar a chave de aplicação (Caso tenha dúvidas de como pegar a chave consulte o Banco)");
				} else if (!this.form.clienteid){
					throw new Error("Para cadastrar o Emissor é necessário informar o cliente ID (O banco informará)");
				} else if (!this.form.clientesecret){
					throw new Error("Para cadastrar o Emissor é necessário informar o cliente secret (O banco informará)");

				}
				await this.$store.dispatch("cadastrarEmissor", {...this.form});
				this.modalConfigPix = false;
				this.form = {};
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},
		abrirConfiguracaoPix(){
			this.modalConfigPix = true;
		},
		async filtrarProdutosConciliadora(novaPage){
			clearTimeout(this.timer);
			this.timer = setTimeout( async() => {
				let filtro = {
					page: novaPage >= 0 ? novaPage : this.paginacao.page,
					size: this.paginacao.size ? this.paginacao.size : 25,
					query: this.campoPesquisa,
				};
				await this.$store.dispatch("buscarProdutosConciliadora", {...filtro});
			}, 1000);
		},
		async selecionarProdutoConciliadora() {
			this.abrirModalProduto = true;
			await this.$store.dispatch("buscarProdutosConciliadora");
		},
		mudarAtivo(campo, letra1, letra2) {
			if (this.FormaPagamentoCadastrado[campo] == letra1) {
				this.FormaPagamentoCadastrado[campo] = letra2;
			} else {
				this.FormaPagamentoCadastrado[campo] = letra1;
			}
		},
		setUpperCase(e) {
			this.FormaPagamentoCadastrado[
				e.target.id
			] = this.FormaPagamentoCadastrado[e.target.id].toUpperCase();
		},
		setOnlyNumbers(e) {
			const regex = /[0-9,]/;
			if (!regex.test(e.data) && e.data != null) {
				this.FormaPagamentoCadastrado[
					e.target.id
				] = this.FormaPagamentoCadastrado[e.target.id].substring(
					0,
					this.FormaPagamentoCadastrado[e.target.id].length - 1
				);
			}
		},
		async salvarFormaPagamento() {
			if (!this.FormaPagamentoCadastrado.descricao) {
				return this.$store.commit("relatarErro", {
					mensagem:
						"Campo descrição da forma de pagamento não pode estar vazio"
				});
			}
			if(this.FormaPagamentoCadastrado.planoContasFixo[0].cdplano != "SEL" && this.FormaPagamentoCadastrado.planoContasFixo[0].cdCentroCusto == "SEL"){
				return this.$store.commit("relatarErro", {
					mensagem:
						"Campo centro de custo da venda não pode estar vazio quando houver plano de contas de venda!"
				});
			}
			if(this.FormaPagamentoCadastrado.planoContasFixo[1].cdplano != "SEL" && this.FormaPagamentoCadastrado.planoContasFixo[1].cdCentroCusto == "SEL"){
				return this.$store.commit("relatarErro", {
					mensagem:
						"Campo centro de custo da compra não pode estar vazio quando houver plano de contas de compra!"
				});
			}
			if (this.FormaPagamento && this.FormaPagamento.novo) {
				this.$store.dispatch("cadastrarFormasPagamento", {
					...this.FormaPagamentoCadastrado
				});
				setTimeout(() => {
					this.$emit("cancelarEdicao");
				},500);
				return;
			}
			if (
				JSON.stringify(this.FormaPagamentoCadastrado) !=
				JSON.stringify(this.FormaPagamento)
			) {
				this.$emit("cancelarEdicao");
				return this.$store.dispatch("atualizarFormasPagamento", {
					...this.FormaPagamentoCadastrado
				});
			}
		},
		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
		async adicionarSelecionados(){
			const produtosSelecionados = [];
			this.listaProdutoSelecionado.forEach(produto => {
				produtosSelecionados.push(`${produto.id}`);
			});
			const data = await this.$store.dispatch("vincularCdtipoProdutos", {produtosSelecionados, cdtipo: this.FormaPagamentoCadastrado.cdtipo});
			if(data){
				if(data.erro && data.mensagem){
					return this.$store.commit("relatarErro", {mensagem: data.mensagem});
				}
				if(!data.erro && data.mensagem){
					this.listaProdutoSelecionado.forEach(produto => {
						const existe = this.produtosConciliadora.find(prod => prod.codigo == produto.codigo);
						if(existe){
							return;
						}
						this.produtosConciliadora.push({...produto});
					});
					this.abrirModalProduto = false;
					this.listaProdutoSelecionado = [];
					return this.$store.commit("relatarErro", {mensagem: data.mensagem, sucess: true});
				}
			}

			this.abrirModalProduto = false;
		},
		async buscarProdConciliadora(){
			this.produtosConciliadora = await this.$store.dispatch("buscarProdutosConciliadora", {cdtipo: this.FormaPagamentoCadastrado.cdtipo});
		},
		solicitarDesvincularProduto(produto, index){
			this.$store.commit("relatarErro", {mensagem: `Tem certeza que deseja desvincular o produto ${produto.id} - ${produto.nome}`,
				tipo: "polar",
				item: {produto, index}
			});
		},
		async polarConfirmado(item){
			if(item.produto){
				const data = await this.$store.dispatch("desvincularProdutoConciliadora", {cdtipo: this.FormaPagamento.cdtipo, codigo: item.produto.codigo});
				this.produtosConciliadora.splice(item.index, 1);
				if(data.mensagem && !data.erro){
					this.$store.commit("relatarErro", {mensagem: data.mensagem, sucess: true});
				}
			}
		},
		async buscaFormaPagamento(id){
			return await this.$store.dispatch("buscarFormasPagamentoPorId", id);
		},
		selecionarForma(forma){
			if(forma == "vistaprazo"){
				this.FormaPagamentoCadastrado.cartao = false;
				this.FormaPagamentoCadastrado.consorcio = false;
				this.FormaPagamentoCadastrado.deposito = false;
				this.FormaPagamentoCadastrado.ehPix = false;
				this.FormaPagamentoCadastrado.cheque = false;
			}
			else{
				this.FormaPagamentoCadastrado.cartao = forma == "cartao" && this.FormaPagamentoCadastrado.cartao;
				this.FormaPagamentoCadastrado.consorcio = forma == "consorcio" && this.FormaPagamentoCadastrado.consorcio;
				this.FormaPagamentoCadastrado.deposito = forma == "deposito" && this.FormaPagamentoCadastrado.deposito;
				this.FormaPagamentoCadastrado.ehPix = forma == "pix" && this.FormaPagamentoCadastrado.ehPix;
				this.FormaPagamentoCadastrado.cheque = forma == "cheque" && this.FormaPagamentoCadastrado.cheque;
			}			
		}

	},
	mounted() {
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		setTimeout(async()=>{
			let formaPagamentoAtualizada = await this.buscaFormaPagamento(this.FormaPagamento.cdtipo);
			this.$store.dispatch("buscarPlanoContasVisualizar");
			this.$store.dispatch("buscarCentroCusto",{descricao:""});
			if (this.FormaPagamento) {
				if (this.FormaPagamento.novo) {
					return;
				}
				formaPagamentoAtualizada.cdempresa = this.$store.state.Empresa.id;
				this.FormaPagamento.cdempresa = this.$store.state.Empresa.id;
				this.FormaPagamentoCadastrado = new FormaPagamentoComponent(
					formaPagamentoAtualizada ? formaPagamentoAtualizada : this.FormaPagamento
				);
				["cdtipo"].forEach(key => {
					document.getElementById(key).disabled = true;
				});
			}
		}, 40);
		if(this.FormaPagamento.cartao == "S"){
			this.buscarProdConciliadora();
		}
	},
	destroyed(){
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
	}
};
</script>
<style scoped>
.parametro{
	display: flex;
	flex-direction: column;
	padding-bottom: 9px;
	justify-content: flex-end;
}
.abaParametros{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
	font-size: 12px;
}
span{
	align-self: center;
}
thead tr th {
    font-size: 13pxt;
}
tbody tr {
	line-height: 20px;
}
tbody tr td {
	font-size: 13px;
}
.overflow{
	overflow-y: auto;
}
.input-group:nth-child(2){
	width: 50%;
}
.campos {
	margin: 5px 0;
}
#TelaConta {
	display: grid;
	width: 95%;
	margin: 0px auto;
	grid-template-columns: 1fr;
	grid-template-rows: 55px auto;
	grid-template-areas:
		"titulo"
		"cadastro";
}
.box-cadastro{
	overflow-y: auto;
	margin-bottom: 0px !important;
}
.titulo {
	height: 50px;
}
label {
	width: auto;
	white-space: nowrap;
	margin: 0 10px;
}
input,
select {
	width: 100%;
}
.displayFuncionalidade {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
	width: 100%;
}
.funcionalidade {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	border-radius: 15px;
	box-shadow: 6px 6px 3px 1px rgba(154 154 154 / 59%);
	width: 33.33%;
	margin: 6px auto;
	transition: ease-in 0.5s;
}
.funcionalidade:hover {
	transform: translateY(-2px);
}
.checked {
	display: flex;
	align-content: flex-start;
	justify-content: flex-start;
}
.selecionar {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.selecionar input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 40px;
	height: 20px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}
.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	transition: 0.5s;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}
.containerCard{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 70px;
}
.creditCard{
	color: var(--primario);
	font-size: 20px;
}

.containerModalProduto{
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;

}
.filtroPesquisa{
	margin-top: 5px;
}
.containerTabela{
	min-width: 1000px;
	display: flex;
	justify-content: center;
	height: 78vh;
	overflow-y: auto;
}
.containerTabela table{
	width: 100%;
	height: 20px;
	padding: 10px 10px;
}

.button{
	width: 50%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
}

.containerModalPix, .contentPix{
	display: flex;
	flex-direction: column;
	width: 100%;
}
.pixLinha{
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-top: 10px;
}

.filtroPesquisa{
	display: flex;
	flex-direction: row;
	gap: 15px;
}
.filtroPesquisa button{
	width: 100%;
}

.campo{
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.campo input{
	width: 100%;
}
.campo select{
	width: 150px;
}


.button button{
	width: 250px;
}

.buttons{
	width: 100%;
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.buttons button{
	width: 45%
}
fieldset {
	width: 100%;
	height: auto;
}
fieldset table{
	width: 100%;
}

fieldset table thead tr th,
fieldset table tbody tr td{
	text-align: center;
}

#qtdeParcela{
	width: 30% ;
}
.botoes {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;
	width: 100%;
}

.botoes .botao {
	width: 45%;
}
.lg-view {
	display: inline-block;
}

.sm-view {
	display: none;
}
.plano-disabled {
	color: #f00;
}
@media screen and (max-width: 768px){
	.input-group{
		margin: 5px;
	}
	.input-group:nth-child(2){
		width: auto;
	}
	.titulo{
		font-size: 13px;
		flex-wrap: wrap;
		width: 100vw;
	}
	.box-cadastro{
		width: 93vw;
	}
	.row{
		flex-direction: column;
	}
	.funcionalidade{
		width: 45%;
	}
	.campos{
		height: auto;
	}
	#ativo{
		flex-direction: row;
	}
	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}
}
@media screen and (max-width: 425px){
	.funcionalidade div label{
		flex-wrap: wrap;
		white-space: wrap;
	}
}
</style>
