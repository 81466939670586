<template>
	<div class="main between">
		<div class="aba-controle" id="abas" v-if="ListaProdutosFavoritos.length > 0">
			<div :class="`aba ${aba=='favoritos' ? 'abaSelecionada':''}`" @click="mudarAba('favoritos')">
				<h2>FAVORITOS</h2>
			</div>
			<div :class="`aba ${aba=='itens' ? 'abaSelecionada':''}`" @click="mudarAba('itens')">
				<h2>
					{{$route.query.baixarCondicional && !$store.state.inserirProduto ? "DEVOLUÇÃO" : "ITENS"}} 
					{{tipoVenda != "C" && ((!$route.query.baixarCondicional || !$route.query.alteracao) || 
					(!$store.state.Usuario.supervisor && !$store.state.Usuario.caixa)) ? "DA VENDA" : "DO CONDICIONAL"}}
				</h2>
				&nbsp;
				<div class="identificador-custo" style="font-size:30px" v-if="$store.state.Usuario.usuarioPrivilegiado && $store.state.Venda.tipoVenda != 'C'">
					<i @click="gerarCusto" class="fas fa-hand-holding-usd" :style="custo ? 'color:green' : 'color:red'"></i>
				</div>
			</div>
		</div>
		<div class="row w-100 center align" v-else>
			<h2>
				{{$route.query.baixarCondicional && !$store.state.inserirProduto ? "DEVOLUÇÃO" : "ITENS"}} 
				{{tipoVenda != "C" && ((!$route.query.baixarCondicional || !$route.query.alteracao) || 
				(!$store.state.Usuario.supervisor && !$store.state.Usuario.caixa)) ? "DA VENDA" : "DO CONDICIONAL"}}
			</h2>
			&nbsp;
			<div class="identificador-custo" style="font-size:30px" v-if="$store.state.Usuario.usuarioPrivilegiado && $store.state.Venda.tipoVenda != 'C'">
				<i @click="gerarCusto" class="fas fa-hand-holding-usd" :style="custo ? 'color:green' : 'color:red'"></i>
			</div>
		</div>
		<div v-if="aba=='favoritos'" class="w-100">
			<div class="col scrollable w-100" id="tabela-produto-scroll" style="height: 400px;">
				<div class="containerCards">
					<CardGrupoProduto 
						v-for="produto in ListaProdutosFavoritos" 
						:key="produto.id"
						:ativo="false"
						:item="produto"
						:type="'Produto'"
						@selecionouProduto="selecionouProduto"					
					/>
				</div>
			</div>
		</div>
		<div class="w-100"  style="height:60vh" v-else>
			<div class="col scrollable w-100 h-100" v-if="itens.length >= 1" id="tabela-produto-scroll">
				<table class="w-100">
					<thead>
						<tr>
							<td>Seq.</td>
							<td style="color: var(--primario)">Cód.</td>
							<td v-if="$route.query.baixarCondicional">Origem</td>
							<td v-if="$route.query.baixarCondicional">Retirada</td>
							<td>Produtos</td>
							<td>Qtd.</td>
							<td style="max-width: 100px">$ Unit.</td>
							<td class="center">SubTotal</td>
							<td>Tipo</td>
							<td class="center">
								Ação
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in itens" v-bind:key="index" :id="`js-${item.seq}`"
						:style="item.cdTipo == 'T' || item.situacao == 'D' ? 'color: var(--deletar);' : ''">
							<td>{{item.seq}}</td>
							<td :style="`color: var(${item.cdTipo == 'T' || item.situacao == 'D' ? '--deletar' : '--primario'});`"
							>{{item.produto.id}}</td>
							<td v-if="$route.query.baixarCondicional">{{item.vendaOrigem}}</td>
							<td v-if="$route.query.baixarCondicional">
								{{item.dataOrigem ? new Date(item.dataOrigem).toLocaleDateString("pt-BR") : ""}}
							</td>
							<td class="text-left">{{item.produto.descricao}} {{ item.ehPeso }}</td>
							<td @dblclick="solicitaAlterarQuantidade(item, index)" >
								<span v-if="!alterarQuantidade">{{(item.quantidade || 0).toLocaleString("pt-BR", {minimumFractionDigits: 3, maximumFractionDigits: 3})}}</span>
								<input type="number" :id="`js-troca-quant-${index}`" v-else min="1" :value="item.quantidade" @keydown="mascara"
								@keydown.enter="alterarQuantidadeTroca(index)" style="width: 60px;">
							</td>
							<td 
								style="text-align: right; max-width:100px;"
								@dblclick="solicitaAlterarValor(index)"
								@contextmenu.prevent="solicitaAlterarValor(index)"
							>
								<input
									type="text"
									id="js-valor-unitario"
									v-if="alterarValorUnit === index"
									@keydown.enter="alterarValorUnitario(index)"
									@focusout="alterarValorUnitario(index)"
									@keydown="mascara"
								>
								<span v-else>
									{{Number(item.valorUnitario) | formatarParaReais }}
								</span>
							</td>
							<td style="text-align: right; padding-right: 5px;">
								{{(Number(`${item.valorUnitario}`.replace(",", ".")) * item.quantidade) | formatarParaReais}}</td>
							<td class="center text-nowrap">
								<i class="fas fa-dollar-sign" v-show="item.cdTipo == 'S' && item.situacao != 'D'" style="color: var(--confirmar);"></i>
								<i class="fas fa-shopping-bag" v-show="item.cdTipo == 'C' && item.situacao != 'D'" style="color: var(--primario);"></i>
								<i class="fas fa-exchange-alt" v-show="item.cdTipo == 'T'" style="color: var(--deletar);"></i>
								<i class="fas fa-cart-arrow-down" v-show="item.situacao == 'D'" style="color: var(--deletar)"></i>
							</td>
							<td class="center">
								<i class="fas fa-pencil-alt" style="color: var(--confirmar)" @click="editarItem(item)" 
								@keydown.space="editarItem(item)" :tabindex="8+item.seq" 
								v-if="item.cdTipo != 'T' && item.situacao != 'D' && (!$route.query.baixarCondicional || $store.state.inserirProduto)">
							</i>
								<i 
									class="fas fa-trash-alt" 
									style="color: var(--deletar)" 
									@click="removerItem(item, index)"
									@keydown.space="removerItem(item, index)" 
									:tabindex="9+item.seq" 
									v-if="
										item.situacao != 'D' 
										&& ($route.query.baixarCondicional 
										// && ($store.state.Usuario.supervisor || $store.state.Usuario.caixa || !item.vendaOrigem) 
										|| (!$route.query.baixarCondicional))"></i>
								<i class="fas fa-cart-plus" v-show="item.situacao == 'D'" style="color: var(--confirmar)" 
								@click="adicionarItem(item, index)" @keydown.space="adicionarItem(item, index)" :tabindex="9+item.seq"></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-if="itens.length < 1" class="col center m-5">
				<i class="fas fa-cart-plus" style="font-size: 100px"></i>
				<h1>Insira os produtos {{tipoVenda != "C" ? "da venda" : "do condicional"}}</h1>
				<h3>{{"Hoje: " + new Date().toLocaleString("pt-BR")}}</h3>
				<label for="js-id-produto"><h3>Basta inserir produtos para começar.</h3></label>
			</div>
		</div>
		<div
			id="rodapeTabela"
			class="col p-5 w-100" 
		>
			<div 
				class="row between w-100 wrap"
			>
				<div class="col between flex-grow">
					<div 
						class="row between flex-grow mx-2"
					>
						<input 
							type="text"
							id="quantidade"
							class="inputControle"
							readonly
							disabled 
							:value="`${quantidadeItens} itens`"
						>
					</div>

					<div
						class="row between flex-grow mx-2"
					>
						<input 
							type="text"
							id="subTotal"
							class="inputControle"
							readonly
							disabled
							:value="`Sub Total: ${(subTotal || 0).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`"
						>
					</div>
				</div>
				<SeletorVistaPrazo
					v-bind:itens="itens"
					:valorReceber="valorReceber"
					v-on:recalcular="recalcularValores"
				/>
			</div>

			<div 
				id="total"
				class="row between w-100"
			>
				<input
					disabled
					type="text"
					id="totalGeral"
					class="inputControle"
					:value="`Total Geral: ${totalGeral.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`"
					@keydown.enter="finalizarVenda(itens)"
				>
			</div>
		</div>
		<Modal 
			v-if="inserirCusto" 
			@fecharModal="confirmarCusto" 
			:iconeFechar="true"
			:dimensoes="{width:'auto', height: 'auto'}"
			:idModal="'inserir-custo'"
		>
			<h3>Configurar Lançamento</h3>
			<div class="informacoes-custo-item">
				<label for="custo">
					<h3>Lançar no custo</h3>
				</label>
				<div class="checked">
					<div class="selecionar">
						<input 
							v-model="custo"
							:value="true"
							id="custo"
							type="checkbox"
							@change="mudarOpcao"
						>
						<label for="custo" class="check"></label>
						&nbsp;
						<label for="custo" v-if="custo">SIM</label>
						<label for="custo" v-else>NÃO</label>
					</div>
				</div>
			</div>
			<div class="informacoes-custo-item">
				<button class="botao botao-cancelar" @click="confirmarCusto" style="width: 100%;margin-top: 1px;">Fechar</button>
			</div>
		</Modal>
	</div>
</template>

<script>
import Modal from "../components/Modal.vue";
import SeletorVistaPrazo from "../components/SeletorVistaPrazo.vue";
import formatarParaReais from "../filters/formatarParaMoeda";
import CardGrupoProduto from "./CardGrupoProduto.vue";
export default {
	name: "TabelaProdutos",

	props: {
		itens: Array,
		valorReceber: Number,
	},
	components: {
		Modal,
		SeletorVistaPrazo,
		CardGrupoProduto,
	},

	data() {
		return {
			inserirCusto: false,
			custo: false,
			alterarQuantidade: false,
			alterarValorUnit: false,
			aba:"favoritos",
		};
	},
	
	computed: {	
		ListaProdutosFavoritos(){
			return this.$store.state.ProdutosFavoritos;
		},
		quantidadeItens() {
			let quantidade = 0;
			this.itens.forEach(item => {
				if (item.situacao != "D") {
					quantidade += (item.quantidade || 0);
				}
			});
			return quantidade;
		},
		tipoVenda(){
			return !this.$store.state.Venda.tipoVenda ? !this.$route.query.tipoVenda ?
				"S" : this.$route.query.tipoVenda : this.$store.state.Venda.tipoVenda;
		},

		totalVista(){
			let preco = 0;
			this.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D"){
					preco += Number(Number(((item.produto.promocaoVista && !this.$route.query.trocarProdutos && this.$route.query.tipoVenda != "C") ? (item.produto.valorPromocaoVista) : (item.produto.valorVista)) * item.quantidade).toFixed(2));
				} else if (item.cdTipo == "T") {
					preco -= Number(Number(Number(`${item.valorUnitario}`.replace(",", ".")) * item.quantidade).toFixed(2));
				}
			});
			return preco;
		},

		totalPrazo(){
			let preco = 0;
			this.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D"){
					preco += Number(Number(((item.produto.promocaoPrazo && !this.$route.query.trocarProdutos && this.$route.query.tipoVenda != "C") ? (item.produto.valorPromocaoPrazo) : (item.produto.valorPrazo)) * item.quantidade).toFixed(2));
				} else if (item.cdTipo == "T") {
					preco -= Number(Number(Number(`${item.valorUnitario}`.replace(",", ".")) * item.quantidade).toFixed(2));
				}
			});
			return preco;
		},

		subTotal(){
			let preco = 0;
			const { valorVista } = this.$store.state.Venda;
			const { alteracao } = this.$route.query;
			this.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D"){
					if(!valorVista) {
						const temPromocao = item.cdpromocao;
						const promocaoPrazo = item?.produto?.promocaoPrazo;
						const vlrPromocao = item?.produto?.valorPromocaoPrazo;
						const vlrPrazo = alteracao ? item.valorUnitario : item?.produto.valorPrazo;
						if(temPromocao) {
							preco += Number(
								(promocaoPrazo ? (vlrPromocao) : vlrPrazo) * item.quantidade
							);
						} else {
							preco += Number(
								vlrPrazo * item.quantidade
							);
						}
					} else {
						const temPromocao = item?.cdpromocao;
						const promocaoVista = item?.produto?.promocaoVista;
						const vlrPromocao = item?.produto?.valorPromocaoVista;
						const vlrVista = alteracao ? item.valorUnitario : item?.produto?.valorVista;

						if(temPromocao) {
							preco += Number(
								(promocaoVista ? vlrPromocao : vlrVista) * item.quantidade
							);
						} else {
							preco += Number(
								vlrVista * item.quantidade
							);
						}
					}
				} else if (item.cdTipo == "T") {
					preco -= Number(Number(Number(`${item.valorUnitario}`.replace(",", ".")) * item.quantidade));
				}
			});
			return preco;
		},

		totalGeral() {
			let preco = 0;
			this.itens.forEach(item => {
				if (item.cdTipo != "T" && item.situacao != "D") {
					preco += Number(Number(Number(`${item.valorUnitario}`.replace(",", ".")) * item.quantidade));
				} else if (item.cdTipo == "T") {
					preco -= Number(Number(Number(`${item.valorUnitario}`.replace(",", ".")) * item.quantidade));
				}
			});
			return preco;
		},
	},
	filters: {
		formatarParaReais
	},
	methods: {
		mudarAba(aba){
			if(this.$store.state.produtoSelecionado && this.aba == "itens" && aba == "favoritos"){
				return this.$store.commit("relatarErro", {
					mensagem: "Item em edição por favor concluir edição antes de mudar de aba."
				});
			}
			this.aba = aba;
		},
		selecionouProduto(item){
			return this.$emit("selecionarFavorito",item);
		},
		gerarCusto(){
			this.inserirCusto = true;
			if(this.$store.state.editarProduto){
				this.$store.state.editarProduto = false;
				this.$store.state.produtoSelecionado = null;
				document.getElementById("tipoVenda") ? document.getElementById("tipoVenda").style.display = "block" : null;
				document.getElementById("js-id-produto").readOnly = false;
				document.getElementById("js-id-produto").disabled = false;
				document.getElementById("js-btn-cancelar").style.display = "none";
				document.getElementById("btn-baixa") ? document.getElementById("btn-baixa").style.display = "block" : null;
				document.getElementById("js-id-produto").focus();
				this.$bus.$emit("selecionarItem", {id: ""});

			}
		},
		confirmarCusto(){
			this.$store.dispatch("buscarClassificacao");

			this.inserirCusto = false;
		},
		mudarOpcao(){
			this.$store.state.LancarCusto = this.custo;
			for(let pos in this.$store.state.itensVenda){
				if (this.$store.state.itensVenda[pos].cdTipo != "S") return;
				if(this.$store.state.LancarCusto) {
					if(this.$store.state.itensVenda[pos].custofixo == null){
						this.$store.state.itensVenda[pos].custofixo = 0;
					}
					this.$store.state.itensVenda[pos].produto.valorVistaAnterior = this.$store.state.itensVenda[pos].produto.valorVista;
					this.$store.state.itensVenda[pos].produto.valorPrazoAnterior = this.$store.state.itensVenda[pos].produto.valorPrazo;
					this.$store.state.itensVenda[pos].valorUnitarioAnterior = this.$store.state.itensVenda[pos].valorUnitario;
					this.$store.state.itensVenda[pos].produto.valorVista = this.$store.state.itensVenda[pos].custofixo || this.$store.state.itensVenda[pos].custo;
					this.$store.state.itensVenda[pos].produto.valorPrazo = this.$store.state.itensVenda[pos].custofixo || this.$store.state.itensVenda[pos].custo;
					this.$store.state.itensVenda[pos].valorUnitario = this.$store.state.itensVenda[pos].custofixo || this.$store.state.itensVenda[pos].custo;
				} else {
					this.$store.state.itensVenda[pos].produto.valorVista = this.$store.state.itensVenda[pos].produto.valorVistaAnterior || this.$store.state.itensVenda[pos].produto.valorVista;
					this.$store.state.itensVenda[pos].produto.valorPrazo = this.$store.state.itensVenda[pos].produto.valorPrazoAnterior || 	this.$store.state.itensVenda[pos].produto.valorPrazo;
					this.$store.state.itensVenda[pos].valorUnitario = this.$store.state.itensVenda[pos].valorUnitarioAnterior || this.$store.state.itensVenda[pos].valorUnitario;
				}
			}
		},
		mascara(evento) {
			const id = evento.target.id;
			if (!id) return console.log("Falta o id para mascara!");
			if (id == "js-valor-unitario") {
				const regex = /[0-9,]/;
				const regexKey = [
					"Backspace",
					"ArrowRight",
					"ArrowLeft",
					"Delete",
					"End",
					"Home",
					"Tab"
				];
				if (!regex.test(evento.key) && !regexKey.includes(evento.key)) {
					evento.preventDefault();
				}
				if ((`${evento.target.value}`.includes(",") || !`${evento.target.value}`.length) && evento.key == ","){
					evento.preventDefault();
				}
			}
		},
		editarItem(item){
			this.$emit("selecionarItem", {id: item.produto.id, ...item});
			this.$bus.$emit("selecionarItem", {id: item.produto.id, quantidade: item.quantidade});
		},

		removerItem(item, index){
			const remover = this.$store.commit("relatarErro", {
				tipo: "polar",
				mensagem: `Deseja realmente remover o produto ${item.produto.descricao}`,
				item: {
					removerProduto: true,
					index
				}
			});
			if (!remover){
				return;
			}
			if (item.cdTipo != "T") {
				this.$emit("removerItem", index);
			} else {
				this.itens[index].quantidade--;
			}
			this.$store.state.produtoSelecionado = null;
		},

		recalcularValores(formaPagamento){
			const inputVista = document.getElementsByName("formaPagamento")[1];
			const inputPrazo = document.getElementsByName("formaPagamento")[0];
			const { alteracao } = this.$route.query;
			const temTroca = this.$route.query.trocarProdutos;
			const processItem = (item, valorPromocao, valorNormal ) => {
				// SE TEM PROMOCAO E NAO TEM ALTERACAO DO VALOR UNITARIO A SOMA DEVE SER FEITA PELO VALORPROMOCAOPRAZO
				// SE TEM PROMOCAO E TEM ALTERACAO NO VALOR UNITARIO A SOMA DEVE SER FEITA PELO VALOR UNITARIO DO PRODUTO
				// SE NAO TEM PROMOCAO E TEM ALTERACAO NO VALOR UNITARIO A SOMA DEVE SER FEITA PELO VALOR UNITARIO DO PRODUTO
				// SE NAO TEM PROMOCAO E NAO TEM ALTERACAO NO VALOR UNITARIO A SOMA DEVE SER FEITA PELO VALOR DO CADASTRO DO PRODUTO
				if(alteracao) {
					// COM PROMOCAO, SEM ALTERACAO NO VALORUNIT, NA ALTERACAO O VALOR UNITARIO VOLTA COM O CADASTRO DO PRODUTO
					// COM PROMOCAO, ALTERANDO VALOR UNIT, NA ALTERACAO VALOR UNITARIO VEM COM O VALOR ALTERADO
					const vlrUnitario = item.valorUnitario;
					const promocaoAplicavel = item.cdpromocao && valorPromocao > 0 && !temTroca;
					const alterouVlrUnitario = (vlrUnitario !== item.produto.valorVista) && vlrUnitario !== item.produto.valorPrazo;

					if(promocaoAplicavel && alterouVlrUnitario) {
						const vlrPromo = this.$store.state.Venda.valorVista ? item.produto.valorPromocaoVista : item.produto.valorPromocaoPrazo;
						if(vlrUnitario === vlrPromo) {
							item.valorUnitario = valorPromocao;
						}
					}
					if(promocaoAplicavel && !alterouVlrUnitario) {
						item.valorUnitario = valorPromocao;
					} else if(!promocaoAplicavel && !alterouVlrUnitario) {
						item.valorUnitario = valorNormal;
					}


				} else {
					const promocaoAplicavel = item.cdpromocao && valorPromocao > 0 && !temTroca;
					const vlrUnitario = item.valorUnitario;
					const alterouVlrUnitario = !promocaoAplicavel 
						? (vlrUnitario !== item.produto.valorVista) && vlrUnitario !== item.produto.valorPrazo
						: vlrUnitario !== item.produto.valorPromocaoVista && vlrUnitario !== item.produto.valorPromocaoPrazo;
					if(promocaoAplicavel) {
						item.valorUnitario = valorPromocao;
					} else if(promocaoAplicavel && alterouVlrUnitario) {
						return;
					} else if(!promocaoAplicavel && alterouVlrUnitario) {
						return;
					} else {
						item.valorUnitario = valorNormal;
					}
				}


				// const promocaoAplicavel = item.produto[tipoPromocao] && !temTroca;
				// const alterouVlrUnitario = (
				// 	(item.produto.valorVista !== item.valorUnitario) && (item.produto.valorPrazo !== item.valorUnitario) 
				// 	// && !promocaoAplicavel
				// );
				// if(alterouVlrUnitario && alteracao) return;
				// if(!alteracao && promocaoAplicavel) {
				// 	item.valorUnitario = valorPromocao;
				// } else if (alteracao && promocaoAplicavel && !alterouVlrUnitario) {
				// 	item.valorUnitario = valorPromocao;
				// } else {
				// 	item.valorUnitario = valorNormal;
				// }
			};

			if (formaPagamento === "vista"){
				const condicional = this.itens.find(item => item.cdTipo == "C");
				if (condicional) {
					this.$store.commit("relatarErro", {mensagem: "Não é possível vender condicional a vista."});
					return setTimeout(() => {
						inputPrazo.checked = true;
					}, 10);
				}
				this.itens.forEach(item => {
					const vlrPromocao = item.produto.valorPromocaoVista || 0;
					const valorNormal = item.produto.valorVista || item.produto.vlrvista;
					if (item.cdTipo == "S") {
						processItem(item, vlrPromocao, valorNormal, "valorPromocaoVista");
					}
				});
				
				if (!inputVista.checked){
					inputVista.checked = true;
				}
				this.$store.state.Venda.valorVista = true;
			} else {
				this.itens.forEach(item => {
					const vlrPromocao = item.produto.valorPromocaoPrazo || 0;
					const valorNormal = item.produto.valorPrazo || item.produto.pontoequi;

					if (item.cdTipo == "S") {
						processItem(item, vlrPromocao, valorNormal, "valorPromocaoPrazo");
					}
				});
				if (!inputPrazo.checked) {
					inputPrazo.checked = true;
				}
				this.$store.state.Venda.valorVista = false;
			}
		},

		finalizarVenda(itensVenda){
			this.$emit("finalizarVenda", itensVenda);
		},

		solicitaAlterarQuantidade(item, index) {
			if (item.cdTipo != "T" || item.quantidade < 2) {
				return;
			}
			this.alterarQuantidade = true;
			setTimeout(() => {
				document.getElementById(`js-troca-quant-${index}`).focus();
				document.getElementById(`js-troca-quant-${index}`).select();
			}, 20);
		},

		alterarQuantidadeTroca(index) {
			const jsQuant = document.getElementById(`js-troca-quant-${index}`);
			if (!jsQuant.value) {
				return setTimeout(() => this.$store.commit("relatarErro", {mensagem: "Quantidade de devolução não informada."}));
			}
			if (Number(jsQuant.value) > this.itens[index].quantidade) {
				return setTimeout(() => this.$store.commit("relatarErro", {mensagem: "A quantidade não pode ser maior que a atual, inicie novamente a troca."}));
			}
			this.itens[index].quantidade = Number(jsQuant.value);
			this.alterarQuantidade = false;
		},

		solicitaAlterarValor(index) {
			if (this.itens[index].situacao == "D") return;
			if (this.itens[index].cdTipo == "T" && !this.$store.state.Usuario.multiTroca) return;
			this.alterarValorUnit = index;
			setTimeout(() => {
				document.getElementById("js-valor-unitario").value = this.itens[index].valorUnitario.toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(".", "");
				document.getElementById("js-valor-unitario").focus();
				document.getElementById("js-valor-unitario").select();
			}, 10);
		},

		alterarValorUnitario(index) {
			try {
				let jsValorUnitario = document.getElementById("js-valor-unitario");
				if (!jsValorUnitario.value) {
					throw new Error("Valor unitário não informado.");
				}
				jsValorUnitario = Number(`${jsValorUnitario.value}`.replace(".","").replace(",", "."));
				if ((jsValorUnitario < this.itens[index].valorUnitario) && this.itens[index].cdTipo != "T") {
					if (!this.$store.state.Usuario.usuarioPrivilegiado) {
						throw new Error("O valor não pode ser menor que o anterior.");
					}
				} else if ((jsValorUnitario > this.itens[index].valorUnitario) && this.itens[index].cdTipo == "T") {
					if (!this.$store.state.Usuario.usuarioPrivilegiado) {
						throw new Error("O valor não pode ser maior que o anterior.");
					}
				}
				// COMENTEI AQUI AGORA
				if(this.itens[index]?.cdpromocao){
					this.itens[index].produto.valorPromocaoVista = jsValorUnitario;
					this.itens[index].produto.valorPromocaoPrazo = jsValorUnitario;
				}
				this.itens[index].valorUnitario = jsValorUnitario;
				this.itens[index].produto.valorVista = jsValorUnitario;
				this.itens[index].produto.valorPrazo = jsValorUnitario;
				this.alterarValorUnit = false;
				setTimeout(() => {
					document.getElementById("js-id-produto").focus();
				}, 20);
			} catch (error) {
				setTimeout(() => {
					this.alterarValorUnit = false;
					this.$store.commit("relatarErro", {mensagem: error.message});
				},20);
			}
		},

		polarConfirmado(item) {
			if (item.cliente && item.valorBruto && !item.liberarCliente) {
				return this.$router.push({name: "Fechamento", query: this.$route.query});
			}
			if (item.removerProduto) {
				this.$emit("removerItem", item.index);
			}
		},
		polarCancelado(item) {
			if (!item) return;
			if (item.cliente && item.valorBruto && !item.liberarCliente) {
				return this.$store.dispatch("inserirVendaSemPagamento", {...item});
			}
		},

		adicionarItem(item, index) {
			this.$store.state.itensVenda[index].situacao = "A";
		}
	},

	watch: {
		quantidadeItens(newQtd, oldQtd) {
			if(newQtd>oldQtd){
				this.aba = "itens";
			}
		},
	},

	created() {
		this.$bus.$on("confirmarPolar", this.polarConfirmado);
		this.$bus.$on("cancelarPolar", this.polarCancelado);
		this.$bus.$on("recalcularValores", this.recalcularValores);
	},

	mounted(){
		this.$store.dispatch("buscarFavoritos").then(()=>{
			if(this.ListaProdutosFavoritos.length < 1 || this.$route.query.baixarCondicional){
				this.aba = "itens";
			}
		});
		this.custo = this.$store.state.LancarCusto || false;
		if (((this.$store.state.ClientePadrao.id != this.$store.state.Venda.cliente.id) || this.$store.state.Venda.somenteVista) && (this.$store.state.Venda.tipoVenda != "C")){
			this.recalcularValores("vista");
		} else {
			this.recalcularValores("prazo");
		}
		
	},

	destroyed(){
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
		this.$bus.$off("cancelarPolar", this.polarCancelado);
		this.$bus.$off("recalcularValores", this.recalcularValores);
	}
};
</script>
<style scoped>
.containerCards{
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 5px;
	width: 100%;
}
.aba-controle{
	height: 35px;
	min-height: 35px;
	max-height: 35px;
}
h2 {
	align-self: center;
	margin: 10px 0px;
	font-size: 20px;
}
.align{
	align-items: flex-start;
}
.identificador-custo .fas{
	font-size: 24px;
	color: var(--primario);
}
.informacoes-custo-item{
	display: flex;
	flex-direction: row;
	align-items: center;

}
.selecionar {
	display: flex;
	margin-left: 20px;
}
.check {
  display: block;
  position: relative;
  width: 60px;
  height: 30px;
  background: var(--branco);
  border: 1px solid var(--deletar);
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  transition: ease-in 0.5s;
}
.check:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  background: var(--deletar);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.5s;
}
input[type='checkbox']:checked ~ .check:before {
  transform: translateX(-50px);
}
.check:after {
  content: '';
  position: absolute;
  top: 4px;
  right: 4px;
  background: var(--confirmar);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.5s;
  transform: translateX(50px);
}
input[type='checkbox']:checked ~ .check:after {
  transform: translateX(0);
}
.checked{
	display: flex;
	align-content: center;
	justify-content: center;
}

input[type='checkbox']{
	-webkit-appearance: none;
	visibility: hidden;
	display: none;
}
input[type='checkbox']:checked ~ .check {
  border: 1px solid var(--confirmar);
}
.informacoes-custo-item .btn{
	margin: 5px auto;
	width: 100%;
}
.scrollable {
	background-color: var(--bg-app);
	box-shadow: var(--shadow-secundario);
	overflow: auto;
	max-width: 95vw;
}

td:nth-child(1),
td:nth-child(2),
td:nth-child(3) {
	max-width: 25%;
	text-align: center;
}

td:nth-child(6) {
	max-width: 30%;
	text-align: center;
}

td:nth-child(4) {
	text-align: center;
}

td:nth-child(5) {
	max-width: 50%;
}

thead td:nth-child(5) {
	text-align: center;
}

td:nth-child(6) {
	max-width: 50%;
}

td:nth-child(7) {
	text-align: center;
}

.fas.fa-cart-plus {
	color: black;
	font-size: var(--txt-medio);
}

.separador {
	margin: 5px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 33%;
	justify-content: space-around;
	align-items: center;
}

.separador h1 {
	margin: 5px;
	text-align: right;
}

.separador label {
	font-size: 14pt;
}

.valorFinal {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin: 2px;
}

.inputControle {
	padding-left: 5px;
	width: 100%;
	font-size: var(--txt-medio);
	text-align: right;
	margin-bottom: 5px;
}

#totalGeral {
	font-size: var(--txt-grande);
	text-align: right;
	font-weight: bolder;
} 

#caixaLivre {
	margin: auto 15px;
}

.text-left {
	text-align: left !important;
}

#js-valor-unitario {
	display: flex;
	height: 100%;
	width: 100%;
	border: none;
	margin: 0px;
	text-align: right;
	background-color: transparent;
}

.flex-grow {
	flex-grow: 1;
}

@media screen and (max-width: 1150px) and (min-width: 931px) {
	.row.between.m-2 {
		flex-direction: column;
	}
}

@media screen and (max-width: 768px) and (min-width: 676px) { 
	.col.between .row.w-50 .inputControle {
		width: 100%;
	}

	.col.between label h1{
		text-align: right;
	}

	.row.between.m-2 {
		flex-direction: row;
	}
}

@media screen and (max-width: 675px) { 
	.col.between .row.w-50 .inputControle {
		width: 100%;
	}

	.col.between label h1{
		text-align: right;
	}
}

@media screen and (max-width: 425px) {
	.row.center.w-100.my-5.botao-controle {
		flex-direction: column;
	}

	.row.center.w-100.my-5.botao-controle button {
		margin: 5px 0px;
	}
}


@media screen and (max-width: 380px) {
	* {
		font-size: var(--txt-pequeno) !important;
	}
}

@media screen and (max-height: 455px) {
	* {
		font-size: 10pt;
	}
}

</style>