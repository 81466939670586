import Venda from "../service/Venda.js";
import Financeiro from "../service/Financeiro.js";
import Usuario from "../service/Usuario.js";
import Vendedor from "../service/Vendedor.js";
import Empresa from "../service/Empresa.js";
import Cliente from "../service/Cliente.js";
import MetodosPagamento from "../service/MetodosPagamento.js";
import Produto from "../service/Produto.js";
import Caixa from "../service/Caixa.js";
import Cidade from "../service/Cidade.js";
import Contas from "../service/Conta.js";
import Relatorio from "../service/Relatorio.js";
import GrupoProduto from "../service/GrupoProduto.js";
import Bandeira from "../service/Bandeira.js";
import Cfop from "../service/Cfop.js";
import Grade from "../service/Grade.js";
import Promocao from "../service/Promocao.js";
import Entradas from "../service/Entradas.js";
import Consorcio from "../service/Consorcio.js";
import Perfil from "../service/Perfil.js";
import Transferencia from "../service/Transferencia.js";
import Log from "../service/Log.js";
import Pix from "../service/Pix.js";
import Conciliadora from "../service/Conciliadora.js";
import ContaPagar from "../service/ContaPagar.js";
import PrevisaoCompra from "../service/PrevisaoCompra.js";
import PlanoContas from "../service/PlanoContas.js";
import Agenda from "../service/Agenda.js";
import CentroCusto from "../service/CentroCusto.js";
import Dashboard from "../service/Dashboard.js";
import Ncm from "../service/Ncm.js";
import FluxoCaixa from "../service/FluxoCaixa.js";
// import SSE from "../service/SSE.js";
import {
	gerarReciboCredito,
	gerarCupom,
	gerarCupomPreVenda,
	gerarRecibo,
	gerarCupomTroca,
	gerarExtrato,
	relatorioFechamentoCaixa,
	relatorioVendedor,
	relatorioCaixa,
	gerarExtratoContasReceber,
	gerarRelatorioVenda,
	gerarCodigoBarrasProduto,
	gerarRelatorioMovimentoProduto,
	gerarComprovanteConsorcio,
	gerarRelatorioDesempenhoProduto,
	gerarRelatorioSaldoEstoque,
	gerarRelatorioCompras,
	gerarRelatorioCompraUnica,
	gerarRelatorioEntradaProduto,
	gerarRelatorioHistoricoProduto,
	gerarRelatorioLotesVencimento,
	gerarCupomLanchonete,
	gerarRelatorioPosicaoEstoque,
	gerarRelatorioFaturamentoDiasXHoras,
	gerarRelatorioAniversarios,
	gerarRelatorioVendasPorFornecedorPDF,
	gerarRelatorioContasPagar,
	gerarRelatorioFluxoCaixa,
	gerarRelatorioDetalhesFluxoCaixa,
	gerarRelatorioCrmPDF,
	gerarEtiquetaVitrineValor,
	gerarEtiquetaVitrinePromocao,
	geraPromissorias,
} from "../service/Impressao.js";
import{
	gerarRelatorioVendasPorFornecedorXLSX,
	gerarRelatorioCrmXLSX,
} from "../service/ExportarXLSX.js";
import Paginacao from "../service/Paginacao.js";
import Cargo from "../service/Cargo.js";

const actions = {
	...Venda.actions,
	...Financeiro.actions,
	...Usuario.actions,
	...Vendedor.actions,
	...Empresa.actions,
	...Cliente.actions,
	...Produto.actions,
	...MetodosPagamento.actions,
	...Caixa.actions,
	...Cidade.actions,
	...Relatorio.actions,
	...GrupoProduto.actions,
	...Bandeira.actions,
	...Contas.actions,
	...Cfop.actions,
	...Grade.actions,
	...Entradas.actions,
	...Promocao.actions,
	...Consorcio.actions,
	...Paginacao.actions,
	...Perfil.actions,
	...Cargo.actions,
	...Transferencia.actions,
	...Log.actions,
	...Pix.actions,
	...Conciliadora.actions,
	...ContaPagar.actions,
	...PrevisaoCompra.actions,
	...PlanoContas.actions,
	...Agenda.actions,
	...CentroCusto.actions,
	...Dashboard.actions,
	...Ncm.actions,
	...FluxoCaixa.actions,
	gerarCupom,
	gerarCupomPreVenda,
	gerarRecibo,
	gerarCupomTroca,
	gerarExtrato,
	relatorioFechamentoCaixa,
	relatorioVendedor,
	relatorioCaixa,
	gerarExtratoContasReceber,
	gerarRelatorioVenda,
	gerarRelatorioMovimentoProduto,
	gerarCodigoBarrasProduto,
	gerarComprovanteConsorcio,
	gerarRelatorioDesempenhoProduto,
	gerarRelatorioHistoricoProduto,
	gerarRelatorioLotesVencimento,
	gerarRelatorioSaldoEstoque,
	gerarRelatorioCompras,
	gerarRelatorioCompraUnica,
	gerarRelatorioEntradaProduto,
	gerarCupomLanchonete,
	gerarReciboCredito,
	gerarRelatorioPosicaoEstoque,
	gerarRelatorioFaturamentoDiasXHoras,
	gerarRelatorioAniversarios,
	gerarRelatorioVendasPorFornecedorPDF,
	gerarRelatorioVendasPorFornecedorXLSX,
	gerarRelatorioContasPagar,
	gerarRelatorioFluxoCaixa,
	gerarRelatorioDetalhesFluxoCaixa,
	gerarRelatorioCrmPDF,
	gerarRelatorioCrmXLSX,
	gerarEtiquetaVitrineValor,
	gerarEtiquetaVitrinePromocao,
	geraPromissorias,

	async iniciarApp({ state }) {
		try {
			const versao = localStorage.getItem("versao");
			if (!versao) {
				return localStorage.setItem("versao", state.versao);
			}
			if (versao != state.versao) {
				localStorage.clear();
				window.location.href = "/";
				return localStorage.setItem("versao", state.versao);
			}
		} catch (error) {
			console.log(error);
			localStorage.clear();
		}
	},

	async buscarStateInicial({ state, dispatch }, payload) {
		try {
			if (!state.Usuario) return;
			state.carregando = true;
			await dispatch("buscarClientePadrao");
			if ((state.Usuario.supervisor || state.Usuario.caixa) || state.Usuario.permiteBaixarCondicional || state.Usuario.permiteAlterarVenda) {
				await dispatch("buscarFormasPagamento");
				await dispatch("buscarVendedores");
				await dispatch("buscarVendasAberto");
				state.carregando = true;
				await dispatch("buscarVendasCondicionalEmAberto");
				if (state?.Caixa?.caixa?.cdcaixa && payload?.atualizar) {
					if(!payload?.caixaSelecionado){
						await dispatch("buscarCaixas");
					}
					state.carregando = true;
					await dispatch("atualizarInformacoesCaixa", { cdCaixa: state.Caixa.caixa.cdcaixa });
				} else {
					await dispatch("buscarCaixas");
				}
			} else {
				await dispatch("atualizarInformacoesCaixa", { cdCaixa: state.Caixa.caixa.cdcaixa });
			}
			state.carregando = false;
		} catch (error) {
			state.carregando = false;
			console.log(error);
		}
	},
};

export default actions;
