<template>
	<div style="height: 100%;">
		<div class="dados-nota-item">
			<h3 class="dados-nota-titulo">Cartas de Correção da venda {{ this.venda.cdVenda || 0 }}</h3>
		</div>
		<div class="table">
			<table>
				<thead>
					<tr>
						<td style="text-align: center;">Cód.</td>
						<td>Justificativa</td>
						<td style="text-align: center;">Ação <i class="fas fa-plus" style="color: var(--confirmar)" @click="adicionaCarta"/></td>
					</tr>
				</thead>
				<tbody  v-if="cartasCorrecao && !cartasCorrecao.erro">
					<tr v-for="(carta, index) in cartasCorrecao"
						:key="index">
						<td >{{ carta.cdcorrecao }}</td>
						<td ><span>{{ carta.correcao }}</span></td>
						<td v-if="carta.emitir != 'S'"><i class="fas fa-edit"  style="color: var(--primario)" @click="editaCarta(carta)"/></td>
						<td v-else><i class="fas fa-eye"  style="color: var(--primario)" @click="editaCarta(carta)"/></td>
					</tr>
				</tbody>
			</table>
		</div>
		<Modal 
			v-if="cartaCorrecaoModal"
			@fecharModal="saiuCarta"
			:dimensoes="{width: '90%', height: '90%'}"
			:idModal="'cartaCorrecao'"
		>
			<CartaCorrecao 
			:venda="venda" :carta="cartaCorrecaoSelecionada"
			@cancelarCarta="saiuCarta"/>	
		</Modal>
	</div>
</template>
<script>
import CartaCorrecao from "./CartaCorrecao.vue";
import Modal from "./Modal.vue";
export default {
	name: "ConsultaCartaCorrecao",

	components: {
		CartaCorrecao,
		Modal,
	},

	props: {
		venda:Object,
	},

	data() {
		return {
			cartaCorrecaoModal: false,
			cartaCorrecaoSelecionada: {
				novo:true,
				cdvenda:"",
				cdcorrecao:1,
			},
			cartasCorrecao: null,
		};
	},
	computed: {
		Empresa () {
			return this.$store.state.Empresa;
		},
		Clientes(){
			return this.$store.state.Clientes;
		},
	},
	methods: {
		adicionaCarta(){
			const ultCodigo = this.cartasCorrecao.length > 0? this.cartasCorrecao[this.cartasCorrecao.length-1].cdcorrecao : 0;
			this.cartaCorrecaoSelecionada = {
				novo:true,
				cdvenda:this.venda?.cdVenda,
				cdcorrecao:ultCodigo+1,
			};
			this.cartaCorrecaoModal = true;
		},
		editaCarta(carta){
			this.cartaCorrecaoSelecionada = carta;
			this.cartaCorrecaoModal = true;
		},
		saiuCarta(){
			this.$store.dispatch("buscaCartasCorrecao", this.venda.cdVenda).then((resp)=>{
				this.cartasCorrecao = resp;
			});	
			this.cartaCorrecaoModal = false;
		}
	},

	mounted() {
		this.cartaCorrecaoSelecionada.cdvenda = this.venda.cdVenda;
		this.$store.dispatch("buscaCartasCorrecao", this.venda.cdVenda).then((resp)=>{
			this.cartasCorrecao = resp;
		});
	},
};
</script>
<style scoped>
</style>