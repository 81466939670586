<template>
	<div style="width: 100%;">
		<div class="container-page">
			<div class="aba-controle js-aba-nota">
				<span id="indentificacao" class="aba" @click="mudarAba">Identificação da Nota</span>
				<span id="destinatario" class="aba" @click="mudarAba">Destinatário</span>
				<span id="entrega" class="aba" @click="mudarAba">Entrega</span>
				<span id="produtos" class="aba" @click="mudarAba">Produtos</span>
				<span id="transporte" class="aba" @click="mudarAba">Transporte</span>
				<span id="total" class="aba" @click="mudarAba">Totais</span>
			</div>
			<div class="container-form" v-if="mostrar == 'indentificacao'">
				<div class="row" style="align-items: center;justify-content: space-around;">
					<div class="row center" style="gap:10px">
						<input 
							type="radio" name="normal" 
							id="normal" value="1"
							v-model="tipoNota" 
						>
						<label for="normal">NF-e normal</label> &nbsp;
					</div>
					<div class="row center" style="gap:10px">
						<input 
							type="radio" name="complementar" 
							id="complementar" value="2"
							v-model="tipoNota" 
						>
						<label for="complementar">NF-e complementar</label> &nbsp;
					</div>
					<div class="row center" style="gap:10px">
						<input 
							type="radio" name="ajuste" 
							id="ajuste" value="3"
							v-model="tipoNota" 
						>
						<label for="ajuste">NF-e de ajuste</label> &nbsp;
					</div>
					<div class="row center" style="gap:10px">
						<input 
							type="radio" name="devolucao" 
							id="devolucao" value="4"
							v-model="tipoNota" 
						>
						<label for="devolucao">Devolução</label> &nbsp;
					</div>
				</div>
				<div class="row" style="align-items: center;">
					<i class="fas fa-barcode" style="color: var(--acao-texto);margin-left: 10px;" @click="solicitarLancamentoXML" v-if="tipoNota!=1"/>
					<input 
						type="file" 
						id="js-xml"
						style="display:none"
						@input="lerArquivoXML"
					>
					<i class="fas fa-search" style="color: var(--acao-texto);margin-left: 10px;" @click="listaCfop = true"/>
					<div class="input-group-minimalista" style="width: 100px;">
						<input 
							type="text" placeholder=" " 
							id="cfop"
							v-model="nota.cfop"
							@keydown.enter="proximoCampo('dataEmissao')"
							@input="digitouCFOP"
							maxlength="4"
						/>
						<label for="cfop">
							CFOP:
						</label>
					</div>
					<div class="input-group-minimalista" style="width: 100%;min-width: 150px;" >
						<input 
							type="text" placeholder=" " 
							id="natureza"
							v-model="nota.natureza"
							readonly
						/>
						<label for="natureza">
							Natureza da Operação:
						</label>
					</div>
				</div>
				<div class="row" style="">
					<div class="input-group-minimalista" style="width: 150px">
						<input type="date" id="dataEmissao" v-model="nota.dataEmissao" @keydown.enter="proximoCampo('dataMovimentacao')" readonly/>
						<label for="dataEmissao">
							Data da Emissão:
						</label>
					</div>
					<div class="input-group-minimalista" style="width: 150px;">
						<input type="date" id="dataMovimentacao" v-model="nota.dataMovimentacao" @keydown.enter="proximoCampo('horaMovimentacao')"/>
						<label for="dataMovimentacao">
							Saida/Entrada:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:100px">
						<input type="time" step="1" id="horaMovimentacao" v-model="nota.horaMovimentacao" @keydown.enter="proximoCampo('valorDoFrete')"/>
						<label for="horaMovimentacao">
							Horas:
						</label>
					</div>
					<div class="input-group-minimalista" style="width: 150px;">
						<select 
							id="tipoOperacao"
							v-model="nota.tipoOperacao"
						>
							<option :value="1">
								1 - Saída
							</option>
							<option :value="2">
								2 - Entrada
							</option>
						</select>
						<label for="tipoFrete">Tipo de Operação:</label>
					</div>
				</div>
				<div class="botoes" style="align-items: center;">
					<button class="botao botao-acao" @click="chaveDevolucao='';listaChavesXML = true" v-if="tipoNota != 1">
							Documentos Relacionados
					</button>
				</div>
			</div>
			<div class="container-form" v-if="mostrar == 'destinatario'">
				<div class="row" style="align-items: center;">
					<i class="fas fa-search" style="color: var(--primario); margin-left:10px" @click="listarClientes = true" v-if="!clienteIconeXML || cliente"></i>
					<i class="fas fa-plus" style="color: var(--confirmar); margin-left:10px" @click="adicionarCliente" v-else></i>
					<div class="input-group-minimalista" style="width: 100%;min-width: 280px;">
						<input 
							type="text" placeholder=" " 
							id="nome"
							v-model="nota.nomeCliente"
							@click="listarClientes = true"
						/>
						<label for="nome">
							Nome/Razão Social:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width: 20%;min-width: 180px;">
						<input 
							type="text" placeholder=" " 
							id="cpf"
							v-model="nota.cpfCliente"
							readonly
						/>
						<label for="cpf">
							CPF/CNPJ:
						</label>
					</div>					
					<div class="input-group-minimalista" style="width:20%;min-width: 180px;">
						<input type="text" id="ie" v-model="nota.ieCliente" readonly/>
						<label for="ie">
							Inscrição Estadual:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width: 20%;min-width: 180px;">
						<input type="text" id="cep" v-model="nota.cepCliente" readonly/>
						<label for="cep">
							CEP:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:100%;min-width: 300px;">
						<input type="text" id="municipio" v-model="nota.municipioCliente" readonly/>
						<label for="municipio">
							Município:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:60px">
						<input type="text" id="uf" v-model="nota.ufCliente" readonly/>
						<label for="uf">
							UF:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width: 70%;min-width: 300px;">
						<input type="text" id="endereco" v-model="nota.enderecoCliente" readonly/>
						<label for="endereco">
							Endereço:
						</label>
					</div>
					<div class="input-group-minimalista" style="width: 30%;min-width: 180px;">
						<input type="text" id="bairro" v-model="nota.bairroCliente" readonly/>
						<label for="bairro">
							Bairro/Distrito:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width:30%;min-width: 300px;">
						<input type="text" id="fone" v-model="nota.foneCliente" readonly/>
						<label for="fone">
							Fone/Fax:
						</label>
					</div>
					
				</div>
			</div>
			<div class="container-form" v-if="mostrar == 'entrega'">
				<div class="row" style="align-items: center;">
					<i class="fas fa-search" style="color: var(--primario); margin-left:10px" @click="listarClientesEntrega = true"></i>
					<div class="input-group-minimalista" style="width: 100%;min-width: 280px;">
						<input 
							type="text" placeholder=" " 
							id="nome"
							v-model="nota.nomeEntrega"
							@click="listarClientesEntrega = true"
						/>
						<label for="nome">
							Nome/Razão Social:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width: 20%;min-width: 180px;">
						<input 
							type="text" placeholder=" " 
							id="cpf"
							v-model="nota.cpfEntrega"
							readonly
						/>
						<label for="cpf">
							CPF/CNPJ:
						</label>
					</div>					
					<div class="input-group-minimalista" style="width:20%;min-width: 180px;">
						<input type="text" id="ie" v-model="nota.ieEntrega" readonly/>
						<label for="ie">
							Inscrição Estadual:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width: 20%;min-width: 180px;">
						<input type="text" id="cep" v-model="nota.cepEntrega" readonly/>
						<label for="cep">
							CEP:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:100%;min-width: 300px;">
						<input type="text" id="municipio" v-model="nota.municipioEntrega" readonly/>
						<label for="municipio">
							Município:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:60px">
						<input type="text" id="uf" v-model="nota.ufEntrega" readonly/>
						<label for="uf">
							UF:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width: 70%;min-width: 300px;">
						<input type="text" id="endereco" v-model="nota.enderecoEntrega" readonly/>
						<label for="endereco">
							Endereço:
						</label>
					</div>
					<div class="input-group-minimalista" style="width: 30%;min-width: 180px;">
						<input type="text" id="bairro" v-model="nota.bairroEntrega" readonly/>
						<label for="bairro">
							Bairro/Distrito:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width:30%;min-width: 300px;">
						<input type="text" id="fone" v-model="nota.foneEntrega" readonly/>
						<label for="fone">
							Fone/Fax:
						</label>
					</div>
					
				</div>
			</div>
			<div class="container-form" v-if="mostrar == 'produtos'">
				<div class="botoes" style="align-items: center;justify-content: flex-start">
					<button class="botao botao-acao" @click="iniciaAdicaoProduto">
							Adicionar Produto
					</button>
					<button class="botao botao-acao" @click="editarFinanceiro = true">
							<span v-if="this.financeiroNota.length>0">Alterar</span><span v-else>Lançar</span> Financeiro
					</button>
				</div>
				<div style="margin-left:5px;margin-right: 5px;height: 400px;min-height: 400px;overflow-y: auto;background-color: lightgray;">
					<table>
						<thead>
							<tr>
								<th>CÓDIGO</th>
								<th>DESCRIÇÃO PRODUTO/SERVIÇO</th>
								<th>NCM/SH</th>
								<th>O/CST</th>
								<th>CFOP</th>
								<th>UN</th>
								<th>QUANT</th>
								<th>VALOR UNIT</th>
								<th>VALOR TOTAL</th>
								<th>VALOR DESC</th>
								<th>B. CÁLC ICMS</th>
								<th>VALOR ICMS</th>
								<th>VALOR IPI</th>
								<th>ALÍQ ICMS</th>
								<th>ALÍQ IPI</th>
								<th>AÇÃO</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(produto, index) in produtos" :key="produto.codigo">
								<td v-if="produto.vinculado">{{produto.vinculado}}</td>
								<td v-else @click="indiceAssociar = index;linkarProduto = true" style="color:var(--primario)">Buscar</td>
								<td>{{produto.descricao}}</td>
								<td>{{produto.ncm}}</td>
								<td>{{produto.cst}}</td>
								<td>{{produto.cfop}}</td>
								<td>{{produto.unidade}}</td>
								<td>{{produto.quantidade.toString().replace(".",",")}}</td>
								<td>{{produto.valorUnitario.toString().replace(".",",")}}</td>
								<td>{{produto.valorTotal.toString().replace(".",",")}}</td>
								<td>{{produto.valorDesc.toString().replace(".",",")}}</td>
								<td>{{produto.baseCalculo.toString().replace(".",",")}}</td>
								<td>{{produto.valorICMS.toString().replace(".",",")}}</td>
								<td>{{produto.valorIPI.toString().replace(".",",")}}</td>
								<td>{{produto.aliqICMS.toString().replace(".",",")}}</td>
								<td>{{produto.aliqIPI.toString().replace(".",",")}}</td>
								<td style="display:flex;flex-direction: row"><i class="fas fa-search"  style="color: var(--primario)" @click="indiceAssociar = index;linkarProduto = true" v-if="!produto.adicionado && !produto.vinculado"></i><i class="fas fa-edit" style="color: var(--confirmar)" @click="editarProduto(produto, index)"></i><i class="fas fa-trash" style="color: var(--deletar)" @click="removeProduto(index)"></i></td>
							</tr>
						</tbody>
					</table>
				</div> 
			</div>
			<div class="container-form" v-if="mostrar == 'transporte'">
				<div class="row" style="align-items: center;">
					<i class="fas fa-brush" style="rotate: 220deg; color: var(--cancelar-texto); margin-left:10px" @click="limparTransporte"></i>
					<div class="input-group-minimalista" style="width:40%;min-width: 280px;">
						<input type="text" id="nomeTransporte" v-model="nota.nomeTransporte" @click="listarClientesTransporte = true"/>
						<label for="nomeTransporte">
							Nome/Razão Social:
						</label>
					</div>
					<div class="input-group-minimalista" style="width: 310px;">
						<select 
							id="tipoFrete"
							v-model="nota.tipoFrete"
							@keydown.enter="proximoCampo('quantidade')"
						>
							<option :value="0">
								0 - POR CONTA DO EMITENTE
							</option>
							<option :value="1">
								1 - POR CONTA DESTINATÁRIO/REM
							</option>
							<option :value="2">
								2 - POR CONTA DE TERCEIROS
							</option>
							<option :value="9">
								9 - SEM FRETE
							</option>
						</select>
						<label for="tipoFrete">Frete:</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width:30%;min-width: 300px;">
						<input type="text" id="cpfTransporte" v-model="nota.cpfTransporte" readonly/>
						<label for="cpfTransporte">
							CNPJ/CPF:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:20%;min-width: 240px;">
						<input type="text" id="ieTransporte" v-model="nota.ieTransporte" readonly/>
						<label for="ieTransporte">
							Inscrição Estadual:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width:20%;min-width: 300px;">
						<input type="text" id="municipioTransporte" v-model="nota.municipioTransporte" readonly/>
						<label for="municipioTransporte">
							Município:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:60px">
						<input type="text" id="ufTransporte" v-model="nota.ufTransporte" readonly/>
						<label for="ufTransporte">
							UF:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:100%;min-width: 300px;">
						<input type="text" id="enderecoTransporte" v-model="nota.enderecoTransporte" readonly/>
						<label for="enderecoTransporte">
							Endereço:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width:40%;min-width: 180;">
						<input type="text" id="quantidade" v-model="nota.quantidade" @keydown.enter="proximoCampo('especie')"/>
						<label for="quantidade">
							Quantidade:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:40%;min-width: 180;">
						<input type="text" id="especie" v-model="nota.especie" @keydown.enter="proximoCampo('marca')"/>
						<label for="especie">
							Espécie:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:40%;min-width: 180;">
						<input type="text" id="marca" v-model="nota.marca" @keydown.enter="proximoCampo('numeracao')"/>
						<label for="marca">
							Marca:
						</label>
					</div>
				</div>
				<div class="row">
					<div class="input-group-minimalista" style="width:40%;min-width: 180;">
						<input type="text" id="numeracao" v-model="nota.numeracao" @keydown.enter="proximoCampo('pesoBruto')"/>
						<label for="numeracao">
							Numeração:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:40%;min-width: 180;">
						<input type="text" id="pesoBruto" v-model="formatedNotaPesoBruto" @keydown.enter="proximoCampo('pesoLiquido')" v-mascaraFloat/>
						<label for="pesoBruto">
							Peso Bruto:
						</label>
					</div>
					<div class="input-group-minimalista" style="width:40%;min-width: 180;">
						<input type="text" id="pesoLiquido" v-model="formatedNotaPesoLiq" @keydown.enter="proximoCampo('codigoProduto')" v-mascaraFloat/>
						<label for="pesoLiquido">
							Peso Líquido:
						</label>
					</div>
				</div>
			</div>
			<div class="container-form" v-if="mostrar == 'total'" style="justify-content: space-between;">
				<div class="col" style="gap:15px;">
					<div class="row">
						<div class="input-group-minimalista" style="width:180px;">
							<input type="text" id="baseCalcICMS" v-model="formatedNotaBaseICMS" readonly/>
							<label for="baseCalcICMS">
								Base Cálc. ICMS:
							</label>
						</div>
						<div class="input-group-minimalista" style="width:180px;">
							<input type="text" id="valorICMS" v-model="formatedNotaValorICMS" readonly/>
							<label for="valorICMS">
								Valor ICMS:
							</label>
						</div>
						<div class="input-group-minimalista" style="width:180px;">
							<input type="text" id="baseCalcICMSST" v-model="formatedNotaBaseICMSST" readonly/>
							<label for="baseCalcICMSST">
								Base Cálc. ICMS S.T.:
							</label>
						</div>
						<div class="input-group-minimalista" style="width:180px;">
							<input type="text" id="valorICMSSub" v-model="formatedNotaValorICMSSub" readonly/>
							<label for="valorICMSSub">
								Valor ICMS Subst:
							</label>
						</div>
					</div>
					<div class="row">
						<div class="input-group-minimalista" style="width:180px;">
							<input type="text" id="valorPis" v-model="formatedNotaValorPis" readonly/>
							<label for="valorPis">
								Valor do Pis:
							</label>
						</div>
						<div class="input-group-minimalista" style="width:180px;">
							<input type="text" id="valorCofins" v-model="formatedNotaValorCofins" readonly/>
							<label for="valorCofins">
								Valor da Cofins:
							</label>
						</div>
						<div class="input-group-minimalista" style="width:180px">
							<input type="text" id="vTotalProdutos" v-model="formatedNotaVTotalProdutos" readonly/>
							<label for="vTotalProdutos">
								Total Produtos:
							</label>
						</div>
						<div class="input-group-minimalista" style="width:180px">
							<input type="text" id="valorDoFrete" v-model="formatedNotaValorFrete" @keydown.enter="proximoCampo('valorDoSeguro')" v-mascaraFloat/>
							<label for="valorDoFrete">
								Valor do Frete:
							</label>
						</div>
					</div>
					<div class="row">
						<div class="input-group-minimalista" style="width:180px">
							<input type="text" id="valorDoSeguro" v-model="formatedNotaValorSeguro" @keydown.enter="proximoCampo('desconto')" v-mascaraFloat/>
							<label for="valorDoSeguro">
								Valor do Seguro:
							</label>
						</div>
						<div class="input-group-minimalista" style="width:180px">
							<input type="text" id="desconto" v-model="formatedNotaDesconto" @keydown.enter="proximoCampo('outrasDespesas')" v-mascaraFloat/>
							<label for="desconto">
								Desconto:
							</label>
						</div>
						<div class="input-group-minimalista" style="width:180px">
							<input type="text" id="outrasDespesas" v-model="formatedNotaOutrasDespesas" @keydown.enter="proximoCampo('quantidade')" v-mascaraFloat/>
							<label for="outrasDespesas">
								Outras Despesas:
							</label>
						</div>
						<div class="input-group-minimalista" style="width:180px">
							<input type="text" id="valorTotalIPI" v-model="formatedNotaValorTotalIPI" readonly/>
							<label for="valorTotalIPI">
								Valor Total IPI:
							</label>
						</div>
					</div>
					<div class="row">
						<div class="input-group-minimalista" style="width:300px">
							<input type="text" id="vTotalNota" v-model="formatedNotaVTotalNota" readonly/>
							<label for="vTotalNota">
								Total da Nota:
							</label>
						</div>
					</div>
					<div class="textarea-group-minimalista" style="width: 90%;">
						<textarea type="text" id="observacao" autocomplete="off"
							v-model="nota.observacao" @keydown.enter="proximoCampo('valorFrete')"></textarea>
						<label for="observacao">Observacao contábil:</label>
					</div>
				</div>
				<div class="botoes" style="width: 100%;">
					<button class="botao botao-cancelar"  @click="$emit('cancelarNFE')">
						<span class="lg-view">
							Cancelar
						</span>
						<span class="sm-view">
							Cancelar
						</span>
					</button>
					<button class="botao botao-adicionar" @click="adicionarNota">
						<span class="lg-view">
							Salvar [Alt + S]
						</span>
						<span class="sm-view">
							Salvar
						</span>
					</button>
				</div>
			</div>
		</div>
		<Modal
			v-if="modalProdutos"
			@fecharModal="modalProdutos = false"
			:dimensoes="{width: '90%', height: '90%'}"
			:idModal="'lista-produtos'"
		>
			<CadastroProduto 
				v-if="novoProduto" 
				:Produto="novoProduto" 
				@cancelarEdicao="novoProduto = null" 
			/>
			<consulta-produtos
				:Produtos="Produtos"
				:mostrarBotaoNovo="true"
				@adicionarNovo="novoProduto = true"
				@selecionarProduto="selecionarProduto"
				:height="'65vh'"
				v-else
			/>
			
		</Modal>
		<Modal
			v-if="modalProdutoTributacao"
			@fecharModal="modalProdutoTributacao = false"
			:dimensoes="{width: '90%', height: '90%'}"
			:idModal="'tributacao-produto'"
		>
			<div class="col" style="align-items: flex-start;gap:10px">
				<label style="margin-left:10px">
					Dados do Produto:
				</label>
				<div class="row w-100">
					<div class="input-group" style="width:100px">
						<input type="text" id="codigo" v-model="produtoTributacao.codigo" readonly/>
						<label for="codigo">
							Codigo:
						</label>
					</div>
					<div class="input-group" style="width:70%">
						<input type="text" id="descricao" v-model="produtoTributacao.descricao" readonly/>
						<label for="descricao">
							Descrição:
						</label>
					</div>
					<div class="input-group" style="width:100px">
						<input type="text" id="ncm" v-model="produtoTributacao.ncm" @keydown.enter="proximoCampo('un')"/>
						<label for="ncm">
							NCM:
						</label>
					</div>
					<div class="input-group" style="width:100px">
						<input type="text" id="un" v-model="produtoTributacao.unidade" @keydown.enter="proximoCampo('quantidadeTributacao')"/>
						<label for="un">
							UN:
						</label>
					</div>
				</div>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="quantidadeTributacao" v-model="formatedProdutoTributacaoQuantidade" @keydown.enter="proximoCampo('valorUnitario')" v-mascaraFloat/>
						<label for="quantidadeTributacao">
							Quantidade:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorUnitario" v-model="formatedProdutoTributacaoValorUnitario" @keydown.enter="proximoCampo('valorTotal')" v-mascaraFloat/>
						<label for="valorUnitario">
							Valor Unitario:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorTotal" v-model="formatedProdutoTributacaoValorTotal" @keydown.enter="proximoCampo('cfopTributacao')" v-mascaraFloat/>
						<label for="valorTotal">
							Valor Total:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="cfopTributacao" v-model="produtoTributacao.cfop" @keydown.enter="proximoCampo('CST')"/>
						<label for="cfopTributacao">
							CFOP:
						</label>
					</div>
				</div>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="valorDoFrete" v-model="formatedProdutoTributacaoValorFrete" @keydown.enter="proximoCampo('valorDoSeguro')" v-mascaraFloat/>
						<label for="valorDoFrete">
							Valor do Frete:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorDoSeguro" v-model="formatedProdutoTributacaoValorSeguro" @keydown.enter="proximoCampo('desconto')" v-mascaraFloat/>
						<label for="valorDoSeguro">
							Valor do Seguro:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="desconto" v-model="formatedProdutoTributacaoDesconto" @keydown.enter="proximoCampo('outrasDespesas')" v-mascaraFloat/>
						<label for="desconto">
							Desconto:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="outrasDespesas" v-model="formatedProdutoTributacaoOutrasDespesas" @keydown.enter="proximoCampo('quantidade')" v-mascaraFloat/>
						<label for="outrasDespesas">
							Outras Despesas:
						</label>
					</div>
				</div>
				<label style="margin-left:10px">
					Tributações Estaduais:
				</label>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="CST" v-model="produtoTributacao.cst" @keydown.enter="proximoCampo('reducaoBC')"/>
						<label for="CST">
							CST:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="reducaoBC" v-model="produtoTributacao.reducaoBC" @keydown.enter="proximoCampo('baseCalculo')"/>
						<label for="reducaoBC">
							Redução BC:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="baseCalculo" v-model="formatedProdutoTributacaoBaseCalculo" @keydown.enter="proximoCampo('porcentagemICMS')" v-mascaraFloat/>
						<label for="baseCalculo">
							Base Cálculo:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="porcentagemICMS" v-model="formatedProdutoTributacaoAliqICMS" @keydown.enter="proximoCampo('valorICMSTributacao')" v-mascaraFloat/>
						<label for="porcentagemICMS">
							% ICMS:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorICMSTributacao" v-model="formatedProdutoTributacaoValorICMS" @keydown.enter="proximoCampo('basePis')" v-mascaraFloat/>
						<label for="valorICMSTributacao">
							Valor ICMS:
						</label>
					</div>
				</div>
				<label style="margin-left:10px">
					Tributações Federais:
				</label>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="basePis" v-model="formatedProdutoTributacaoBasePis" @keydown.enter="proximoCampo('baseIPI')" v-mascaraFloat/>
						<label for="basePis">
							Base Pis/Cofins:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="baseIPI" v-model="formatedProdutoTributacaoBaseIPI" @keydown.enter="proximoCampo('enquadramento')" v-mascaraFloat>
						<label for="baseIPI">
							Base IPI:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="enquadramento" v-model="produtoTributacao.enquadramento" @keydown.enter="proximoCampo('cstPis')" v-mascaraFloat/>
						<label for="enquadramento">
							Enquadramento:
						</label>
					</div>
				</div>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="cstPis" v-model="produtoTributacao.cstPis" @keydown.enter="proximoCampo('aliqPis')"/>
						<label for="cstPis">
							CST Pis:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="aliqPis" v-model="formatedProdutoTributacaoAliqPis" @keydown.enter="proximoCampo('pis')" v-mascaraFloat/>
						<label for="aliqPis">
							Aliquota Pis:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="pis" v-model="formatedProdutoTributacaoValorPis" @keydown.enter="proximoCampo('cstCofins')" v-mascaraFloat/>
						<label for="pis">
							Valor Pis:
						</label>
					</div>
				</div>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="cstCofins" v-model="produtoTributacao.cstCofins" @keydown.enter="proximoCampo('aliqCofins')"/>
						<label for="cstCofins">
							CST Cofins:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="aliqCofins" v-model="formatedProdutoTributacaoAliqCofins" @keydown.enter="proximoCampo('cofins')" v-mascaraFloat/>
						<label for="aliqCofins">
							Aliquota Cofins:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="cofins" v-model="formatedProdutoTributacaoValorCofins" @keydown.enter="proximoCampo('cstIpi')" v-mascaraFloat/>
						<label for="cofins">
							Valor Cofins:
						</label>
					</div>
				</div>
				<div class="row w-100">
					<div class="input-group" style="width:100%">
						<input type="text" id="cstIpi" v-model="produtoTributacao.cstIPI" @keydown.enter="proximoCampo('aliqIpi')"/>
						<label for="cstIpi">
							CST IPI:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="aliqIpi" v-model="formatedProdutoTributacaoAliqIPI" @keydown.enter="proximoCampo('valorIpi')" v-mascaraFloat/>
						<label for="aliqIpi">
							Aliquota IPI:
						</label>
					</div>
					<div class="input-group" style="width:100%">
						<input type="text" id="valorIpi" v-model="formatedProdutoTributacaoValorIPI" v-mascaraFloat/>
						<label for="valorIpi">
							Valor IPI:
						</label>
					</div>
					
				</div>
				<div class="botoes">
					<button class="botao botao-cancelar" @click="modalProdutoTributacao = false">
						<span>Cancelar</span>
					</button>
					<button class="botao botao-adicionar" @click="salvarProdutoTributacao">
						<span>Salvar</span>
					</button>
				</div>
			</div>
		</Modal>
		<Modal 
			v-if="listaCfop"
			@fecharModal="listaCfop = false"
		>
			<ConsultaCFOP @escolheucfop="escolheCfop" />
		</Modal>
		<Modal v-if="listarClientes" @fecharModal="listarClientes = false" :dimensoes="{width: '90%', height: '90%'}" :idModal="'tributacao-produto'">
			<consultaCliente  
				@selecionarPessoa="selecionarCliente" 
				@adicionarNovo="clienteSelecioando = {novo: true}"
				:mostrarBotaoNovo="true"
				v-if="!clienteSelecioando"
			/>
			<CadastroCliente @cancelarEdicao="clienteSelecioando = null" @clienteCadastrado="alterarClienteNota" v-else :cliente="clienteSelecioando"/>
		</Modal>
		<Modal v-if="listarClientesTransporte" @fecharModal="listarClientesTransporte = false" :dimensoes="{width: '90%', height: '90%'}" :idModal="'tributacao-produto'">
			<consultaCliente  
				@selecionarPessoa="selecionarClienteTransporte" 
				@adicionarNovo="ClienteTransporteSelecionado = {novo: true}"
				:mostrarBotaoNovo="true"
				v-if="!ClienteTransporteSelecionado"
			/>
			<CadastroCliente @cancelarEdicao="ClienteTransporteSelecionado = null" @clienteCadastrado="alterarClienteTransporteNota" v-else :cliente="ClienteTransporteSelecionado"/>
		</Modal>
		<Modal v-if="listarClientesEntrega" @fecharModal="listarClientesEntrega = false" :dimensoes="{width: '90%', height: '90%'}" :idModal="'tributacao-produto'">
			<consultaCliente  
				@selecionarPessoa="selecionarClienteEntrega" 
				@adicionarNovo="ClienteEntregaSelecionado = {novo: true}"
				:mostrarBotaoNovo="true"
				v-if="!ClienteEntregaSelecionado"
			/>
			<CadastroCliente @cancelarEdicao="ClienteEntregaSelecionado = null" @clienteCadastrado="alterarClienteEntregaNota" v-else :cliente="ClienteEntregaSelecionado"/>
		</Modal>
		<Modal v-if="listaChavesXML" @fecharModal="listaChavesXML = false" :dimensoes="{width: '90%', height: '90%'}" :idModal="'xml-chaves'">
			<div class="col" style="width: 100%;gap:10px;min-width: 410px; align-items: center;">
				<div class="row" style="align-items: center;width: 50%; min-width: 410px;">
					<button class="botao botao-acao" @click="solicitarLancamentoXML" style="width:200px;margin-right: 10px;">
							importar XML
					</button>
					<div class="input-group" style="width: 100%;margin: 0px">
						<input 
							type="text" placeholder=" " 
							id="chaveDevolucao"
							v-model="chaveDevolucao"
							@keydown.enter="adicionaDocumentoReferenciado"
							maxlength="44"
							v-mascaraInteger
						/>
						<label for="chaveDevolucao">
							Documentos Fiscais Referenciados:
						</label>
					</div>
					<i class="fas fa-plus" style="color: var(--primario)" @click="adicionaDocumentoReferenciado"></i>
				</div>
				<div style="overflow-y: auto;height: 400px; width: 50%; min-width: 410px;">
					<table v-if="chaves.length > 0" class="w-100">
						<thead>
							<tr>
								<td class="text-left">Chave</td>
								<td class="text-center text-nowrap">Ação</td>
							</tr>
						</thead>
						<tbody>
							<tr class="itemProd" v-for="(chave,index) in chaves"
								:key="index">
								<td class="text-left text-nowrap">{{ chave }}</td>
								<td class="text-center text-nowrap">
									<i class="fas fa-trash-alt" style="color: var(--deletar)"
										@click="removeChaveDevolucao(index);"></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<button class="botao botao-cancelar" @click="listaChavesXML = false">
						Voltar
				</button>
			</div>
		</Modal>
		<Modal v-if="listarProdutosXML" @fecharModal="listarProdutosXML = false" :dimensoes="{width: '90%', height: '90%'}" :idModal="'xml-produto'">
			<div class="col" style="margin:10px;overflow-y: auto;height: 450px;">
				<table style="margin-left:5px;margin-right: 5px;">
					<thead>
						<tr>
							<th>CÓDIGO</th>
							<th>DESCRIÇÃO PRODUTO/SERVIÇO</th>
							<th>NCM/SH</th>
							<th>O/CST</th>
							<th>CFOP</th>
							<th>UN</th>
							<th>QUANT</th>
							<th>VALOR UNIT</th>
							<th>VALOR TOTAL</th>
							<th>VALOR DESC</th>
							<th>B. CÁLC ICMS</th>
							<th>VALOR ICMS</th>
							<th>VALOR IPI</th>
							<th>ALÍQ ICMS</th>
							<th>ALÍQ IPI</th>
							<th>Selecionar</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(produto, index) in produtosXML" :key="produto.codigo">
							<td>{{produto.codigo}}</td>
							<td>{{produto.descricao}}</td>
							<td>{{produto.ncm}}</td>
							<td>{{produto.cst}}</td>
							<td>{{produto.cfop}}</td>
							<td>{{produto.unidade}}</td>
							<td>
								<input type="number" id="quantidade" min="0" :max="produto.quantidadeLimite" v-model="produto.quantidade" @blur="enforceMinMax" @input="updateProdutoXML(index)" v-if="!produto.desabilitado"/>
								<span v-else>{{produto.quantidade.toString().replace(".",",")}}</span>
							</td>
							<td>{{produto.valorUnitario.toString().replace(".",",")}}</td>
							<td>{{produto.valorTotal.toString().replace(".",",")}}</td>
							<td>{{produto.valorDesc.toString().replace(".",",")}}</td>
							<td>{{produto.baseCalculo.toString().replace(".",",")}}</td>
							<td>{{produto.valorICMS.toString().replace(".",",")}}</td>
							<td>{{produto.valorIPI.toString().replace(".",",")}}</td>
							<td>{{produto.aliqICMS.toString().replace(".",",")}}</td>
							<td>{{produto.aliqIPI.toString().replace(".",",")}}</td>
							<td><input type="checkbox" class="mx-5 pointer" :id="'selecionar'+index" v-model="produto.selecionado" :disabled="produto.desabilitado"></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="botoes">
				<button class="botao botao-cancelar" @click="listarProdutosXML = false">
					<span>
						fechar
					</span>
				</button>
				<button class="botao botao-adicionar" @click="adicionarProdutosXml">
					<span>
						Adicionar produtos
					</span>
				</button>
			</div>
		</Modal>
		<Modal v-if="linkarProduto"
			@fecharModal="linkarProduto = false"
			:dimensoes="{width: '90%', height: '90%'}"
			:idModal="'lista-produtos'"
		>
			<CadastroProduto 
				v-if="novoProduto" 
				:Produto="novoProduto" 
				@cancelarEdicao="novoProduto = null"
			/>
			<consulta-produtos
				:Produtos="Produtos"
				:mostrarBotaoNovo="true"
				@adicionarNovo="novoProduto = true"
				@selecionarProduto="associarProduto"
				:height="'65vh'"
				v-else
			/>
			
		</Modal>
		<Modal v-if="editarFinanceiro" @fecharModal="editarFinanceiro = false" :dimensoes="{width: '90%', height: '90%'}" :idModal="'financeiroNota'">
			<div class="container-pagamento">
				<div class="container-title">
					<h3>ADICIONAR FINANCEIRO</h3>
				</div>
				<div class="campos-container">
					<div class="input-group">
						<select 
							id="tipo-pagamento" 
							v-model="financeiro.tipoPgto" 
							@keydown.enter.prevent="proximoCampo('valor-pagar')"
							@change="selecionarValor"
						>
							<option disabled :value="{selecionar: true}">
								SELECIONE UMA OPÇÃO
							</option>
							<option value="SEMPAG">

							</option>
							<option 
								v-for="(pgto, index) in FormasPagamento" 
								:key="index"
								:value="pgto.cdtipo"
							>
								{{ pgto.descricao }}
							</option>
						</select>
						<label>Tipo pagamento</label>
					</div>
					<div class="input-group">
						<input 
							type="text" 
							id="valor-pagar"
							autocomplete="off"
							v-mascaraFloat
							v-model="financeiro.valorParcela"
							@keydown.enter="addValorParcela"
						/>
						<label for="valor-pagar">Valor a Pagar</label>
					</div>
				</div>
				<div class="campos-container">
					<button class="botao botao-adicionar" id="add-pgto" @click="adicionarPagamentoParcial">
						<span v-if="indexEditando === -1">Adicionar</span> <span v-else>Alterar</span> pagamento
					</button>
				</div>
				<div class="campos-container">
					<div class="container-tabela-pagamento">
						
						<table v-if="financeiroNota.length > 0">
							<thead>
								<tr>
									<th>
										Seq.
									</th>
									<th>
										Descrição
									</th>
									<th>
										Valor Pago
									</th>
									<th>
										Ação
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(fin, index) in financeiroNota" :key="index">
									<td class="code">
										{{ index + 1 }}
									</td>
									<td>
										{{ (fin.descricao || fin.pagamento) }}
									</td>
									<td class="valor">
										{{ (fin.valorParcela || 0).toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL"})
										}}
									</td>
									<td style="text-align: center">
										<i class="fas fa-edit" style="color: var(--primario)" @click="editPagamento(index, fin)" />
										<i class="fas fa-trash" style="color: var(--deletar)" @click="removerPagamento(index)"/>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>				
				<div class="campos-container">
					<div class="input-group">
						<input 
							type="text" 
							id="total-pago"
							:value="TotalPago"
							class="valor"
							readonly
						/>
						<label for="total-pago">
							$ Total Pago
						</label>
					</div>
					<div class="input-group" style="border: 3px solid var(--salvar-texto)">
						<input 
							type="text" 
							id="total-bruto"
							:value="formatedNotaVTotalNota"
							style="font-weight: bold;"
							class="valor"
							readonly
						/>
						<label for="total-bruto">
							$ Total a Pagar
						</label>
					</div>
				</div>
				<div class="campos-container">
					<button class="botao botao-acao" @click="finalizarLancamentoFinanceiro">
						Finalizar
					</button>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
import Modal from "../components/Modal";
import ConsultaProdutos from "../components/ConsultaProdutos.vue";
import CadastroProduto from "./CadastroProduto.vue";
import ConsultaCFOP from "./ConsultaCFOP.vue";
import ConsultaCliente from "./ConsultaCliente.vue";
import CadastroCliente from "./CadastroCliente.vue";
import { FormacaoPrecoCompra } from "../models/Produto";
import { proximoCampo } from "../utils/utils";

export default {
	name: "CadastroNFE",
	props: {
		PropProduto: Object,
		Fornecedor: Object
	},

	components: {
		Modal,
		ConsultaProdutos,
		CadastroProduto,
		ConsultaCFOP,
		ConsultaCliente,
		CadastroCliente,
	},
	data(){
		return {
			tipoNota:1,
			nota:{
				cfop:"",
				natureza:"",
				nomeCliente:"",
				cpfCliente:"",
				dataEmissao: new Date().toLocaleDateString("en-CA"),
				enderecoCliente:"",
				bairroCliente:"",
				cepCliente:"",
				dataMovimentacao: new Date().toLocaleDateString("en-CA"),
				municipioCliente:"",
				ufCliente:"",
				foneCliente:"",
				ieCliente:"",
				horaMovimentacao:new Date().getHours().toString().padStart(2,"0")+":"+new Date().getMinutes().toString().padStart(2,"0")+":"+new Date().getSeconds().toString().padStart(2,"0"),
				baseCalcICMS:"",
				valorICMS:"",
				baseCalcICMSST:"",
				valorICMSSub:"",
				valorImpImportacao:"",
				vICMSUFRemet:"",
				vFCPUFDest:"",
				valorPis:"",
				vTotalProdutos:"",
				valorDoFrete:"",
				valorDoSeguro:"",
				desconto:"",
				outrasDespesas:"",
				valorTotalIPI:"",
				vICMSUFDest:"",
				vTotTrib:"",
				valorCofins:"",
				vTotalNota:0,
				nomeTransporte:"",
				tipoFrete:"9",
				cpfTransporte:"",
				enderecoTransporte:"",
				municipioTransporte:"",
				ufTransporte:"",
				ieTransporte:"",
				quantidade:"",
				especie:"",
				marca:"",
				pesoBruto:"",
				pesoLiquido:"",
				nomeEntrega:"",
				cpfEntrega:"",
				enderecoEntrega:"",
				municipioEntrega:"",
				ufEntrega:"",
				ieEntrega:"",
				tipoOperacao:1,
				observacao:"",
			},
			produtos:[],
			codigoProduto:null,
			novoProduto:null,
			modalProdutos:false,
			modalProdutoTributacao:false,
			produtoTributacao:null,
			indiceProdutoTributacao:null,
			listaCfop:false,
			listarClientes:false,
			listarProdutosXML:false,
			clienteSelecioando:null,
			cliente:null,
			listarClientesTransporte:false,
			ClienteTransporteSelecionado:null,
			clienteTransporte:null,
			listarClientesEntrega:false,
			ClienteEntregaSelecionado:null,
			clienteEntrega:null,
			xml:null,
			produtosXML:[],
			chaves:[],
			listaChavesXML:false,
			chaveDevolucao:"",
			linkarProduto:false,
			indiceAssociar:null,
			clienteIconeXML:false,
			editarFinanceiro:false,
			financeiro: {
				valorParcela: 0,
				tipoPgto: {selecionar: true}
			},
			indexEditando: -1,
			financeiroNota:[],
			venda: null,
			mostrar: "indentificacao",
		};
	},

	computed: {
		TotalPago() {
			this.indexEditando;
			let pago = 0;
			this.financeiroNota.forEach(financeiro=>{
				pago += financeiro.valorParcela;
			});
			return pago;
		},
		FormasPagamento() {
			return this.$store.state.formasPagamento.filter(forma => forma.mostravenda === "S");
		},
		Produtos() {
			return this.$store.state.Produtos;
		},
		formatedProdutoTributacaoQuantidade:{
			get(){
				return this.produtoTributacao.quantidade.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.quantidade = numericValue;
				}
			}
		},
		formatedProdutoTributacaoValorUnitario:{
			get(){
				return this.produtoTributacao.valorUnitario.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorUnitario = numericValue;
				}
			}
		},
		formatedProdutoTributacaoValorTotal:{
			get(){
				return this.produtoTributacao.valorTotal.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorTotal = numericValue;
				}
			}
		},
		formatedProdutoTributacaoValorICMS:{
			get(){
				return this.produtoTributacao.valorICMS.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorICMS = numericValue;
				}
			}
		},
		formatedProdutoTributacaoBaseCalculo:{
			get(){
				return this.produtoTributacao.baseCalculo.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.baseCalculo = numericValue;
				}
			}
		},
		formatedProdutoTributacaoAliqICMS:{
			get(){
				return this.produtoTributacao.aliqICMS.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.aliqICMS = numericValue;
				}
			}
		},
		formatedProdutoTributacaoBasePis:{
			get(){
				return this.produtoTributacao.basePis.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.basePis = numericValue;
				}
			}
		},
		formatedProdutoTributacaoBaseIPI:{
			get(){
				return this.produtoTributacao.baseIPI.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.baseIPI = numericValue;
				}
			}
		},
		formatedProdutoTributacaoAliqPis:{
			get(){
				return this.produtoTributacao.aliqPis.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.aliqPis = numericValue;
				}
			}
		},
		formatedProdutoTributacaoAliqCofins:{
			get(){
				return this.produtoTributacao.aliqCofins.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.aliqCofins = numericValue;
				}
			}
		},
		formatedProdutoTributacaoAliqIPI:{
			get(){
				return this.produtoTributacao.aliqIPI.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.aliqIPI = numericValue;
				}
			}
		},
		formatedProdutoTributacaoValorPis:{
			get(){
				return this.produtoTributacao.valorPis.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorPis = numericValue;
				}
			}
		},
		formatedProdutoTributacaoValorCofins:{
			get(){
				return this.produtoTributacao.valorCofins.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorCofins = numericValue;
				}
			}
		},
		formatedProdutoTributacaoValorIPI:{
			get(){
				return this.produtoTributacao.valorIPI.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorIPI = numericValue;
				}
			}
		},
		formatedProdutoTributacaoValorFrete:{
			get(){
				return this.produtoTributacao.valorDoFrete.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorDoFrete = numericValue;
				}
			}
		},
		formatedProdutoTributacaoValorSeguro:{
			get(){
				return this.produtoTributacao.valorDoSeguro.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorDoSeguro = numericValue;
				}
			}
		},
		formatedProdutoTributacaoDesconto:{
			get(){
				return this.produtoTributacao.valorDesc.toString().replace(".",",");
			},
			set(value){
				let numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if(value == ""){
					numericValue = 0;
				}
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.valorDesc = numericValue;
				}
			}
		},
		formatedProdutoTributacaoOutrasDespesas:{
			get(){
				return this.produtoTributacao.outrasDespesas.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.produtoTributacao.outrasDespesas = numericValue;
				}
			}
		},
		formatedNotaBaseICMS:{
			get(){
				return this.nota.baseCalcICMS.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.baseCalcICMS = numericValue;
				}
			}
		},
		formatedNotaValorICMS:{
			get(){
				return this.nota.valorICMS.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.valorICMS = numericValue;
				}
			}
		},
		formatedNotaBaseICMSST:{
			get(){
				return this.nota.baseCalcICMSST.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.baseCalcICMSST = numericValue;
				}
			}
		},
		formatedNotaValorICMSSub:{
			get(){
				return this.nota.valorICMSSub.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.valorICMSSub = numericValue;
				}
			}
		},
		formatedNotaValorPis:{
			get(){
				return this.nota.valorPis.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.valorPis = numericValue;
				}
			}
		},
		formatedNotaValorCofins:{
			get(){
				return this.nota.valorCofins.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.valorCofins = numericValue;
				}
			}
		},
		formatedNotaVTotalProdutos:{
			get(){
				return this.nota.vTotalProdutos.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.vTotalProdutos = numericValue;
				}
			}
		},
		formatedNotaValorFrete:{
			get(){
				return this.nota.valorDoFrete.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.valorDoFrete = numericValue;
				}
			}
		},
		formatedNotaValorSeguro:{
			get(){
				return this.nota.valorDoSeguro.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.valorDoSeguro = numericValue;
				}
			}
		},
		formatedNotaDesconto:{
			get(){
				return this.nota.desconto.toString().replace(".",",");
			},
			set(value){
				let numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if(value == ""){
					numericValue = 0;
				}
				if (!isNaN(numericValue)) { 
					this.nota.desconto = numericValue;
				}
			}
		},
		formatedNotaOutrasDespesas:{
			get(){
				return this.nota.outrasDespesas.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.outrasDespesas = numericValue;
				}
			}
		},
		formatedNotaValorTotalIPI:{
			get(){
				return this.nota.valorTotalIPI.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.valorTotalIPI = numericValue;
				}
			}
		},
		formatedNotaVTotalNota:{
			get(){
				return this.nota.vTotalNota.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.vTotalNota = numericValue;
				}
			}
		},
		formatedNotaPesoBruto:{
			get(){
				return this.nota.pesoBruto.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.pesoBruto = numericValue;
				}
			}
		},
		formatedNotaPesoLiq:{
			get(){
				return this.nota.pesoLiquido.toString().replace(".",",");
			},
			set(value){
				const numericValue = parseFloat(value.replace(/[^\d,-]/g, "").replace(",", ".")); 
				if (!isNaN(numericValue)) { 
					this.nota.pesoLiquido = numericValue;
				}
			}
		}

	},
	watch:{
		produtos(){
			this.nota.baseCalcICMS=0;
			this.nota.valorICMS=0;
			this.nota.valorPis=0;
			this.nota.vTotalProdutos=0;
			this.nota.valorTotalIPI=0;
			this.nota.valorCofins=0;
			this.nota.vTotalNota=0;
			for(const produto of this.produtos){
				this.nota.baseCalcICMS+=Number(produto.baseCalculo);
				this.nota.valorICMS+=Number(produto.valorICMS);
				this.nota.valorPis+=Number(produto.valorPis);
				this.nota.valorCofins+=Number(produto.valorCofins);
				this.nota.vTotalProdutos+=Number(produto.valorTotal);
				this.nota.valorTotalIPI+=Number(produto.valorIPI);
			}
			this.nota.vTotalNota = Number(this.nota.vTotalProdutos) - Number(this.nota.desconto) + Number(this.nota.valorDoFrete) + Number(this.nota.valorDoSeguro) + Number(this.nota.outrasDespesas);
			this.nota.baseCalcICMS = Math.round(this.nota.baseCalcICMS * 100) / 100;
			this.nota.valorICMS = Math.round(this.nota.valorICMS * 100) / 100;
			this.nota.valorPis = Math.round(this.nota.valorPis * 100) / 100;
			this.nota.valorCofins = Math.round(this.nota.valorCofins * 100) / 100;
			this.nota.vTotalProdutos = Math.round(this.nota.vTotalProdutos * 100) / 100;
			this.nota.valorTotalIPI = Math.round(this.nota.valorTotalIPI * 100) / 100;
			this.nota.vTotalNota = Math.round(this.nota.vTotalNota * 100) / 100;
		},
		"produtoTributacao.valorUnitario":function (newVal){
			this.produtoTributacao.valorTotal =  Math.round((this.produtoTributacao.quantidade * newVal) * 100) / 100;
		},
		"produtoTributacao.quantidade":function (newVal){
			if(newVal <= 0){
				this.produtoTributacao.quantidade = 1;
				return;
			}
			this.produtoTributacao.valorTotal = Math.round((newVal * this.produtoTributacao.valorUnitario) * 100) / 100;
		},
		"produtoTributacao.valorTotal":function (newVal){
			this.produtoTributacao.valorUnitario = Math.round((newVal/this.produtoTributacao.quantidade) * 100) / 100;
		},
		"produtoTributacao.aliqPis":function (newVal){
			this.produtoTributacao.valorPis = Math.round((this.produtoTributacao.basePis * (newVal/100)) * 100) / 100;
		},
		"produtoTributacao.aliqCofins":function (newVal){
			this.produtoTributacao.valorCofins = Math.round((this.produtoTributacao.basePis * (newVal/100)) * 100) / 100;
		},
		"produtoTributacao.basePis":function (newVal){
			this.produtoTributacao.valorPis = Math.round((newVal * (this.produtoTributacao.aliqPis/100)) * 100) / 100;
			this.produtoTributacao.valorCofins = Math.round((newVal * (this.produtoTributacao.aliqCofins/100)) * 100) / 100;
		},
		"produtoTributacao.aliqIPI":function (newVal){
			this.produtoTributacao.valorIPI = Math.round((this.produtoTributacao.baseIPI * (newVal/100)) * 100) / 100;
		},
		"produtoTributacao.baseIPI":function (newVal){
			this.produtoTributacao.valorIPI = Math.round((newVal * (this.produtoTributacao.aliqIPI/100)) * 100) / 100;
		},
		"produtoTributacao.aliqICMS":function (newVal){
			this.produtoTributacao.valorICMS = Math.round((this.produtoTributacao.baseCalculo * (newVal/100)) * 100) / 100;
		},
		"produtoTributacao.baseCalculo":function (newVal){
			this.produtoTributacao.valorICMS = Math.round((newVal * (this.produtoTributacao.aliqICMS/100)) * 100) / 100;
		},
		"nota.desconto":function (newVal){
			this.nota.vTotalNota = Math.round((Number(this.nota.vTotalProdutos) - Number(newVal) + Number(this.nota.valorDoFrete) + Number(this.nota.valorDoSeguro) + Number(this.nota.outrasDespesas)) * 100) / 100;
			const totalProdutos = this.produtos.reduce((sum,produto) => sum + produto.quantidade, 0);
			const descontoUnitario = newVal / totalProdutos;
			for(const produto of this.produtos){
				produto.valorDesc = descontoUnitario * produto.quantidade;
			}
		},
		"nota.valorDoFrete":function (newVal){
			this.nota.vTotalNota = Math.round((Number(this.nota.vTotalProdutos) - Number(this.nota.desconto) + Number(newVal) + Number(this.nota.valorDoSeguro) + Number(this.nota.outrasDespesas)) * 100) / 100;
			const totalProdutos = this.produtos.reduce((sum,produto) => sum + produto.quantidade, 0);
			const freteUnitario = newVal / totalProdutos;
			for(const produto of this.produtos){
				produto.valorDoFrete = freteUnitario * produto.quantidade;
			}
		},
		"nota.valorDoSeguro":function (newVal){
			this.nota.vTotalNota = Math.round((Number(this.nota.vTotalProdutos) - Number(this.nota.desconto) + Number(this.nota.valorDoFrete) + Number(newVal) + Number(this.nota.outrasDespesas)) * 100) / 100;
			const totalProdutos = this.produtos.reduce((sum,produto) => sum + produto.quantidade, 0);
			const seguroUnitario = newVal / totalProdutos;
			for(const produto of this.produtos){
				produto.valorDoSeguro = seguroUnitario * produto.quantidade;
			}
		},
		"nota.outrasDespesas":function (newVal){
			this.nota.vTotalNota = Math.round((Number(this.nota.vTotalProdutos) - Number(this.nota.desconto) + Number(this.nota.valorDoFrete) + Number(this.nota.valorDoSeguro) + Number(newVal)) * 100) / 100;
			const totalProdutos = this.produtos.reduce((sum,produto) => sum + produto.quantidade, 0);
			const outrosUnitario = newVal / totalProdutos;
			for(const produto of this.produtos){
				produto.outrasDespesas = outrosUnitario * produto.quantidade;
			}
		}
	},
	beforeMount() {
	},
	mounted(){
		this.$bus.$on("confirmarPolar", this.polarConfirmado);	
	},
	destroyed(){
		this.$bus.$off("confirmarPolar", this.polarConfirmado);
	},
	methods: {
		mudarAba(evento) {
			this.mudandoAba = true;
			setTimeout(() => {
				const h3Id = evento.target.id;
				this.mostrar = h3Id;
				const abaControle = document.querySelector(".aba-controle.js-aba-nota");
				abaControle.querySelectorAll(".aba").forEach(aba => {
					if (aba.classList.contains("abaSelecionada")) {
						aba.classList.remove(["abaSelecionada"]);
					}
					if (aba.id == h3Id) {
						aba.classList.add(["abaSelecionada"]);
					}
				});
			}, 200);
		},
		removerPagamento(index) {
			if(this.indexEditando !== -1) return;
			this.financeiroNota.splice(index, 1);
		},
		editPagamento(index, pagamento) {
			
			this.financeiro.tipoPgto = pagamento?.cdTipo || pagamento?.cdtipo || pagamento?.cdTipoPgto || pagamento?.cdtipopgto;
			
			this.financeiro.cddig = pagamento?.cdDig || 0;
			
			this.financeiro.pagamento = pagamento?.pagamento || "";
			
			this.financeiro.valorParcela = `${pagamento.valorParcela}`.toLocaleString("pt-BR", {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			proximoCampo("valor-pagar");
			this.indexEditando = index;
		},
		finalizarLancamentoFinanceiro(){
			if(this.nota.vTotalNota > this.TotalPago){
				this.$store.commit("relatarErro", {
					mensagem: "O valor total da nota não foi satisfeito favo verifique!"
				});
			}
			else{
				this.editarFinanceiro = false;
			}
		},
		selecionarValor() {
			if(!this.financeiro.tipoPgto.selecionar && this.nota.vTotalNota !== 0){
				proximoCampo("valor-pagar");
				this.financeiro.valorParcela = (Number(this.nota.vTotalNota)-Number(this.TotalPago)).toFixed(2);
			}
		},
		adicionarPagamentoParcial() {
			try {
				this.financeiro.tipoPgto = this.FormasPagamento.find(forma => 
					forma.cdtipo === this.financeiro.tipoPgto);
				if(!this.validatePgto()) return;
			
				if(this.indexEditando !== -1) {
					this.financeiroNota[this.indexEditando] = {
						...this.financeiro.tipoPgto,
						valorParcela: this.financeiro.valorParcela,
						cddig: this.financeiro.cddig,
						pagamento: this.financeiro.pagamento,
					};
					this.financeiro = {
						valorParcela: "",
						tipoPgto: {selecionar: true}
					};
					this.indexEditando = -1;
					proximoCampo("tipo-pagamento");
					return;
				}

				this.financeiro.tipoPgto.valorParcela = this.financeiro.valorParcela;
				this.financeiroNota.push({
					...this.financeiro.tipoPgto,
					metodoParcela: this.financeiro?.tipoPgto?.metodofinanceiro ? this.financeiro.tipoPgto.metodofinanceiro : this.financeiro.tipoPgto?.descricao || this.financeiro.tipoPgto?.descricao
				});
				this.financeiro = {
					valorParcela: 0,
					tipoPgto: {selecionar: true}
				};
				this.indexEditando = -1;

				proximoCampo("tipo-pagamento");

			} catch (error) { 
				console.log(error);
			}
		},
		validatePgto() {
			try {
				this.financeiro.valorParcela = parseFloat(`${this.financeiro.valorParcela}`.replace(",", "."));
				if(!this.financeiro?.tipoPgto || this.financeiro?.tipoPgto?.selecionar)	 {
					throw new Error("Para adicionar o pagamento é necessário informar uma forma de pagamento!");
				}

				if(!this.financeiro.valorParcela) {
					throw new Error("Para adicionar o pagamento é necessário informar o valor a pagar!");
				}
				let totalParcela = this.TotalPago + this.financeiro.valorParcela;
				if(this.indexEditando !== -1){
					totalParcela -= this.financeiroNota[this.indexEditando].valorParcela;
				}
				if(parseFloat(totalParcela.toFixed(2)) > parseFloat(this.nota.vTotalNota.toFixed(2))) {
					throw new Error("Não é possível receber um valor maior que o total a pagar!");
				}

				if(this.indexEditando !== -1) return true;

				if(this.nota.vTotalNota === 0) {
					throw new Error("Você ja recebeu o valor total da nota!");
				}
				return true;
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
				this.clearFormFinanceiro();
				return false;
			}
		},
		updateProdutoXML(index){
			this.produtosXML[index].valorTotal = Math.round((this.produtosXML[index].valorUnitario * this.produtosXML[index].quantidade) * 100) / 100;
			this.produtosXML[index].valorDesc = Math.round((this.produtosXML[index].descontoUnitario * this.produtosXML[index].quantidade) * 100) / 100;
			this.produtosXML[index].outrasDespesas = Math.round((this.produtosXML[index].outrasDespesasUnitario * this.produtosXML[index].quantidade) * 100) / 100;
			this.produtosXML[index].valorDoFrete = Math.round((this.produtosXML[index].valorDoFreteUnitario * this.produtosXML[index].quantidade) * 100) / 100;
			this.produtosXML[index].valorDoSeguro = Math.round((this.produtosXML[index].valorDoSeguroUnitario * this.produtosXML[index].quantidade) * 100) / 100;
			this.produtosXML[index].baseCalculo = Math.round((this.produtosXML[index].valorTotal - this.produtosXML[index].valorDesc) * 100) / 100;
			this.produtosXML[index].valorICMS = Math.round((this.produtosXML[index].baseCalculo * (this.produtosXML[index].aliqICMS/100)) * 100) / 100;
			this.produtosXML[index].basePis = Math.round((this.produtosXML[index].quantidade * (this.produtosXML[index].basePis/this.produtosXML[index].quantidadeLimite)) * 100) / 100;
			this.produtosXML[index].valorPis = Math.round((this.produtosXML[index].basePis * (this.produtosXML[index].aliqPis/100)) * 100) / 100;
			this.produtosXML[index].valorCofins = Math.round((this.produtosXML[index].basePis * (this.produtosXML[index].aliqCofins/100)) * 100) / 100;
			this.produtosXML[index].baseIPI = Math.round((this.produtosXML[index].quantidade * (this.produtosXML[index].baseIPI/this.produtosXML[index].quantidadeLimite)) * 100) / 100;
			this.produtosXML[index].valorIPI = Math.round((this.produtosXML[index].baseIPI * (this.produtosXML[index].aliqIPI/100)) * 100) / 100;
			if(this.produtosXML[index].quantidade>0){
				this.produtosXML[index].selecionado = true;
			}
			if(this.produtosXML[index].quantidade<1){
				this.produtosXML[index].selecionado = false;
			}
		},
		enforceMinMax(event) {
			const el = event.target;
			if (el.value !== "") {
				if (parseInt(el.value) < parseInt(el.min)) {
					el.value = el.min;
				}
				if (parseInt(el.value) > parseInt(el.max)) {
					el.value = el.max;
				}
			}
		},
		polarConfirmado(item) {
			if (item.removeChaveDevolucao) {
				this.chaves.splice(item.indice,1);
			}
		},
		removeChaveDevolucao(indice){
			this.$store.commit("relatarErro", {
				mensagem: "Tem certeza que deseja remover esse documento?", 
				tipo: "polar",
				item: {
					removeChaveDevolucao: true,
					indice: indice
				}
			});
		},
		adicionaDocumentoReferenciado(){
			if(this.chaveDevolucao.length != 44){
				return this.$store.commit("relatarErro", { mensagem: "Numero de caracteres incompativel. A chave deve ter 44 caracteres!" });
			}
			const contem = this.chaves.filter((chave) => chave == this.chaveDevolucao);
			if(contem.length > 0){
				return this.$store.commit("relatarErro", { mensagem: "Essa chave já esta incluida!" });
			}
			this.chaves.push(this.chaveDevolucao);
			this.chaveDevolucao = "";
		},
		async adicionarProdutosXml(){
			const contem = this.chaves.filter((chave) => chave == this.xml.chave);
			let jaIncluiuChave = false;
			if(contem.length > 0){
				jaIncluiuChave = true;
			}
			const cnpjClienteLimpo = this.nota.cpfCliente.replaceAll(".","").replaceAll("/","").replaceAll("-","");
			if(cnpjClienteLimpo != "" && cnpjClienteLimpo != this.xml.clienteCnpj){
				return this.$store.commit("relatarErro", { mensagem: "Esse xml tem um cnpj diferente do cnpj da nota atual, por favor verifique!" });
			}
			let selecionouProduto = false;
			this.produtosXML.forEach((produto) =>{
				if(produto.selecionado){
					selecionouProduto = true;
					this.produtos.push(produto);
				}
			});
			if(selecionouProduto){
				if(!jaIncluiuChave){
					this.chaves.push(this.xml.chave);
				}
				const existeCliente= await this.$store.dispatch("filtrarClientes",{
					cnpj: this.xml.clienteCnpj,
					checkboxPesquisa: true,
				});
				if (!existeCliente){
					let maskedCnpj = this.xml.clienteCnpj.replace(/\D/g, "");
					if(maskedCnpj.length == 11){
						maskedCnpj = maskedCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
					}
					if(maskedCnpj.length == 14){
						maskedCnpj = maskedCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
					}
					let maskedCep = this.xml.cepCliente.replace(/\D/g, "");
					maskedCep = maskedCep.replace(/^(\d{5})(\d{3})$/, "$1-$2");
					this.nota.nomeCliente = this.xml.nomeCliente;
					this.nota.cpfCliente = maskedCnpj;
					this.nota.enderecoCliente =  this.xml.enderecoCliente;
					this.nota.bairroCliente =  this.xml.bairroCliente;
					this.nota.cepCliente =  maskedCep;
					this.nota.municipioCliente =  this.xml.municipioCliente;
					this.nota.ufCliente =  this.xml.ufCliente;
					this.nota.foneCliente =  this.xml.foneCliente;
					this.nota.ieCliente =  this.xml.ieCliente;
					this.clienteIconeXML = true;
				}
			}			
			this.listarProdutosXML = false;
		},
		async solicitarLancamentoXML() {
			const input = document.getElementById("js-xml");
			input.value = null;
			input.click();
		},
		async validarEntradaXML(xmlString) {
			this.$store.state.carregando = true;
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(xmlString, "application/xml");
			try {
				const existeCliente = await this.$store.dispatch("filtrarClientes",{
					cnpj: xmlDoc.querySelector("emit")?.querySelector("CNPJ")?.innerHTML,
					checkboxPesquisa: true,
				});
				if(existeCliente){
					this.selecionarCliente(existeCliente.content[0]);
					if(!this.alterarClienteNota(existeCliente.content[0])) return;
				}
				this.xml = {
					chave: xmlDoc.querySelector("infProt")?.querySelector("chNFe")?.innerHTML,
					clienteCnpj: xmlDoc.querySelector("emit")?.querySelector("CNPJ")?.innerHTML,
					nomeCliente: xmlDoc.querySelector("emit")?.querySelector("xNome")?.innerHTML,
					enderecoCliente: xmlDoc.querySelector("emit")?.querySelector("enderEmit")?.querySelector("xLgr")?.innerHTML,
					bairroCliente: xmlDoc.querySelector("emit")?.querySelector("enderEmit")?.querySelector("xBairro")?.innerHTML,
					cepCliente: xmlDoc.querySelector("emit")?.querySelector("enderEmit")?.querySelector("CEP")?.innerHTML,
					municipioCliente: xmlDoc.querySelector("emit")?.querySelector("enderEmit")?.querySelector("xMun")?.innerHTML,
					municipioIBGECliente: xmlDoc.querySelector("emit")?.querySelector("enderEmit")?.querySelector("cMun")?.innerHTML,
					ufCliente: xmlDoc.querySelector("emit")?.querySelector("enderEmit")?.querySelector("UF")?.innerHTML,
					foneCliente: xmlDoc.querySelector("emit")?.querySelector("enderEmit")?.querySelector("fone")?.innerHTML,
					ieCliente: xmlDoc.querySelector("emit")?.querySelector("IE")?.innerHTML,
				};
				const itens = xmlDoc.querySelectorAll("det");
				this.produtosXML = [];
				itens.forEach(async (item,index) => {
					const tagInternaICMS = item.querySelector("imposto")?.querySelector("ICMS")?.firstElementChild.localName;
					const tagInternaPIS = item.querySelector("imposto")?.querySelector("PIS")?.firstElementChild.localName;
					const tagInternaIPI = item.querySelector("imposto")?.querySelector("IPI")?.children[1].localName;
					const tagInternaCOFINS = item.querySelector("imposto")?.querySelector("COFINS")?.firstElementChild.localName;
					const chavePosInt =  xmlDoc.querySelector("infProt")?.querySelector("chNFe")?.innerHTML+"-"+index;
					const jaAdicionou = this.produtos.filter((produto) => produto.chavePos == chavePosInt).length > 0;
					this.produtosXML.push({
						codigo: item.querySelector("prod")?.querySelector("cProd")?.innerHTML,
						cdreferencia: item.querySelector("prod")?.querySelector("cProd")?.innerHTML,
						codbarraFornecedor: `${item.querySelector("cEANTrib")?.innerHTML}`.substring(0,12),
						descricao: item.querySelector("prod")?.querySelector("xProd")?.innerHTML,
						ncm: item.querySelector("prod")?.querySelector("NCM")?.innerHTML,
						unidade: item.querySelector("prod")?.querySelector("uTrib")?.innerHTML,
						quantidade: item.querySelector("prod")?.querySelector("qTrib")?.innerHTML,
						valorUnitario:  (Math.round(item.querySelector("prod")?.querySelector("vUnCom")?.innerHTML*100)/100) || 0,
						valorTotal: item.querySelector("prod")?.querySelector("vProd")?.innerHTML || 0,
						cst: item.querySelector("imposto")?.querySelector("ICMS")?.querySelector(tagInternaICMS)?.querySelector("CST")?.innerHTML || item.querySelector("imposto")?.querySelector("ICMS")?.querySelector(tagInternaICMS)?.querySelector("CSOSN")?.innerHTML,
						reducaoBC: item.querySelector("imposto")?.querySelector("ICMS")?.querySelector(tagInternaICMS)?.querySelector("pRedBC")?.innerHTML || 0,//% se for usar valor fazer calculo
						baseCalculo: item.querySelector("imposto")?.querySelector("ICMS")?.querySelector(tagInternaICMS)?.querySelector("vBC")?.innerHTML  || 0,
						aliqICMS: item.querySelector("imposto")?.querySelector("ICMS")?.querySelector(tagInternaICMS)?.querySelector("pICMS")?.innerHTML  || 0,
						valorICMS: item.querySelector("imposto")?.querySelector("ICMS")?.querySelector(tagInternaICMS)?.querySelector("vICMS")?.innerHTML  || 0,
						basePis: item.querySelector("imposto")?.querySelector("PIS")?.querySelector(tagInternaPIS)?.querySelector("vBC")?.innerHTML  || 0,
						baseIPI: item.querySelector("imposto")?.querySelector("IPI")?.querySelector(tagInternaIPI)?.querySelector("vBC")?.innerHTML  || 0,
						enquadramento: "",
						cstPis: item.querySelector("imposto")?.querySelector("PIS")?.querySelector(tagInternaPIS)?.querySelector("CST")?.innerHTML,
						aliqPis: item.querySelector("imposto")?.querySelector("PIS")?.querySelector(tagInternaPIS)?.querySelector("pPIS")?.innerHTML  || 0,
						valorPis: item.querySelector("imposto")?.querySelector("PIS")?.querySelector(tagInternaPIS)?.querySelector("vPIS")?.innerHTML  || 0,
						cstCofins: item.querySelector("imposto")?.querySelector("COFINS")?.querySelector(tagInternaCOFINS)?.querySelector("CST")?.innerHTML,
						aliqCofins: item.querySelector("imposto")?.querySelector("COFINS")?.querySelector(tagInternaCOFINS)?.querySelector("pCOFINS")?.innerHTML  || 0,
						valorCofins: item.querySelector("imposto")?.querySelector("COFINS")?.querySelector(tagInternaCOFINS)?.querySelector("vCOFINS")?.innerHTML  || 0,
						cstIPI: item.querySelector("imposto")?.querySelector("IPI")?.querySelector(tagInternaIPI)?.querySelector("CST")?.innerHTML,
						aliqIPI: item.querySelector("imposto")?.querySelector("IPI")?.querySelector(tagInternaIPI)?.querySelector("pIPI")?.innerHTML  || 0,
						valorIPI: item.querySelector("imposto")?.querySelector("IPI")?.querySelector(tagInternaIPI)?.querySelector("vIPI")?.innerHTML  || 0,
						cfop: item.querySelector("prod")?.querySelector("CFOP")?.innerHTML,
						valorDesc: item.querySelector("prod")?.querySelector("vDesc")?.innerHTML || 0,
						outrasDespesas: item.querySelector("prod")?.querySelector("vOutro")?.innerHTML || 0,
						valorDoFrete: item.querySelector("prod")?.querySelector("vFrete")?.innerHTML || 0,
						valorDoSeguro: item.querySelector("prod")?.querySelector("vSeg")?.innerHTML || 0,

						quantidadeLimite: item.querySelector("prod")?.querySelector("qTrib")?.innerHTML,
						desabilitado: jaAdicionou,
						selecionado: jaAdicionou,
						descontoUnitario: (item.querySelector("prod")?.querySelector("vDesc")?.innerHTML || 0)/(item.querySelector("prod")?.querySelector("qTrib")?.innerHTML || 1),
						outrasDespesasUnitario: (item.querySelector("prod")?.querySelector("vOutro")?.innerHTML || 0)/(item.querySelector("prod")?.querySelector("qTrib")?.innerHTML || 1),
						valorDoFreteUnitario: (item.querySelector("prod")?.querySelector("vFrete")?.innerHTML || 0)/(item.querySelector("prod")?.querySelector("qTrib")?.innerHTML || 1),
						valorDoSeguroUnitario: (item.querySelector("prod")?.querySelector("vSeg")?.innerHTML || 0)/(item.querySelector("prod")?.querySelector("qTrib")?.innerHTML || 1),
						chavePos: chavePosInt
					});
				});
				this.listarProdutosXML = true;
			} catch (error) {
				console.log(error);
				this.$store.state.carregando = false;
				this.$store.commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
				throw new Error(error.message || JSON.stringify(error));
			}
		},
		async lerXMLString(docXML) {
			try {
				this.$store.state.carregando = true;
				if (!docXML) throw new Error("Não contém a string XML em docXML!");
				await this.validarEntradaXML(docXML);
				this.$store.state.carregando = false;
			} catch (error) {
				console.log(error);
				this.$store.state.carregando = false;
				return this.$store.commit("relatarErro", { mensagem: error.message || JSON.stringify(error) });
			}
		},
		lerArquivoXML(event) {
			try {
				this.modalBuscaXML = false;
				if (!event.target.files) {
					throw new Error("Nenhum arquivo selecionado!");
				}
				const file = event.target.files[0];
				if (!file.type.match("text/xml")) {
					throw new Error("Arquivo não suportado!");
				}
				const reader = new FileReader();
				this.$store.state.carregando = true;
				reader.onload = () => {
					this.lerXMLString(reader.result);
				};
				reader.readAsText(file);
			} catch (error) {
				console.log(error);
				this.$store.state.carregando = false;
				return setTimeout(() => {
					this.$store.commit("relatarErro", {mensagem: error.message || (error.details || JSON.stringify(error))});
				}, 20);
			}
		},
		proximoCampo(id) {
			try {
				if (!id) {
					throw new Error("Falta o atribuito id!");
				}
				setTimeout(() => {
					const input = document.getElementById(id);
					if (input) {
						input.focus();
					}
					if(input.select){
						input.select();
					}
				},20);
			} catch (error) {
				console.log(error);
			}
		},
		removeProduto(indice){
			this.produtos.splice(indice,1);
		},
		async digitouCFOP(){
			if(this.nota.cfop.length == 4){
				const response = await this.$store.dispatch("buscarUnicoCFOP", this.nota.cfop);
				if(response){
					this.nota.natureza = response.descricao;
				}
				else{
					this.nota.natureza = "";
				}
			}
			else{
				this.nota.natureza = "";
			}
		},
		validarCampos() {
			try {
				if(!this.nota.cfop){
					this.$store.commit("relatarErro", {
						mensagem: "CFOP não informado.\nFavor informar e tente novamente!", 
					});
					return false;
				}
				if(!this.nota.ufCliente){
					this.$store.commit("relatarErro", {
						mensagem: "Destinatário ou campos nescessários não informados.\nFavor informar os dados e tente novamente!", 
					});
					return false;
				}
				if(this.nota.vTotalNota > this.TotalPago){
					this.$store.commit("relatarErro", {
						mensagem: "O valor total da nota não foi satisfeito favo verifique o lançamento do financeiro!"
					});
					return false;
				}
				if(!this.cliente?.id){
					this.$store.commit("relatarErro", {
						mensagem: "Cliente não cadastrado, favor verifique!"
					});
					return false;
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message,
				});
				return false;
			}
		},
		async adicionarNota() {
			if (!this.validarCampos()) return;
			let itens = [];
			for(const [indice, produto] of this.produtos.entries()){
				if (!produto.vinculado) {
					const produtoCadastrado = await this.$store.dispatch("cadastrarProduto", {
						...produto,
						formacaoPreco: {
							[this.$store.state.Empresa.id]: new FormacaoPrecoCompra({...produto, saldo: 0, ultsaldo: 0})
						},
					});
					if (produtoCadastrado ? !produtoCadastrado.id : true) {
						throw new Error(`Erro ao cadastrar ${produto.descricao}.`+"\n"+produtoCadastrado.message);
					}
					produto.codigo = produtoCadastrado.id;
					produto.variacao = produtoCadastrado.variacao;
					produto.grade = produtoCadastrado.grade;
					produto.ecf = produtoCadastrado.ecf;
				}
				itens.push(
					{
						...produto,
						cdseq:indice,
						custo: produto.valorUnitario,
						valorUnitario: produto.valorUnitario,
						quantidade: produto.quantidade,
						produto:{
							cdproduto: produto.codigo,
							descricao: produto.descricao,
							unidade: produto.unidade,
							custo: produto.valorUnitario,
						},
						desconto: produto.valorDesc,
					}
				);
			}
			const cliente = {//ver de mudar conforme nescessario para back
				id: this.cliente?.id || this.$store.state.ClientePadrao.id,
				cnpj: this.cliente?.cnpj || this.nota.cpfCliente,
				ierg: this.cliente?.ierg || this.nota.ieCliente,
				nome: this.cliente?.nome || this.nota.nomeCliente,
				fone: this.cliente?.fone || this.nota.foneCliente,
				endereco: this.cliente?.endereco || this.nota.enderecoCliente,
				bairro: this.cliente?.bairro || this.nota.bairroCliente,
				cep: this.cliente?.cep || this.nota.cepCliente,
				cidade: this.cliente?.cidade?.nomecidade || this.nota.municipioCliente,
				uf: this.cliente?.cidade?.uf || this.nota.ufCliente,
				dataNascimento: this.cliente?.dataNascimento,
				numero: this.cliente?.numero,				
			};
			const payloadPedido = {
				cdEmpresa: this.$store.state.Empresa.id,
				cdVenda: null,
				dataEmissao: this.nota.dataEmissao+"T00:00:00.000",
				dataentsaida: this.nota.dataMovimentacao,
				baixarCondicional: false,
				emitirNota:null,
				vendedor: {id:this.$store.state.Usuario.cdVendedor || this.$store.state.Usuario.id},
				cliente: cliente,
				desmoeda: 0,
				desperc: 0,
				itens: itens,
				valorComissao: 0,
				troca: 0,
				vlrcondicional: 0,
				valorbruto: this.nota.vTotalProdutos,
				valorfrete: this.nota.valorDoFrete,
				fretegratis: "N",
				financeiro: this.financeiroNota,
				mesa: false,
				descontoMoeda: this.nota.desconto,
				acrescimo: this.nota.outrasDespesas,
				descontoPercentual: 0,
				cfop: this.nota.cfop,
				observacao: "",
			};
			if(this.venda){				
				this.venda = await this.$store.dispatch("atualizarVendaNFE", { ...payloadPedido, cdVenda: this.venda.cdVenda });
			}
			else{
				this.venda = await this.$store.dispatch("inserirVendaComPagamento", { ...payloadPedido });
			}
			let documentos = [];
			this.chaves.forEach((chave)=>{
				documentos.push(
					{
						cdempresa: this.$store.state.Empresa.id,
						cdvenda: this.venda.cdVenda || this.venda.cdvenda,
						tipo:"E",
						xml:chave,
					}
				);
			});
			const transporte = {
				cdtransporte: this.clienteTransporte?.id,
				cfop: this.nota.cfop,
				especie: this.nota.especie,
				tipoFrete: this.nota.tipoFrete,
				marca: this.nota.marca,
				observacao: this.nota.observacao,
				peso: this.nota.pesoBruto,
				pesoliq: this.nota.pesoLiquido,
				quantidade: this.nota.quantidade,
				valorfrete: this.nota.valorDoFrete,
				documentos: documentos,
				operacao: this.tipoOperacao == 1? "S":"E",//ver como vai ser no back
				entrega: this.clienteEntrega?.id,//ver como vai ser no back
			};
			this.$store.dispatch("gerarNFE", { cdVenda: this.venda.cdVenda || this.venda.cdvenda, tipoNota:"A", body: transporte });
		},
		async iniciarData() {//alterar
			this.$store.state.carregando = true;
			setTimeout(async () => {
				if (this.Fornecedor?.id) {
					this.produtoCadastrado.fornecedor = {...this.Fornecedor};
				}
				if ((this.PropProduto && !this.PropProduto?.novo) || (this.PropProduto?.novo && this.PropProduto?.duplicando)) {
					let grupoBanco = null;
					if (this.PropProduto.cdgrupo) {
						grupoBanco = await this.verificarGrupo();
					}
					this.produtoCadastrado = this.preencherProdutoProp(grupoBanco);
					if(this.produtoCadastrado.idImagem !== ""){
						this.imagemProdutoSrc = "https://drive.google.com/thumbnail?id="+this.produtoCadastrado.idImagem;
					}
					this.proximoCampo("cdproduto");
				} else {
					this.proximoCampo("buscaGrupo");
					this.produtoCadastrado.subGrupoSelecionado.nenhumSelecionado = true;
				}
				if(!this.PropProduto?.novo){
					const input = document.getElementById("cdbarrafornecedor");
					input.disabled = true;
				}
				this.bloqueiaAlterarCampos();
				this.$store.state.carregando = false;
			}, 500);
		},
		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
		iniciaAdicaoProduto(){
			if(!this.nota.ufCliente){
				return this.$store.commit("relatarErro", {
					mensagem: "Destinatário ou campos nescessários não informados.\nFavor informar os dados e tente novamente", 
				});
			}
			this.modalProdutos = true;
		},
		selecionarProduto(produto){
			this.modalProdutos = false;
			this.produtoTributacao = {
				codigo:produto.id,
				descricao:produto.descricao,
				ncm:produto.ncm,
				unidade:produto.unidade,
				quantidade:1,
				valorUnitario:produto.valorPrazo,
				valorTotal:1*produto.valorPrazo,
				cst: (this.$store.state.Empresa.ufCidade == this.nota.ufCliente? produto.icmsestsittrib : produto.icmsintsittrib) || 0,
				reducaoBC:0,
				baseCalculo:0,
				aliqICMS:(this.$store.state.Empresa.ufCidade == this.nota.ufCliente? produto.icmsest : produto.icmsint) || 0,
				valorICMS:0,
				basePis:0,
				baseIPI:0,
				enquadramento:0,
				cstPis:produto.pissittrib || 0,
				aliqPis: produto.pistributacao || 0,
				pis: 0,
				cstCofins: produto.cofinssittrib || 0,
				aliqCofins: produto.cofinstributacao || 0,
				cofins: 0,
				cstIPI: produto.ipisittrib || 0,
				aliqIPI: produto.ipitributacao || 0,
				valorIPI:produto.valoripi || 0,
				valorPis:produto.valorpis || 0,
				valorCofins:produto.valorcofins || 0,
				cfop:this.nota.cfop,
				valorDesc:0,
				outrasDespesas: 0,
				valorDoFrete: 0,
				valorDoSeguro: 0,
				adicionado:true,
				vinculado: produto.id,
				desconto: 0,
			};
			this.modalProdutoTributacao = true;
		},
		associarProduto(produto){
			this.produtos[this.indiceAssociar].vinculado = produto.id;
			this.indiceAssociar = false;
			this.linkarProduto = false;
		},
		editarProduto(produto,index){
			this.produtoTributacao = {...produto};
			this.indiceProdutoTributacao = index;
			this.modalProdutoTributacao = true;
		},
		salvarProdutoTributacao(){
			if(!this.indiceProdutoTributacao && this.indiceProdutoTributacao!=0){
				this.produtos.push(this.produtoTributacao);
			}
			else{
				this.produtos[this.indiceProdutoTributacao]=this.produtoTributacao;
			}
			this.modalProdutoTributacao = false;
			this.indiceProdutoTributacao = null;
		},
		escolheCfop(cfop){
			this.nota.cfop = cfop.cfop;
			this.nota.natureza = cfop.descricao;
			this.listaCfop = false;
		},
		async adicionarCliente(){
			const classiPadrao = await this.$store.dispatch("buscarClassificacaoPadrao");
			const cidade = await this.$store.dispatch("buscarIbge", this.xml.municipioIBGECliente);
			const payload = {
				bairro: this.nota.bairroCliente,
				cep: this.nota.cepCliente,
				cidade: {
					cdcidade: cidade ? cidade.cdcidade : 0,
					codibge: this.xml.municipioIBGECliente,
					nomecidade: this.nota.municipioCliente,
					uf: this.nota.ufCliente,
				},
				cnpj: this.nota.cpfCliente,
				endereco: this.nota.enderecoCliente,
				fone: this.nota.foneCliente,
				ierg: this.nota.ieCliente,
				nome: this.nota.nomeCliente,
				dataCadastro: new Date().toLocaleDateString("en-GB").split("/").reverse().join("-"),
				classificacao: classiPadrao,
			};
			this.cliente = await this.$store.dispatch("adicionarCliente", { ...payload });
		},
		selecionarCliente(cliente){
			this.clienteSelecioando = cliente;			
		},
		alterarClienteNota(cliente){
			try{
				this.cliente = cliente;
				this.listarClientes = false;
				this.nota.nomeCliente=cliente.nome;
				this.nota.cpfCliente=cliente.cnpj;
				this.nota.enderecoCliente=cliente.endereco;
				this.nota.bairroCliente=cliente.bairro;
				this.nota.cepCliente=cliente.cep;
				this.nota.municipioCliente=cliente.cidade.nomecidade;
				this.nota.ufCliente=cliente.cidade.uf;
				this.nota.foneCliente=cliente.fone;
				this.nota.ieCliente=cliente.ierg;
				this.listarClientes=false;
				return true;
			}
			catch(error){
				this.$store.commit("relatarErro", {
					mensagem: "Houve um erro ao carregar os dados do cliente, verifique e tente novamente!", 
				});
				return false;
			}
		},
		limparTransporte(){
			this.clienteTransporte = null;
			this.nota.nomeTransporte = "";
			this.nota.cpfTransporte = "";
			this.nota.enderecoTransporte = "";
			this.nota.municipioTransporte = "";
			this.nota.ufTransporte = "";
			this.nota.ieTransporte = "";
		},
		selecionarClienteTransporte(cliente){
			this.ClienteTransporteSelecionado = cliente;
		},
		selecionarClienteEntrega(cliente){
			this.ClienteEntregaSelecionado = cliente;
		},
		alterarClienteTransporteNota(cliente){
			this.clienteTransporte = cliente;
			this.listarClientesTransporte = false;
			this.nota.nomeTransporte=cliente.nome;
			this.nota.cpfTransporte=cliente.cnpj;
			this.nota.enderecoTransporte=cliente.endereco;
			this.nota.municipioTransporte=cliente.cidade.nomecidade;
			this.nota.ufTransporte=cliente.cidade.uf;
			this.nota.ieTransporte=cliente.ierg;
			setTimeout(() => {
				document.getElementById("tipoFrete").focus();
				document.getElementById("tipoFrete").select();
			}, 50);
		},
		alterarClienteEntregaNota(cliente){
			this.clienteEntrega = cliente;
			this.listarClientesEntrega = false;
			this.nota.nomeEntrega = cliente.nome;
			this.nota.cpfEntrega = cliente.cnpj;
			this.nota.enderecoEntrega = cliente.endereco;
			this.nota.municipioEntrega = cliente.cidade.nomecidade;
			this.nota.ufEntrega = cliente.cidade.uf;
			this.nota.ieEntrega = cliente.ierg;
			this.nota.bairroEntrega = cliente.bairro;
			this.nota.cepEntrega = cliente.cep;
			this.nota.foneEntrega = cliente.fone;
		},
	},
};
</script>

<style scoped>
.aba-controle{
	height: 30px;
	min-height: 30px;
	max-height: 30px;
}
.aba{
	font-size: 14px;
}
.campos-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: space-around;
	margin-top: 15px;
}

.campos-container .input-group{
	width: 50%;
}
.container-tabela-pagamento{
	width: 100%;
	height: 40vh;
	overflow-y: auto;
}
.input-group{
	margin-right: 0px;
}
.row{
	margin-right: 10px;
}
.container-page{
	height: 88vh;
	width: 100%;
	overflow-y: auto;
	padding-bottom: 10px;
}
.container-form{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100% - 60px);
	margin-top: 30px;
	gap:15px;
}
.botoes{
	gap: 20px;
	justify-content: space-between;
	width: 70%;
}
.botoes button{
	width: 45%;
}
.sm-view {
	display: none;
}
/* MEDIA QUERIES */
@media screen and (max-width: 1080px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}
	.row {
		flex-wrap: wrap;
	}
	.row .input-group-minimalista {
		margin-top: 15px;
	}
	.row .input-group {
		margin-top: 15px;
	}
}

@media screen and (max-width: 440px) {
	.botao i{
		display: none;
	}
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.botoes {
		width: 100%;
		gap: 10px;
		margin-bottom: 10px;
	}
	.row{
		margin-right: 0px;
	}
}

@media screen and (max-width: 425px){	
	.container-form{
		grid-template-columns: auto 200px;
		gap: 10px;
	}
	.botoes{
		margin-top: 25px;
	}
}
@media screen and (max-width: 425px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
	}
	.input-group {
		width: 46%;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}
}
@media screen and (max-width: 400px) {
	.botao i{
		display:none;
	}
}
@media screen and (max-width: 375px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}
}
@media screen and (max-width: 320px) {
	.container-page {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container-form {
		width: 100%;
		gap: 15px 5px;
	}
	.lg-view {
		display: none;
	}
	.sm-view {
		display: inline-block;
	}
	.botoes {
		gap: 10px;
		margin-bottom: 10px;
	}
}
</style>