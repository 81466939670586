import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
Vue.use(VueRouter);

import Principal from "../pages/Principal.vue";
import Lancamento from "../pages/Lancamento.vue";
import FinalizarVenda from "../pages/FinalizarVenda.vue";
import ContasReceber from "../pages/ContasReceber.vue";
import FecharCaixa from "../pages/FecharCaixa.vue";
import Gerenciamento from "../pages/Gerenciamento.vue";
import Caixa from "../pages/Caixa.vue";
import ConsultaVendas from "../pages/ConsultaVendas.vue";
import Parametros from "../pages/Parametros.vue";
import EtiquetaVitrine from "../pages/EtiquetaVitrine.vue";

import SelecionaCaixa from "../components/SelecionaCaixa.vue";
import SelecionaEmpresa from "../components/SelecionaEmpresa.vue";
import TrocarSenha from "../components/TrocarSenha.vue";
import SolicitaAutorizacao from "../components/SolicitaAutorizacao.vue";
import Autenticacao from "../components/Autenticacao.vue";
import CadastroCliente from "../components/CadastroCliente.vue";
import Impressao from "../pages/Impressao.vue";
import Relatorios from "../pages/Relatorios.vue";
import CadastroProduto from "../components/CadastroProduto.vue";
import Empresas from "../pages/Empresas.vue";
import Acessos from "../pages/Acessos.vue";
import LancamentoMesa from "../pages/LancamentoMesa.vue";
import LancamentoLanchonete from "../pages/LancamentoLanchonete.vue";
import CardapioOnline from "../pages/CardapioOnline.vue";
import FechamentoMesa from "../pages/FechamentoMesa.vue";
import Logs from "../pages/Logs.vue";
import Financeiro from "../pages/Financeiro.vue";
import CadastroClassificacao from "../components/CadastroClassificacao";

const routes = [
	{ name: "Principal", path: "/", component: Principal },
	{ name: "Lancamento", path: "/lancamento", component: Lancamento },
	{ name: "LancamentoMesa", path: "/lancamentoMesa", component: LancamentoMesa },
	{ name: "LancamentoLanchonete", path: "/lancamentoLanchonete", component: LancamentoLanchonete },
	{ name: "CardapioOnline", path: "/cardapioOnline", component: CardapioOnline },
	{ name: "Fechamento", path: "/fechamento", component: FinalizarVenda },
	{ name: "FechamentoMesa", path: "/fechamentoMesa", component: FechamentoMesa },
	{ name: "FecharCaixa", path: "/fecharCaixa", component: FecharCaixa },
	{ name: "ContasReceber", path: "/contasreceber", component: ContasReceber },
	{ name: "SolicitaAutorizacao", path: "/solicitaAutorizacao", component: SolicitaAutorizacao },
	{ name: "TrocarSenha", path: "/trocarSenha", component: TrocarSenha },
	{ name: "Gerenciamento", path: "/gerenciamento", component: Gerenciamento },
	{ name: "SelecionaCaixa", path: "/selecionaCaixa", component: SelecionaCaixa },
	{ name: "SelecionaEmpresa", path: "/selecionaEmpresa", component: SelecionaEmpresa },
	{ name: "Autenticacao", path: "/!autenticado", component: Autenticacao },
	{ name: "Caixa", path: "/caixa", component: Caixa },
	{ name: "Vendas", path: "/vendas", component: ConsultaVendas },
	{ name: "Financeiro", path: "/financeiro", component: Financeiro },
	{ name: "CadastroCliente", path: "/cliente", component: CadastroCliente },
	{ name: "Parametros", path: "/parametros", component: Parametros },
	{ name: "Impressao", path: "/impressao", component: Impressao },
	{ name: "Relatorios", path: "/relatorios", component: Relatorios },
	{ name: "CadastroProduto", path: "/produto", component: CadastroProduto },
	{ name: "Empresas", path: "/empresas", component: Empresas },
	{ name: "Acessos", path: "/acessos", component: Acessos },
	{ name: "Logs", path: "/logs", component: Logs},
	{ name: "CadastroClassificacao", path: "/classificacao", component: CadastroClassificacao},
	{ name: "etiqueta", path: "/etiqueta", component: EtiquetaVitrine},
];

const router = new VueRouter({
	routes,
	mode: "history",
});

router.beforeEach((to, from, next) => {

	if (to.query?.userName) {
		const { userName, password, empresa } = to.query;
		if (!userName || !password) {
			store.dispatch("logOut");
			store.commit("relatarErro", { mensagem: "Credenciais ausentes para autenticação!" });
			return next({ name: "Autenticacao" });
		}
		return store.dispatch("autenticarUsuario", { usuario: userName, senha: password, retornarLogin: true }).then((usuario) => {
			if (usuario.empresa) {
				store.dispatch("buscarEmpresaId", { id: usuario.empresa }).then(emp => {
					if (emp?.id) {
						store.commit("atualizarEmpresa", emp);
						store.commit("selecionarCaixa", {
							caixa: {
								aberto: "S",
								cdcaixa: 0,
								identificador: "SEM CAIXA",
								cdempresa: emp.id
							}
						});
						store.dispatch("buscarStateInicial", { atualizar: true });
						next();
					} else {
						next({ name: "SelecionaEmpresa" });
					}
				});
			} else if (empresa) {
				store.dispatch("buscarEmpresaPorAtalho", empresa).then(empLogin => {
					if (empLogin.cdempresa) {
						store.commit("atualizarEmpresa", empLogin);
						store.commit("selecionarCaixa", {
							caixa: {
								aberto: "S",
								cdcaixa: 0,
								identificador: "SEM CAIXA",
								cdempresa: empLogin.cdempresa
							}
						});
						store.dispatch("buscarStateInicial", { atualizar: true });
						next();
					} else {
						next({ name: "SelecionaEmpresa" });
					}
				});
			}
		});
	}
	else if (to.name !== "Autenticacao" && !store.state.Usuario) return next({ name: "Autenticacao" });
	else if (to.name == "Principal" || to.name == "Autenticacao") {
		if (!to.query.autorizacao || (to.query.autorizacao && !store.state.Usuario)) {
			delete to.query.autorizacao;
			store.state.Venda = null;
			store.state.itensVenda = [];
		}
	}
	if ((to.name == "Principal" || from.name == "Impressao") && store.state.Usuario && (!store.state.Usuario.supervisor && !store.state.Usuario.caixa) && !store.state.Usuario.permiteBaixarCondicional && !store.state.Usuario.vendarapida) {
		store.dispatch("logOut");
		return next({ name: "Autenticacao" });
	}
	if ((to.name == "Principal" || from.name == "Impressao") && store.state.Usuario.vendarapida) {
		return next({ name: "Lancamento" });
	}
	next();
});
Vue.use(router);

export default router;