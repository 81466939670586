const userApi = {
	password: process.env.VUE_APP_API_PASSWORD,
	userName: process.env.VUE_APP_API_USERNAME,
};

const userApiClientes = {
	login: process.env.VUE_APP_API_CLIENTES_USERNAME,
	senha: process.env.VUE_APP_API_CLIENTES_PASSWORD
};

const parseJwt = (token) => {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
		return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(""));
	return jsonPayload;
};

const isTokenExpired = () => {
	const localJwt = sessionStorage.getItem("jwt-decoded");
	if (!localJwt) {
		sessionStorage.clear();
		return true;
	}
	const decodedJwt = JSON.parse(localJwt);
	if (!decodedJwt) {
		sessionStorage.clear();
		return true;
	}
	return decodedJwt.exp < new Date().getTime()/1000 ? true : false;
};

const getAuthorizationApi = async (commit, API_URL) => {
	try {
		const body = JSON.stringify(userApi);
		const response = await fetch(`${API_URL}/auth`, {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},
			body,
			cache: "no-cache"
		});
		const data = await response.json();
		if (!data.token || data.token == undefined){
			return commit("relatarErro", {mensagem: "Erro ao autenticar com API"});
		}
		saveAuthorizationHeaders(data);
		return {token: data.token, tipo: data.tipo};
	} catch (error) {
		console.log(error);
	}
};

export const getAuthorizationApiIntercept = async (commit, API_URL, BASE_URL) => {
	try {
		const body = JSON.stringify({
			data: userApi,
			method: "post",
			url: `${API_URL}/auth`,
			headers: {
				"Content-Type": "application/json"
			}
		});
		const response = await fetch(`${BASE_URL}/interceptor`, {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},
			body,
			cache: "no-cache"
		});
		const data = await response.json();
		if (!data.token || data.token == undefined){
			return commit("relatarErro", {mensagem: "Erro ao autenticar com API"});
		}
		saveAuthorizationHeaders(data);
		return {token: data.token, tipo: data.tipo};
	} catch (error) {
		console.log(error);
	}
};

const getAuthorizationApiClientes = async (commit, API_URL) => {
	try {
		const url = `${API_URL}/login`;
		const bodyLoginJSON = JSON.stringify(userApiClientes);
		const res = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: bodyLoginJSON,
		});
		const data = await res.json();
		if (!data.token || data.token == undefined){
			return commit("relatarErro", {mensagem: "Erro ao autenticar com API de clientes"});
		}
		saveAuthorizationHeadersClientesAPI(data);
	} catch (error) {
		console.error(error);
	}
	
};

const saveAuthorizationHeadersClientesAPI = ({token}) => {
	sessionStorage.setItem("token-clientes", token);
	sessionStorage.setItem("token-clientes-decoed", parseJwt(token));
};

const saveAuthorizationHeaders = ({token, tipo}) => {
	sessionStorage.setItem("jwt-decoded", parseJwt(token));
	sessionStorage.setItem("jwt-token", token);
	sessionStorage.setItem("jwt-tipo", tipo);
};

export default {
	getAuthorizationHeaders: async (commit, BASE_URL, API_URL) => {
		try {
			const token = sessionStorage.getItem("jwt-token");
			const tipo = sessionStorage.getItem("jwt-tipo");
			if (!token || !tipo) {
				return await getAuthorizationApi(commit, BASE_URL, API_URL);
			}
			if (isTokenExpired()){
				return await getAuthorizationApi(commit, BASE_URL, API_URL);
			}
			return {token, tipo};
		} catch (error){
			console.log(error);
		}
	},
	getTokenClientesAPI: async (commit, API_URL_CLIENTES) => {
		try {
			const tokenClientes = sessionStorage.getItem("token-clientes");
			if(!tokenClientes) {
				return getAuthorizationApiClientes(commit, API_URL_CLIENTES);
			}
			return tokenClientes;

		} catch (error) {
			console.error(error);
		}
	}

};