<template>
	<div class="main-cadastro-produto">
		<div class="aba-controle js-aba-produto">
			<h3 id="dados" @click="mudarAba" class="aba abaSelecionada" v-if="!lancarVariacao">Dados</h3>
			<h3 id="formacaoPreco" @click="mudarAba" class="aba" v-if="!lancarVariacao">Formação de Preço</h3>
			<h3 id="EstoqueETributacao" @click="mudarAba" class="aba" v-if="!lancarVariacao">Estoque e Tributação</h3>
			<h3 id="variacao" @click="mudarAba" class="aba" v-if="!inserindoCompra || lancarVariacao">Variações</h3>
			<h3 id="montagemKit" @click="mudarAba" class="aba" v-if="!inserindoCompra">Montagem de Kit</h3>
			<h3 id="gerenciaLote" @click="mudarAba" class="aba">Rastreabilidade</h3>
			<!-- <h3 @click="mudarAba(5, 'planoContas')" class="aba">Plano de Contas</h3> -->
		</div>

		<ProdutoDados v-if="mostrar == 'dados'" :PropProduto="produtoSelecionado" :Fornecedor="Fornecedor"
			v-on:mudarAba="mudarAba" v-on:cancelarEdicao="cancelarEdicao" v-on:salvarProduto="salvarProduto"
			v-on:selecionouProdutoRepetido="selecionarProdutoProp"
			v-on:duplicarProduto="duplicarProduto" />

		<ProdutoPreco v-else-if="mostrar == 'formacaoPreco'" :PropProduto="produtoSelecionado" v-on:mudarAba="mudarAba"
			v-on:salvarProduto="salvarProduto" :inserindoCompra="inserindoCompra" />

		<EstoqueETributacao v-else-if="mostrar == 'EstoqueETributacao'" :PropProduto="produtoSelecionado"
			v-on:mudarAba="mudarAba" v-on:salvarProduto="salvarProduto" />

		<ProdutoVariacao v-else-if="mostrar == 'variacao' && !inserindoCompra && !lancarVariacao" :PropProduto="produtoSelecionado"
			v-on:mudarAba="mudarAba" v-on:salvarProduto="salvarProduto" v-on:cancelarEdicao="cancelarEdicao"/>

		<ProdutoKit v-else-if="mostrar == 'montagemKit' && !inserindoCompra" :PropProduto="produtoSelecionado"
			v-on:mudarAba="mudarAba" v-on:salvarProduto="salvarProduto" />

		<ProdutoLote v-else-if="mostrar == 'gerenciaLote'" :PropProduto="produtoSelecionado" :CdCompra="CdCompra"
			:inserindoCompra="inserindoCompra" v-on:mudarAba="mudarAba" v-on:cancelarEdicao="cancelarEdicao" @salvarProduto="salvarProduto"
		/>
	</div>
</template>
<script>
import ProdutoDados from "./ProdutoDados.vue";
import ProdutoPreco from "./ProdutoPreco.vue";
import EstoqueETributacao from "./EstoqueETributacao.vue";
import ProdutoVariacao from "./ProdutoVariacao.vue";
import ProdutoKit from "./ProdutoKit.vue";
import ProdutoLote from "./ProdutoLote.vue";
export default {
	name: "CadastroProduto",

	components: {
		ProdutoDados,
		ProdutoPreco,
		EstoqueETributacao,
		ProdutoVariacao,
		ProdutoKit,
		ProdutoLote,
	},

	props: {
		Produto: Object,
		Fornecedor: Object,
		inserindoCompra: Boolean,
		lancarVariacao: Boolean,
		CdCompra: Number,
	},

	computed: {
		produtoSelecionado() {
			if (this.inserindoCompra) {
				return this.Produto;
			} else {
				return this.$store.state.produtoSelecionado;
			}
		}
	},

	data() {
		return {
			mostrar: "dados"
		};
	},
	
	methods: {
		async duplicarProduto() {
			if (!this.inserindoCompra) {
				this.$store.state.produtoSelecionado.id = null;
				this.$store.state.produtoSelecionado.codBarra = null;
				this.$store.state.produtoSelecionado.codinter = null;
				this.$store.state.produtoSelecionado.novo = true;
				this.$store.state.produtoSelecionado.duplicando = true;
			}
			this.$emit("duplicarProduto");
		},
		podeMudarAba() {
			try {
				if (this.produtoSelecionado ? !this.produtoSelecionado.id : true) {
					this.$bus.$emit("salvarProduto");
					return false;
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", { mensagem: error.message });
				return false;
			}
		},
		mudarAba(evento) {
			if (!this.podeMudarAba()) return;
			const h3Id = evento.target.id;
			this.mostrar = h3Id;
			const abaControle = document.querySelector(".aba-controle.js-aba-produto");
			abaControle.querySelectorAll(".aba").forEach(aba => {
				if (aba.classList.contains("abaSelecionada")) {
					aba.classList.remove(["abaSelecionada"]);
				}
				if (aba.id == h3Id) {
					aba.classList.add(["abaSelecionada"]);
				}
			});
		},

		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},

		async salvarProduto(podeFinalizar) {
			this.$store.state.produtoSelecionado.ipi = this.produtoSelecionado.ipi;
			this.$store.state.produtoSelecionado.valoripi = this.produtoSelecionado.valoripi;
			await this.$emit("salvarProduto", { ...this.$store.state.produtoSelecionado }, podeFinalizar);
			if (podeFinalizar && this.inserindoCompra) return;
			if (this.mostrar == "dados") {
				this.mudarAba({target: {id: "formacaoPreco"}});
			} else if (this.mostrar == "formacaoPreco") {
				this.mudarAba({target: {id: "EstoqueETributacao" }});
			} else if (this.mostrar == "EstoqueETributacao") {
				this.mudarAba({target: {id: "variacao" }});
			} else if (this.mostrar == "variacao") {
				this.mudarAba({target: {id: "montagemKit" }});
			}
		},

		async selecionarProdutoProp(idProdutoRepetido) {
			if (((this.Produto && this.Produto.id) && !this.inserindoCompra) || (idProdutoRepetido && !this.inserindoCompra)) {
				await this.$store.dispatch("selecionarProdutoPorEmpresa", {id: this.Produto.id || idProdutoRepetido});
			}
			if (this.lancarVariacao) {
				this.mudarAba({target: {id: "variacao"}});
			}
		}
	},

	destroyed() {
		this.$store.commit("selecionarProduto", null);
	},
};

</script>
<style scoped>
.main-cadastro-produto {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	width: 100%;
}

.cadastro-produto-aba {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
	width: 100%;
}

@media screen and (max-width: 620px) {
	.aba-controle {
		justify-content: flex-start;
		min-height: 40px;
		height: 40px;
	}

	.aba-controle h3 {
		font-size: 12pt;
	}
}
</style>