<template>
	<div class="container-page">
		<paginacao-filtro
			@filtrar="filtrar"
			@handleShowTable="handleShowTable"
			:mostrarBotaoNovo="true"
			@adicionarNovo="$emit('cadastrarNFE')"
			@limpar="limparFiltro"
			:ControlePaginacao="Paginacao"
		>
			<template v-slot:filtro>
				<div class="w-100">
					<div class="col w-100">
						<div class="row w-100">
							<div class="input-group">
								<select
									v-model="tipoNota"
									id="tipoNota"
								>
									<option value="T">
										Todos
									</option>
									<option value="E">
										Digitação
									</option>
									<option value="A">
										Autorizado
									</option>
									<option value="C">
										Cancelado
									</option>
									<option value="D">
										Denegado
									</option>
								</select>
								<label for="tipoNota" style="background-color: #f8f8f8;">
									Sitação Nota:
								</label>
							</div>
							<div class="input-group">
								<select
									v-model="tipoFiltro"
									id="tipoFiltro"
									@change="filtroPesquisa = ''"
								>
									<option value="T">
										Periodo
									</option>
									<option value="C">
										Cliente e Periodo
									</option>
									<option value="N">
										Numero Nota
									</option>
									<option value="P">
										Pedido
									</option>
								</select>
								<label for="tipoFiltro" style="background-color: #f8f8f8;">
									Filtrar Por:
								</label>
							</div>
							<div class="input-group" v-if="tipoFiltro == 'T' || tipoFiltro == 'C'">
								<input 
									type="date" id="dataF" 
									v-model="dataI"
									@blur="saiuData"
								>
								<label for="textoPesquisa" style="background-color: #f8f8f8;">
									Data Inicial:
								</label>
							</div>
							<div class="input-group" v-if="tipoFiltro == 'T' || tipoFiltro == 'C'">
								<input 
									type="date" id="dataF" 
									v-model="dataF"
									@blur="saiuData"
								>
								<label for="dataF" style="background-color: #f8f8f8;">
									Data Final:
								</label>
							</div>	
							<div class="input-group" v-if="tipoFiltro !== 'T'">
								<input 
									type="search"
									id="textoPesquisa"
									name="filtro"
									placeholder=" "
									autocomplete="off"
									v-model="filtroPesquisa"
									@keydown.enter.prevent="filtrar(null)"
									@click="listarClientes = true"
									v-if="tipoFiltro == 'C'"
								/>
								<input 
									type="search"
									id="textoPesquisa"
									name="filtro"
									placeholder=" "
									autocomplete="off"
									v-model="filtroPesquisa"
									@keydown.enter.prevent="filtrar(null)"
									v-else
								/>
								<label for="textoPesquisa" style="background-color: #f8f8f8;">
									Pesquisar
								</label>
							</div>						
						</div>
					</div>
				</div>
			</template>
			<template v-slot:tabela>
				<TabelaNota
					:height="'65vh'"
					:showTable="false"
					@selecionarNota="$emit('selecionarNota', $event)"
					@iniciaCartaCorrecao="iniciaCartaCorrecao"
				/>
			</template>			
		</paginacao-filtro>
		<Modal v-if="listarClientes" @fecharModal="listarClientes = false" :dimensoes="{width: '90%', height: '90%'}" :idModal="'tributacao-produto'">
			<ConsultaCliente  
				@selecionarPessoa="selecionarCliente" 
				@adicionarNovo="cadastrarCliente = true"
				:mostrarBotaoNovo="true"
				v-if="!cadastrarCliente"
			/>
			<CadastroCliente @cancelarEdicao="cadastrarCliente = false" v-else :cliente="{novo: true}"/>
		</Modal>
		<Modal 
			v-if="cartaCorrecao"
			@fecharModal="cartaCorrecao = false"
			:dimensoes="{width: '90%', height: '90%'}"
			:idModal="'correcao'"
		>
			<ConsultaCartaCorrecao :venda="vendaCartaCorrecao"/>	
		</Modal>
	</div>
</template>
<script>
import PaginacaoFiltro from "../components/PaginacaoFiltro.vue";
import { dateFormatISO } from "../utils/dateformat";
import Modal from "./Modal.vue";
import ConsultaCliente from "./ConsultaCliente.vue";
import TabelaNota from "./TabelaNota.vue";
import ConsultaCartaCorrecao from "./ConsultaCartaCorrecao.vue";
export default {
	name: "ConsultaNota",	
	components: {
		PaginacaoFiltro,
		TabelaNota,
		Modal,
		ConsultaCliente,
		ConsultaCartaCorrecao,
	},
	computed: {
		Paginacao() {
			return {
				paginaAtual: 0,
				totalPaginas: 10
			};
		}
	},
	methods: {
		async filtrar(page){
			const payload = {
				page: page,
				empresa: this.$store.state.Empresa.id,
				tipoNota: "T",
				dataI: this.tipoFiltro == "T" || this.tipoFiltro == "C" ? this.dataI : null,
				dataF: this.tipoFiltro == "T" || this.tipoFiltro == "C" ? this.dataF : null,
				cliente: this.tipoFiltro == "C" ? this.filtroPesquisa : null,
				venda: this.tipoFiltro == "P" ? this.filtroPesquisa : null,
				//ver campo para numero da nota
			};
			await this.$store.dispatch("filtrarVendas", payload);
		},
		limparFiltro(){

		},
		handleShowTable(){

		},
		saiuData(){
			if(this.dataI == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataI = dateFormatISO({date: null, format: "ISOdate"});
			}
			if(this.dataF == ""){
				this.$store.commit("relatarErro", {mensagem: "Data inválida!"});
				this.dataF = dateFormatISO({date: null, format: "ISOdate"});
			}
		},
		selecionarCliente(cliente){
			this.filtroPesquisa = cliente.nome;
			this.listarClientes = false;
		},
		iniciaCartaCorrecao(nota){
			this.cartaCorrecao = true;
			this.vendaCartaCorrecao = nota;
		}
	},
	data() {
		return {
			tipoNota:"T",
			tipoFiltro:"T",
			filtroPesquisa:"",
			dataI: dateFormatISO({date: null, format: "ISOdate"}),
			dataF: dateFormatISO({date: null, format: "ISOdate"}),
			listarClientes: false,
			vendaCartaCorrecao: null,
			cartaCorrecao: false,
		};
	}
};
</script>
<style scoped>
.container-page{
	width: 100%;
}
.botoes{
	gap: 20px;
}
</style>