<template>
	<div id="gridPrincipalVendas">
		<div id="conteudo">
			<div class="col w-30 center container">
				<div class="inputs">					
					<div class="col" v-if="tipo=='v'">		
						<div class="row">	
							<div class="input-group">
								<select v-model="tipo" @change="descricao=''">
									<option value="v">Valor</option>
									<option value="p">Promoção</option>
								</select>
								<label>Tipo</label>
							</div>			
							<div class="input-group descricao">
								<input type="text" id="descricao" class="mx-5 w-100" v-model="descricao"
									autocomplete="off"
									placeholder=" "
									@keydown.enter="proximoCampo('prazo')">
								<label for="descricao">Descrição</label>
							</div>
						</div>	
						<div class="row">	
							<div class="input-group">
								<input type="text" id="prazo" class="mx-5 w-100" v-model="valor.prazo"
									autocomplete="off"
									placeholder=" "
									@input="alteraValor('prazo');alteraParcela()"
									@keydown.enter="proximoCampo('desconto')"
									v-mascaraFloat>
								<label for="prazo">$ Prazo</label>
							</div>
							<div class="input-group">
								<input type="text" id="desconto" class="mx-5 w-100" v-model="valor.desconto"
									autocomplete="off"
									placeholder=" "
									@input="alteraValor('desconto')"
									@keydown.enter="proximoCampo('vista')"
									v-mascaraFloat>
								<label for="desconto">% Desconto</label>
							</div>
							<div class="input-group">
								<input type="text" id="vista" class="mx-5 w-100" v-model="valor.vista"
									autocomplete="off"
									placeholder=" "
									@input="alteraValor('vista')"
									@keydown.enter="proximoCampo('qtdParcela')"
									v-mascaraFloat>
								<label for="vista">$ Vista</label>
							</div>
							<div class="input-group">
								<input type="number" id="qtdParcela" class="mx-5 w-100" v-model="valor.qtdParcela"
									autocomplete="off"
									v-mascaraInteger
									@input="alteraParcela()"
									@keydown.enter="proximoCampo('valorParcela')"
									placeholder=" ">
								<label for="qtdParcela">Qtd. Parcelas</label>
							</div>
							<div class="input-group">
								<input type="text" id="valorParcela" class="mx-5 w-100" v-model="valor.parcela"
									autocomplete="off"
									placeholder=" "
									@keydown.enter="proximoCampo('qtdEtiqueta')"
									v-mascaraFloat>
								<label for="valorParcela">$ Parcela</label>
							</div>
						</div>
					</div>
					<div class="col" v-if="tipo=='p'">
						<div class="row">
							<div class="input-group">
								<select v-model="tipo" @change="descricao=''">
									<option value="v">Valor</option>
									<option value="p">Promoção</option>
								</select>
								<label>Tipo</label>
							</div>	
							<div class="input-group descricao">
								<input type="text" id="descricao" class="mx-5 w-100" v-model="descricao"
									autocomplete="off"
									placeholder=" "
									@keydown.enter="proximoCampo('de')">
								<label for="descricao">Descrição</label>
							</div>
						</div>
						<div class="row">
							<div class="input-group">
								<input type="text" id="de" class="mx-5 w-100" v-model="promocao.de"
									autocomplete="off"
									placeholder=" "
									@input="alteraPromocao('de')"
									@keydown.enter="proximoCampo('descontoPromocao')"
									v-mascaraFloat>
								<label for="de">De $:</label>
							</div>
							<div class="input-group">
								<input type="text" id="descontoPromocao" class="mx-5 w-100" v-model="promocao.desconto"
									autocomplete="off"
									placeholder=" "
									@input="alteraPromocao('desconto')"
									@keydown.enter="proximoCampo('por')"
									v-mascaraFloat>
								<label for="descontoPromocao">% Desconto</label>
							</div>
							<div class="input-group">
								<input type="text" id="por" class="mx-5 w-100" v-model="promocao.por"
									autocomplete="off"
									placeholder=" "
									@input="alteraPromocao('por')"
									@keydown.enter="proximoCampo('qtdEtiqueta')"
									v-mascaraFloat>
								<label for="por">Por $:</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="input-group" style="max-width: 200px;">
							<input type="number" id="qtdEtiqueta" class="mx-5 w-100" v-model="qtdEtiqueta"
								autocomplete="off"
								v-mascaraInteger
								placeholder=" ">
							<label for="qtdEtiqueta">Qtd. Etiquetas</label>
						</div>
											
						<button class="botao botao-acao" @click="gerarEtiquetas">Gerar Etiquetas</button>
					</div>
				</div>
				<div class="tabelas">
					<div class="tabela">
						<table v-if="EtiquetasValor.length > 0 && tipo=='v'">
							<thead>
								<tr>
									<td colspan="2">Valor</td>
									<td colspan="2">
										Limpar
										<i class="fas fa-trash"
										style="color: var(--deletar)"
										@click="limpaTabela('v')"></i>
									</td>
								</tr>
								<tr>
									<td>Descrição</td>
									<td>Prazo</td>
									<td>Vezes</td>
									<td>Parcela</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="etq in EtiquetasValor" :key="etq.etiqueta.descricao">
									<td class="text-left descricaoTabela">{{ etq.etiqueta.descricao }}</td>
									<td class="text-left valoresTabela">{{ etq.etiqueta.prazo.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) }}</td>
									<td class="text-center">{{ etq.etiqueta.qtdParcela }}</td>
									<td class="text-left valoresTabela">{{ etq.etiqueta.parcela.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tabela">
						<table v-if="EtiquetasPromocao.length>0 && tipo=='p'">
							<thead>
								<tr>
									<td>Promoção</td>
									<td colspan="2">
										Limpar
										<i class="fas fa-trash"
										style="color: var(--deletar)"
										@click="limpaTabela('p')"></i>
									</td>
								</tr>
								<tr>
									<td>Descrição</td>
									<td>De</td>
									<td>Por</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="etq in EtiquetasPromocao" :key="etq.etiqueta.descricao">
									<td class="text-left descricaoTabela">{{ etq.etiqueta.descricao }}</td>
									<td class="text-left valoresTabela">{{ Number(etq.etiqueta.de.replace(",",".")).toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) }}</td>
									<td class="text-left valoresTabela">{{ Number(etq.etiqueta.por.replace(",",".")).toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<button class="botao botao-acao" @click="imprimeEtiqueta">Imprimir Etiquetas</button>
			</div>
		</div>
	</div>
</template>

<script>
import { proximoCampo } from "../utils/utils";
export default {
	name: "EtiquetaVitrine",

	components: {
	},

	data() {
		return {
			tipo:"v",
			descricao:"",
			qtdEtiqueta: 1,
			valor:{
				prazo:0,
				vista:0,
				desconto:0,
				qtdParcela:0,
				parcela:0,
			},
			promocao:{
				de:0,
				por:0,
				desconto:0,
			},
			etiquetas:[],
			proximoCampo
		};
	},

	computed: {
		EtiquetasPromocao(){
			return this.etiquetas.filter((etiqueta)=>{return etiqueta.tipo == "p";});
		},
		EtiquetasValor(){
			return this.etiquetas.filter((etiqueta)=>{return etiqueta.tipo == "v";});
		}
	},
	methods: {
		limpaTabela(tipo){
			const tamanho = this.etiquetas.length-1;
			for(let i = tamanho; i>-1;i--){
				if(this.etiquetas[i].tipo == tipo){
					this.etiquetas.splice(i,1);
				}
			}
		},
		alteraValor(campo){
			if(campo != "vista"){
				this.valor.vista = this.valor.prazo.replace(",",".")-(this.valor.prazo.replace(",",".")*(this.valor.desconto/100));
				this.valor.vista = this.valor.vista.toFixed(2).replace(".",",");
			}
			else{
				this.valor.desconto = (100-(((this.valor.vista.replace(",",".")||1)*100)/(this.valor.prazo.replace(",",".")||1))).toFixed(2).replace(".",",");
			}
		},
		alteraPromocao(campo){
			if(campo != "por"){
				this.promocao.por = this.promocao.de.replace(",",".")-(this.promocao.de.replace(",",".")*(this.promocao.desconto.replace(",",".")/100));
				this.promocao.por = this.promocao.por.toFixed(2).replace(".",",");
			}
			else{
				this.promocao.desconto = (100-(((this.promocao.por.replace(",",".")||1)*100)/(this.promocao.de.replace(",",".")||1))).toFixed(2).replace(".",",");
			}
		},
		alteraParcela(){
			this.valor.parcela = this.valor.qtdParcela < 1?0 : (this.valor.prazo.replace(",",".")/this.valor.qtdParcela).toFixed(2);
			this.valor.parcela = this.valor.parcela.replace(".",",");
		},
		gerarEtiquetas(){
			for(let i = 0; i<this.qtdEtiqueta; i++){
				let etiqueta={
					descricao: this.descricao,
				};
				if(this.tipo == "v"){
					etiqueta.prazo = Number(this.valor.prazo.replace(",","."));
					etiqueta.vista = Number(this.valor.vista.replace(",","."));
					etiqueta.desconto = this.valor.desconto;
					etiqueta.qtdParcela = this.valor.qtdParcela;
					etiqueta.parcela = Number(this.valor.parcela.replace(",","."));
				}
				if(this.tipo == "p"){
					etiqueta.de = this.promocao.de;
					etiqueta.por = this.promocao.por;
					etiqueta.desconto = Number(this.promocao.desconto.replace(",","."));
				}
				this.etiquetas.push(
					{tipo:this.tipo,etiqueta:etiqueta}
				);
			}
		},
		imprimeEtiqueta(){
			let payloadValor = {etiquetas:[]};
			for(const etq of this.EtiquetasValor){
				payloadValor.etiquetas.push(etq.etiqueta);
			}
			if(payloadValor.etiquetas.length > 0 && this.tipo=="v") this.$store.dispatch("gerarEtiquetaVitrineValor", payloadValor );
			let payloadPromoacao = {etiquetas:[]};
			for(const etq of this.EtiquetasPromocao){
				payloadPromoacao.etiquetas.push(etq.etiqueta);
			}
			if(payloadPromoacao.etiquetas.length > 0 && this.tipo=="p") this.$store.dispatch("gerarEtiquetaVitrinePromocao", payloadPromoacao );
		},
	},
};
</script>

<style scoped>
.valoresTabela{
	min-width: 80px;
}
.descricaoTabela{
	min-width: 280px;
}
.descricao{
	min-width: 280px;
}
.tabelas{
	display:flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	overflow: auto;
	margin: 10px;
}
.tabela{
	height: 100%;
}
.inputs{
	width: 100%;
}
.row .input-group{
	width: 100%;
}
.row{
	margin-top: 10px;
}
.container {
    margin: 0px auto;
    border-radius: 10px;
    height: 90%;
    width: 90%;
    background-color: var(--bg-app);
    box-shadow: var(--shadow-primario);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
}

#gridPrincipalVendas {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-template-areas:
		"conteudo";
	background-color: var(--bg-app);
	padding-top: 5px;
	height: 100%;
	max-height: 92vh;
	/* min-height: 530px; */
	width: 100%;
}
#conteudo {
	grid-area: conteudo;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	margin: 0px auto;
	border-top-left-radius: 3%;
	border-top-right-radius: 3%;
	background-color: var(--bg-secundario);
	overflow: hidden;
}
</style>