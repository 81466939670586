<template>
	<div class="EmpresaConfig">
		<div class="titulo">
			<h1>{{ Empresa ? !Empresa.novo ? `${Empresa.id} - ${Empresa.nome}` : "Configurar empresa" : "Configurar empresa"}}</h1>
		</div>
		<div class="config">
			<fieldset class="fildset">
				<legend class="legend">Imposto</legend>
				<div class="fieldset-item">
					<fieldset class="fieldset-interno">
						<legend class="legend">Estadual</legend>
						<div class="input-group">
							<input v-model="EmpConfig.sittribest" autocomplete="off" id="sittribest" type="text"
							@keydown.enter="proximoCampo('cfopestacompra')" class="input-pequeno" maxlength="3"
							@input="mascaraInteger" placeholder=" ">
							<label for="sittribest">Situação tributária</label>
						</div>
						<fieldset class="fieldset-cfop">
							<legend class="legend">CFOP</legend>
							<div class="input-group">
								<input v-model="EmpConfig.cfopestacompra" autocomplete="off" id="cfopestacompra"
								type="text" @keydown.enter="proximoCampo('cfopesta')" class="input-pequeno"
								maxlength="4" @input="mascaraInteger" placeholder=" ">
								<label for="cfopestacompra">Compra</label>
							</div>
							<div class="input-group">
								<input v-model="EmpConfig.cfopesta" autocomplete="off" id="cfopesta" type="text"
								@keydown.enter="proximoCampo('sittribint')" class="input-pequeno" maxlength="4"
								@input="mascaraInteger" placeholder=" ">
								<label for="cfopesta">Venda</label>
							</div>
						</fieldset>
					</fieldset>
					<fieldset class="fieldset-interno">
						<legend class="legend">Interestadual</legend>
						<div class="input-group">
							<input v-model="EmpConfig.sittribint" autocomplete="off" id="sittribint" type="text"
							@keydown.enter="proximoCampo('cfopintercompra')" class="input-pequeno" maxlength="3"
							@input="mascaraInteger" placeholder=" ">
							<label for="sittribint">Situação tributária</label>
						</div>
						<fieldset class="fieldset-cfop">
							<legend class="legend">CFOP</legend>
							<div class="input-group">
								<input v-model="EmpConfig.cfopintercompra" autocomplete="off" id="cfopintercompra"
								type="text" @keydown.enter="proximoCampo('cfopinter')" class="input-pequeno"
								maxlength="4" @input="mascaraInteger" placeholder=" ">
								<label for="cfopintercompra">Compra</label>
							</div>
							<div class="input-group">
								<input v-model="EmpConfig.cfopinter" autocomplete="off" id="cfopinter" type="text"
								@keydown.enter="proximoCampo('pissittrib')" class="input-pequeno" maxlength="4"
								@input="mascaraInteger" placeholder=" ">
								<label for="cfopinter">Venda</label>
							</div>
						</fieldset>
					</fieldset>
					<fieldset class="fieldset-interno">
						<legend class="legend">Federal</legend>
						<div class="campos">
							<div class="input-group">
								<input v-model="EmpConfig.pissittrib" autocomplete="off" id="pissittrib" type="text"
								@keydown.enter="proximoCampo('pis')" class="input-pequeno" maxlength="3"
								@input="mascaraInteger" placeholder=" ">
								<label for="pissittrib">Sit. Trib. PIS</label>
							</div>
							<div class="input-group">
								<input v-model="EmpConfig.pis" autocomplete="off" id="pis" type="text"
								@keydown.enter="proximoCampo('cofinssittrib')" class="input-pequeno"
								@input="mascaraFloat" placeholder=" ">
								<label for="pis">PIS</label>
							</div>
						</div>
						<div class="campos">
							<div class="input-group">
								<input v-model="EmpConfig.cofinssittrib" autocomplete="off" id="cofinssittrib"
								type="text" @keydown.enter="proximoCampo('cofins')" class="input-pequeno"
								maxlength="3" @input="mascaraInteger" placeholder=" ">
								<label for="cofinssittrib">Sit. Trib. COFINS</label>
							</div>
							<div class="input-group">
								<input v-model="EmpConfig.cofins" autocomplete="off" id="cofins" type="text"
								@keydown.enter="proximoCampo('ipisittrib')" class="input-pequeno"
								@input="mascaraFloat" placeholder=" ">
								<label for="cofins">COFINS</label>
							</div>
						</div>
						<div class="campos">
							<div class="input-group">
								<input v-model="EmpConfig.ipisittrib" autocomplete="off" id="ipisittrib" type="text"
								@keydown.enter="proximoCampo('ipi')" class="input-pequeno" maxlength="3"
								@input="mascaraInteger" placeholder=" ">
								<label for="ipisittrib">Sit. Trib. IPI</label>
							</div>
							<div class="input-group">
								<input v-model="EmpConfig.ipi" autocomplete="off" id="ipi" type="text"
								@keydown.enter="proximoCampo('multa')" class="input-pequeno" @input="mascaraFloat" placeholder=" ">
								<label for="ipi">IPI</label>
							</div>
						</div>
					</fieldset>
				</div>
			</fieldset>
			<fieldset class="fildset">
				<legend class="legend">Juros</legend>
				<div class="fieldset-item">
					<div class="fieldset-item-coluna">
						<div class="campo-item">
							<div class="input-group-switch">
								<label class="label-switch">Tipo de Juros</label>
								<label class="container">
									<input v-model="EmpConfig.tipoJuros" id="tipoJuros"
										type="checkbox" class="esconder-input">
									<span class="thumb-container">
										<span class="thumb"></span>
									</span>
									<span class="track"></span>
								</label>
								<div class="checked">
									<label for="tipoJuros" v-if="EmpConfig.tipoJuros">Composto</label>
									<label for="tipoJuros" v-else>Normal</label>
								</div>
							</div>
						</div> 
					</div>
					<div class="fieldset-item-linha">
						<div class="campos-parametros">
							<div class="campos">
								<div class="input-group input-margin">
									<input v-model="EmpConfig.multa" autocomplete="off" id="multa" type="text"
									@keydown.enter="proximoCampo('financeiro')" class="input-pequeno" @input="mascaraFloat" placeholder=" ">
									<label for="multa">% Multa cobrado do cliente</label>
								</div>
								<div class="input-group input-margin" >
									<input v-model="EmpConfig.financeiro" autocomplete="off" id="financeiro" type="text"
									@keydown.enter="proximoCampo('carenciaJuros')" class="input-pequeno"
									@input="mascaraFloat" placeholder=" ">
									<label for="financeiro">% Juros cobrado do cliente</label>
								</div>
							</div>
							<div class="campos">
								<div class="input-group input-margin">
									<input v-model="EmpConfig.carenciaJuros" autocomplete="off" id="carenciaJuros" type="text"
									@keydown.enter="proximoCampo('desrevenda')" class="input-pequeno"
									@input="mascaraInteger">
									<label for="carenciaJuros">Dias de carência dos juros</label>
								</div>
								<div class="input-group input-margin">
									<input v-model="EmpConfig.desrevenda" autocomplete="off" id="desrevenda" type="text"
									@keydown.enter="proximoCampo('atalhoLoginPdv')" class="input-pequeno"
									@input="mascaraInteger" placeholder=" ">
									<label for="desrevenda">Dias para ver pedido em aberto</label>
								</div>
							</div>

						</div>
					</div>
				</div>
			</fieldset>
			<fieldset class="fildset">
				<legend class="legend">Parametros</legend>
				<div id="alinha-parametros">
					<div class="fieldset-item">
						<div class="fieldset-item-coluna">
							<div class="campo-item">
								<div class="input-group-switch">
									<label class="label-switch">Custo Médio</label>
									<label class="container">
										<input v-model="EmpConfig.customedio" id="esconder-input"
											type="checkbox" class="esconder-input">
										<span class="thumb-container">
											<span class="thumb"></span>
										</span>
										<span class="track"></span>
									</label>
								</div>
							</div>
						</div>
						<div class="fieldset-item-linha">
							<div class="campos-parametros">
								<div class="campos">
									<div class="campo-item input-magin" >
										<div class="input-group">
											<input autocomplete="off" id="cdconsumidorfinal" v-if="EmpConfig.clientePadrao != ''" disabled :value="EmpConfig.clientePadrao" type="text" >
											<label for="cdconsumidorfinal">Cliente padrão</label>
										</div>
										<i class="fas fa-address-book" style="color: var(--primario)" @click="selecionarClientePadrao = true"></i>
									</div>
									<div class="input-group">
										<input autocomplete="off" id="diasEmAtraso" v-model="EmpConfig.carenciaCliente" type="text" placeholder=" "/>
										<label for="diasEmAtraso" class="lg-view">Dias em atraso para bloqueio do cliente</label>
										<label for="diasEmAtraso" class="sm-view">Dias atraso p/ bloq. do cliente</label>
									</div>

									<div class="input-group">
										<input autocomplete="off" id="atalhoLoginPdv" type="text" maxlength="30" style="text-transform: lowercase;"
										v-model="EmpConfig.atalhoLoginPdv" @keydown.enter="proximoCampo('prazoTrocaCupom')" @input="mascaraLogin"
										placeholder=" ">
										<label>Login de Atalho</label>
									</div>
								</div>								
							</div>
						</div>
					</div>
					<div class="fieldset-item">
						<div class="fieldset-item-linha">
							<div class="campos-parametros">
								<div class="campos">
									<div class="input-group">
										<input v-model="EmpConfig.prazoTrocaCupom" autocomplete="off" id="prazoTrocaCupom"
										type="text" @keydown.enter="proximoCampo('minutosExpiraSenha')" class="input-pequeno"
										@input="mascaraInteger" placeholder=" ">
										<label for="prazoTrocaCupom">Prazo para troca de mercadoria</label>
									</div>
									<div class="input-group">
										<input v-model="EmpConfig.minutosExpiraSenha" autocomplete="off" id="minutosExpiraSenha"
										type="text" class="input-pequeno" @input="mascaraInteger" placeholder=" ">
										<label for="minutosExpiraSenha">Expiração de senha (Min.)</label>
									</div>
								</div>
								<div class="campos gap-20">
									<div class="form-column">
										<div class="campo-item">
											<div class="input-group-switch mt-15">
												<label class="label-switch" v-if="!menor440()">Cód. barra diferente do Cód. produto</label>
												<label class="label-switch" v-if="menor440()">Cód. barra diferente<br>do Cód. produto</label>
												<div class="trackContainer">
													<label class="container">
														<input type="checkbox"  id="selectAll"  v-model="EmpConfig.geraCodBarraProduto" @change="selecionarCodBarra"
														class="esconder-input"/>
														<span class="thumb-container">
															<span class="thumb"></span>
														</span>
														<span class="track"></span>
													</label>
												</div>
											</div>
											<!-- <div class="selecionarCodBarra">
												<label class="check" for="selectAll"></label>
												<span v-if="EmpConfig.geraCodBarraProduto">&nbsp;SIM</span>
												<span v-else>&nbsp;NÃO</span>
											</div> -->
										</div>
										<div class="input-group-switch mt-15">
											<label class="label-switch lg-view">Utilizar Cód. barra do fornecedor no produto</label>
											<label class="label-switch sm-view" v-if="!menor440()">Cód. barra do fornecedor no produto</label>
											<label class="label-switch sm-view" v-if="menor440()">Cód. barra fornecedor<br>no produto</label>
											<div class="trackContainer" style="right: 40px;">
												<label class="container">
													<input type="checkbox"  id="cdBarraFornecedor"  v-model="EmpConfig.usarCodigoBarraFornecedor" 
													@change="utilizarCdBarraFornecedor" class="esconder-input" />
													<span class="thumb-container">
														<span class="thumb"></span>
													</span>
													<span class="track"></span>
												</label>
											</div>
										</div>
										<div class="input-group-switch mt-15">
											<label class="label-switch lg-view">Agrupar Produtos Iguais na Entrada</label>
											<label class="label-switch sm-view" v-if="!menor440()">Agrupar Prod. Iguais Entrada</label>
											<label class="label-switch sm-view" v-if="menor440()">Agrupar Prod. Iguais Entrada</label>
											<div class="trackContainer" style="right: 40px;">
												<label class="container">
													<input type="checkbox"  id="agruparProdutoIguaisCompra"  v-model="EmpConfig.agruparProdutoIguaisCompra" 
													@change="handleAgruparProdutoIguaisCompra" class="esconder-input" />
													<span class="thumb-container">
														<span class="thumb"></span>
													</span>
													<span class="track"></span>
												</label>
											</div>
										</div>
										<div class="input-group-switch mt-15">
											<label class="label-switch lg-view">Transferência de produtos automático na venda</label>
											<label class="label-switch sm-view" v-if="!menor440()">Transf. de prod. automático na venda</label>
											<label class="label-switch sm-view" v-if="menor440()">Transf. de prod.<br>automático na venda</label>
											<div class="trackContainer">
												<label class="container">
													<input type="checkbox"  id="transfereProdutoAutomatico"  v-model="EmpConfig.transfereProdutoAutomatico"
													@change="selecionarCodBarra" class="esconder-input" />
													<span class="thumb-container">
														<span class="thumb"></span>
													</span>
													<span class="track"></span>
												</label>
											</div>
										</div>
										<div class="input-group-switch mt-15">
											<label class="label-switch lg-view">Dados adicionais do cliente</label>
											<label class="label-switch sm-view" v-if="!menor440()">Dados adicionais do cliente</label>
											<label class="label-switch sm-view" v-if="menor440()">Dados extra do cliente</label>
											<div class="trackContainer">
												<label class="container">
													<input type="checkbox"  id="dadosAdicionaiCliente"  v-model="EmpConfig.validaAdicionalCliente"
													class="esconder-input" />
													<span class="thumb-container">
														<span class="thumb"></span>
													</span>
													<span class="track"></span>
												</label>
											</div>
										</div>
									</div>
									<div class="campo-item">
										<fieldset id="alinha-etiqueta">
											<legend style="text-align: center;">Tipo Etiqueta</legend>
											<div class="campos-radio">
												<div>
													<div>
														<input type="radio" name="inputTipo" id="tipo1" v-model="EmpConfig.tipoEtiqueta" :value="1"/>
														<label for="tipo1">Tipo 1</label>
														&nbsp;
														<input type="radio" name="inputTipo" id="tipo2" v-model="EmpConfig.tipoEtiqueta" :value="2"/>
														<label for="tipo2">Tipo 2</label>
													</div>
												</div>
												<div>
													<div>
														<input type="radio" name="inputTipo" id="descricao" v-model="EmpConfig.tipoEtiqueta" :value="3"/>
														<label for="descricao">Descrição</label>
														&nbsp;
														<input type="radio" name="inputTipo" id="tipo4" v-model="EmpConfig.tipoEtiqueta" :value="4"/>
														<label for="tipo4">Tipo 4</label>
													</div>
												</div>
												<div>
													<div>
														<input type="radio" name="inputTipo" id="tipo5" v-model="EmpConfig.tipoEtiqueta" :value="5"/>
														<label for="tipo5">Tipo 5</label>
														&nbsp;
														<input type="radio" name="inputTipo" id="tipo6" v-model="EmpConfig.tipoEtiqueta" :value="6"/>
														<label for="tipo6">Tipo 6</label>
													</div>
												</div>
												<div>
													<div>
														<input type="radio" name="inputTipo" id="tipo7" v-model="EmpConfig.tipoEtiqueta" :value="7"/>
														<label for="tipo7">Tipo 7</label>
														&nbsp;
													</div>
												</div>
												&nbsp;
											</div>
										</fieldset>
									</div>
									<div class="campo-item">
										<fieldset id="alinha-sistema">
											<legend style="text-align: center;">
												Tipo Sistema
											</legend>
											<div class="campos-radio">
												<div>
													<div>
														<input 
															type="radio" 
															name="tipoSistema" 
															id="tipoComercio" 
															v-model="EmpConfig.tiposistema" 
															value="C"
														/>
														<label for="tipoComercio">
															Comércio
														</label>
														&nbsp;
														<input 
															type="radio" 
															name="tipoSistema" 
															id="tipoLanchonete" 
															v-model="EmpConfig.tiposistema" 
															value="L"
														/>
														<label for="tipoLanchonete">
															Lanchonete
														</label>
													</div>
												</div>
											</div>
										</fieldset>										
									</div>
									<div class="campo-item">
										<fieldset id="alinha-sistema">
											<legend style="text-align: center;">
												Configuração Produto
											</legend>	
											<div>
												<div class="input-group">
													<input v-model="EmpConfig.tamCodProduto" autocomplete="off" id="tamCodProd"
													type="text" class="input-pequeno" placeholder=" " v-mascaraInteger>
													<label for="tamCodProd">Tamanho do Código</label>
												</div>
												<div class="input-group" style="margin-top: 10px;margin-bottom: 10px;">
													<select id="tipoCodProd" v-model="EmpConfig.tipoCodProduto">
														<option value="S">
															SEQUENCIAL
														</option>
														<option value="U">
															POR USUÁRIO
														</option>
														<option value="L">
															COM LINHA
														</option>
													</select>
													<label for="tipoCodProd">Tipo do Código</label>
												</div>
												<div class="input-group">
													<input v-model="EmpConfig.cadEan" autocomplete="off" id="EAN"
													type="text" class="input-pequeno" placeholder=" " v-mascaraInteger>
													<label for="EAN">Cadastro EAN</label>
												</div>
											</div>
										</fieldset>
									</div>
								</div>	
							</div>
						</div>
					</div>
				</div>
			</fieldset>
			
			<fieldset class="fildset fieldset-nfc">
				<legend class="legend">
					Conciliadora
					<i class="fas fa-trash" style="color: var(--deletar)" @click="removerConciliadora"></i>
				</legend>
				<div class="fieldset-item">
					<div class="fieldset-item-linha">
						<div class="campo-item">
							<div class="input-group mx-5">
								<input
								type="date"
								autocomplete="off"
								id="dataCadastroConciliadora"
								:value="EmpConfig.dataCadastroConciliadora"
								disabled placeholder=" "
								>
								<label for="dataCadastroConciliadora">Data cadastro conciliação</label>
							</div>
							<div class="input-group mx-5">
								<input
								type="date"
								autocomplete="off"
								id="dataUltConciliacao"
								:value="EmpConfig.dataUltConciliacao"
								disabled placeholder=" "
								>
								<label for="dataUltConciliacao">Data ultima conciliação</label>
							</div>
							<div class="campo-item" style="margin:5px 0px 5px 0px">
								<div class="input-group row w-100 center">
									<input
									type="text"
									id="idEmpresa"
									autocomplete="off"
									v-model="EmpConfig.idEmpresa"
									:disabled="!$store.state.Usuario.usuarioPrivilegiado || !mostrarAPIConciliadora"
									/>
									<label for="idEmpresa">
										Id Empresa
									</label>
								</div>
							</div>
							<div class="input-group row w-100 center">
								<input
								type="text"
								id="senhaEmpresa"
								autocomplete="off"
								v-model="EmpConfig.senhaEmpresa"
								:disabled="!$store.state.Usuario.usuarioPrivilegiado || !mostrarAPIConciliadora"
								/>
								<label for="senhaEmpresa">
									Senha Empresa
								</label>
							</div>
						</div>
						<div class="campo-item">
							<div class="input-group row w-100 center" id="api-key">
								<label for="apiKey">API KEY</label>
								<input
									autocomplete="off"
									:type="$store.state.Usuario.usuarioPrivilegiado && mostrarAPIConciliadora ? 'text' : 'password'"
									id="apiKeyConciliadora"
									v-model="EmpConfig.apiKeyConciliadora"
									:disabled="!$store.state.Usuario.usuarioPrivilegiado || !mostrarAPIConciliadora"
									maxlength="255"
									class="w-100"
								/>
							</div>
							<i v-if="mostrarAPIConciliadora" class="fas fa-save" style="color: var(--confirmar); font-size: 14pt;" @click="salvarConciliadora"></i>
							<i v-else-if="$store.state.Usuario.usuarioPrivilegiado" class="fas fa-edit" style="color: var(--confirmar); font-size: 14pt;" @click="visualizarAPIKEYConciliadora"></i>
						</div>
						<div class="campo-item">
							<div class="botoes-container" style="width: 100%;">
								<button class="botao botao-acao" id="integrarProdutos" @click="integracao('Produtos')">
									<label class="lg-view" for="integrarProdutos">Integrar Produtos</label>
									<label class="sm-view" for="IntegrarProdutos">Int. Produtos</label>
								</button>
								<button class="botao botao-acao" d="integrarAdq" @click="integracao('Adquirentes')">
									<label class="lg-view" for="integrarAdq">Integrar Adiquirentes</label>
									<label class="sm-view" for="integrarAdq" id="small-view">Int. Adquirentes</label>
									<label class="sm-view" for="integrarAdq" id="smaller-view">Adquirentes</label>

								</button>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
		<div class="botoes-container">
			<button @click="cancelarEdicao" class="botao botao-cancelar">
				<i class="fas fa-times" style="color: var(--borda-cancelar)"></i>
				Cancelar
			</button>
			<button @click="salvarEmpresaConfig" class="botao botao-adicionar">
				<i class="fa fa-check" style="color: var(--borda-salvar)"></i>
				Salvar
			</button>
		</div>
		<Modal v-if="selecionarClientePadrao" v-on:fecharModal="selecionarClientePadrao = false">
			<consulta-cliente  
				@selecionarPessoa="selecionouClientePadrao" 
			/>
			<!-- <Paginacao tipoConteudo="Clientes" :desabilitarNovo="true">
				<TabelaPessoas tipoConteudo="Clientes"
				v-on:selecionarPessoa="selecionouClientePadrao" v-on:fecharModal="selecionarClientePadrao = false" />
			</Paginacao> -->
		</Modal>
	</div>
</template>
<script>
class EmpresaDados {
	constructor(empConfig) {
		this.ativo = empConfig.ativo;
		this.sittribest = empConfig.sittribest;
		this.cfopestacompra = empConfig.cfopestacompra;
		this.cfopesta = empConfig.cfopesta;
		this.sittribint = empConfig.sittribint;
		this.cfopintercompra = empConfig.cfopintercompra;
		this.cfopinter = empConfig.cfopinter;
		this.pissittrib = empConfig.pissittrib;
		this.pis = empConfig.pis;
		this.cofinssittrib = empConfig.cofinssittrib;
		this.cofins = empConfig.cofins;
		this.ipisittrib = empConfig.ipisittrib;
		this.ipi = empConfig.ipi;
		this.tipoJuros = empConfig.tipoJuros;
		this.multa = empConfig.multa;
		this.financeiro = empConfig.financeiro;
		this.carenciaJuros = empConfig.carenciaJuros;
		this.desrevenda = empConfig.desrevenda;
		this.customedio = empConfig.customedio;
		this.prazoTrocaCupom = empConfig.prazoTrocaCupom;
		this.minutosExpiraSenha = empConfig.minutosExpiraSenha;
		this.cdconsumidorfinal = empConfig.cdconsumidorfinal;
		this.atalhoLoginPdv = empConfig.atalhoLoginPdv;


		this.tipoEtiqueta = empConfig.tipoEtiqueta || 1;
		this.geraCodBarraProduto = empConfig.geraCodBarraProduto == true;
		this.agruparProdutoIguaisCompra = empConfig.agruparProdutoIguaisCompra == true;

		this.carenciaCliente = empConfig.carenciaCliente;

		// Conciliadora
		this.dataCadastroConciliadora = empConfig.conciliadora.dataCadastroConciliadora;
		this.dataUltConciliacao = empConfig.conciliadora.dataUltConciliacao;
		this.apiKeyConciliadora = empConfig.conciliadora.apiKeyConciliadora;
		this.usuarioCadastroConciliadora = empConfig.conciliadora.usuarioCadastroConciliadora;
		this.idEmpresa = empConfig.conciliadora.idEmpresaConciliadora;
		this.senhaEmpresa = empConfig.conciliadora.senhaEmpresaConciliadora;

		this.transfereProdutoAutomatico = empConfig.transfereProdutoAutomatico;
		
		this.usarCodigoBarraFornecedor = empConfig.usarCodigoBarraFornecedor == true;
		this.validaAdicionalCliente = empConfig.validaAdicionalCliente;
		this.tiposistema = empConfig.tiposistema;

		this.cadEan = empConfig.cadEan;
		this.tamCodProduto = empConfig.tamCodProduto;
		this.tipoCodProduto = empConfig.tipoCodProduto || "S"; 
	}
}
// import TabelaPessoas from "./TabelaPessoas.vue";
import Modal from "./Modal.vue";
// import Paginacao from "./Paginacao.vue";
import ConsultaCliente from "./ConsultaCliente.vue";

export default {
	name: "EmpresaConfig",
	props: {
		Empresa: Object,
	},
	components: {
		// TabelaPessoas,
		Modal,
		// Paginacao
		ConsultaCliente,
	},
	data() {
		return {
			selecionarClientePadrao: false,
			EmpConfig: {
				//CERTO
				sittribest: "",
				cfopestacompra: "",
				cfopesta: "",
				sittribint: "",
				cfopintercompra: "",
				cfopinter: "",
				pissittrib: "",
				pis: "",
				cofinssittrib: "",
				cofins: "",
				ipisittrib: "",
				ipi: "",
				//ERRADO
				tipoJuros: false,
				//CERTO
				multa: "",
				financeiro: "",
				carenciaJuros: "",
				desrevenda: "",
				//ERRADO
				customedio: false,
				//CERTO
				prazoTrocaCupom: "",
				minutosExpiraSenha: "",
				cdconsumidorfinal: "",
				clientePadrao: "",
				atalhoLoginPdv: "",
				transfereProdutoAutomatico: "",
				tipoEtiqueta: 1,
				geraCodBarraProduto: true,
				agruparProdutoIguaisCompra: false,
				usarCodigoBarraFornecedor: false,
				validaAdicionalCliente: false,
				carenciaCliente: "",
				apiKeyConciliadora: "",
				senhaEmpresa: "",
				idEmpresa: "",
				tiposistema: "",

				cadEan: "",
				tipoCodProduto: "S",
				tamCodProduto: ""
			},

			mostrarAPIConciliadora: false,
		};
	},
	computed:{
		empresaSel(){
			return this.$store.state.Empresa;
		}
	},
	mounted() {
		if (this.Empresa) {
			if (this.Empresa.novo) return;
			this.EmpConfig = new EmpresaDados(this.Empresa);
		
			this.buscarClientePadrao();

		}
	},
	methods: {
		async integracao(tipo){

			await this.$store.dispatch(`integrar${tipo}Conciliadora`);
		},
		selecionarCodBarra () {
			this.EmpConfig.geraCodBarraProduto = this.EmpConfig.geraCodBarraProduto ? true : false;
		},
		utilizarCdBarraFornecedor(){
			this.EmpConfig.usarCodigoBarraFornecedor = this.EmpConfig.usarCodigoBarraFornecedor ? true : false;

		},
		handleAgruparProdutoIguaisCompra() {
			this.EmpConfig.agruparProdutoIguaisCompra = this.EmpConfig.agruparProdutoIguaisCompra ? true : false;
		},
		
		visualizarAPIKEYConciliadora () {
			this.mostrarAPIConciliadora = !this.mostrarAPIConciliadora;
			if (this.mostrarAPIConciliadora) {
				this.proximoCampo("apiKeyConciliadora");
			}
		},
		async salvarConciliadora () {
			this.$store.carregando = true;
			let action = "salvarConfigConciliadora";
			if (this.EmpConfig.dataCadastroConciliadora != null) {
				action = "alterarConfigConciliadora";
			}
			const data = await this.$store.dispatch(action, {
				conciliadora: {
					dataCadastroConciliadora: this.EmpConfig.dataCadastroConciliadora,
					dataUltConciliacao: this.EmpConfig.dataUltConciliacao,
					apiKeyConciliadora: this.EmpConfig.apiKeyConciliadora,
					idEmpresa: this.EmpConfig.idEmpresa,
					senhaEmpresa: this.EmpConfig.senhaEmpresa,
				},
				cdempresa: this.Empresa.id
			});
			this.EmpConfig.dataCadastroConciliadora = data.datacadastroconciliadora;
			this.EmpConfig.dataUltConciliacao = data.dataultconciliacao;
			this.EmpConfig.usuarioCadastroConciliadora = data.usuariocadastroconciliadora;
			this.EmpConfig.apiKeyConciliadora = data.apikeyconciliadora;
			this.EmpConfig.idEmpresa = data.idEmpresaConciliadora;
			this.EmpConfig.senhaEmpresa = data.senhaEmpresaConciliadora;
			this.visualizarAPIKEYConciliadora();
			this.$store.carregando = false;
		},
		async removerConciliadora () {
			this.$store.carregando = true;
			const data = await this.$store.dispatch("removerConfigConciliadora", {...this.EmpConfig, cdempresa: this.Empresa.id});
			if (data?.cdempresa) {
				this.EmpConfig.dataCadastroConciliadora = null;
				this.EmpConfig.dataUltConciliacao = null;
				this.EmpConfig.apiKeyConciliadora = null;
				this.EmpConfig.senhaEmpresa = null;
				this.EmpConfig.idEmpresa = null;
			}
			this.$store.carregando = false;
		},
		selecionouClientePadrao(cliente) {
			this.EmpConfig.clientePadrao = cliente.nome;
			this.EmpConfig.cdconsumidorfinal = cliente.id;
			this.selecionarClientePadrao = false;
		},
		proximoCampo(idCampo) {
			setTimeout(() => {
				const campo = document.getElementById(idCampo);
				if (campo?.focus) {
					campo.focus();
					if (campo.select) {
						campo.select();
					}
				}
			}, 10);
		},
		mudarAtivo(campo, letra1, letra2) {
			if (this.EmpConfig[campo] == letra1) {
				this.EmpConfig[campo] = letra2;
			}
			else {
				this.EmpConfig[campo] = letra1;
			}
		},
		mascaraLogin(e) {
			const regex = /[a-z/^A-Z]/;
			const texto = e.target.value;
			if(!regex.test(e.data) && e.data != null){
				this.EmpConfig[e.target.id] = this.EmpConfig[e.target.id].substring(0, texto.length - 1);

			}
		},
		mascaraInteger(e) {
			const regex = /[0-9]/;
			if (!regex.test(e.data) && e.data != null) {
				this.EmpConfig[e.target.id] = this.EmpConfig[e.target.id].substring(0, this.EmpConfig[e.target.id].length - 1);
			}
		},
		mascaraFloat(e) {
			const regex = /[0-9,.]/;
			if (!regex.test(e.data) && e.data != null) {
				this.EmpConfig[e.target.id] = this.EmpConfig[e.target.id].substring(0, this.EmpConfig[e.target.id].length - 1);
			}
		},
		async salvarEmpresaConfig() {
			this.$emit("salvarConfiguracoes", {...this.EmpConfig});
		},
		async buscarClientePadrao(){
			if(this.EmpConfig.cdconsumidorfinal){
				const cliente = await this.$store.dispatch("buscarUnicoCliente", this.EmpConfig.cdconsumidorfinal);
				if(cliente != null){
					this.EmpConfig.clientePadrao = cliente.nome;
					this.$forceUpdate();
				}
			}else{
				this.EmpConfig.clientePadrao = this.$store.state.ClientePadrao.nome;
			}
		},
		cancelarEdicao() {
			this.$emit("cancelarEdicao");
		},
		menor440(){
			if(screen.width < 441){
				return true;
			}
			return false;
		}
	},
};
</script>
<style scoped>
#alinha-parametros{
	display:flex;
	flex-direction:column;
}
#alinha-etiqueta{
	padding: 0px 10px; 
	margin-left: 100px;
}
#alinha-sistema{
	padding: 0px 10px;
}
.input-margin{
	margin-left: 15px;
}

.input-group label {
	height: 12px;
}

#smaller-view {
	display: none;
}

.sm-view {
	display: none;
}

.mt-15 {
	margin-top: 15px;
}

.mt-15 .label-switch {
	white-space: nowrap;
}

.trackContainer {
	position: absolute;
	top: -25px; 
	right: 0px;
}

.container {
	cursor: pointer;
}

.EmpresaConfig {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.config {
	display: flex;
	flex-direction: column;
	padding: 0 5px;
	overflow: auto;
	flex-grow: 1;
}

.fildset {
	display: flex;
	flex-direction: row;
	border-radius: 8px;
	border: solid 2px var(--border);
}

.fieldset-item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-grow: 1;
}

.fieldset-item fieldset {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: space-around;
	border-radius: 8px;
	border: solid 2px var(--border);
}

.input-group {
	width: 98%;
	margin: 5px;
}

.fieldset-item .fieldset-item-coluna {
	display: flex;
	flex-direction: column;
}

.fieldset-item .fieldset-item-linha {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	flex-grow: 1;
}

.fieldset-interno {
	margin: 0px 5px 5px 5px;
	display: flex;
	width: 100%;
	flex-direction: column;
}

.fieldset-interno fieldset {
	display: flex;
	flex-direction: row;
	margin: 0px 5px 5px 5px;
}


.legend {
	margin-left: 1rem;
	padding: 0 .5rem;
}

.campos {
	display: flex;
	flex-direction: row;
	margin: 0px;
	padding: 0px;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
}

.campo-item {
	display: flex;
	flex-direction: row;
	margin: 5px;
	width: 100%;
	align-items: center;
	white-space: nowrap;
}
.campos-nfE input{
	width: 100%;
}
.fieldset-parametro{
	justify-content: flex-start;
}
.campo-item input[type="text"] {
	width: 100%;
	height: max-content;
	display: flex;
}

.campo-item .input-pequeno {
	display: flex;
	flex-grow: 1;
	margin-right: 5px;
}

.campo-item label {
	text-align: left;
	white-space: nowrap;
}

.funcionalidade-atual {
	margin-top: 0px;
}

.botoes-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 5px 0px;
}

.campos-parametros{
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	flex-grow: 1;
}
.botoes-container button {
	height: auto;
	flex-grow: 1;
	margin: 5px;
}

.titulo {
	text-align: center;
}
.checked {
	margin-top: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
}
.selecionarCodBarra {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
input[type="checkbox"] {
	--webkit-appearance: none;
	visibility: hidden;
	display: none;
}
.check {
	display: block;
	position: relative;
	width: 60px;
	height: 30px;
	background: var(--branco);
	border: 1px solid var(--deletar);
	cursor: pointer;
	border-radius: 25px;
	overflow: hidden;
	transition: ease-in 0.5s;
}
input[type="checkbox"]:checked ~ .check {
	border: 1px solid var(--confirmar);
}
.check:before {
	content: "";
	position: absolute;
	top: 4px;
	left: 4px;
	background: var(--deletar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
}

.campos-radio{
	margin-left: 10px;
	display: flex;
	flex-direction: column;
}
.campos-radio label{
	margin-left: 10px;
}
input[type="checkbox"]:checked ~ .check:before {
	transform: translateX(-50px);
}
.check:after {
	content: "";
	position: absolute;
	top: 4px;
	right: 4px;
	background: var(--confirmar);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	transition: 0.5s;
	transform: translateX(50px);
}
input[type="checkbox"]:checked ~ .check:after {
	transform: translateX(0);
}

.fildset:nth-child(2) .fieldset-item .fieldset-item-linha .campo-item {
	flex-grow: 1;
}

.fildset:nth-child(3) .fieldset-item .fieldset-item-linha .campo-item {
	flex-grow: 1;
}

.fildset:nth-child(3) .fieldset-item .fieldset-item-linha .campo-item:nth-child(1) {
	width: 100%;
}

.buttons{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 10px;
}

.buttons button{
	width: 45%;
}

.gap-20 {
	gap: 20px;
}
@media screen and (max-width: 1024px) {
	.gap-20 {
		flex-wrap: wrap;
	}

	.gap-20 .campo-item {
		width: auto;
	}

	.sm-view {
		display: inline-block;
	}

	.lg-view {
		display: none;
	}

	.trackContainer {
		left: 300px;
	}
}

@media screen and (max-width: 1023px) {
	.fildset:nth-child(1) .fieldset-item {
		flex-wrap: wrap;
	}

	.fildset:nth-child(1) .fieldset-item fieldset:nth-child(1),
	.fildset:nth-child(1) .fieldset-item fieldset:nth-child(2) {
		width: 48%;
	}

	.fildset:nth-child(1) .fieldset-item fieldset:nth-child(3) {
		flex-grow: 1;
	}

	.campos {
		margin: 0px;
		padding: 0px;
		flex-grow: 1;
	}

	.campos .campo-item {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.campo-item{
		flex-wrap: wrap;
	}
	.fildset:nth-child(1) .fieldset-item fieldset:nth-child(1),
	.fildset:nth-child(1) .fieldset-item fieldset:nth-child(2) {
		flex-grow: 1;
	}
	.titulo {
		font-size: 10pt;
	}
	.campos{
		display: flex;
		flex-direction: column;
		margin-left: 10px;
		width: 100%;
		padding: 10px;
	}

	.form-column {
		margin-right: 100px;
	}
}

@media screen and (max-width: 640px) {

	.fieldset-item {
		display: flex;
		flex-wrap: wrap;
	}
	.input-pequeno{
		min-width: 60px;
	}
	.fieldset-interno {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
		align-items: flex-start;
	}
	.fieldset-interno .campos {
		margin: 0px 5px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.titulo {
		font-size: 8pt;
	}
	.primaryfieldset:nth-child(n + 2) .fieldset-item .box .campos:nth-child(n + 2) {
		width: 100%;
	}
	.primaryfieldset:nth-child(n + 2) .fieldset-item .box .campos:nth-child(n + 2) .inputBox {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.botoes-container span {
		display: none;
	}
}

@media screen and (max-width: 440px) {
	#api-key{
		width:89%;
	}
	#alinha-etiqueta{
		margin-left: 0;
		align-self:center;
	}
	#alinha-sistema{
		align-self:center;
	}
	.campos{
		align-items: flex-start;
	}
	.trackContainer {
		left: 190px !important;
	}

	.mt-15 {
		margin-top: 45px;
	}

	.fieldset-item-linha {
		width: 90vw;
	}

	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}

	.form-column {
		margin: 0;
	}

	.input-group-switch {
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;
	}

	.checked {
		position: absolute;
		left: 130px;
		top: 0px;
	}
}

@media screen and (max-width: 425px){
	.campos{
		margin-left: 0;
	}
	.campos-parametros .campo-item{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.campo-item .mx-5 {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 5px 0px;
	}
}

@media screen and (max-width: 375px) {
	.lg-view {
		display: none;
	}

	.sm-view {
		display: inline-block;
	}
}

@media screen and (max-width: 320px) {
	fieldset {
		margin: 0 !important;
		padding: 0 !important;
		padding-right: 5px !important;
	}

	.input-group-switch label {
		white-space: normal !important;
	}

	#small-view {
		display: none;
	}

	#smaller-view {
		display: inline-block;
	}

}

</style>
