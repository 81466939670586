import {doRequest} from "./http.js";
import auth from "./auth.js";
import router from "../router/index.js";
import {StateEmpresa, Empresa, EmpresaAPI} from "../models/Empresa";
const API_URL_CLIENTES = process.env.VUE_APP_API_URL_CLIENTES;

export default {
	state: new StateEmpresa(),
	actions: {
		async buscarEmpresas({commit}, payload){
			try {
				let url = "empresa/all?size=25&sort=empresa&sortOrder=asc";
				if (payload) {
					const {page} = payload;
					url += `&page=${page || 0}`;
				} else {
					url += "&page=0";
				}
				const data = await doRequest(url, {
					method: "get", 
					cache: "no-cache"
				}, commit);
				if (data.content) {
					commit("atualizarListaEmpresas", data);
				} else {
					commit("atualizarListaEmpresas", null);
				}
			} catch (error) {
				console.log(error);
			}
		},

		async buscarEmpresaPorId({commit, dispatch, state}, payload){
			try {
				const url = `empresa?id=${payload}`;
				state.carregando = true;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (!data.cdconsumidorfinal) {
					throw new Error("Ops, essa empresa não possui cliente padrão, favor solicitar ao Supervisor.");
				}
				if(router.currentRoute.path === "/selecionaEmpresa") {
					await dispatch("verificarEmpresaLiberada", data);
					if(!state.EmpresaLiberada) {
						state.carregando = false;
						commit("relatarErro", {
							mensagem: "Empresa não autorizada à utilizar o sistema, por favor entre em contato com o financeiro!"
						});
						return;
					}
				}
				commit("atualizarEmpresa", data);
				await dispatch("verificarCaixaExistente");
				await dispatch("iniciarLogoutUsuario");
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
			}
		},


		async buscarEmpresaId({commit, state}, payload){
			try {
				const {id} = payload;
				const url = `empresa?id=${id}`;
				state.carregando = true;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data?.cdempresa) {
					state.carregando = false;
					return new Empresa(data);
				}
				state.carregando = false;
				return {};
			} catch (error) {
				state.carregando = false;
				console.log(error);
				return {};
			}
		},
		async validarCnpjEmpresa({state, commit}, payload) {
			try {
				state.carregando = true;
				const {cnpj} = payload;
				const url = `empresa/cnpj?cnpj=${cnpj}`;
				const data = await doRequest (url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if(!data.mensagem){
					return {...data, naovalida: true};
				}else{
					return {...data};
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
			}
		},

		async buscarEmpresaPorAtalho({commit}, payload){
			try {
				const url = `empresa/atalhoLogin?atalho=${payload}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.mensagem) {
					commit("relatarErro", {mensagem: data.mensagem});
					return {...data, error: true};
				} else {
					return {...data};
				}
			} catch (error) {
				console.log(error);
			}
		},

		async filtrarEmpresas({state, commit}, payload){
			try {
				state.carregando = true;
				const {query, page} = payload;
				const url = `empresa/filter?query=${query}&page=${page}&size=25&sort=cdempresa&sortOrder=asc`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.content) {
					commit("atualizarListaEmpresas", null);
				} else {
					commit("atualizarListaEmpresas", data);
				}
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
			}
		},

		async cadastrarEmpresa({commit, state}, payload){
			try {
				state.carregando = true;
				const url = `empresa?cdUsuario=${state.Usuario.id}`;
				const body = JSON.stringify(new EmpresaAPI(payload));
				const data = await doRequest(url, {method: "post", body, cache: "no-cache"}, commit);
				if (!data) return null;
				if (!data.cdempresa) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				state.carregando = false;
				return new Empresa(data);
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
				return null;
			}
		},
		async alterarEmpresa({commit, state}, payload){
			try {
				state.carregando = true;
				const url = `empresa?cdUsuario=${state.Usuario.id}&id=${payload.id}`;
				const body = JSON.stringify(new EmpresaAPI(payload));
				const data = await doRequest(url, {method: "put", body, cache: "no-cache"}, commit);
				if (!data) return null;
				if (!data.cdempresa) {
					throw new Error(data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				}
				if (data.cdempresa == state.Empresa.id) {
					commit("atualizarEmpresa", data);
				}
				state.carregando = false;
				return new Empresa(data);
			} catch (error) {
				console.log(error);
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
				return null;
			}
		},
		async uploadFile({state, commit}, payload) {
			try {
				if (payload.type != "application/x-pkcs12"){
					throw new Error("Tipo de arquivo inválido, por favor verifique");
				}
				const body = new FormData();
				body.append("certFile", payload);
				const url = `empresa/certificado?cdempresa=${state.Empresa.id}`;
				const data = await doRequest(url, {method: "PUT", body, cache: "no-cache", contentType: "off"}, commit);
				return data;

			} catch (error) {
				commit("relatarErro", {mensagem: error.message});
			}
		},
		async deleteFile({state, commit}){
			state.carregando = true;
			const url = `empresa/certificado?cdempresa=${state.Empresa.id}`;
			const data = await doRequest(url, {method: "DELETE", cache: "no-cache"}, commit);
			state.carregando = false;
			return data;
		},

		async salvarConfigConciliadora ({state, commit}, payload){
			state.carregando = true;
			const url = `empresa/conciliadora?cdempresa=${payload.cdempresa}&cdUsuario=${state.Usuario.id}`;
			const body = JSON.stringify(payload.conciliadora);
			const data = await doRequest(url, {method: "post", body, cache: "no-cache"}, commit);
			state.carregando = false;
			return data;
		},

		async alterarConfigConciliadora ({state, commit}, payload){
			state.carregando = true;
			const url = `empresa/conciliadora?cdempresa=${payload.cdempresa}&cdUsuario=${state.Usuario.id}`;
			const body = JSON.stringify(payload.conciliadora);
			const data = await doRequest(url, {method: "put", body, cache: "no-cache"}, commit);
			state.carregando = false;
			return data;
		},

		async removerConfigConciliadora ({state, commit}, payload){
			state.carregando = true;
			const url = `empresa/conciliadora?cdempresa=${payload.cdempresa}&cdUsuario=${state.Usuario.id}`;
			const data = await doRequest(url, {method: "delete", cache: "no-cache"}, commit);
			state.carregando = false;
			return data;
		},


		async verificarEmpresaLiberada({commit}, empresa) {
			try {
				const token = await auth.getTokenClientesAPI(commit, API_URL_CLIENTES);
				if(token) {
					const headers = {
						"Authorization": `Bearer ${token}`,
						"Content-Type": "application/json"
					};
					let url = `${API_URL_CLIENTES}/clientes/cnpj`;
					url += `?cpfcnpj=${empresa.cnpj}`;

					const res = await fetch(url, {
						headers,
						method: "GET",
					});
					if(res.status === 500) {
						commit("atualizarEmpresaLiberada", true);
						return;
					}
					const data = await res.json();

					if(data && data.id) {
						if(data?.ativo) {
							commit("atualizarEmpresaLiberada", true);
							return;
						} else {
							commit("atualizarEmpresaLiberada", false);
							return;
						}
					}
					commit("atualizarEmpresaLiberada", true);
					return;
				}
			} catch (error) {
				console.log(error);
			}
		}

	},

	mutations: {
		atualizarEmpresaLiberada(state, payload) {
			state.EmpresaLiberada = payload;
		},

		atualizarListaEmpresas(state, payload){
			state.Empresas = [];
			state.paginaEmpresasAtual = -1;
			state.totalPaginasEmpresas = 0;
			if (!payload) return;
			state.Empresas = payload.content.map(emp => new Empresa(emp)).sort((a, b) => a.id - b.id);
			state.paginaEmpresasAtual = payload.page;
			state.totalPaginasEmpresas = payload.totalPages;
		},

		atualizarEmpresa(state, payload){
			state.Empresa = null;
			state.Empresa = new Empresa(payload);
		}
	}
};
