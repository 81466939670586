import { localStorageManage } from "../service/localStorage.js";
import Vue from "vue";
import { ListaVendas, Venda, VendaAPI, VendaTroca } from "../models/Venda.js";
import { Empresa } from "../models/Empresa.js";
import router from "../router/index.js";
import {doRequest} from "./http.js";
import { dateFormatISO } from "../utils/dateformat";
import JSZip from "jszip";
import auth from "./auth.js";
export const API_PATH = `${process.env.VUE_APP_API_PATH}`;


const maximoTempoEsperaNota = (1 * 60 * 1000);

export default {
	state: {
		LancarCusto: false,
		Venda: null,
		Vendas: [],
		VendasAberto: [],
		VendasCondicional: [],
		VendasFiltradas: [],
		ListaVendas: {
			content: [],
			size: 25,
			paginaAtual: 0,
			totalPaginas: 0,
			totalItens: 0
		},
		Liberacao: {},
		paginaVendasAtual: 0,
		totalPaginasVendas: -1,
		PaginaAtualCondicional: 0,
		PaginaMaxCondicional: -1,
		VendasTroca: [],
		itensVenda: [],
		anexoVendas: null,
		anexoFinanceiro: null,
		controller: null,
		paginaVendasTrocaAtual: 0,
		totalPaginasVendasTroca: -1,

	},

	actions: {
		async buscarVendaAbertoPorCliente({commit, state}, payload) {
			try {
				state.carregando = true;

				let url = `venda/venda-aberto-cliente?empresa=${state.Empresa.id}`;

				url += `${payload ? `&cliente=${payload}` : ""}`;

				const data = await doRequest(url, {
					method: "GET",
					cache: "no-cache"
				}, commit);

				state.carregando = false;

				return data;

			} catch (error) {
				console.log(error);
			}
		},

		async buscarVendasTroca({commit, state}, payload){
			try {
				state.carregando = true;
				const { 
					dataI,
					dataF,
					codbarra,
					cdProduto,
					cdVenda,
					page,
					query,
					referencia,
					descproduto,
					nomeCliente
				} = payload;
				let url = "consultaTroca?";
				url += "size=25";
				url += "&sortOrder=desc";
				url += "&sort=cdvenda";
				url += `&empresa=${state.Empresa.id}`;
				url += `${page ? `&page=${page}` : ""}`;
				url += `${dataI ? `&datai=${dataI}` : ""}`;
				url += `${dataF ? `&dataf=${dataF}` : ""}`;
				url += `${query ? `&query=${query}` : ""}`;
				url += `${cdVenda ? `&venda=${cdVenda}` : ""}`;
				url += `${codbarra ? `&codbarra=${codbarra}` : ""}`;
				url += `${cdProduto ? `&cdProduto=${cdProduto}` : ""}`;
				url += `${nomeCliente ? `&cliente=${nomeCliente}` : ""}`;
				url += `${referencia ? `&referencia=${referencia}` : ""}`;
				url += `${descproduto ? `&descproduto=${descproduto}` : ""}`;
				const response = await doRequest(url, {method: "GET", cache: "no-cache"}, commit);
				state.carregando = false;

				if (response && ((response.content && response.content.length > 0) || response.length > 0)){
					commit("atualizarListaVendas", {data: response, tipo: "VendasTroca", troca: true});
				} else {
					commit("atualizarListaVendas", {data: [], tipo: "VendasTroca"});
					commit("limparStateListaVendas");
					commit("alterarInformativo", {mensagem: "Nenhuma venda localizada!", tipo: "erro", erro: false});
				}

			} catch (error) {
				console.log(error);
			}
		},

		async buscarRelatorioVendas({commit, state, dispatch}, payload) {
			state.carregando = true;
			try {
				const {
					dataf,
					datai,
					mostrarCondicional,
					page,
					size,
					sort,
					sortOrder,
					cancelada,
					completo,
					vendedor,
					tipoPagamento,
					enviada,
					cdVenda,
					descricao,
				} = payload;
				let url = "venda/findInFields?";
				if (!cdVenda) {
					url += `dataf=${dataf}&datai=${datai}`;
				} else {
					url += `venda=${cdVenda}`;
				}
				url += `&mostrarCondicional=${mostrarCondicional}`;
				url += `&empresa=${state.Empresa.id}`;
				url += `&page=${page}`;
				url += `&size=${size}`;
				url += `&sort=${sort}`;
				url += `&sortOrder=${sortOrder}`;
				if (cancelada) {
					url += `&cancelada=${cancelada}`;
				}
				if (vendedor) {
					url += `&vendedor=${vendedor}`;
				}
				if (enviada) {
					url += `&enviada=${enviada}`;
				}
				if (tipoPagamento) {
					url += `&tipoPagamento=${tipoPagamento}`;
				}
				if (descricao){
					url += `descproduto=${descricao.toUpperCase()}&`;
				}
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content) {
					dispatch("gerarRelatorioVenda", {
						anexo: data.anexo,
						payload: [...data.content],
						completo,
						datai,
						dataf,
						mostrarCondicional,
					});
				}
				state.carregando = false;
			} catch (error) {
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
		},

		async inserirVendaSemPagamento({commit, state, dispatch}, payload){
			state.carregando = true;
			try {
				const cdusuario = state.Usuario.id;
				const form = new VendaAPI({
					...payload,
					cdIdentificador: payload.cdIdentificador === 0 ? payload.cdIdentificador :  state.Caixa.caixa.cdcaixa >= 0 ? state.Caixa.caixa.cdcaixa : state.Caixa.caixa.cdCaixa,
					cdUsuarioCaixa: !payload.cdUsuarioCaixa ? state.Usuario.caixa || state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioCaixa,
					cdUsuarioLiberacao: !payload.cdUsuarioLiberacao ? state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioLiberacao
				}, state);
				const body = JSON.stringify(form);
				let url= "venda";

				if(cdusuario) {
					url += `?cdusuario=${cdusuario}`;
				}
				const data = await doRequest(url, {method: "post", body, cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdvenda){
					return commit("relatarErro", {mensagem: data.mensagem || data.details ? JSON.stringify(data.details) : JSON.stringify(data)});
				}
				commit("alterarInformativo", {mensagem: "Venda registrada com sucesso!", tipo: "sucesso", erro: false});
				if (data.vlrcondicional > 0 && data.condicao == "A") {
					await dispatch("gerarCupom", {
						empresa: state.Empresa,
						venda: new Venda(data)
					});
				}
				if (!state.Usuario.supervisor && !state.Usuario.caixa && !state.Usuario.usaCelular) {
					return dispatch("logOut");
				}
				await dispatch("buscarStateInicial");
				state.situacaoCliente = null;
				
				if(!state.Usuario.usaCelular && state.Empresa.tiposistema === "L") {
					const venda = new Venda(data);
					state.Venda = venda;
					state.itensVenda = venda.itens;
					router.replace({name: "FechamentoMesa"});
					return;
				}
				if(!state.Usuario.usaCelular){
					router.replace({name: "Principal"});
				}
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
			}
			state.carregando = false;
			
		},

		async inserirVendaComPagamento({state, commit, dispatch}, payload){
			state.carregando = true;
			try {
				const cdusuario = state.Usuario.id;
				const form = new VendaAPI({
					...payload,
					cdIdentificador: state.Caixa.caixa.cdcaixa >= 0 ? state.Caixa.caixa.cdcaixa : state.Caixa.caixa.cdCaixa,
					cdUsuarioCaixa: !payload.cdUsuarioCaixa ? state.Usuario.caixa || state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioCaixa,
					cdUsuarioLiberacao: !payload.cdUsuarioLiberacao ? state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioLiberacao
				}, state);
				const body = JSON.stringify(form);
				let url= "venda";
				if(cdusuario) {
					url += `?cdusuario=${cdusuario}`;
				}
				const data = await doRequest(url, {method: "post", body, cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdvenda){
					return commit("relatarErro", {mensagem: data.mensagem || data.details ? JSON.stringify(data.details) : JSON.stringify(data)});
				}
				commit("alterarInformativo", {mensagem: "Venda registrada com sucesso!", tipo: "sucesso", erro: false});
				if (!state.Usuario.supervisor && !state.Usuario.caixa) {
					return new Venda(data, state);
				}
				await dispatch("buscarStateInicial");
				state.situacaoCliente = null;
				return new Venda(data, state);
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || JSON.stringify(error)});
				state.carregando = false;
			}
		},

		async inserirPagamentoVenda({state, commit, dispatch}, payload){
			state.carregando = true;
			try {
				const cdusuario = state.Usuario.id;
				const form = new VendaAPI({
					...payload,
					cdIdentificador: state.Caixa.caixa.cdcaixa >= 0 ? state.Caixa.caixa.cdcaixa : state.Caixa.caixa.cdCaixa,
					cdUsuarioCaixa: !payload.cdUsuarioCaixa ? state.Usuario.caixa || state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioCaixa,
					cdUsuarioLiberacao: !payload.cdUsuarioLiberacao ? state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioLiberacao
				}, state);
				const body = JSON.stringify(form);
				let url= `venda?cdvenda=${payload.cdVenda}&empresa=${payload.cdEmpresa}&cdusuario=${cdusuario}`;
				const data = await doRequest(url, {method: "put", body, cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdvenda){
					commit("relatarErro", {mensagem: data.mensagem || (data.details ? JSON.stringify(data.details) : JSON.stringify(data))});
					return null;
				}
				commit("alterarInformativo", {mensagem: "Venda registrada com sucesso!", tipo: "sucesso", erro: false});
				await dispatch("buscarStateInicial");
				state.situacaoCliente = null;
				return new Venda(data, state);
			} catch (error) {
				commit("relatarErro", {mensagem: error.mensagem || error.message || JSON.stringify(error)});
			}
			state.carregando = false;
		},

		async filtrarVendas({state, commit}, payload){
			state.carregando = true;
			try {
				const {
					page,
					cdProduto,
					cliente,
					codbarra,
					dataEmissao,
					descricao,
					referencia,
					cdVenda,
					dataI,
					dataF,
					tipoPagamento,
					data,
					cancelada,
					troca,
					size,
					nomeCliente,
					vendedor,
					sort,
					mostrarCondicional,
					enviada,
					impressa
				} = payload;
				let url = "venda/findInFields?";
				if (dataI && dataF) {
					url += `dataf=${dataF}&datai=${dataI}&`;
				} else if (data) {
					url += `dataf=${data}&datai=${data}&`;
				}
				if (cdProduto) {
					url += `cdProduto=${cdProduto}&`;
				}
				if (cliente || nomeCliente){
					url += `cliente=${(cliente || nomeCliente).toUpperCase()}&`;
				}
				if (codbarra){
					url += `codbarra=${codbarra}&`;
				}
				if (dataEmissao){
					url += `data=${dataEmissao}&`;
				}
				if (descricao){
					url += `descproduto=${descricao.toUpperCase()}&`;
				}
				if (tipoPagamento) {
					url += `tipoPagamento=${tipoPagamento}&`;
				}
				if (mostrarCondicional) {
					url += `mostrarCondicional=${mostrarCondicional}&`;
				}
				if (enviada) {
					url += `enviada=${enviada}&`;
				}
				if (impressa) {
					url += `impressa=${impressa}&`;
				}
				if (troca) {
					url += `dataf=${new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")}&datai=${
						new Date(
							new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - state.Usuario.maxDiasTroca
						).toLocaleDateString("en-CA")
					}&`;
				}
				url += `empresa=${state.Empresa.id}&page=${page || 0}${referencia ? `&referencia=${referencia}` : ""}&`;
				url += `size=${size || 25}&sort=${sort || "dataemissao"}&sortOrder=desc${cdVenda ? `&venda=${cdVenda}` : ""}`;
				if ((!state.Usuario.supervisor || !state.Usuario.usuarioPrivilegiado) && !troca) {
					url += `&cdidentificador=${state.Caixa.caixa.cdcaixa || state.Caixa.caixa.cdCaixa}`;
				}
				if (cancelada || troca) {
					url += `&cancelada=${cancelada || "N"}`;
				}
				if (vendedor) {
					url += `&vendedor=${vendedor}`;
				}
				const response = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (response && ((response.content && response.content.length > 0) || response.length > 0)){
					commit("atualizarListaVendas", {data: response, tipo: "Vendas", troca});
					commit("atualizarStateListaVendas", response);
				} else {
					commit("atualizarListaVendas", {data: [], tipo: "Vendas"});
					commit("limparStateListaVendas");
					commit("alterarInformativo", {mensagem: "Nenhuma venda localizada!", tipo: "erro", erro: false});
				}
			} catch (error) {
				state.carregando = false;
			}
		},

		async buscarVendasAberto({state, commit}){
			try {
				let url = `venda/VendaAberto?empresa=${state.Empresa.id}`;
				doRequest(url, {method: "get", cache: "no-cache"}, commit).then((res) => {
					if (res && res.length > 0){
						return commit("atualizarListaVendas", {data: res, tipo: "VendasAberto"});
					} else {
						commit("atualizarListaVendas", {data: [], tipo: "VendasAberto"});
					}
				}).catch(() => {
					commit("atualizarListaVendas", {data: [], tipo: "VendasAberto"});
				});
			} catch (error) {
				commit("atualizarListaVendas", {data: [], tipo: "VendasAberto"});
			}
		},

		async buscarVendasCondicionalEmAberto({state, commit}, payload){
			state.carregando = true;
			try {
				let url;
				if (!payload) {
					url = `venda/condicionalAberto?empresa=${state.Empresa.id}&identificador=${state.Caixa.caixa.cdcaixa >=0 ? state.Caixa.caixa.cdcaixa : state.Caixa.caixa.cdCaixa}`;
					url += `&page=0&size=${150}&sort=dataemissao&sortOrder=asc`;
				} else {
					url = `venda/condicionalAberto?empresa=${state.Empresa.id}&identificador=${state.Caixa.caixa.cdcaixa >=0 ? state.Caixa.caixa.cdcaixa : state.Caixa.caixa.cdCaixa}`;
					url += `&page=${payload.page}&size=${150}&sort=dataemissao&sortOrder=asc`;
				}
				doRequest(url, {method: "get", cache: "no-cache"}, commit).then((res) => {
					if (res.length > 0) {
						return commit("atualizarListaVendas", {data: res, tipo: "VendasCondicional"});
					}
					return commit("atualizarListaVendas", {data: [], tipo: "VendasCondicional"});
				}).catch(() => {
					return commit("atualizarListaVendas", {data: [], tipo: "VendasCondicional"});
				});
			} catch (error) {
				return commit("atualizarListaVendas", {data: [], tipo: "VendasCondicional"});
			}
		},

		async cancelarCondicional({state, commit, dispatch}, payload){
			state.carregando = true;
			try {
				const {vendaOrigem} = payload;
				let url= `venda/cancelarCondicional?cdvenda=${vendaOrigem}&empresa=${state.Empresa.id}&tipo=F&identificador=${state.Caixa.caixa.cdcaixa || state.Caixa.caixa.cdCaixa}`;
				await doRequest(url, {method: "put", cache: "no-cache"}, commit);
				await dispatch("buscarStateInicial");
			} catch (error) {
				state.carregando = false;
			}
		},

		async buscarVendasParaTroca({state, commit}, payload){
			state.carregando = true;
			try {
				const {size, cliente} = payload;
				let url= `venda/findInFields?cancelada=N&dataF=${new Date().toLocaleDateString("en-GB").split("/").reverse().join("-")} 23:59`;
				url += `&dataI=${new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - state.Usuario.maxDiasTroca).toLocaleDateString("en-CA")} 00:00`;
				url += `&cliente=${cliente.id}`;
				url += `&empresa=${state.Empresa.id}&page=0&size=${size || 25}&sort=dataemissao&sortOrder=asc`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				commit("atualizarListaVendas", {data, tipo: "VendasTroca", troca: true});
			} catch (error) {
				state.carregando = false;
			}
		},


		async atualizarFinanceiroVenda({state, commit}, payload) {
			try {
				state.carregando = true;
				const form = new VendaAPI({
					...payload,
					valorbruto: payload.valorBruto,
					cdIdentificador: payload.cdIdentificador === 0 ? payload.cdIdentificador : state.Caixa.caixa.cdcaixa >= 0 ? state.Caixa.caixa.cdcaixa : state.Caixa.caixa.cdCaixa,
					cdUsuarioCaixa: !payload.cdUsuarioCaixa ? state.Usuario.caixa || state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioCaixa,
					cdUsuarioLiberacao: !payload.cdUsuarioLiberacao ? state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioLiberacao
				}, state);
				const body = JSON.stringify(form);
				let url= `${state.API_URL}/venda?cdvenda=${payload.cdVenda}&empresa=${payload.cdEmpresa}`;

				
				const authHeader = await auth.getAuthorizationHeaders(commit, state.API_URL);
				const { token, tipo} = authHeader;
				const headers = {
					"Authorization": `${tipo} ${token}`
				};
				headers["Content-Type"] = "application/json";

				const controller = new AbortController();
				const timeout = setTimeout(() => { controller.abort(); }, 5000);

				const options = {
					headers,
					body,
					method: "PUT",
					signal: controller.signal,
					cache: "no-cache"
				};

				fetch(url, { ...options }).then((response) => { return response.json(); }).then((data) => {
					state.carregando = false;
					if(!data.cdvenda){
						state.controller = null;
						return commit("relatarErro", {
							mensagem: data.mensagem || data.details 
								? JSON.stringify(data.details) 
								: JSON.stringify(data)
						});
					} else {
						state.controller = null;
						state.Venda = new Venda(data);
						return data;
					}
				}).catch((err) => {
					if(err.name === "AbortError") {
						state.controller = null;
						state.carregando = false;
						commit("relatarErro", {
							mensagem: "Erro ao lançar o pagamento, por favor tente novamente se persistir entre em contato com o suporte.",
						});
						return err;
					}
				}).finally(() => {
					state.carregando = false;
					state.controller = null;
					clearTimeout(timeout);
				});
			} catch (error) {
				console.log(error);
			}	
		},

		async atualizarVenda({state, commit, dispatch}, payload){
			try {
				state.carregando = true;
				const form = new VendaAPI({
					...payload,
					cdIdentificador: payload.cdIdentificador === 0 ? payload.cdIdentificador : state.Caixa.caixa.cdcaixa >= 0 ? state.Caixa.caixa.cdcaixa : state.Caixa.caixa.cdCaixa,
					cdUsuarioCaixa: !payload.cdUsuarioCaixa ? state.Usuario.caixa || state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioCaixa,
					cdUsuarioLiberacao: !payload.cdUsuarioLiberacao ? state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioLiberacao
				}, state);
				const body = JSON.stringify(form);
				let url= `venda?cdvenda=${payload.cdVenda}&empresa=${payload.cdEmpresa}`;
				const data = await doRequest(url, {method: "put", body, cache: "no-cache"}, commit);

				state.carregando = false;
				if (!data.cdvenda){
					return commit("relatarErro", {mensagem: data.mensagem || data.details ? JSON.stringify(data.details) : JSON.stringify(data)});
				}
				if(payload.variacao) return;
				if (data.vlrcondicional > 0 && data.condicao == "A") {
					await dispatch("gerarCupom", {
						empresa: state.Empresa,
						venda: new Venda(data)
					});
				}
				if (!state.Usuario?.supervisor && !state.Usuario.caixa) {
					if(!state.Usuario.usaCelular){
						state.Usuario = null;
						return router.push({name: "Autenticacao"});
					}
				}
				await dispatch("buscarStateInicial");
				if(!state.Usuario.usaCelular && state.Empresa.tiposistema === "L" && payload.route !== "FechamentoMesa") {
					return router.push({name: "FechamentoMesa"});
				}
				if(!state.Usuario.usaCelular) {
					return router.push({name: "Principal"});
				}
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || error.details ? JSON.stringify(error.details) : JSON.stringify(error)});
			}
			state.carregando = false;
		},

		async atualizarVendaNFE({state, commit}, payload){
			try {
				state.carregando = true;
				const form = new VendaAPI({
					...payload,
					cdIdentificador: payload.cdIdentificador === 0 ? payload.cdIdentificador : state.Caixa.caixa.cdcaixa >= 0 ? state.Caixa.caixa.cdcaixa : state.Caixa.caixa.cdCaixa,
					cdUsuarioCaixa: !payload.cdUsuarioCaixa ? state.Usuario.caixa || state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioCaixa,
					cdUsuarioLiberacao: !payload.cdUsuarioLiberacao ? state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioLiberacao
				}, state);
				const body = JSON.stringify(form);
				let url= `venda?cdvenda=${payload.cdVenda}&empresa=${payload.cdEmpresa}`;
				const data = await doRequest(url, {method: "put", body, cache: "no-cache"}, commit);

				state.carregando = false;
				if (!data.cdvenda){
					return commit("relatarErro", {mensagem: data.mensagem || data.details ? JSON.stringify(data.details) : JSON.stringify(data)});
				}
				return data;
			} catch (error) {
				commit("relatarErro", {mensagem: error.message || error.details ? JSON.stringify(error.details) : JSON.stringify(error)});
			}
			state.carregando = false;
		},

		lerXML({state, commit}, payload){
			try {
				
				state.carregando = true;
				if(!payload) return commit("relatarErro", {mensagem: "XML não encontrado"});
				const xml = payload;
				let xmlDoc;
				const pagamentos = [];
				const produtos = [];
				if(window.DOMParser){
					const parser = new DOMParser();
					xmlDoc = parser.parseFromString(xml, "text/xml");
				}
	
				//Pegar dados financeiro
				let financeiro = xmlDoc.getElementsByTagName("detPag");
				for(let i = 0; i < financeiro.length; i++){
					const pag = financeiro[i];
					const tipopgto = pag.childNodes[0].textContent;
					let pagamento = {
						tipoPgto: tipopgto === "01" ? "DINHEIRO" : tipopgto == "02" ? "CHEQUE" : tipopgto === "03" ? "CARTÃO DE CRÉDITO" : tipopgto === "04" ? "CARTÃO DE DÉEBITO" : tipopgto == "05" ? "CRÉDITO DE LOJA" : tipopgto === "10" ? "VALE ALIMENTAÇÃO" : tipopgto === "11" ? "VALE REFEIÇÃO" : tipopgto === "12" ? "VALE PRESENTE" : tipopgto === "13" ? "VALE COMBUSTÍVEL" : tipopgto === "14" ? "DUPLICATA MERCANTI" : tipopgto === "15" ? "BOLETO BANCÁRIO" : tipopgto === "16" ? "DEPÓSITO BANCÁRIO" : tipopgto === "17" ? "PAGAMENTO INSTANTÂNEO (PIX)" : tipopgto === "18" ? "TRANSFERÊNCIA BANCÁRIA, CARTEIRA DIGITAL" : tipopgto === "19" ? "PROGRAMA FIDELIDADE, CASHBACK, CRÉDITO VIRTUAL" : tipopgto === "90" ? "SEM PAGAMENTO" : "OUTROS",
						valor: Number(pag.childNodes[1].textContent)
					};
					pagamentos.push(pagamento);
				}
				//Pegar dados dos itens 
				let itens = xmlDoc.getElementsByTagName("prod");
				for(let i = 0; i < itens.length; i++){
					const item = itens[i];
					let parsedItem = {
						cdproduto: item.getElementsByTagName("cProd")[0].textContent,
						descricao: item.getElementsByTagName("xProd")[0].textContent,
						vlrunitario: Number(item.getElementsByTagName("vProd")[0].textContent),
						unidade: item.getElementsByTagName("uCom")[0].textContent,
						quantidade: Number(item.getElementsByTagName("qCom")[0].textContent)
					};
					produtos.push(parsedItem);
				}
				//Pegar dados do consumidor final (dest)
				let consumidor = {};
				const dest = xmlDoc.getElementsByTagName("dest")[0];
				if(dest){
					consumidor = {
						cpf: dest.childNodes[0].textContent,
						nome: dest.childNodes[1].textContent,
					};
				}
				//Pegar dados do emitente
				const emit = xmlDoc.getElementsByTagName("emit")[0];
				const enderEmit = xmlDoc.getElementsByTagName("enderEmit")[0];
				let emitente = {
					cnpj: emit.childNodes[0].textContent,
					nome: emit.childNodes[1].textContent,
					ie: emit.childNodes[4].textContent,
					logradouro: enderEmit.childNodes[0].textContent,
					numero: enderEmit.childNodes[1].textContent,
					bairro: enderEmit.childNodes[2].textContent,
					municipio: enderEmit.childNodes[4].textContent,
					uf: enderEmit.childNodes[5].textContent,
					cep: enderEmit.childNodes[6].textContent,
				};
	
				// Pegar dados da nota
				const infNFe = xmlDoc.getElementsByTagName("infNFeSupl")[0]; 
				const ide = xmlDoc.getElementsByTagName("ide")[0];
				const protocolo = xmlDoc.getElementsByTagName("infProt")[0];
				const total = xmlDoc.getElementsByTagName("ICMSTot")[0];
				let infoNota = {
					qrcode: infNFe.childNodes[0].textContent,
					urlConsulta: infNFe.childNodes[1].textContent,
					serie: ide.childNodes[4].textContent,
					nmrNF: ide.childNodes[5].textContent,
					dataEmissao: ide.childNodes[6].textContent,
					chNFe: protocolo.childNodes[2].textContent,
					nmrProtocolo: protocolo.childNodes[4].textContent,
					dataAuth: protocolo.childNodes[3].textContent,
					totalProduto: Number(total.childNodes[8].textContent)
				};
				state.carregando = false;
				const parsedXML = {
					pagamentos,
					produtos,
					consumidor,
					emitente,
					infoNota,
				};
				return parsedXML;
			} catch (error) {
				console.log(error);
				commit("relatarErro",{mensagem: error.message || error});
			}
		
		},
		
		async gerarNFCe({state, commit, dispatch}, payload){
			try {
				let {
					cdVenda,
					cpf
				} = payload;
				if(cpf){
					cpf = cpf.split(".").join("").split("-").join("");
				}
				state.carregando = true;
				let { 
					cnpj, 
					id 
				} = state.Empresa;
			
				if(payload.emitirNota === "E"){
					state.carregando = false;
					const xml = await dispatch("lerXML", payload.arquivoxml);
					if(xml){
						return dispatch("imprimirNFCE", xml);
					}
				}
				//verificar se o emissor ta correto
				state.carregando = false;
				let url = `fiscal/consultaEmissor?cnpj=${cnpj}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if(!data){
					return await dispatch("gerarNFE", payload);
				}
				if(typeof data == "object"  && !data.mensagem && !data.erro){
					state.carregando = true;
					state.controller = new AbortController();
					const signal = state.controller.signal;

					setTimeout(async() => {
						state.carregando = false;
						state.controller.abort();
						commit("relatarErro", {mensagem: "tempo de espera da resposta excedido tente novamente mais tarde"});
						state.controller = null;
					}, maximoTempoEsperaNota);
					url = `notaFiscal/emitirNFCe?cdvenda=${cdVenda}&empresa=${id}&producao=false&tipo=F${cpf ? `&cpf=${cpf}` : ""}`;
					await doRequest(url, {method: "post", cache: "no-cache", signal: signal}, commit).then(async (response) => {
						if(response && response.xml){
							state.controller = null;
							state.carregando = false;
							const xml = await dispatch("lerXML", response.xml);
							return dispatch("imprimirNFCE", xml);
						}
						if(response && response.mensagem){
							state.carregando = false;
							state.controller = null;
							const mensagem = JSON.parse(response.mensagem);
							return commit("relatarErro", {mensagem: `Erro: ${mensagem.mensagem || mensagem.ErrosRegras} \n ${mensagem.correcao ? `Correçao: ${mensagem.correcao}` : ""}`});
						}
					}).catch(err => {
						state.controller = null;
						state.carregando = false;
						console.log(err);
					});
				} else {
					return await dispatch("gerarNFE", payload);
				}
			} catch (error) {
				console.log(error);
				return await dispatch("gerarNFE", payload);
			}
		},
		async gerarNFE({state, commit, dispatch}, payload){		
			try {
				state.carregando = true;
				let body = {
					cdtransporte: payload.body.cdtransporte,
					especie: payload.body.especie,
					marca: payload.body.marca,
					observacao: payload.body.observacao,
					peso: typeof payload.body.peso == "number"? payload.body.peso : Number(payload.body.peso.replace(",",".")) || 0,
					pesoliq: typeof payload.body.pesoliq == "number"? payload.body.pesoliq : Number(payload.body.pesoliq.replace(",",".")) || 0,
					quantidade: Number(payload.body.quantidade),
					valorfrete: typeof payload.body.valorfrete == "number"? payload.body.valorfrete : Number(payload.body.valorfrete.replace(",",".")) || 0,
					documentos: payload.body.documentos,
					cfop: payload.body.cfop,
					frete: payload.body.tipoFrete,
				};
				body = JSON.stringify(body);
				let url = `notaFiscal/emitir-nfe?cdvenda=${payload.cdVenda}${payload.cpf ? `&cpf=${payload.cpf}` : ""}&cdempresa=${state.Empresa.id}&tipo=F`;
				url += `&tipoNota=${payload.tipoNota || "S"}`;
				const data = await doRequest(url, {method: "POST", body, cache: "no-cache"}, commit);

				if(data?.nota?.pdfBase64) {
					state.carregando = false;
					return dispatch("imprimirNFE", data.nota.pdfBase64);
				}
				if (data.erro) {
					state.carregando = false;
					return commit("relatarErro", {mensagem: data.mensagem});
				}
				if(data?.nota && data?.nota?.cStat) {
					state.carregando = false;
					return commit("relatarErro", {mensagem: `Erro ao emitir nota fiscal, motivo: ${data.nota.cStat} -> ${data.nota.xMotivo}`});
				}
				if(data.envio.cStat != 100 || data.retorno.cSat !== 104) {
					state.carregando = false;
					return commit("relatarErro", {
						mensagem: `Erro ao emitir nota fiscal, motivo: ${data.envio.cStat || data.retorno.cSat} -> ${data.envio.XMotivo || data.retorno.XMotivo}`
					});
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("relatarErro", {
					mensagem: error.mensagem || `Erro ao gerar a NFE. Tente novamente. [ERROR]: ${JSON.stringify(error)}`
				});
			}
		},

		async imprimirNFE({commit, state}, payload) {
			try {
				state.carregando = false;
				if (payload != null) {
					state.base64 = `data:application/pdf;base64,${payload}`;
				} else {
					commit("relatarErro", {mensagem: "NFC-E não retornou corretamente."});
				}
			} catch (error) {
				commit("relatarErro", {mensagem: error.mensagem || JSON.stringify(error)});
				state.carregando = false;
			}
		},

		async buscaCartasCorrecao({commit, state}, payload){
			state.carregando = true;
			try {
				let url= `nota-correcao/findByCdvenda?cdvenda=${payload}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				state.carregando = false;
			}
		},

		async salvaCartaCorrecao({commit, state}, payload){
			state.carregando = true;
			try {
				const form = {
					cdempresa:payload.cdEmpresa,
					cdvenda:payload.cdvenda,
					correcao:payload.justificativa,
					data:payload.data,
					tipo:payload.tipo,
					xml:payload.xml,
				};
				const body = JSON.stringify(form);
				let url= "nota-correcao";
				const data = await doRequest(url, {method: "post",body,  cache: "no-cache"}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				state.carregando = false;
			}
		},

		async editaCartaCorrecao({commit, state}, payload){
			state.carregando = true;
			try {
				const form = {
					cdempresa:payload.cdEmpresa,
					cdvenda:payload.cdvenda,
					correcao:payload.justificativa,
					data:payload.data,
					tipo:payload.tipo,
					xml:payload.xml,
				};
				const body = JSON.stringify(form);
				let url= `nota-correcao?cdcorrecao=${payload.cdcorrecao}&cdvenda=${payload.cdvenda}`;
				const data = await doRequest(url, {method: "put",body,  cache: "no-cache"}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				state.carregando = false;
			}
		},

		async emitirCartaCorrecao({commit, state}, payload){
			state.carregando = true;
			try {
				let url= `nota-correcao/emitir?cdempresa=${payload.cdEmpresa}&cdvenda=${payload.cdvenda}`;
				const data = await doRequest(url, {method: "put",body:null,  cache: "no-cache"}, commit);
				state.carregando = false;
				return data;
			} catch (error) {
				state.carregando = false;
			}
		},

		async cancelarVenda({state, commit, dispatch}, payload){
			state.carregando = true;
			try {
				if(payload.webnfeid){
					await dispatch("cancelarNota",payload);
					return;
				}
				const {cdVenda, justificativa, cdEmpresa} = payload;
				let url = `venda/cancelarVenda?cdvenda=${cdVenda}&empresa=${cdEmpresa}`;
				url += `&justificativa=${justificativa}&tipo=F`;
				const data = await doRequest(url, {method: "put", cache: "no-cache"}, commit);
				if (data.mensagem == "Venda Cancelada.") {
					await dispatch("buscarStateInicial");
				} else {
					commit("relatarErro", {mensagem: data.mensagem});
				}
			} catch (error) {
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
			state.carregando = false;
		},

		async cancelarNota({state, commit, dispatch}, payload){
			state.carregando = true;
			try {
				const {cdVenda, cdEmpresa} = payload;
				const form = {
					chaveDfe:payload.webnfeid,
					justificativa:payload.justificativa,
				};
				const body = JSON.stringify(form);
				let url = `notaFiscal/cancelamento-nfe?cdvenda=${cdVenda}&cdempresa=${cdEmpresa}&tipo=F`;
				const data = await doRequest(url, {method: "post", cache: "no-cache", body}, commit);
				if (data.cStat) {
					commit("relatarErro", {mensagem: `${data.cStat} -> ${data.xMotivo}`});
				}
				if (data.mensagem == "Venda Cancelada.") {
					await dispatch("buscarStateInicial");
				}
			} catch (error) {
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
			state.carregando = false;
		},

		async buscarVendaPorId({state, commit}, payload){
			state.carregando = true;
			try {
				const {cdVenda, cdEmpresa} = payload;
				let url= `venda?cdvenda=${cdVenda}&empresa=${cdEmpresa}`;
				const response = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (response && response.mensagem){
					return commit("relatarErro", {mensagem: response.mensagem});
				}
				return new Venda(response, state);
			} catch (error) {
				state.carregando = false;
			}
		},

		async solicitarImpressaoVenda({state, commit, dispatch}, payload) {
			try {
				const {cdVenda, cdEmpresa} = payload;
				const emp = await dispatch("buscarEmpresaId", {id: cdEmpresa});
				state.carregando = true;
				let url= `venda/imprimir?cdvenda=${cdVenda}&empresa=${emp.id}&tipo=F`;
				const data = await doRequest(url, {method: "put", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.via > 3 && (!state.Usuario.supervisor || !state.Usuario.usuarioPrivilegiado)) {
					return commit("relatarErro", {mensagem: "Foi atingido o número máximo de reimpressões desta venda!"});
				}
				dispatch("gerarCupom", {
					empresa: new Empresa(emp),
					venda: new Venda(data)
				});
			} catch (error) {
				state.carregando = false;
			}
		},

		async solicitarImpressaoPreVenda({state, commit, dispatch}, payload) {
			try {
				const {cdVenda, cdEmpresa} = payload;
				const emp = await dispatch("buscarEmpresaId", {id: cdEmpresa});
				state.carregando = true;
				let url= `venda/imprimir?cdvenda=${cdVenda}&empresa=${emp.id}&tipo=F`;
				const data = await doRequest(url, {method: "put", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.via > 3 && (!state.Usuario.supervisor || !state.Usuario.usuarioPrivilegiado)) {
					return commit("relatarErro", {mensagem: "Foi atingido o número máximo de reimpressões desta venda!"});
				}
				dispatch("gerarCupomPreVenda", {
					empresa: new Empresa(emp),
					venda: new Venda(data)
				});
			} catch (error) {
				state.carregando = false;
			}
		},

		async confirmarVenda({state, commit, dispatch}, payload) {
			try {
				state.carregando = true;
				const dataEntradaSaida = dateFormatISO({date: null, format: "ISOhour"});
				const form = new VendaAPI({
					...payload,
					dataEntradaSaida: dataEntradaSaida,
					cdIdentificador: state.Caixa.caixa.cdcaixa >= 0 ? state.Caixa.caixa.cdcaixa : state.Caixa.caixa.cdCaixa,
					cdUsuarioCaixa: !payload.cdUsuarioCaixa ? state.Usuario.caixa || state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioCaixa,
					cdUsuarioLiberacao: !payload.cdUsuarioLiberacao ? state.Usuario.supervisor ? state.Usuario.id : 0
						: payload.cdUsuarioLiberacao,
				}, state);
				const body = JSON.stringify(form);
				let url= `venda?cdvenda=${payload.cdVenda}&empresa=${payload.cdEmpresa}`;
				const data = await doRequest(url, {method: "put", body, cache: "no-cache"}, commit);
				state.carregando = false;
				state.Venda = null;
				state.itensVenda = [];
				if (!data.cdvenda){
					return commit("relatarErro", {mensagem: data.mensagem ? data.mensagem : data.details ? JSON.stringify(data.details) : JSON.stringify(data)});
				}
				await dispatch("buscarStateInicial");
			} catch (error) {
				console.log(error);
				commit("relatarErro", {mensagem: JSON.stringify(error)});
			}
			state.carregando = false;
		},

		async alterarEmLote({state, commit, dispatch}, payload) {
			try {
				state.carregando = true;
				let vendasOrigem = {};
				const condicoesVendas = {
					baixado: "B",
					aberto: "A",
					devolvido: "D",
					cancelado: "C"
				};
				let novosItens = [...state.itensVenda].filter(item => !item.vendaOrigem);

				Object.keys(payload).map((key) => {
					let condicao = condicoesVendas.aberto;
					let itensAtuais = [...state.itensVenda].filter(item => (item.vendaOrigem == key));
					if (!itensAtuais.length && !novosItens.length) {
						condicao = condicoesVendas.devolvido;
						payload[key].itens?.forEach(itemOrigem => {
							itemOrigem.situacao = condicoesVendas.devolvido;
						});
					} else {
						console.log(payload[key].itens);
						payload[key].itens?.forEach(itemOrigem => {
							const item = itensAtuais.find(it => (it.seqOrigem == itemOrigem.seq) && (it.produto?.id == itemOrigem.produto?.id));
							console.log(item, "venda 992");
							if (!item) {
								itemOrigem.situacao = condicoesVendas.devolvido;
							} else {
								if(itemOrigem?.quantidade !== item?.quantidade) {
									itemOrigem.quantidade = item.quantidade;
								}
								if (item.cdTipo == "C" && (item.situacao != condicoesVendas.devolvido)) {
									itemOrigem.situacao = condicoesVendas.aberto;
								} else if (item.cdTipo == "S" && (item.situacao == condicoesVendas.devolvido)) {
									itemOrigem.situacao = condicoesVendas.devolvido;
								} else if (item.cdTipo == "S" && (item.situacao != condicoesVendas.devolvido)) {
									itemOrigem.situacao = condicoesVendas.baixado;
								}
							}
						});
						if(novosItens){
							novosItens?.forEach(item => {
								itensAtuais.push({...item});
							});
						}
						novosItens = [];

						if (payload[key]?.baixarCondicional) {
							condicao = condicoesVendas.baixado;
						}
					}
					if(state.Venda.tipoVenda == "C" && !payload[key].alteracao) {
						payload[key].itens = itensAtuais;
					}
					vendasOrigem[key] = {...new VendaAPI({...payload[key], condicao}, state)};
				});
				const body = JSON.stringify({...vendasOrigem});
				const cdCaixa = state.Caixa.caixa.cdcaixa || state.Caixa.caixa.cdCaixa;
				const cdusuario = state.Usuario.id;
				let url = `venda/alterarEmLote?identificadorCaixa=${cdCaixa || 0}&cdusuario=${cdusuario}`;
				const data = await doRequest(url, {method: "put", body, cache: "no-cache"}, commit);
				state.carregando = false;
				if (data?.mensagem == "Vendas Salvas com sucesso!") {
					await dispatch("buscarStateInicial");
					if(router.currentRoute.path == "/lancamento"){
						router.replace({name: "Principal"});
						if (Object.keys(vendasOrigem).length == 1 && state.itensVenda?.length > 0) {
							await dispatch("solicitarImpressaoVenda", {
								cdVenda: Object.keys(vendasOrigem)[0],
								cdEmpresa: vendasOrigem[Object.keys(vendasOrigem)[0]].cdempresa
							});
						}
					}

					state.situacaoCliente = null;
				} else {
					commit("relatarErro", {mensagem: data.mensagem || data.details ? JSON.stringify(data.details) : JSON.stringify(data)});
				}
			} catch (error) {
				state.carregando = false;
				console.log(error);
			}
		},

		async compactarNotas({state, commit}, payload) {
			try {
				state.carregando = true;
				const {
					dataf,
					datai,
					size,
					sort,
					sortOrder,
				} = payload;
				let url = `venda/findInFields?empresa=${state.Empresa.id}`;
				url += "&mostrarCondicional=N";
				url += "&cancelada=N";
				url += "&enviada=S";
				url += "&page=0";
				url += `&size=${size}`;
				url += `&sort=${sort}`;
				url += `&sortOrder=${sortOrder}`;
				url += `&dataf=${dataf}&datai=${datai}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (!data) return state.carregando = false;
				if (!data.content) {
					throw new Error(data.message || (data.details ? JSON.stringify(data.details) : JSON.stringify(data)));
				} else {
					const zip = new JSZip();
					const vendas = [...data.content].map(venda => new Venda(venda));
					vendas.forEach(venda => {
						if (payload.gerarPdf) {
							if (venda.nfepdf) {
								zip.file(`Venda-${venda.cdVenda}.pdf`, venda.nfepdf, {base64: true});
							} else if (venda.arquivoxml) {
								throw new Error(`Foi verificado que o pedido nº ${venda.cdVenda} contém arquivo XML, porém não contém o arquivo PDF, favor entrar em contato com o suporte!`);
							}
						}
						if (payload.gerarXml) {
							if (venda.arquivoxml) {
								zip.file(`Venda-${venda.cdVenda}.xml`, venda.arquivoxml);
							} else if (venda.nfepdf) {
								throw new Error(`Foi verificado que o pedido nº ${venda.cdVenda} contém arquivo PDF, porém não contém o arquivo XML, favor entrar em contato com o suporte!`);
							}
						}
					});
					zip.generateAsync({type:"base64"}).then(function (base64) {
						const a = document.createElement("a");
						a.href = "data:application/zip;base64," + base64;
						a.download = "arquivos.zip";
						document.body.appendChild(a);
						a.click();
						a.remove();
						state.carregando = false;
					});
				}
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
			}
		},

		async retornaNota({state, commit}, payload) {
			try {
				state.carregando = true;
				const {
					venda,
					tipo,
				} = payload;
				let url = `venda/findInFields?empresa=${state.Empresa.id}`;
				url += "&mostrarCondicional=N";
				url += "&cancelada=N";
				url += "&enviada=S";
				url += "&page=0";
				url += "&size=50";
				url += "&sort=dataemissao";
				url += "&sortOrder=desc";
				url += `&venda=${venda}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (!data) return state.carregando = false;
				if (!data.content) {
					throw new Error("Essa nota ainda não foi enviada!");
				} else {
					var download = document.createElement("a");
					const vendas = [...data.content].map(venda => new Venda(venda));
					vendas.forEach(venda => {
						if (tipo == "pdf") {
							if (venda.nfepdf) {
								const linkSource = `data:application/pdf;base64,${venda.nfepdf}`;
								download.href = linkSource;
								download.download = `${venda.webnfeid}.pdf`;
								download.click();
							} else if (!venda.nfepdf) {
								throw new Error(`Foi verificado que o pedido nº ${venda.cdVenda} não contém o arquivo PDF, favor entrar em contato com o suporte!`);
							}
						}
						if (tipo == "xml") {
							if (venda.arquivoxml) {
								const arquivo = new Blob([venda.arquivoxml], {type: "text/plain"});
								download.href = window.URL.createObjectURL(arquivo);
								download.download = `${venda.webnfeid}.xml`;
								download.click();
							} else if (!venda.arquivoxml) {
								throw new Error(`Foi verificado que o pedido nº ${venda.cdVenda} não contém o arquivo XML, favor entrar em contato com o suporte!`);
							}
						}
					});
				}
				state.carregando = false;
			} catch (error) {
				state.carregando = false;
				commit("relatarErro", {mensagem: error.message || (error.details ? JSON.stringify(error.details) : JSON.stringify(error))});
			}
		},

		async buscaPessoaVenda({state, commit}, payload) {
			try {
				state.carregando = true;
				let url = `venda/pessoa?empresa=${state.Empresa.id}`;
				if (payload.cnpjCliente) {
					url += `&cnpjCliente=${payload.cnpjCliente}`;
				}
				if (payload.nomeCliente) {
					url += `&nomeCliente=${payload.nomeCliente}`;
				}
				if (payload.foneCliente) {
					url += `&foneCliente=${payload.foneCliente}`;
				}
				if (payload.enderecoCliente) {
					url += `&enderecoCliente=${payload.enderecoCliente}`;
				}
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.id) {
					return null;
				}
				return data;
			} catch (error) {
				state.carregando = false;
				console.log(error);
				return null;
			}
		},
	},

	mutations: {

		setValorFrete(state, payload) {
			state.Venda.fretegratis = payload?.fretegratis;
			state.Venda.valorfrete = payload?.valorfrete;
		},

		limparStateVenda(state){
			state.Venda = {};
			state.itensVenda = [];
			state.Venda.tipoVenda = "S";
			state.Venda.cliente = {...state.ClientePadrao};
		},
		atualizarConsumidorVenda(state, payload){
			localStorageManage("cliente", {...payload});
			Vue.set(state.Venda, "cliente", {...payload});
			state.Venda.nomeCliente = payload.nome;
			if (payload.mensagemvenda && state.ClientePadrao.id != payload.id) {
				state.mensagemErro = {
					mensagem: payload.mensagemvenda,
					alerta: true,
				};
			}
			state.Venda.observacao = payload.mensagemVenda || "";
		},

		atualizarVendedorVenda(state, payload){
			localStorageManage("vendedor", {...payload});
			Vue.set(state.Venda, "vendedor", {...payload});
		},

		alterarTipoVenda(state, payload){
			state.carregando = true;
			state.Venda.tipoVenda = payload;
			let queryString = `${router.currentRoute.path}?tipoVenda=${payload}`;
			Object.keys(router.currentRoute.query).forEach(query => {
				if (query != "tipoVenda") {
					queryString += `&${query}=${router.currentRoute.query[query]}`;
				}
			});
			router.replace(queryString);
			state.itensVenda.forEach(item => {
				item.cdTipo = payload;
				if (payload == "C"){
					item.valorUnitario = state.LancarCusto ? item.produto.valorPrazoAnterior : item.produto.valorPrazo;
					item.situacao = item.situacao != "D" ? "A" : item.situacao;
				} else {
					item.situacao = "S";
					if (item.produto.promocaoVista) {
						item.valorUnitario = (item.produto.valorPrazo - (item.produto.valorPrazo * (item.produto.promocaoPrazo /100)));
					}
				}
			});
			state.carregando = false;
		},

		atualizarVendaAtual(state, payload){
			state.Venda = new Venda(payload, state.Usuario);
		},

		atualizarStateListaVendas(state, payload) {
			state.ListaVendas = new ListaVendas(payload);
		},

		limparStateListaVendas(state) {
			state.ListaVendas = new ListaVendas();
		},

		atualizarListaVendas(state, payload){
			const {data, tipo, troca} = payload;
			const vendas = data.content ? data.content : data;
			state[tipo] = [];
			if(tipo === "VendasTroca"){
				state[tipo] = vendas.map(venda => new VendaTroca(venda)); 
				state.paginaVendasTrocaAtual = data.page || 0;
				state.totalPaginasVendasTroca = data.totalPages;
			}	
			if (tipo == "Vendas") {
				state["VendasFiltradas"] = [];
			}
			if (tipo == "VendasCondicional") {
				state.PaginaAtualCondicional = data.page || 0;
				state.PaginaMaxCondicional = data.totalPages;
			} else {
				state.paginaVendasAtual = data.page || 0;
				state.totalPaginasVendas = data.totalPages;
				state.anexoVendas = data.anexo;
				state.anexoFinanceiro = data.anexoFinanceiro;
			}
			if (vendas.length > 0) {
				if (troca) {
					return vendas.filter(venda => venda.vlrcondicional <= 0)
						.sort( (a, b) => new Date(b.dataemissao).getTime() - new Date(a.dataemissao).getTime() )
						.forEach( venda => {
							state[tipo].push(new Venda(venda, state.Usuario));
							if (tipo == "Vendas") {
								state["VendasFiltradas"].push(new Venda(venda, state.Usuario));
							}
						});
				}
				(tipo != "VendasCondicional" && !state.Usuario.supervisor ?
					vendas.filter(venda =>
						venda.cdIdentificador == (state.Caixa.caixa.cdcaixa || state.Caixa.caixa.cdCaixa) || venda.cdIdentificador >= 0
					) : (state.Usuario.usuarioPrivilegiado && router.currentRoute.name == "Vendas") ? vendas :
						vendas.filter(venda => venda.cdIdentificador >= 0 )
				)
					.sort((a, b) => tipo == "VendasCondicional" ? a.cliente.nome.localeCompare(b.cliente.nome) : b.cdvenda -a.cdvenda )
					.forEach( venda => {
						state[tipo].push(new Venda(venda, state.Usuario));
						if (tipo == "Vendas") {
							state["VendasFiltradas"].push(new Venda(venda, state.Usuario));
						}
					});
			}
		},

		iniciarVenda(state) {
			try {
				state.Venda = {
					cdIdentificador: state.Caixa.caixa.cdcaixa,
					cliente: {...state.ClientePadrao},
					vendedor: !state.Usuario.cdVendedor ? null : {...state.Usuario, id: state.Usuario.cdVendedor},
					valorVista: false,
					tipoVenda: router.currentRoute.query.tipoVenda || "S",
					somenteVista: false,
					cdUsuario: state.Usuario.id
				};
			} catch (error) {
				console.log(error);
				state.mensagemErro = {mensagem: error.message};
			}
		},

		inserirNovaVenda(state, payload){
			const {data, tipo} = payload;
			state[tipo].push(new Venda( data, state.Usuario));
		},

		inserirItemVenda(state, payload) {
			if (state.LancarCusto) {
				payload.valorVistaAnterior = payload.valorVista;
				payload.valorPrazoAnterior = payload.valorPrazo;
				payload.valorUnitarioAnterior = payload.valorUnitario;
				payload.valorUnitario = payload.custofixo || payload.custo;
				payload.valorVista = payload.custofixo || payload.custo;
				payload.valorPrazo = payload.custofixo || payload.custo;
			}
			const tipoVenda = router.currentRoute.query.tipoVenda;
			state.itensVenda.push({
				seq: state.itensVenda.length > 0 ? state.itensVenda[state.itensVenda.length - 1].seq + 1 : 1,
				cdCor: payload.cdCor,
				cdGrade: payload.cdGrade,
				cdTipo: tipoVenda,
				descor: payload.descor,
				situacao: payload.situacao ? payload.situacao : (tipoVenda == "C" ? "A" : "S"),
				valorUnitarioAnterior: payload.valorUnitarioAnterior,
				cdlinha: payload.cdgrupo,
				produto: {
					ativo: payload.ativo,
					id: payload.id,
					cdfornecedor: payload.cdFornecedor,
					cdgrupo: payload.cdsubgrupo,
					cdpai: payload.cdpai,
					cdlinha: payload.cdgrupo,
					cdReferencia: payload.cdReferencia,
					codBarra: payload.codBarra,
					descricao: payload.descricao,
					unidade: payload.unidade,
					saldo: payload.saldo,
					valorVista: Number(payload.valorVista),
					valorPrazo: Number(payload.valorPrazo),
					promocaoPrazo: payload.promocaoPrazo,
					promocaoVista: payload.promocaoVista,
					nomePromocao: payload.nomePromocao,
					cdpromocao: payload.cdpromocao,
					custo: payload.custo,
					custofixo: payload.custofixo,
					servprod: payload.servprod,
					valorVistaAnterior: payload.valorVistaAnterior,
					valorPrazoAnterior: payload.valorPrazoAnterior,
					valorPromocaoVista: payload.valorPromocaoVista,
					valorPromocaoPrazo: payload.valorPromocaoPrazo,
				},
				custo: payload.custo,
				custofixo: payload.custofixo,
				cdpromocao: payload.cdpromocao,
				quantidade: payload.quantidade,
				valorUnitario: state.Venda.valorVista ?
					Number(!Number(payload.valorUnitario) ? Number(payload.promocaoVista ? (payload.valorVista - (payload.valorVista * (payload.promocaoVista /100))) : payload.valorVista) : Number(payload.valorUnitario)) :
					Number(!Number(payload.valorUnitario) ? Number(payload.promocaoPrazo && ((state.Venda.tipoVenda || router.currentRoute.query.tipoVenda) != "C") ? (payload.valorPrazo - (payload.valorPrazo * (payload.promocaoPrazo /100))) : payload.valorPrazo) : Number(payload.valorUnitario)),
				cstCofins: payload.cofinssittrib || state.Empresa.cofinssittrib,
				cst: (state.Empresa.ufCidade == state.Venda.cliente.cidade.uf? payload.icmsestsittrib : payload.icmsintsittrib) || (state.Empresa.ufCidade == state.Venda.cliente.cidade.uf? state.Empresa.sittribest : state.Empresa.sittribint),
				cstPis: payload.pissittrib || state.Empresa.pissittrib,
				cfop: (state.Empresa.ufCidade == state.Venda.cliente.cidade.uf?  payload.cfopesta : payload.cfopinter) || (state.Empresa.ufCidade == state.Venda.cliente.cidade.uf? state.Empresa.cfopesta : state.Empresa.cfopinter),
			});
		},


		atualizarCdIdentificador(state, payload) {
			state.Venda.cdIdentificador = payload;
		},

		filtrarVendas(state, payload) {
			const {textoPesquisa} = payload;
			if (textoPesquisa == null && state.VendasFiltradas.length < 1) {
				return state.VendasFiltradas = state.Vendas;
			}
			state.VendasFiltradas = state.Vendas.filter(
				venda => venda.cliente.nome.toUpperCase().includes(textoPesquisa.toUpperCase()) ||
				venda.nomeCliente.toUpperCase().includes(textoPesquisa.toUpperCase()));
		},

		// setClienteVenda(state, payload) {
		// 	state.Venda.nomeCliente = payload?.nomeCliente;
		// 	state.Venda.cliente = {
		// 		...state.Venda.cliente,
		// 		id: payload?.cdCliente ? payload.cdCliente : payload.id,

		// 		endereco: payload?.enderecoCliente,
		// 		bairro: payload?.bairroCliente,
		// 		cidade: {
		// 			nomecidade: payload?.cidadeCliente,
		// 			cdcidade: payload?.cdcidade,
		// 			codibge: payload?.codibge,
		// 			uf: payload?.ufCliente,
		// 		},
		// 		cep: payload?.cepCliente,
		// 		numero: payload?.numeroEndereco,


		// 		nome: payload?.nomeCliente,
		// 		fone: payload?.foneCliente,
		// 		dataNascimento: payload?.dataNascimento,
		// 		ierg: payload?.ierg,
		// 		cnpj: payload?.cnpjCliente,
		// 		tipoPessoa: payload?.tipoPessoa

		// 	};
		// }
	}
};
